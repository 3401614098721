import {CrudApiService} from "./common/crud.api.service";
import {PolicyType, IPolicyType} from "../model/policy-type";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";


@Injectable()
export class PolicyTypeService extends CrudApiService<PolicyType, IPolicyType, Partial<PolicyType>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/policyTypes");
    }

    protected assembleSingleInstance(_: any): PolicyType {
        return new PolicyType(_);
    }
}
