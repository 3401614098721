import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountWidgetModule } from '../account-widget/account-widget.module';
import { AuditSetupPageComponent } from './audit-setup-page/audit-setup-page.component';
import { CreateAndEditPolicyModalDialogComponent } from './create-and-edit-policy-modal-dialog/create-and-edit-policy-modal-dialog.component';
import { FacilitySelectorModule } from '../facility-selector/facility-selector.module';
import { TooltipModule } from 'primeng/tooltip';
import { SimpleModalModule } from 'ngx-simple-modal';
import { QuestionProgressIndicatorComponent } from './question-progress-indicator/question-progress-indicator.component';
import { QuestionComponent } from './create-and-edit-policy-modal-dialog/question/question.component';
import { BrowserModule } from '@angular/platform-browser';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import {PrintedFormComponent} from "./audit-setup-page/printed-form/printed-form.component";
import {PrintedPolicyComponent} from "./audit-setup-page/printed-policy/printed-policy.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    FacilitySelectorModule,
    AccountWidgetModule,
    TooltipModule,
    SimpleModalModule.forRoot({ container: document.body }),
    SharedComponentsModule,
  ],
  declarations: [
      AuditSetupPageComponent,
      CreateAndEditPolicyModalDialogComponent,
      QuestionComponent,      
      QuestionProgressIndicatorComponent,
      PrintedFormComponent,
      PrintedPolicyComponent
  ],
  entryComponents: [CreateAndEditPolicyModalDialogComponent],
})
export class AuditSetupModule { }
