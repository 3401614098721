
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LocalStorageManager, StorageKeys} from "./LocalStorageManager";
import {ConstantService} from "./constant-service";
import {Headers, Http, RequestOptions} from "@angular/http";
import {TokenService} from "./token.service";
import {Util} from "../../util/util";
import {HeaderConstants} from "../constants/HeaderConstants";
import {WebserviceUrl} from "./webservice-url";

const OAUTH_PLATFORM_TYPE_WEB = 'web';
const OAUTH_API_PATH = 'api/oauth';
const OAUTH_GET_TOKEN_PATH = `${OAUTH_API_PATH}/token`;
const OAUTH_REFRESH_TOKEN_PATH = `${OAUTH_API_PATH}/tokenRefresh`;

export type OAuthTokens = {
    access_token: string,
    refresh_token: string
}

interface AuthResponseData {
    oauthTokens: OAuthTokens;
    userProfileToken: string;
}

function isOAuthToken(x: any): x is OAuthTokens {
    if (typeof x.access_token === 'string' && typeof x.refresh_token === 'string') {
        return true;
    }
    return false;
}

//TODO: refactoring required
@Injectable()
export class OauthService {
    private localStorageManager: LocalStorageManager;
    private constantService: ConstantService;
    private http: Http;
    private tokenService: TokenService;

    public constructor(
        localStorageManager: LocalStorageManager,
        constantService: ConstantService,
        http: Http,
        tokenService: TokenService
    ) {
        this.localStorageManager = localStorageManager;
        this.constantService = constantService;
        this.http = http;
        this.tokenService = tokenService;
    }

    public getOAuthTokens(oAuthCode: string): Promise<void> {
        const request = {
            code: oAuthCode,
            platform: OAUTH_PLATFORM_TYPE_WEB
        };

        return this.getTokens(OAUTH_GET_TOKEN_PATH, request);
    }

    public refreshOAuthTokens(refreshToken: string): Promise<void> {
        const request = {
            refreshToken: refreshToken,
            platform: OAUTH_PLATFORM_TYPE_WEB
        };

        return this.getTokens(OAUTH_REFRESH_TOKEN_PATH, request);
    }

    private async getTokens(apiPath: string, request: any): Promise<void> {

        const tokenResponse: AuthResponseData = await this.doTokenRequest(apiPath, request);

        this.tokenService.set(tokenResponse.oauthTokens.access_token, tokenResponse.userProfileToken);
        const oauthTokens: OAuthTokens = tokenResponse.oauthTokens;
        this.localStorageManager.set(StorageKeys.OAUTH_ACCESS_TOKEN, oauthTokens.access_token);
        this.localStorageManager.set(StorageKeys.OAUTH_REFRESH_TOKEN, oauthTokens.refresh_token);

        const loggedUser = this.tokenService.getUserToken();

        if (Util.isDefinedAndNotNull(loggedUser)) {
            // TODO: update to work with new terms and conditions flow.
        }
    }

    public async tryRefreshStoredToken(): Promise<boolean> {
        const storedRefreshToken = this.localStorageManager.get(StorageKeys.OAUTH_REFRESH_TOKEN);
        if (!storedRefreshToken) {
            return false;
        }
        try {
            await this.refreshOAuthTokens(storedRefreshToken);
            return true;

        } catch (error) {
            // this is an expected case, since a refresh token can also expire
            return false;
        }
    }

    private doTokenRequest(path, args): Promise<AuthResponseData> {
        const url = WebserviceUrl.WEBSERVICE_URL + path;
        let headers = new Headers();
        //headers.append('Content-Type', 'application/json');
        headers.append('Content-Type', 'text/plain');
        const options = new RequestOptions({
            withCredentials: false,
            headers: headers
        });

        return new Promise((resolve, reject) => {
            this.http.post(url, JSON.stringify(args), options).pipe(
                map(res => {
                    console.log(res);
                    const oauthTokens = res.json();
                    const userProfileToken = res.headers.get(HeaderConstants.XAuthToken);
                    if (isOAuthToken(oauthTokens) && typeof userProfileToken === 'string') {
                        const out: AuthResponseData = {
                            oauthTokens,
                            userProfileToken
                        };
                        return out;
                    }
                    reject('invalid response from server.');
                }))
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    
                    console.log(error);
                    reject(error);
                });
        });
    }

}