import {
    Component,
    ElementRef,
    Renderer,
    ChangeDetectorRef,
    OnChanges,
    OnDestroy,
    ViewEncapsulation,
    forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LocaleService } from '../../../node_modules/mydatepicker/dist/services/my-date-picker.locale.service';
import { UtilService } from '../../../node_modules/mydatepicker/dist/services/my-date-picker.util.service';
import { MyDatePicker } from '../../../node_modules/mydatepicker/dist/my-date-picker.component';
import {
    IMyDate,
    IMyDateRange,
    IMyMonth,
    IMyCalendarDay,
    IMyCalendarMonth,
    IMyCalendarYear,
    IMyWeek,
    IMyDayLabels,
    IMyMonthLabels,
    IMyOptions,
    IMyDateModel,
    IMyInputFieldChanged,
    IMyCalendarViewChanged,
    IMyInputFocusBlur,
    IMyMarkedDates,
    IMyMarkedDate,
    IMyDateFormat,
} from '../../../node_modules/mydatepicker/dist/interfaces/index';
import { PEAK_DATE_PICKER_COMPONENT as UI_CONSTANTS } from '../shared/constants/uiConstants';

export const MYDP_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PeakDatePickerComponent),
    multi: true,
};

@Component({
    selector: 'peak-date-picker',
    templateUrl: './peak-date-picker.component.html',
    styleUrls: ['./peak-date-picker.component.scss'],
    providers: [LocaleService, UtilService, MYDP_VALUE_ACCESSOR],
})
export class PeakDatePickerComponent extends MyDatePicker implements OnChanges, ControlValueAccessor, OnDestroy {
    constructor(
        elem: ElementRef,
        renderer: Renderer,
        cdr: ChangeDetectorRef,
        localeService: LocaleService,
        utilService: UtilService,
        private thisUtilService: UtilService
    ) {
        super(elem, renderer, cdr, localeService, utilService);
    }

    public isDayCurrentlySelected(date: IMyCalendarDay): boolean {
        return (
            this.selectedDate.day === date.dateObj.day &&
            this.selectedDate.month === date.dateObj.month &&
            this.selectedDate.year === date.dateObj.year &&
            date.cmo === this.currMonthId
        );
    }

    public isDayInDisplayedMonth(date: IMyCalendarDay): boolean {
        return (
            date.cmo === this.currMonthId
        );
    }

    public isWeekPartOfCurrentMonth(week: IMyWeek): boolean {
        let hasFoundDayInCurrentMonth: boolean = false;
        week.week.forEach(date => {
            if (true == this.isDayInDisplayedMonth(date)) {
                hasFoundDayInCurrentMonth = true;
            }
        });
        return hasFoundDayInCurrentMonth;
    }

    public get labelForSelectedDate(): string {
        if (this.selectionDayTxt != null && this.selectionDayTxt != '') {
            return this.selectionDayTxt;
        } else {
            return UI_CONSTANTS.NULL_DATE_SELECTED;
        }
    }

    public setFocusToInputBox() {
        // This is intentionally a no-op to override the function in MyDatePicker.
        // Our date picker isn't supposed to have an input box so it doesn't exist
        // and  we can't set focus to it. 
    }
}
