
import { CommonModule } from '@angular/common';
import { SummaryReportsComponent } from './summary-reports.component';
import { HighchartsChartComponent, HighchartsChartModule } from 'highcharts-angular';
import {RouterModule} from '@angular/router';
import {SummaryReportsRoutes} from './summary-reports.route';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/primeng';
import { PeakDatePickerModule } from '../peak-date-picker/peak-date-picker.module';

//import { DashboardComponent } from './dashboard.component';
//import { DashboardService } from './dashboard.service';
import { ExcelService } from '../shared/service/report/excel.service';
import { PptService } from '../shared/service/report/ppt.service';
import { DialogModule } from 'primeng/primeng';
import { CalendarComponent } from '../calendar-date-picker/calendar-date-picker';
import { FacilitySelectorModule } from '../facility-selector/facility-selector.module';
import { AccountWidgetModule } from '../account-widget/account-widget.module';
import { SharedComponentsModule } from '../shared/components/shared-components.module';

import { ReportService } from '../shared/service/report/report.service';
import {ReportTypeService} from '../shared/api/report.type.service';
import {ReportDataService} from '../shared/api/report.data.service';
import { TooltipModule } from 'primeng/tooltip';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
//import { ExportAnalyticDataComponent } from "./export-analytic-data/export-analytic-data.component";





@NgModule({
  declarations: [SummaryReportsComponent,HighchartsChartComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(SummaryReportsRoutes, {useHash: true}),
      BrowserModule,
      FormsModule,
      OverlayPanelModule,
      PeakDatePickerModule,
      DialogModule,
      FacilitySelectorModule,
      AccountWidgetModule,
      SharedComponentsModule,
      TooltipModule,
      NgxDatatableModule
  ],
})
export class SummaryReportsModule { }
