import {Injectable} from '@angular/core';
import 'rxjs/Rx';
import {Http} from '@angular/http';
import {ConstantService} from '../../shared/service/constant-service';
import {TokenService} from '../../shared/service/token.service';
import {AbstractBackendService} from "../../shared/service/AbstractBackendService";
import {DataService} from "../../shared/service/data.service";

@Injectable()
export class PolicyListService extends AbstractBackendService {
  private dataService: DataService;
  constructor(constantService: ConstantService,
              http: Http,
              tokenService: TokenService,
              dataService: DataService,
  ) {
    super(constantService, http, tokenService);
    this.dataService = dataService;
  }

  public createFormattedPolicyJSON(policyJSON: any) {
    let auditPolicyValue = [];
    for (const key in policyJSON) {
      const element = policyJSON[key];
      if (element) {
        auditPolicyValue[element.questionId] = element.answerValue;
      }
    }
    return auditPolicyValue;
  }
}
