import { ComponentFactoryResolver, Injectable, Inject, ReflectiveInjector, ViewContainerRef, ComponentRef, EventEmitter } from '@angular/core';
import { PopupMenuFacilityComponent } from './popup-menu-facility.component';

@Injectable()
export class PopupMenuFacilityService {
    private componentRef: ComponentRef<PopupMenuFacilityComponent>;

    constructor(private factoryResolver: ComponentFactoryResolver) {}

    public openMenu(viewContainerRef: ViewContainerRef, options: string[], onOptionSelected: (number) => void) {
        const factory = this.factoryResolver.resolveComponentFactory(PopupMenuFacilityComponent);
        const component = factory.create(viewContainerRef.injector);
        
        viewContainerRef.insert(component.hostView);

        component.instance.options = options;
        component.instance.onOptionSelected = (indexSelected: number | null) => {
            component.destroy();
            if (indexSelected != null) {
                onOptionSelected(indexSelected);
            }
        }
    }
}
