import {Injectable} from "@angular/core";

import {HttpClient, HttpHeaders} from "@angular/common/http";

import {TokenService} from "../service/token.service";

import {LoginCredentials, User} from "../model/user";

import {Observable} from "rxjs";

import {HeaderConstants} from "../constants/HeaderConstants";

import {WebserviceUrl} from "../service/webservice-url";

import {Router} from "@angular/router";

import {Route} from "../constants";
 

@Injectable()

export class LoginService {

 private readonly loginPath = WebserviceUrl.WEBSERVICE_URL + "api/login";
 

 constructor(private http: HttpClient,

 private tokenService: TokenService,

 private router: Router) {

 }
 

 login(creds: LoginCredentials): Observable<User> {

 // this header required to omit sending OPTIONS request to api/login endpoint

 // this is because EP managed by filter on backend side and spring returns 404

 // issue should be fixed on backend side

 let header = new HttpHeaders({'Content-Type': 'text/plain'});

 var option = {headers: header};

 var data = Observable.create(observer => {

 this.http.post(this.loginPath, creds, {observe: "response", withCredentials: false, headers: header})

    .subscribe(resp => {

        var jsonObj = JSON.parse(JSON.stringify(resp.body)); //read user token
        var jwtToken = JSON.parse(jsonObj.oktaToken); //Read okta Token
        this.tokenService.set(jwtToken.AccessToken, jsonObj.result); //Modify function

        observer.next(this.tokenService.getUserToken());

    }, err => observer.error(err))
 });

  return data;
 }

 logout():void {

 this.tokenService.signOutAndClearUserData();
 this.router.navigate([Route.LOGIN]);

 }

}