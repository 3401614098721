import { Injectable } from '@angular/core';
import {ReportContext} from './report.context';
import { RawReport } from '../../model/raw-report';

import * as XLSX from 'xlsx';

@Injectable()
export class ExcelService {
    constructor() { }

    private static readonly fileExtension = '.xlsx';

    public exportReport(reportContext: ReportContext, fileName: string) {
        // Convert the data into an array of arrays of strings
        const rows: string[][] = [];
        rows.push(reportContext.data.header.map(cell => cell.value));
        for (const row of reportContext.data.rows) {
            rows.push(row.cells.map(cell => cell.value));
        }

        const workSheetTitle = reportContext.reportView != null ? reportContext.reportView.title : 'Report';

        this.exportStringTableToExcel(rows, workSheetTitle, fileName);
    }

    public exportRawData(rawReports: RawReport[], fileName: string, healthSystem? :string) {
        
        if (rawReports.length < 1) {
            return;
        }
        
        const rows: string[][] = [];

        const headerRow: string[] = [];
        const dataVerifyRow: string[]=[];

        // Make header row
        for (const key of Object.keys(rawReports[0])) {
            if (key !== 'values' && key !== 'side' && key !== "identifier" && this.isAnyOfKeyNotNull(rawReports, key)) {
                if(key == "facilityName"){
                    if(healthSystem == undefined)
                        headerRow.push("Facility Name");
                    else
                        headerRow.push("Health System Name");

                }
                else if(key == 'patientSequenceNumber')
                headerRow.push("Patient#");
                else if(key == 'catheterSequenceNumber')
                headerRow.push("Catheter#");
                else if(key == "unitName")
                headerRow.push("Unit Name");
                else if(key == "shift")
                headerRow.push("Shift");
                else if(key == "dateAndTime")
                headerRow.push("Date");
                //else if(key == "identifier")
                //console.log(key); 
                //headerRow.push("Item #");
                else
                headerRow.push(key);

                dataVerifyRow.push(key);
            }
        }
        if (rawReports[0].values != null) {
            for (const rawReportValue of rawReports[0].values) {                
                headerRow.push(rawReportValue.name);
            }    
        }

        if(rawReports[0].values != null && rawReports[0].values[0].code == "TwentyOneDayChallenge"){
            var temp = headerRow[headerRow.length-1];
            headerRow[headerRow.length-1] = headerRow[headerRow.length - 2];
            headerRow[headerRow.length-2] = temp;
        }

        rows.push(headerRow);

        // Make value row for each rawReport
        for (const rawReport of rawReports) {
            const row: string[] = [];
            var count:number=0;
            for (const key of Object.keys(rawReport)) {
                count=count + 1; 
                if (dataVerifyRow.includes(key)) {
                    if (rawReport[key] != null) {
                        if(healthSystem != undefined && count == 1)
                            row.push(healthSystem);
                        else
                            row.push(rawReport[key]);                                              
                        
                    } else {
                        row.push('');
                    }
                }
            }
            if (rawReport.values != null) {
                for (const rawReportValue of rawReport.values) {
                    if (rawReportValue.value != null ) {
                        row.push(rawReportValue.value.toString());
                    } else {
                        row.push('');
                    }
                }
            }

            if(rawReports[0].values != null && rawReports[0].values[0].code == "TwentyOneDayChallenge"){
                var temp = row[row.length-1];
                 row[row.length-1] = row[row.length - 2];
                 row[row.length-2] = temp;
            }

            rows.push(row);
        }

        // Create Excel file
        this.exportStringTableToExcel(rows, 'Raw Data', fileName);
    }

    private isAnyOfKeyNotNull(array: Array<any>, key: string) {
        for (const obj of array) {
            if (obj[key] != null) {
                return true;
            }
        }
        return false;
    }

    private exportStringTableToExcel(table: string[][], workSheetTitle: string, fileName: string) {
        // Create a new workbook and add a new sheet using the aoa_to_sheet exporter
        const workBook = XLSX.utils.book_new();
        const workSheet = XLSX.utils.aoa_to_sheet(table);

        XLSX.utils.book_append_sheet(workBook, workSheet, workSheetTitle);

        //add second worksheet with information
        if(workSheetTitle == 'Raw Data') {
            let info = [['Patient number and Catheter number are not identifiable information'], ['Patient number resets daily']];
            const infoWorkSheet = XLSX.utils.aoa_to_sheet(info);
            XLSX.utils.book_append_sheet(workBook, infoWorkSheet, 'PEAK Excel Report Information');
        }

        // Write out file
        XLSX.writeFile(workBook, fileName + ExcelService.fileExtension);
    }

    public exportAnalyticData(rawReports: any[], fileName: string) {
        if (rawReports.length < 1) {
            return;
        }

        const rows: string[][] = [];

        const headerRow: string[] = [];

        // Make header row
        for (const key of Object.keys(rawReports[0])) {
            if (key !== 'values' && key !== 'side' && this.isAnyOfKeyNotNull(rawReports, key)) {
                headerRow.push(key);
            }
        }
        if (rawReports[0].values != null) {
            for (const rawReportValue of rawReports[0].values) {
                headerRow.push(rawReportValue.name);
            }    
        }

        rows.push(headerRow);

        // Make value row for each rawReport
        for (const rawReport of rawReports) {
            const row: string[] = [];
            for (const key of Object.keys(rawReport)) {
                if (headerRow.includes(key)) {
                    if (rawReport[key] != null) {
                        row.push(rawReport[key]);
                    } else {
                        row.push('');
                    }
                }
            }
            if (rawReport.values != null) {
                for (const rawReportValue of rawReport.values) {
                    if (rawReportValue.value != null ) {
                        row.push(rawReportValue.value.toString());
                    } else {
                        row.push('');
                    }
                }
            }

            rows.push(row);
        }

        // Create Excel file
        this.exportStringTableToExcel(rows, 'Raw Data', fileName);
    }
}
