import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NgControl, NgForm } from '@angular/forms';
import { NotificationService } from '../../shared/service/notification.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { CREATE_AND_EDIT_UNIT_MODAL_DIALOG as UI_CONSTANTS } from '../../shared/constants/uiConstants';
import { AppBlurService } from '../../shared/service/app-blur.service';
import {Unit} from "../../shared/model/unit";

export enum CreateAndEditUnitModalDialogMode {
    Create,
    Edit,
    
}

export interface ICreateAndEditUnitModalDialog {
    mode: CreateAndEditUnitModalDialogMode;
    unitName;
    unit?: Unit;
    existingUnitsOfFacility: Unit[];
}

@Component({
    selector: 'app-create-and-edit-unit-modal-dialog',
    templateUrl: './create-and-edit-unit-modal-dialog.component.html',
    styleUrls: ['./create-and-edit-unit-modal-dialog.component.scss'],
})
// The first type of SimpleModalComponent is the input and the second is the result
export class CreateAndEditUnitModalDialogComponent
    extends SimpleModalComponent<ICreateAndEditUnitModalDialog, ICreateAndEditUnitModalDialog>
    implements ICreateAndEditUnitModalDialog, OnInit, AfterViewInit, OnDestroy {
    public unit: Unit;
    public unitName: string;
    public mode: CreateAndEditUnitModalDialogMode = CreateAndEditUnitModalDialogMode.Create;
    public UI = UI_CONSTANTS;
    public existingUnitsOfFacility: Unit[];

    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('unitNameInput') public unitNameInput: NgControl;

    constructor(private notificationService: NotificationService,
        private appBlurService: AppBlurService) {
        super();
    }

    public ngAfterViewInit() {
            this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }

    ngOnInit(): void {
        if (CreateAndEditUnitModalDialogMode.Edit == this.mode) {
            console.assert(
                undefined != this.unit,
                'CreateAndEditUnitModalDialogComponent#ngOnInit - mode is Edit but unit passed in was undefined'
            );

            this.unitName = this.unit.name;
            console.assert(
                this.formContainer != null,
                'CreateAndEditUnitModalDialogComponent#ngOnInit - this.formContainer was null'
            );
            console.assert(
                this.unitNameInput != null,
                'CreateAndEditUnitModalDialogComponent#ngOnInit - this.unitNameInput was null'
            );
        }
    }

    public get labelForSubmitButton(): string {
        switch (this.mode) {
            case CreateAndEditUnitModalDialogMode.Create:
                return UI_CONSTANTS.CREATE;
            case CreateAndEditUnitModalDialogMode.Edit:
                return UI_CONSTANTS.SAVE;
        }
        console.assert(
            false,
            'CreateAndEditUnitModalDialogComponent#labelForAffirmativeButton this.mode set to unknown value'
        );
    }

    public onSubmitPressed(): void {
        console.assert(
            this.formContainer != null,
            'CreateAndEditUnitModalDialogComponent#onSubmitPressed - this.formContainer was null'
        );
        if (this.doesUnitNameAlreadyExistForThisFacility(this.unitName)) {
             
            this.notificationService.error('', UI_CONSTANTS.ERRORS.UNIT_NAME_NOT_UNIQUE);
            return;
        }

        if (false == this.formContainer.form.valid) {
            this.handleFormErrors();
            return;
        }

        this.result = {
            mode: this.mode,
            unitName: this.unitName,
            existingUnitsOfFacility: this.existingUnitsOfFacility,
        };

        if (CreateAndEditUnitModalDialogMode.Edit == this.mode) {
            console.assert(
                undefined != this.unit,
                'CreateAndEditUnitModalDialogComponent#onAffirmitivePressed - mode is Edit but unit passed in was undefined'
            );
            this.unit.name = this.unitName;
            this.result.unit = this.unit;
        }

        this.close();
    }

    public get hasSubmitted(): boolean {
        console.assert(
            this.formContainer != null,
            'CreateAndEditUnitModalDialogComponent#hasSubmitted - this.formContainer was null'
        );

        
       
        return this.formContainer.submitted;
    }

    public shouldControlShowErrorStyle(control: NgControl) {
        console.assert(
            control != null,
            'CreateAndEditUnitModalDialogComponent#shouldControlShowErrorStyle - control argument was null'
        );

        //if (false === control.touched && false === this.hasSubmitted) {
        if (true === control.pristine && false === this.hasSubmitted) {
            
            return false;
        }

        if (control === this.unitNameInput) {  
                                         
            if (this.doesUnitNameAlreadyExistForThisFacility(this.unitName)) {
                 
                return true;
            }
            

        }

        return control.errors != null;
    }
    public get tooltipForUnitNameInput(): string {
        if (this.doesUnitNameAlreadyExistForThisFacility(this.unitName)) {
            return UI_CONSTANTS.ERRORS.UNIT_NAME_NOT_UNIQUE;
        } else {
            return '';
        }
    }

    private doesUnitNameAlreadyExistForThisFacility(UnitName: string): boolean {
        const trimmedName = UnitName.trim();
        
        return this.existingUnitsOfFacility.find(Unit => Unit.name === trimmedName) != null;
    }

    private handleFormErrors(): void {
        let areAllRequiredFieldsFilled: boolean = true;

        if (this.unitNameInput.errors && this.unitNameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
        }
       
    }
}
