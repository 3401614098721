import { Component } from '@angular/core';
import {EmitterService} from "../../shared/service/emitter.service";
import {EulaCopy, eulaCopy, PolicyCopy, policyLinkString, privacyPolicyCopy, eulaLinkString} from "./legalCopy";
import { trigger, style, animate, transition } from '@angular/animations';

export interface TermsAndConditionsEvent {
  status: boolean;
}

@Component({
  exportAs: 'terms-conditions',
  selector: 'terms-conditions',
  templateUrl: './terms.component.html',
  styleUrls: ['terms.scss'],
  animations: [
    trigger('opacityFade', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('0.2s', style({ opacity: 1 })),
        ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate('0.2s', style({ opacity: 0 })),
    ])])],
})
export class TermsComponent {
  public showTermsAndConditions: boolean = false;
  public showPrivacyPolicy: boolean = false;

  public eulaCopy: EulaCopy = eulaCopy;
  public eulaSections = Object.values(this.eulaCopy.sections);
  public policyLinkString = policyLinkString;

  public policyCopy: PolicyCopy = privacyPolicyCopy;
  public policySections = Object.values(this.policyCopy.sections);
  public eulaLinkString = eulaLinkString;

  private emitterService: EmitterService;
  
  constructor(
    emitterService: EmitterService,
  ) {
    this.emitterService = emitterService;
  }

  public onClickAgreeToTermsAndConditions() {
    this.emitterService.agreeToTermsAndConditions.emit({ status: true });
    this.showTermsAndConditions = false;
  }

  public onClickDoNothing() {
    this.showTermsAndConditions = false;
  }

  public closePrivacyPolicy() {
    this.showPrivacyPolicy = false;
  }

  public displayPrivacyPolicy() {
    this.showPrivacyPolicy = true;
  }

  public isString(x: any): boolean {
    return typeof x === 'string';
  }

  public isObject(x: any): boolean {
    return (null != x && typeof x === 'object');
  }

  /**
   * Specifically check if blurrgin is suported in the current host and if not
   * specify that we need additional css / settings.
   * 
   * TODO: (PeterStJ) we might want to extract this in a service, but as of 
   * now it is only required in this component thus its staying here.
   */
  public needsBlurSubstitute() {
    let needsit = false;
    document.body.style.filter = 'blur(40px)';
    if (document.body.style.filter === '') needsit = true;
    document.body.style.filter = '';
    return needsit;
  }
}