import {Component, OnInit} from '@angular/core';
import {OauthService} from "../shared/service/oauth.service";
import {extractQueryParams} from "../util/decodeQueryStringParams";
import {MESSAGES} from "../shared/constants";
import {TokenService} from "../shared/service/token.service";
import {NotificationService} from "../shared/service/notification.service";
import {UserService} from "../shared/api";

const MMM_OAUTH_LOGIN_CALLBACK_CODE_PARAM: string = 'code';

@Component({
  templateUrl: 'oauth.component.html',
})
export class OauthComponent implements OnInit {
  private oauthService: OauthService;
  private tokenService: TokenService;
  private notificationService: NotificationService;

  constructor(
    oauthService: OauthService,
    tokenService: TokenService,
    notificationService: NotificationService,
    private userService: UserService
  ) {
    this.oauthService = oauthService;
    this.tokenService = tokenService;
    this.notificationService = notificationService;
  }

  ngOnInit() {
    const queryString = window.location.search;
    const params = extractQueryParams(queryString);
    const code = params.get(MMM_OAUTH_LOGIN_CALLBACK_CODE_PARAM);
    if (code) {
      this.onOAuthCodeReceived(code)
    } else {
      this.tokenService.signOutAndClearUserData();
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, "invalid response from server.");
    }
  }

  private onOAuthCodeReceived(oAuthCode: string) {
    this.oauthService.getOAuthTokens(oAuthCode)
      .then(() => {
        this.userService.checkUserRoleAndRedirectToDefaultRoute();
      })
      .catch(() => {
        this.tokenService.signOutAndClearUserData();
        this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, "Error authenticating with server.");
      });
  }
}
