import { Component, ViewChild, ViewChildren, ViewContainerRef, QueryList, OnInit } from '@angular/core';
import { AUDIT_SETUP_PAGE_COMPONENT as UI_CONSTANTS } from '../../shared/constants/uiConstants';
import { MODAL_DIALOG_FADE_DURATION_MS } from '../../shared/constants/animationConstants';
import AbstractSkeletonLoader from '../../shared/animations/AbstractSkeletonLoader';
import { SimpleModalService } from 'ngx-simple-modal';
import {
    CreateAndEditPolicyModalDialogComponent,
    CreateAndEditPolicyModalDialogMode
} from '../create-and-edit-policy-modal-dialog/create-and-edit-policy-modal-dialog.component';
import { PopupMenuService } from '../../shared/components/popup-menu/popup-menu.service';
import { PolicyType } from '../../shared/model/policy-type';
import { Policy } from '../../shared/model/policy';
import { PolicyService } from '../../shared/api/policy.service';
import { PolicyTypeService } from '../../shared/api/policy.type.service';
import { PolicyFilterService } from '../../shared/api/policy.filter.service';
import { ErrorService } from '../../shared/service/error-service';
import { Facility } from '../../shared/model/facility';
import { DataService } from '../../shared/service/data.service';
import { PrintedFormComponent } from './printed-form/printed-form.component';
import { PrintedPolicyComponent } from './printed-policy/printed-policy.component';
import { FacilitySelectorComponent } from '../../facility-selector/facility-selector.component';
import { FacilityService, UserService } from '../../shared/api';
import { trigger, style, animate, transition } from '@angular/animations';
import { element } from '@angular/core/src/render3';
import { NotificationService } from '../../shared/service/notification.service';
import { HealthSystemService } from "../../shared/api";
import { HealthSystem } from '../../shared/model/health-system';
import { TokenService } from '../../shared/service/token.service';


export type AuditSetupPageTabs = 'Policies' | 'History' | 'Archive';

@Component({
    templateUrl: './audit-setup-page.component.html',
    styleUrls: ['./audit-setup-page.component.scss'],
    animations: [
        trigger('opacityFade', [
            transition(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
            transition(':leave', [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))])
        ])
    ],
    viewProviders: [PrintedFormComponent, PrintedPolicyComponent]
})
export class AuditSetupPageComponent extends AbstractSkeletonLoader implements OnInit {
    public UI = UI_CONSTANTS;
    public selectedTab: AuditSetupPageTabs = 'Policies';
    public activePolicy?: Policy;
    private _policies: Policy[] = [];
    private activeFacilityId?: number;
    public policyTypes?: PolicyType[];
    public printIndex:number;
    public policyIndex:number = 0;
    public disableAddMoreButton:boolean = false;
    public selectedPolicy:Policy;
    public policyTypesByPolicy:PolicyType[];
    public policiesHistory:any[];
    public policyIndexForPrint:Number=0;
    public healthSystem: HealthSystem []=[];
    public facility : Facility[];
    public selectForHealthSystem:boolean = false;
    public selectedHealthSystem:string = undefined;
    public isActivePoliceChanged: boolean = false;
    public facilities: Facility[] = [];
    
    

    @ViewChildren('printButton', { read: ViewContainerRef })
    printButtonViewContainerRefs: QueryList<ViewContainerRef>;
    @ViewChild('printActivePolicyButton', { read: ViewContainerRef })
    printActivePolicyButtonViewContainerRef: ViewContainerRef;

    constructor(
        private simpleModalService: SimpleModalService,
        private dropdownMenuService: PopupMenuService,
        private policyService: PolicyService,
        private facilityService: FacilityService,
        private policyTypeService: PolicyTypeService,
        private policyFilterService:PolicyFilterService, 
        private errorService: ErrorService,
        private dataService: DataService,
        private notificationService: NotificationService,
        private userService: UserService,
        private healthSystemService: HealthSystemService,
        private tokenService: TokenService
    ) {
        super();        
        this.updateData();
        this.loadHealthSystemData();
        this.loadFacility();
    }
    
    public printPolicyById(ID:Number) {
      //  this.print(PrintedPolicyComponent.DOM_ID);
  
        this.print('policy'+ID.toString());
       

    }
    public printPolicy() {
         // this.print(PrintedPolicyComponent.DOM_ID);
          
         if(this.isActivePoliceChanged)
            this.printPolicyById(this.indexOfActivePolicy);
        else
            this.print('policy'+this.indexOfActivePolicy.toString());    
        
      }
    public printFormById(ID:Number) {
      
        this.print(ID.toString());
 
        
    }  

    public sortPolicy(policies:Policy[]):Policy[]{

        
        return policies.sort((a, b) => a.name < b.name ? -1 : 1);

    
    }
    public sortPolicyHistory(policies:any[]):any[]{

        
        return policies.sort((a, b) => a.policyName < b.policyName ? -1 : 1);

    
    }


    public printForm() {
      //  this.print(PrintedFormComponent.DOM_ID);
        if(this.isActivePoliceChanged)
            this.printFormById(this.indexOfActivePolicy);
        else
            this.print(this.indexOfActivePolicy.toString());

    }
   

    private print(elementId: string): void {
        //console.log(elementId);        
        const divToPrint = document.getElementById(elementId);
       /*  if(undefined == divToPrint){
          this.notificationService.warn('','Not found valid policy');
        } */
        console.assert(
            undefined != divToPrint,
            'AuditSetupPageComponent#print - failed to find element using provided elementId: ' + elementId
        );

        const newWin = window.open();

        const printedFormHtml =
            '<html><head><style type="text/css" media="print"> @page { size: landscape; } @-moz-document url-prefix() { body { margin-top: 30%; margin-left: 0%; -moz-transform:rotate(-90deg); } } body { filter: progid:DXImageTransform.Microsoft.BasicImage(Rotation=3); } .selector:not(*:root), .logo-print{ margin-top: 3%; list-style-position: inside; } </style><style>.clearfix{clear:both;}.prt-lft{float: left;width: 33.33333%;}.text-center{text-aligh:center;}table{border-collapse: collapse;}#active td{border: 1px solid #ccc;padding-bottom:20px;}td.hide-next + td{border-left: none !important;}td.hide-next{border-right: none !important;}#active td {border: 1px solid #000;}#active tr th {border: 1px solid #000;}.prnt-wdth {width: 25% !important;float:left;margin-bottom:10px;}#policyQuestionsPrint #print-logo{float: left;}</style></head><body>' +
            divToPrint.innerHTML +
            '</body></html>';

        newWin.document.write(printedFormHtml);
        newWin.document.close();
        newWin.focus();
        newWin.print();

        // This delay allows the logo image time to load
       // setTimeout(() => {
        //    newWin.close();
       // }, 10);
    }

    public ngOnInit() {
        
        
        this.loadFacilities();
       
        //this.setActivePolicy();
       // this.loadPolicyHistory();
        //console.log(this.isFacilitySelected);
      
      
    }

    public get indexOfActivePolicy():number{   
           
        let i:number=0;
        for(let policy of this.sortPolicy(this.policies)){

            if(policy.name === this.selectedPolicy.name){

                return i;
            
            }
            i++;
        }
        //return 2;
    }

    public get tooltipForDisabledFeatureDueToFacilitySelection(): string {
        if (this.activeFacilityId == null || this.activeFacilityId == FacilitySelectorComponent.ALL_FACILITIES_ID) {
            return UI_CONSTANTS.SELECT_FACILITY_TO_USE_FEATURE;
        } else {
            return '';
        }
    }

    public get nullLabelForActivePolicySelector() {
        if (
            undefined != this.activeFacilityId &&
            this.activeFacilityId == FacilitySelectorComponent.ALL_FACILITIES_ID
        ) {
            return '';
        }

        return UI_CONSTANTS.NO_ACTIVE_POLICY_SELECTED;
    }

    public get isUserAdmin(): boolean {
       /*  console.log("User Permission:"+JSON.stringify(this.userService.isUserAdmin));*/
        if (
            undefined != this.activeFacilityId &&
            this.activeFacilityId != FacilitySelectorComponent.ALL_FACILITIES_ID
        ) {
            return this.userService.isUserAdminAtFacilityToEditAudit(this.activeFacilityId);
        } 
        //return this.userService.isUserAdmin;
    }
    public isUserAdminForSelectedFacility(facilityId:number): boolean {
        
            return this.userService.isUserAdminAtFacilityToEditAudit(facilityId);                     
      
     }

    public shouldShowPolicy(policy: Policy) {
        return policy != null && this.userService.canActiveUserViewFacility(policy.facilityId);
    }
    public get shouldDisablePrintButton():boolean{
        if (this.activeFacilityId == null || this.activeFacilityId == FacilitySelectorComponent.ALL_FACILITIES_ID) {
            return false;
        }else{
            return false;
        }

        
    }

    public updateData(): void {
        this.showSkeletonAfterDelay();
        const loadTasks: Promise<void>[] = [this.loadPolicies(), this.loadPolicyTypes(),this.loadPolicyHistory()];
        Promise.all(loadTasks).then(() => {
            this.hideSkeletonAndShowContent();
        });


    }
    public updateDataPrintForm(policyIndex:number): void {
        this.showSkeletonAfterDelay();
        const loadTasks: Promise<void>[] = [];
        Promise.all(loadTasks).then(() => {
            this.hideSkeletonAndShowContent();
          this.printFormById(policyIndex);
            
        });


    }
    public updateDataPrintPolicy(policyIndex:number): void {
         this.showSkeletonAfterDelay();
         const loadTasks: Promise<void>[] = [];
         Promise.all(loadTasks).then(() => {
             this.hideSkeletonAndShowContent();
           this.printPolicyById(policyIndex);
         });
 
 
     }


    public printFormIndex(policyIndex?:number):boolean{

        if(this.policyIndexForPrint == policyIndex ){
           
            return true;
        }    
        return false;
    }

    private setActivePolicy(): void {
        if (null == this.activeFacilityId || this.activeFacilityId == FacilitySelectorComponent.ALL_FACILITIES_ID) {
            this.activePolicy = undefined;
        } else {
            const activePolicy = this.policies.find(p => p.active);
            this.activePolicy = activePolicy;
           
          
        }

        
    }
    public get getActivePolicy():Policy{
      
        this.setActivePolicy();
        return this.activePolicy;     
       

    }
    
    private loadPolicies(): Promise<void> {
        return new Promise(resolve => {
            this.policyService.getAllEntities().subscribe(
            //this.policyService.getPolicyByIndex(this.policyIndex,facilId).subscribe(
                policies => {
                   /*  policies.forEach(data => {
                        this._policies.push(data);
                    }); */
                    this._policies=policies;
                    this.setActivePolicy();
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });
    }
    public addMore(){
        this.policyIndex++;
       // this.loadPolicies();
       this.updateData();
    }

    public get policiesForActivePolicySelector(): Policy[] {
        if (this.activeFacilityId == FacilitySelectorComponent.ALL_FACILITIES_ID) {
            return [];
            
        } else {
            return this.policies;
        }
    }

    public policiesFilterHealthSystem():Policy[]{

        if(this.selectForHealthSystem == true){
            return this.policies.filter(_=>_.healthSystemId != undefined);
        }
        //return this.policiesForActivePolicySelector;
        return this.policies;
    }

    public get policies(): Policy[] {
        if (FacilitySelectorComponent.ALL_FACILITIES_ID == this.activeFacilityId) {
            return this._policies.filter(policy => {
                return this.userService.canActiveUserViewFacility(policy.facilityId);
            });
        }

        return this._policies.filter(p => p.facilityId === this.activeFacilityId);
    }

    public policiesForFacilityId(facilityId: number | undefined): Policy[] {
        return this._policies.filter(policy => policy.facilityId === facilityId);
    }

    public get policyHistory(): any[] {
      //  console.log("PolicyHistory:"+JSON.stringify(this.policies.sort((a, b) => (a.numericDate < b.numericDate ? 1 : -1))));
        //return this.policies.sort((a, b) => (a.numericDate < b.numericDate ? 1 : -1));
        let policiesHistory= this.policiesHistory.filter(policy=>{

            for (let p of this.policies){
                if(p.id == policy.id)
                    return true;
            }
            return false;
            
        });
      
        
        return policiesHistory;
    }
    public loadPolicyHistory():Promise<void>{
        return new Promise(resolve => {

            this.policyService.getPolicyHistory().subscribe(
                policiesHistory => {
                    this.policiesHistory = policiesHistory;
                    
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });

    }

    private loadPolicyTypes(): Promise<void> {
        return new Promise(resolve => {
       // if(this.activeFacilityId == null || this.activeFacilityId == FacilitySelectorComponent.ALL_FACILITIES_ID){
            //this.policyFilterService.getPolicy(this.activeFacilityId).subscribe(
            this.policyTypeService.getAllEntities().subscribe(
                policyTypes => {
                    this.policyTypes = policyTypes;
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
       /*  }else{
            this.policyFilterService.getPolicy(this.activeFacilityId).subscribe(
                policyTypes => {
                    this.policyTypes = policyTypes;
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        } */
        });
    }
    private loadPolicyTypesByPolicyById(policy:Policy): Promise<void> {
        return new Promise(resolve => {
            this.policyFilterService.getPolicy(policy.facilityId).subscribe(
            //this.policyFilterService.getPolicyById(policy.id).subscribe(
                policyTypes => {
                    this.policyTypesByPolicy = policyTypes;
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        
        });
    }
    public getPolicyTypesByPolicy(policy:Policy):PolicyType[]{
        this.policyTypesByPolicy=[];
        this.loadPolicyTypesByPolicyById(policy);
        return this.policyTypesByPolicy;

    }


    public onPrintActivePolicyClicked() {
        this.policyIndexForPrint=this.indexOfActivePolicy;
        this.dropdownMenuService.openMenu(
            this.printActivePolicyButtonViewContainerRef,
            UI_CONSTANTS.PRINT_MENU_OPTIONS,
            indexSelected => {
                if (UI_CONSTANTS.PRINT_MENU_OPTIONS[indexSelected] === 'Policy') {
                    // TODO: use string enum to share definitions.
                   // this.printPolicy();
                   this.updateDataPrintPolicy(this.indexOfActivePolicy);
                 
                } else {
                   // this.printForm();
                  this.updateDataPrintForm(this.indexOfActivePolicy);
                
                }
            }
        );
    }

    public onSelectedActivePolicyChanged(selectedPolicy: Policy) {
        if (undefined != selectedPolicy) {
            this.selectedPolicy=selectedPolicy;
            this.setPolicyAsActive(selectedPolicy);
            this.setActivePolicy();
            this.isActivePoliceChanged= true;
            
        }
    }

    private setPolicyAsActive(policyToActivate: Policy) {
        return this.facilityService.activePolicy(policyToActivate.facilityId).subscribe(
            currentlyActivePolicy => {
                return this.policyService.updateEntity(currentlyActivePolicy.id, { active: false }).subscribe(
                    () => {
                        return this.policyService
                            .updateEntity(policyToActivate.id, { active: true })
                            .subscribe(() => {}, err => this.errorService.handleErrorStatusCode(err));
                    },
                    err => {
                        this.errorService.handleErrorStatusCode(err)
                    }
                );
            },
            err => {
                this.errorService.handleErrorStatusCode(err);
            }
        );
    }

    public selectTab(tab: AuditSetupPageTabs) {
        this.selectedTab = tab;
    }

    public isTabSelected(tab: AuditSetupPageTabs): boolean {
        return this.selectedTab === tab;
    }

    public get isFacilitySelected(): boolean {
        // TODO: update this to properly detect when the "all" option is selected in facilities
        // default location doesn't become null after it's been set. need to check in w/ Petar about this.
        if (
            undefined != this.dataService.defaultLocation &&
            typeof this.dataService.defaultLocation.id === 'number' &&
            this.dataService.defaultLocation.id !== FacilitySelectorComponent.ALL_FACILITIES_ID
        ) {
            return true;
        }
        return false;
    }

    public get shouldShowEmptyListIndicator(): boolean {
        return this.shouldShowContent && 0 == this.policies.length;
    }

    public onSelectedFacilityChanged(newFacility: Facility | undefined) {
        if (undefined != newFacility) {
            this.disableAddMoreButton = true;
            this.selectForHealthSystem = false;
            this.selectedHealthSystem = undefined;
            this.activeFacilityId = newFacility.id;
        } else {
            this.activeFacilityId = undefined;
        }
        this.updateData();
        this.setActivePolicy();
        
    }

    public onCreatePolicyPressed() {
        this.simpleModalService
            .addModal(
                CreateAndEditPolicyModalDialogComponent,
                {
                    mode: CreateAndEditPolicyModalDialogMode.Create,
                    existingPoliciesOfFacility: this.policiesForFacilityId(this.activeFacilityId),
                    activeFacilityId: this.activeFacilityId,
                    selectedHealthSystem: this.selectedHealthSystem,
                },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS
                }
            )
            .subscribe(() => {
                this.updateData();
            });
    }

    public getPolicyTypeNameById(policyTypeId: number): string {
        const policyType = this.policyTypes.find(p => policyTypeId === p.id);

        if (policyType != null) {
            return policyType.name;
        } else {
            console.assert(false, 'AuditSetupPageComponent#getPolicyTypeNameById - invalid policyType id');
            return UI_CONSTANTS.UNKNOWN;
        }
    }

    public getPolicyTypeDescriptionById(id: number): string {
        const policyType = this.policyTypes.find(p => id === p.id);

        if (policyType != null) {
            return policyType.description;
        } else {
            console.assert(false, 'AuditSetupPageComponent#getAuditDescriptionById - invalid policyType id');
            return UI_CONSTANTS.UNKNOWN;
        }
    }

    public onPrintButtonClicked(associatedPolicyIndex: number) {    
        
        this.policyIndexForPrint=associatedPolicyIndex;
        //this.updateDataPrint();        
        this.dropdownMenuService.openMenu(
            this.printButtonViewContainerRefs.toArray()[associatedPolicyIndex],
            UI_CONSTANTS.PRINT_MENU_OPTIONS,
            indexSelected => {
                this.printIndex=associatedPolicyIndex;
                if (UI_CONSTANTS.PRINT_MENU_OPTIONS[indexSelected] === 'Policy') {
                    // TODO: use string enum to share definitions.
                    this.updateDataPrintPolicy(associatedPolicyIndex);
                   
                } else {
                    this.updateDataPrintForm(associatedPolicyIndex);
                   
                }
            }
        );
    }

    public onCopyButtonClicked(policy: Policy) {
        //console.log(JSON.stringify(this.policiesForFacilityId(policy.facilityId)));
        this.simpleModalService
            .addModal(
                CreateAndEditPolicyModalDialogComponent,
                {
                    mode: CreateAndEditPolicyModalDialogMode.Edit,
                    policy,
                    existingPoliciesOfFacility: this.policiesForFacilityId(policy.facilityId),
                    selectedHealthSystem: this.selectedHealthSystem,
                },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS
                }
            )
            .subscribe(() => {
                this.updateData();
            });
    }

    public get tooltipForNoPermission(): string {
       
        if (false == this.isUserAdmin) {
            return UI_CONSTANTS.NO_PERMISSION;
        } else {
            return '';
        }
    }
    public sortByPolicyName(policies:Policy[]):Policy[]{
           
        return policies.sort((a, b) => a.name < b.name ? -1 : 1);
        
    }
    public getNameOfPolicy(policy:Policy):string{

        if(policy.healthSystemId != undefined){

            return policy.name +" - "+ this.healthSystem.find(_=>_.id == policy.healthSystemId).name; 
        }

        return policy.name;
    }

    public loadHealthSystemData(): void {
        //this.showSkeletonAfterDelay(10);
        //
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
           // this.hideSkeletonAndShowContent();
          // this.shouldBlurComponent = false;
        });
        

    }
    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
             .subscribe((healthSystem) => {
                           
                 var hSName: HealthSystem[]=[];   
                 var jsonText = JSON.stringify(healthSystem);             
                 var obj =  JSON.parse(jsonText);
                 var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                       
              
                 arr.forEach(a=>{
                                   
                     hSName.push({
                         id:Number(a.id),
                         name:a.name,
                     });
         
                })  
                this.healthSystem = hSName;
                /*if(this.isUserAdmin()){
                    console.log("User1:"+this.userService.getPrimaryRoleForCurrentUser()); 
                    this.healthSystem = hSName;
                }else{
                    if(this.userService.getCurrentLoggedUser().healthSystemIdList != undefined){
                        this.healthSystem = hSName.filter(_=>{
                            return this.userService.getCurrentLoggedUser().healthSystemIdList.includes(_.id);
                        });
                    }
                }    
                */
         
            });
        });         
  
    }
 
    public checkHealtSystem(){
             
        this.selectForHealthSystem ? (this.selectForHealthSystem = false) : (this.selectForHealthSystem = true);
        let facility:Facility[];
        
        if(this.selectForHealthSystem == true){

           this.policiesFilterHealthSystem();
           let facility:Facility = this.facility.find(_=>_.id == this.activeFacilityId);

           this.selectedHealthSystem = this.healthSystem.find(_hs=>_hs.id == facility.healthSystemId).name; 

           console.log("facility: " + JSON.stringify(this.facility.find(_=>_.id == this.activeFacilityId)));
  
             
        }else{

            this.selectedHealthSystem = undefined;
        }       

    }
    private loadFacility(): Promise<void> {
        return new Promise(resolve => {
            this.facilityService.getAllEntities().subscribe(           
                _ => {                  
                    this.facility=_;
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });
    }

    public isFacilityInHealthSystem():boolean{
     
        if(this.activeFacilityId == undefined || this.activeFacilityId == 0){
            return false;
        } 
        let facility:Facility = this.facility.find(_=>_.id == this.activeFacilityId);
        if(facility.healthSystemId == undefined){
            return false;
        }else{
            let facilitesInHealthSystem = this.facilities.filter(f=>f.healthSystemId == facility.healthSystemId);
            let status:boolean = true;
           //console.log(facilitesInHealthSystem);
            facilitesInHealthSystem.forEach(f=>{
               //console.log('Facility:'+f.name);
               if(!this.userService.isUserAdminAtFacilityToEditAudit(f.id)){
                   status= false;
                   //console.log(status+":"+f.name);
               }
            });        
   
               return status;
   
        }
       
    }
    private loadFacilities(){

 
         this.facilityService.getAll().subscribe(_ => {
             const active = _.filter(_ => _.active);
             this.facilities =active;
         });
 
 
 
     }
 
   

}
