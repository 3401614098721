import {QuestionAnswer, IQuestionAnswer,AnswerType } from './question-answer';
import { QuestionCondition, IQuestionCondition } from './question-condition';

export enum InputType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    RADIO_GROUP = 'RADIO_GROUP',
    CHECKBOX = 'CHECKBOX',
}

export function isInputType(x: any): x is InputType {
    const options: InputType[] = [
        InputType.TEXT,
        InputType.NUMBER,
        InputType.BOOLEAN,
        InputType.RADIO_GROUP,
        InputType.CHECKBOX,
    ];
    if (options.indexOf(x) !== -1) {
        return true;
    }
    return false;
}

export enum QuestionType {
    AUDIT = 'AUDIT',
    POLICY = 'POLICY',
}

export function isQuestionType(x: any): x is QuestionType {
    const options: QuestionType[] = [
        QuestionType.AUDIT,
        QuestionType.POLICY,
    ];
    if (options.indexOf(x) !== -1) {
        return true;
    }
    return false;
}

export interface IQuestion {
    id?: number;
    answers: Array<IQuestionAnswer>;
    code: string;
    conditions?: Array<IQuestionCondition>;
    disclaimer?: string;
    inputType: InputType;
    questionGroupId?: number;
    title: string;
    type: QuestionType;
}

export class Question implements IQuestion {
    id?: number;
    title: string;
    code: string;
    inputType: InputType;
    type: QuestionType;
    disclaimer?: string;
    questionGroupId?: number;
    answers: Array<QuestionAnswer>;
    conditions?: Array<QuestionCondition>;

    constructor(args: IQuestion) {

        console.assert(args !== undefined && args !== null, 'question constructor passed null or undefined args object');
        console.assert(Array.isArray(args.answers), 'question constructor passed args with invalid value');
        console.assert(typeof args.code === 'string', 'question constructor passed args with invalid value');
        if (args.conditions !== undefined && args.conditions !== null) {
            console.assert(Array.isArray(args.conditions), 'question constructor passed args with invalid value');
        }
        if (args.disclaimer !== undefined && args.disclaimer !== null) {
            console.assert(typeof args.disclaimer === 'string', 'question constructor passed args with invalid value');
        }
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'question constructor passed args with invalid value');
        }
        if (args.questionGroupId !== undefined && args.questionGroupId !== null) {
            console.assert(typeof args.questionGroupId === 'number', 'question constructor passed args with invalid value');
        }
        console.assert(typeof args.title === 'string', 'question constructor passed args with invalid value');

        console.assert(isQuestionType(args.type), 'question constructor passed args with invalid question type');
        console.assert(isInputType(args.inputType), 'question constructor passed args with invalid inputType');

        this.id = args.id || undefined;
        this.answers = args.answers.map(_ => new QuestionAnswer(_));
        this.conditions = args.conditions.map(_ => new QuestionCondition(_)) || undefined;
        this.code = args.code;
        this.disclaimer = args.disclaimer || undefined;
        this.inputType = args.inputType;
        this.questionGroupId = args.questionGroupId || undefined;
        this.title = args.title;
        this.type = args.type;
    } 

    public getFirstAnswerOfType(answerType: AnswerType): QuestionAnswer {        
        return this.answers.find(answer => answer.type === answerType);
    }
    
}
