import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DataService} from '../shared/service/data.service';
import {NotificationService} from '../shared/service/notification.service';
import {FACILITY_SELECTOR_COMPONENT as UI_CONSTANTS} from '../shared/constants/uiConstants';
import {FacilityService} from "../shared/api/facility.service";
import {Facility} from "../shared/model/facility";
import { HealthSystemService } from '../shared/api';
import { HealthSystem } from '../shared/model/health-system';

@Component({
    selector: 'facility-selector',
    templateUrl: './facility-selector.component.html',
    styleUrls: ['./facility-selector.component.scss'],
})
export class FacilitySelectorComponent implements OnInit {
    public facilityList?: Facility[];
    public selectedFacility?: Facility;
    public shouldShowDropdown: boolean = false;
    public searchText: string = '';
    public UI_CONSTANTS = UI_CONSTANTS;
    public healthSystem: HealthSystem []=[];

    @Output() onSelectedFacilityChanged = new EventEmitter<Facility | undefined>();

    private hasLoaded: boolean = false;
    private allFacilitiesItem: Facility;

    public static readonly ALL_FACILITIES_ID: number = 0;

    //prettier-ignore
    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private facilityService: FacilityService,
        private healthSystemService: HealthSystemService
    ) {
        this.dataService = dataService;
        this.notificationService = notificationService;
        this.allFacilitiesItem = new Facility({
            id: FacilitySelectorComponent.ALL_FACILITIES_ID,
            location: {
                city: '',
                state: '',
                zip: ''
            },
            name: this.UI_CONSTANTS.ALL_FACILITIES,
            active: true
        });
    }

    public ngOnInit() {
        this.loadFacilitiesFromBackendForCurrentUser();
        this.updateData();
        
    }

    public loadFacilitiesFromBackendForCurrentUser() {
        this.facilityService.getAllEntities().subscribe((list: Array<Facility>) => {
            this.hasLoaded = true;

            const activeFacilities = list.filter(f => f.active);
            if (activeFacilities.length == 0) {
                // NL - This probably shouldn't be here but where should it be instead?
                this.notificationService.info(
                    UI_CONSTANTS.NO_FACILITIES_EXIST_NOTIFICATION.TITLE,
                    UI_CONSTANTS.NO_FACILITIES_EXIST_NOTIFICATION.MESSAGE
                );
                this.facilityList = [];
            } else {
                this.facilityList = [this.allFacilitiesItem, ...activeFacilities];
                if (!this.dataService.defaultLocation) {
                    // In the event the facilityList has a length of two, just select the one facility in there.
                    // This probably means you're an auditor and you are probably only on one facility.
                    if (this.facilityList.length === 2) {
                        this.setSelectedFacility(this.facilityList[1]);
                    } else {
                        this.setSelectedFacility(this.facilityList[0]);
                    }
                } else {
                    if (undefined !== this.dataService.defaultLocation) {
                        const newSelectedFacility = this.facilityList.find(facility => facility.id == this.dataService.defaultLocation.id);
                        this.setSelectedFacility(newSelectedFacility);
                    }
                }
            }
        });
    }

    public setSelectedFacility(facility: Facility) {
        this.selectedFacility = facility;
        if (this.selectedFacility) {
            // NL - This functionality in DataService probably should be refactored into FacilitySelectorService or something.
            //  Probably FacilitySelectionService if we actually support an "All" selection or multiple selected facilities.
            this.dataService.defaultLocation = this.selectedFacility;
        }
        this.onSelectedFacilityChanged.emit(facility);
    }

    public onSelectFacilityFromUserInterface(facility: Facility) {
        this.shouldShowDropdown = false;
        this.setSelectedFacility(facility);
    }

    public setShouldShowDropdown(shouldShow: boolean) {
        this.shouldShowDropdown = shouldShow;
    }

    public getCurrentlySelectedFacilityName() {
        if (this.selectedFacility != null) {
            //return this.selectedFacility.name;
            return this.getFacilityName(this.selectedFacility);
        } else {
            if (!this.hasLoaded) {
                return UI_CONSTANTS.LOADING;
            } else if (this.facilityList && this.facilityList.length > 0 && this.selectedFacility == undefined) {
                return UI_CONSTANTS.ALL_FACILITIES;
            } else {
                return UI_CONSTANTS.NO_FACILITIES_AVAILABLE;
            }
        }
    }
    public getFacilityName(facility:Facility):string{
        if(facility.healthSystemId !=undefined){
            return facility.name+" - "+this.getNameOfHealthSystem(facility.healthSystemId);
        }
        return facility.name;

    }
    public updateData(): void {
        //this.showSkeletonAfterDelay(10);
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
           // this.hideSkeletonAndShowContent();
        });

    }
    
    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
             .subscribe((healthSystem) => {
                           
                 var hSName: HealthSystem[]=[];   
                 var jsonText = JSON.stringify(healthSystem);             
                 var obj =  JSON.parse(jsonText);
                 var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));                          
            
                 arr.forEach(a=>{  
                     hSName.push({
                         id:Number(a.id),
                         name:a.name,
                     });   
                })  
                this.healthSystem = hSName;
         
            });
        });          
  
    }
    public getNameOfHealthSystem(healthSystemId:number):string{

        return this.healthSystem.find(_=>_.id == healthSystemId).name;

    }
}
