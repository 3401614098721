import { Component, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { NgForm, NgControl } from '@angular/forms';
import { ErrorService } from '../../shared/service/error-service';
import { NotificationService } from '../../shared/service/notification.service';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT as UI_CONSTANTS } from '../../shared/constants/uiConstants';
import { AppBlurService } from '../../shared/service/app-blur.service';
import { TwoChoiceModalDialogComponent } from '../../shared/components/two-choice-modal-dialog/two-choice-modal-dialog.component';
import { MODAL_DIALOG_FADE_DURATION_MS } from '../../shared/constants/animationConstants';
import {PolicyTypeService} from '../../shared/api/policy.type.service';
import {PolicyType} from '../../shared/model/policy-type';
import {IPolicy, IPolicyQuestionAnswer, Policy, PolicyQuestionAnswer,PolicyCuatomQuestion} from '../../shared/model/policy';
import {Question, QuestionType, InputType} from '../../shared/model/question';
import {sortByQuestionCode} from '../../util/alphaNumericSort';
import {QuestionResponse, QuestionResponseChangeEvent} from './question/question.component';
import {FacilityService, PolicyService, HealthSystemService} from '../../shared/api';
import {DataService} from '../../shared/service/data.service';
import {AnswerType, QuestionAnswer} from '../../shared/model/question-answer';
import { unix } from 'moment';
import { unescapeIdentifier } from '@angular/compiler';
import {Facility} from '../../shared/model/facility';
import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { HealthSystem } from '../../shared/model/health-system';
import { Router } from '@angular/router';
import { TokenService } from '../../shared/service/token.service';
import { DATA_ENTRY_PAGE } from '../../shared/constants/uiConstants';
import { Route } from '../../shared/constants';

export enum CreateAndEditPolicyModalDialogMode {
    Create,
    Edit,
}
export interface ICreateAndEditPolicyModalDialog {
    mode: CreateAndEditPolicyModalDialogMode;
    policy?: Policy;
    existingPoliciesOfFacility: Policy[];
    activeFacilityId?:number;
    selectedHealthSystem:string;
}

export type CreateAndEditPolicyModalDialogStage = 'NameAndType' | 'Settings' | 'Questions' | 'Summary';

const DEFAULT_MIN_COMPLIANCE_RATE_YELLOW: number = 75;
const DEFAULT_MIN_COMPLIANCE_RATE_GREEN: number = 85;

@Component({
    selector: 'app-create-and-edit-policy-modal-dialog',
    templateUrl: require('./create-and-edit-policy-modal-dialog.component.html'),
    styleUrls: [require('./create-and-edit-policy-modal-dialog.component.scss')],

})
export class CreateAndEditPolicyModalDialogComponent
    extends SimpleModalComponent<ICreateAndEditPolicyModalDialog, {}>
    implements ICreateAndEditPolicyModalDialog, AfterViewInit, OnDestroy {
    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('policyNameInput') public policyNameInput: NgControl;
    @ViewChild('auditTypeSelect') public auditTypeSelect: NgControl;
    @ViewChild('minimumComplianceRateYellowInput') public minimumComplianceRateYellowInput: NgControl;
    @ViewChild('minimumComplianceRateGreenInput') public minimumComplianceRateGreenInput: NgControl;
    @ViewChild('healthSystemCheckInput') public healthSystemCheckInput: NgControl;
   
    

    public mode: CreateAndEditPolicyModalDialogMode = CreateAndEditPolicyModalDialogMode.Create;
    public policy?: Policy;
    public existingPoliciesOfFacility: Policy[];
    public policyName: string = '';
    public UI = UI_CONSTANTS;
    public policyTypes: Array<PolicyType> = [];
    public selectedPolicyType?: PolicyType;
    public shouldBlurComponent: boolean = false;
    public showCustomQuestion: boolean = false;
    public customQuestionButtonLabel: string = "Add Question";
    public customQuestions =[];
    public customQuestionCount: number = 0;
    public selectedQuestionType: number = 0;
    public selectedPatientCatheter: number = 0;
    public selectForHealthSystem:boolean;
    public selectedHealthSystem:string;
    public healthSystem:HealthSystem[] = [];
    public healthSystemValues=[];
    public facility:Facility[];
    public activeFacilityId:number;


    // maps question.id to QuestionResponse objects for each selected answer
    public responses: Map<number, QuestionResponse[]> = new Map<number, QuestionResponse[]>();
    public selectedAnswers: Map<number, QuestionResponse> = new Map<number, QuestionResponse>();

    public currentStage: CreateAndEditPolicyModalDialogStage = 'NameAndType';
    public currentQuestionIndex: number = 0;

    private shouldDisableSubmitButton: boolean = false;

    private onNextSubmitPolicy: boolean = false;

    private customQuestionChecked: boolean = false;
    public complainceRateCheckYellow: boolean = false;
    public complainceRateCheckGreen: boolean = false;
    public complainceRateValueIsNull: boolean = false;
    public isFreeFormValueIsNull: boolean = false;
    public tooltipForminimumComplianceRateGreenInput:string = "";
    public tooltipForminimumComplianceRateYellowInput:string = "";


    private _minimumComplianceRateYellow: number = DEFAULT_MIN_COMPLIANCE_RATE_YELLOW;
    private _minimumComplianceRateGreen: number = DEFAULT_MIN_COMPLIANCE_RATE_GREEN;
    private _newAnswersSummary: Array<QuestionResponse[][]>| null = null;

    public set minimumComplianceRateYellow(x: number | null) {
        if (typeof x !== 'number') {
            return;
        } else if (x < 0) {
            this._minimumComplianceRateYellow = 0;
        } else if (x > 100) {
            this._minimumComplianceRateYellow = 100;
        } else {
            this._minimumComplianceRateYellow = x;
        }
    }
    public set minimumComplianceRateGreen(x: number | null) {
        if (typeof x !== 'number') {
            return;
        } else if (x < 0) {
            this._minimumComplianceRateGreen = 0;
        } else if (x > 100) {
            this._minimumComplianceRateGreen = 100;
        } else {
            this._minimumComplianceRateGreen = x;
        }
    }
    public get minimumComplianceRateYellow(): number {
        return this._minimumComplianceRateYellow;
    }
    public get minimumComplianceRateGreen(): number {
        return this._minimumComplianceRateGreen;
    }

    constructor(
        private errorService: ErrorService, 
        private dataService: DataService,
        private notificationService: NotificationService,
        private appBlurService: AppBlurService,
        private simpleModalService: SimpleModalService,
        private policyTypeService: PolicyTypeService,
        private policyService: PolicyService,
        private facilityService: FacilityService,
        private healthSystemService: HealthSystemService,
        private router: Router,
        private tokenService: TokenService,
    ) {
        super();
        
       /* if(this.selectedHealthSystem == undefined){
            this.selectForHealthSystem = false;
        }else{
            this.selectForHealthSystem = true;
            console.log("Selected HealthSystem:"+this.selectedHealthSystem);
        }
        */
        this.loadPolicyTypes();
        this.loadFacility();
        this.loadHealthSystem();

       
    }

    private doesPolicyNameAlreadyExistForThisFacility(policyName: string): boolean {
        const trimmedName = policyName.trim();
        return this.existingPoliciesOfFacility.find(policy => policy.name === trimmedName) != null;
    }

    private getUniqueNameBasedOnName(policyName: string) {
        const trimmedName = policyName.trim().replace(/( Copy\s*\d*)$/g, '');
        let copyNumber: number = 0;
        let potentialUniqueName: string;
        let iterationFailSafe: number = 0;
        const maxIterations = 10000;
        do {
            iterationFailSafe++;
            if (copyNumber === 0) {
                potentialUniqueName = `${trimmedName} Copy`;
            } else {
                potentialUniqueName = `${trimmedName} Copy ${copyNumber + 1}`;
            }
            copyNumber++;
        } while (this.doesPolicyNameAlreadyExistForThisFacility(potentialUniqueName) && iterationFailSafe < maxIterations);

        if (iterationFailSafe >= maxIterations) {
            console.assert(false, 'CreateAndEditPolicyModalDialogComponent#getUniqueNameBasedOnName - maxIterations reached.');
        }

        return potentialUniqueName;
    }

    private loadPolicyToEdit(): void {
        console.assert(this.policyTypes.length > 0, 'CreateAndEditPolicyModalDialogComponent#loadPolicyToEdit - no policyTypes available');
        if (undefined == this.policy) {
            console.assert(false, 'CreateAndEditPolicyModalDialogComponent#loadPolicyToEdit - no policy provided to dialog in edit mode');
            return;
        }
        this._minimumComplianceRateGreen = this.policy.settings.minGreenRate;
        this.minimumComplianceRateYellow = this.policy.settings.minYellowRate;
        this.policyName = this.getUniqueNameBasedOnName(this.policy.name);
        //this.policyName = this.getUniqueNameBasedOnName(this.policy.name);
        this.selectedPolicyType = this.policyTypes.find(t => t.id == this.policy.policyTypeId);
        if(this.policy.healthSystemId != undefined){
            this.selectForHealthSystem = true;
            this.healthSystem.forEach(a=>{

                if(Number(a.id)==this.policy.healthSystemId){

                    this.selectedHealthSystem=a.name;
                }
            });

        }

        if (undefined != this.selectedPolicyType) {

            const answersMap: Map<number, PolicyQuestionAnswer> = new Map<number, PolicyQuestionAnswer>();
            for (const answer of this.policy.answers) {
                answersMap.set(answer.questionAnswerId, answer);
            }

            //const customQuestionsMap: Map<number, PolicyCuatomQuestion> = new Map<number, PolicyCuatomQuestion>();
            //console.log(JSON.stringify(this.policy.customQuestions));
            for (const customQuestion of this.policy.customQuestions) {
                //answersMap.set(answer.questionAnswerId, answer);
                
                this.customQuestions.push(customQuestion);
                this.customQuestionCount ++;
                this.customQuestionButtonLabel = "Add Question";
                //customQuestionsMap.set(customQuestion.id,customQuestion);
                
            }
            //console.log(JSON.stringify(customQuestionsMap));


            for (const question of this.selectedPolicyType.questions) {
                for (const questionAnswer of question.answers) {
                    const policyAnswer: PolicyQuestionAnswer = answersMap.get(questionAnswer.id);
                    if (policyAnswer) {
                        const questionResponses = this.responses.get(question.id) || [];
                        const response: QuestionResponse = {
                            question,
                            answer: questionAnswer,
                            freeText: questionAnswer.type == AnswerType.FREE_FORM ? policyAnswer.answerValue : undefined,
                        };
                        questionResponses.push(response);
                        this.responses.set(question.id, questionResponses);
                        this.selectedAnswers.set(questionAnswer.id, response);
                    }
                }

                
            }
            
        }

        
        
        //this.customQuestions.push({"QuestionText":"Custom Question 2112","AnswerType":0,"ForEveryPatientOrCatheter":1});   
        //console.log("Getting Custom Question")
        //console.log(JSON.stringify(this.customQuestions));
        //this.showCustomQuestion = true;
        //this.customQuestions = [{"questionText":"Custom Question 2112","answerType":0,"forEveryPatientOrCatheter":1},{"questionText":"Custom Question 21123","answerType":0,"forEveryPatientOrCatheter":1}];
    }

    private loadPolicyTypes(): void {
        this.policyTypeService.getAllEntities().subscribe(policyTypes => {
            this.policyTypes = policyTypes;
            
            if (this.mode === CreateAndEditPolicyModalDialogMode.Edit) {
                this.loadPolicyToEdit();
            }
        }, err => {
            this.errorService.handleErrorStatusCode(err);
        });
    }

    public ngAfterViewInit() {
        this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }

    public get answersSummary(): QuestionResponse[][] {
        const out: QuestionResponse[][] = [];
        this.enabledQuestions.forEach((question: Question) => {
            const questionResponses = this.responses.get(question.id);            
            if (questionResponses) {
                out.push(questionResponses);
                
            }
        });
       // console.log(JSON.stringify(this.customQuestions));
        return out;
    }
    public get newAnswersSummary(): Array<QuestionResponse[][]> {
        //return this.answersSummary;
         let responseWithCQ:Array<QuestionResponse[][]>=[];
        var newResponse = this.answersSummary;
            if(newResponse == undefined || newResponse.length == 0){
                newResponse = [];
            }        
            var count = 1;
          this.customQuestions.forEach(ele => {
            let element: PolicyCuatomQuestion = ele;
            let cqQuesArray : QuestionResponse[] = [];
            let cqAnsArr : QuestionAnswer[] = [];
            const cqAns1 : QuestionAnswer = {
                code: "",
                label: element.questionText,
                showAuditSetupWarning: false,
                type: AnswerType.NOT_APPLICABLE,
                
            };
            const cqAns2 : QuestionAnswer = {
                code: "",
                label: "How often should the question be asked?",
                showAuditSetupWarning: false,
                type: AnswerType.NOT_APPLICABLE,
                
            };
            const cqAns3 : QuestionAnswer = {
                code: "",
                label: element.forEveryPatientOrCatheter == 0 ? "Once per Patient": "Once per Line",
                showAuditSetupWarning: false,
                type: AnswerType.NOT_APPLICABLE,
                
            };

            cqAnsArr.push(cqAns1);
            cqAnsArr.push(cqAns2);
            cqAnsArr.push(cqAns3);

            const cqQues1: Question = {
                title : "Custom Question "+ count,
                answers: cqAnsArr,
                code : "",
                inputType: InputType.TEXT,
                type : QuestionType.POLICY,
                getFirstAnswerOfType : null
            };
            const cqResp : QuestionResponse = {
                answer: cqAns1 ,
                question: cqQues1,
                index: count.toString() 
            }

            const cqResp1 : QuestionResponse = {
                answer: cqAns2 ,
                question: cqQues1,
                index: count.toString() 
            }

            const cqResp2 : QuestionResponse = {
                answer: cqAns3 ,
                question: cqQues1,
                index: count.toString() 
            }
            cqQuesArray.push(cqResp);
            cqQuesArray.push(cqResp1);
            cqQuesArray.push(cqResp2);

            newResponse.push(cqQuesArray);
            count++;
            });

           // console.log(count);
        let arr:QuestionResponse[][];
        for(var i=0;i<newResponse.length;i++){
            newResponse[i][0].index=(i+1).toString();
            
            if(i%2 == 0){
                arr = [];
            }

            arr.push(newResponse[i]);

            if(i%2 == 1 || i+1 == newResponse.length)
                responseWithCQ.push(arr);
        }




        //console.log(responseWithCQ);
          return responseWithCQ;
        //return this.answersSummary;
    }

    public get enabledQuestions(): Question[] {
        if (this.selectedPolicyType != null) {
            const allQuestionsForPolicyType: Question[] = this.selectedPolicyType.questions;
            const out: Question[] = [];

            allQuestionsForPolicyType.forEach(q => {
                if (this.shouldDisplayQuestion(q)) {
                    out.push(q);
                }
            });

            return sortByQuestionCode(out);
        }

        return [];
    }

    private shouldDisplayQuestion(question: Question): boolean {
        if (QuestionType.AUDIT == question.type) {
            return false;
        }
        if (undefined == question.conditions || question.conditions.length === 0) {
            return true;
        }

        let out: boolean = false;
        question.conditions.forEach(c => {
            if (out) {
                // conditions are a logical OR, once we find one that is satisfied, no need to check the rest.
                return;
            }
            const match = this.selectedAnswers.get(c.conditionAnswerId);

            if (undefined != match) {
                if (this.shouldDisplayQuestion(match.question)) {
                    out = true;
                }
            }
        });

        return out;
    }

    public get currentQuestionCount(): number {
        console.assert(this.enabledQuestions != null, 'CreateAndEditPolicyModalDialogComponent#get currentQuestionCount - this.enabledQuestions is null');
       
        return this.enabledQuestions.length ;
        
    }

    public get currentQuestion() {
        console.assert(this.enabledQuestions != null, 'CreateAndEditPolicyModalDialogComponent#get currentQuestion - this.questions is null');
        console.assert(this.currentQuestionIndex >= 0 && this.currentQuestionIndex < this.enabledQuestions.length, 'CreateAndEditPolicyModalDialogComponent#get currentQuestion - this.currentQuestionIndex is out of bounds');

        return this.enabledQuestions[this.currentQuestionIndex];
    }

    public get completedQuestionCount() {
        return this.currentQuestionIndex;
    }

    public get previousButtonLabelForCurrentStage(): string {
        switch (this.currentStage) {
            case 'NameAndType':
                return UI_CONSTANTS.CANCEL;
            case 'Settings':
            case 'Summary':
            case 'Questions':
                return UI_CONSTANTS.PREVIOUS;
        }

        console.assert(false, 'CreateAndEditPolicyModalDialogComponent#get previousButtonLabelForCurrentStage - this.currentStage has bad value');
    }

    public get nextButtonLabelForCurrentStage(): string {
        switch (this.currentStage) {
            case 'NameAndType':
            case 'Settings':
            case 'Questions':
                return UI_CONSTANTS.NEXT;
            case 'Summary':
                return UI_CONSTANTS.FINISH;
        }

        console.assert(false, 'CreateAndEditPolicyModalDialogComponent#get nextButtonLabelForStage - this.currentStage has bad value');
    }

    public onSubmitPressed(makeActive: boolean): void {
        console.assert(
            this.formContainer != null,
            'CreateAndEditPolicyModalDialogComponent#onSubmitPressed - this.formContainer was null'
        );

        if (false == this.formContainer.form.valid) {
            this.handleFormErrors();
            return;
        }

        this.submitPolicyToServer(makeActive);
    }

    public onPreviousPressed(): void {
        switch (this.currentStage) {
            case 'NameAndType':
                this.onClosePressed();
                return;
            case 'Settings':
                this.currentStage = 'NameAndType';
                return;
            case 'Questions':
                if (this.currentQuestionIndex > 0) {
                    this.currentQuestionIndex--;                    
                } else {
                    this.currentStage = 'Settings';
                }
                
                return;
            case 'Summary':
                console.assert(this.enabledQuestions != null, `CreateAndEditPolicyModalDialogComponent#onPreviousPressed case 'Summary' - this.questions is null`);
                if(this.customQuestionCount > 0 && this.showCustomQuestion == false){

                    this.showCustomQuestion = true;
                    return;
        
                }
                this.currentStage = 'Questions';
                this.currentQuestionIndex = this.enabledQuestions.length - 1;
                this.showCustomQuestion = false;
                if (this.currentQuestionIndex < 0) {
                    this.currentStage = 'NameAndType';
                }
                return;
            default:
                console.assert(
                    false,
                    `CreateAndEditPolicyModalDialogComponent#onPreviousPressed - this.currentStage has an unhandled value "${this.currentStage}"`
                );
                return;
        }
    }
 
    public onNextPressed(): void {
        switch (this.currentStage) {
            case 'NameAndType':    
            if (this.policyName == "" ) {   
                this.onNextSubmitPolicy  = true;           
                this.handleFormErrors();
                return;
            }else if(this.selectForHealthSystem == true && this.selectedHealthSystem == undefined){

                this.handleFormErrors();
            }else{    
                     
                this.currentStage = 'Settings';
                return;}
            case 'Settings':
                console.assert(this.enabledQuestions != null, `CreateAndEditPolicyModalDialogComponent#onNextPressed case 'Settings' - this.questions is null`);
                if (this.enabledQuestions.length > 0) {
                    this.currentStage = 'Questions';
                    this.currentQuestionIndex = 0;
                } else {
                    this.currentStage = 'Summary';
                }
                return;
            case 'Questions':
                console.assert(this.enabledQuestions != null, `CreateAndEditPolicyModalDialogComponent#onNextPressed case 'Questions' - this.questions is null`);
                
                if (this.currentQuestionIndex < this.enabledQuestions.length - 1) {

                    this.currentQuestionIndex++;                                       
                
                } else {
                    this.currentStage = 'Summary';
                    this._newAnswersSummary = this.newAnswersSummary;
                    if(this.customQuestionCount > 0){

                        this.showCustomQuestion = true;
                    }
                }
                
                return;
            case 'Summary':
                this.onSubmitPressed(false);
                
                
            return;
            default:
                console.assert(
                    false,
                    `CreateAndEditPolicyModalDialogComponent#onNextPressed - this.currentStage has an unhandled value "${this.currentStage}"`
                );
            return;
        }
    }

    public get isNextEnabled(): boolean {
        if (this.shouldDisableSubmitButton) {
            return false;
        }
        switch (this.currentStage) {            

            case 'NameAndType':
                if (this.selectedPolicyType == null) {
                    return false;
                }
             
                return this.doesPolicyNameAlreadyExistForThisFacility(this.policyName) === false;
            case 'Questions':
                
                if(this.customQuestionChecked && this.selectedPolicyType.id == 1 && this.isCurrentQuestionAnswered()){ //Addtional condition for  21 Day Challenge Audit Type
                    return true;
                }else if(this.customQuestionChecked && this.currentQuestionIndex == 0){
                    return true;
                }else if(this.currentQuestionIndex < this.enabledQuestions.length - 1) {
                    return this.isCurrentQuestionAnswered();
                } else {
                    return this.doAllEnabledQuestionsHaveAnswers();
                }
            default:
                return true;
        }
    }

    private isCurrentQuestionAnswered(): boolean {
        if (this.currentQuestion) {
            const responsesToCurrentQuestion = this.responses.get(this.currentQuestion.id);
            
            if (undefined != responsesToCurrentQuestion && responsesToCurrentQuestion.length > 0) {
                return true;
            }
        }
        return false;
    }

    private doAllEnabledQuestionsHaveAnswers(): boolean {
        let out: boolean = true;

        for (const question of this.enabledQuestions) {
            const responsesToQuestion = this.responses.get(question.id);
            //console.log(JSON.stringify(responsesToQuestion));
            if (undefined == responsesToQuestion || responsesToQuestion.length === 0) {
                out = false;
            }
        }

        return out;
    }

    public onClosePressed(): void {
        console.assert(
            this.formContainer != null,
            'CreateAndEditPolicyModalDialogComponent#onClosePressed - this.formContainer was null'
        );
        
        if (!this.formContainer.pristine) {
            this.shouldBlurComponent = true;

            this.simpleModalService
            .addModal(
                TwoChoiceModalDialogComponent,
                { ...UI_CONSTANTS.CLOSE_CONFIRMATION },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                this.shouldBlurComponent = false;
                if (result.didSelectDefaultChoice) {
                    this.close();
                }
            });
    
        } else {
            this.close();
        }
    }

    public onQuestionBubbleClicked(index: number): void {
        console.assert(index >= 0 && index < this.enabledQuestions.length, 'CreateAndEditPolicyModalDialogComponent#onQuestionBubbleClicked - index argument out of bounds');
        
        this.currentQuestionIndex = index;
        this.currentStage = 'Questions';
    }
    public get isValidFreeForm():boolean{

        return this.isFreeFormValueIsNull;
    }

    public onQuestionResponseChange(responseChange: QuestionResponseChangeEvent): void {
        const oldResponses = this.responses.get(responseChange.questionId);
        //console.log("Question:"+JSON.stringify(responseChange));
        let enabledQuestionHasFreeForm:boolean=false;
        responseChange.responses.forEach(response=>{
            if(response.answer.type == 'FREE_FORM'){
                //this.isFreeFormValueIsNull= true;
                enabledQuestionHasFreeForm = true;
                console.log("Disable Next button");
                
                        if(response.freeText == null || response.freeText == undefined|| response.freeText == '') {
                            this.isFreeFormValueIsNull= true;
                            if (undefined != oldResponses) {
                                for (const oldResponse of oldResponses) {
                                    if (undefined == responseChange.responses.find(r => r.answer.id == oldResponse.answer.id)) {
                                        // if an option has been removed from the response set, delete it from the map.
                                        this.selectedAnswers.delete(oldResponse.answer.id);
                                    }
                                }
                            }
                           // return;//console.log("Response Need To remove");
                        }else{         
                            this.isFreeFormValueIsNull= false;
                            
                        }   
                    
                

            }
            

        });
        if(enabledQuestionHasFreeForm == false && this.isFreeFormValueIsNull == true)
            this.isFreeFormValueIsNull = false;

        /*
        if(responseChange.responses[0] !== undefined){
                if(this.isFreeFormValueIsNull)
                if((responseChange.responses[0].freeText == null || responseChange.responses[0].freeText == undefined|| responseChange.responses[0].freeText == '') && responseChange.responses[0].answer.type == 'FREE_FORM'){
                    this.isFreeFormValueIsNull= true;
                    if (undefined != oldResponses) {
                        for (const oldResponse of oldResponses) {
                            if (undefined == responseChange.responses.find(r => r.answer.id == oldResponse.answer.id)) {
                                // if an option has been removed from the response set, delete it from the map.
                                this.selectedAnswers.delete(oldResponse.answer.id);
                            }
                        }
                    }
                    return;//console.log("Response Need To remove");
                }else{         
                    this.isFreeFormValueIsNull= false;
                    
                }   
               
        }
         */
        if (undefined != oldResponses) {
            for (const oldResponse of oldResponses) {
                if (undefined == responseChange.responses.find(r => r.answer.id == oldResponse.answer.id)) {
                    // if an option has been removed from the response set, delete it from the map.
                    this.selectedAnswers.delete(oldResponse.answer.id);
                }
            }
        }

        this.responses.set(responseChange.questionId, responseChange.responses);

        for (const response of responseChange.responses) {
            this.selectedAnswers.set(response.answer.id, response);
        }
    }

    public get hasSubmitted(): boolean {
        console.assert(this.formContainer != null, 'CreateAndEditPolicyModalDialogComponent#hasSubmitted - this.formContainer was null');

        return this.formContainer.submitted;
    }

    public get tooltipForPolicyNameInput(): string {
     
        if (this.doesPolicyNameAlreadyExistForThisFacility(this.policyName)) {
            return UI_CONSTANTS.ERRORS.POLICY_NAME_NOT_UNIQUE;
        } 
         else {
            return '';
        }
    }
    public complianceRateYelloMustNotBeGreaterThanGreen(number:number){
        if(number == null && number ==undefined){
            this.complainceRateValueIsNull=true;
            //this.notificationService.error('','complaince Rate should not be blank');
        }else{
            this.complainceRateValueIsNull=false;
        }
        
        if(this.minimumComplianceRateYellow>=this.minimumComplianceRateGreen){

            this.notificationService.error('',"Minimum compliance rate that should display on reports as green must be greater than minimum compliance rate that should display on reports as yellow");
               
        }
    }
   

    public shouldControlShowErrorStyle(control: NgControl): boolean {
        console.assert(control != null, 'CreateAndEditPolicyModalDialogComponent#shouldControlShowErrorStyle - control argument was null');

        if (!control.touched) {
            return false;
        }
        if (control === this.policyNameInput) {
            if (this.doesPolicyNameAlreadyExistForThisFacility(this.policyName)) {                
                return true;
            }
        }
        if (control === this.minimumComplianceRateGreenInput || control === this.minimumComplianceRateYellowInput){
        

          /*   if(control === this.minimumComplianceRateYellowInput && this.minimumComplianceRateYellowInput.value >=this.minimumComplianceRateGreen){
                this.notificationService.error('',"Minimum compliance rate that should display on reports as green must be greater than minimum compliance rate that should display on reports as yellow");
               // return true;
            }
            if(control === this.minimumComplianceRateGreenInput && this.minimumComplianceRateYellow >= this.minimumComplianceRateGreenInput.value){
                this.notificationService.error('',"Minimum compliance rate that should display on reports as green must be greater than minimum compliance rate that should display on reports as yellow");
                //return true;
            } */
            
            if (this.minimumComplianceRateGreenInput.value < 85 ) {

                this.tooltipForminimumComplianceRateGreenInput="Minimum Compliance Rate must be greater than 85%"
                this.complainceRateCheckGreen=true;
                return true;
            }else{
                this.complainceRateCheckGreen=false;
                this.tooltipForminimumComplianceRateGreenInput="";
            }

        }
        if (control === this.minimumComplianceRateYellowInput){
            if (this.minimumComplianceRateYellowInput.value > 75) {
                this.tooltipForminimumComplianceRateYellowInput="Minimum Compliance Rate must be less than 75%"
                this.complainceRateCheckYellow=true;
                return true;
            }else{
                this.complainceRateCheckYellow=false;
                this.tooltipForminimumComplianceRateYellowInput="";
            }
        }
        if (true === this.onNextSubmitPolicy && this.policyName == "") {                
            
            return true;
        }
   
        if (true === control.pristine && this.hasSubmitted == false) {
           // return false;
        }

        return control.errors != null;
    }

    private handleFormErrors(): void {
        let areAllRequiredFieldsFilled: boolean = true;

        if (this.selectForHealthSystem == true && this.healthSystemCheckInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        } 

        if (this.policyNameInput.errors && this.policyNameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }   
       
  
        if (this.auditTypeSelect.errors && this.auditTypeSelect.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
        }
        if (this.policyNameInput.errors.pattern) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.PATTERN_REQUIRED);
        }
       
    }

    private submitPolicyToServer(shouldSetActive: boolean) {
        console.assert(undefined != this.dataService.defaultLocation, 'CreateAndEditPolicyModalDialogComponent#submitPolicyToServer - no default facility available in dataService.');
        console.assert(null != this.selectedPolicyType, 'CreateAndEditPolicyModalDialogComponent#submitPolicyToServer - this.selectedPolicyType was null');
    
        let policyName: string = this.selectedPolicyType.name;
        let healthSystemID: number = undefined;
        if (this.policyName != '') {
            policyName = this.policyName.trim();
        }
        if (this.doesPolicyNameAlreadyExistForThisFacility(policyName)) {
            policyName = this.getUniqueNameBasedOnName(policyName);
        }
        if(this.selectedHealthSystem != undefined){

            console.log("Selected HealtSystem:"+this.selectedHealthSystem);
            this.healthSystem.forEach(a=>{

                if(a.name == this.selectedHealthSystem){
                    
                    healthSystemID= a.id;
                    } 
            });           
            
        }
        console.log("Selected HealtSystemID:"+healthSystemID);
        
        const newPolicy: IPolicy = {
            active: false, // always create as inactive, activation is a separate step for now
            name: policyName,
            facilityId: this.policy ? this.policy.facilityId : this.dataService.defaultLocation.id,
            policyTypeId: this.selectedPolicyType.id,
            healthSystemId: healthSystemID,
            settings: {
                minYellowRate: this.minimumComplianceRateYellow || DEFAULT_MIN_COMPLIANCE_RATE_YELLOW,
                minGreenRate: this.minimumComplianceRateGreen || DEFAULT_MIN_COMPLIANCE_RATE_GREEN,
            },
            answers: [],
            customQuestions: []
        };
        console.log(JSON.stringify(newPolicy))
        //return;
        // iterate through enabledQuestions so we don't include answers for questions that have been disabled by subsequent changes.
        this.enabledQuestions.forEach((question: Question) => {
            const questionResponses = this.responses.get(question.id) || [];
            questionResponses.forEach(response => {
                const answer: IPolicyQuestionAnswer = {
                    questionAnswerId: response.answer.id,
                    answerValue: response.freeText || response.answer.code,
                };
                newPolicy.answers.push(answer);
            });
        });
        //Save custom Question
        newPolicy.customQuestions =this.customQuestions;
        //console.log("Final Value:"+this.customQuestions);

        this.shouldDisableSubmitButton = true;
        return this.policyService.createEntity(newPolicy)
            .subscribe((createdPolicy) => {
                    this.shouldDisableSubmitButton = false;
                    if (shouldSetActive) {
                        this.shouldDisableSubmitButton = true;
                        return this.setPolicyAsActive(createdPolicy);
                    }
                    this.close();
                },
                err => {
                    this.shouldDisableSubmitButton = false;
                    this.errorService.handleErrorStatusCode(err);
                }
            );
    }

    private handleSetPolicyAsActiveError(errorCode: number) {
        this.shouldDisableSubmitButton = false;
        this.errorService.handleErrorStatusCode(errorCode);
    }

    private setPolicyAsActive(policyToActivate: Policy) {
        return this.facilityService.activePolicy(policyToActivate.facilityId)
            .subscribe((currentlyActivePolicy) => {
                return this.policyService.updateEntity(currentlyActivePolicy.id, {active: false})
                    .subscribe(() => {
                            return this.policyService.updateEntity(policyToActivate.id, {active: true}).subscribe(() => {
                                    this.close();
                                },
                                err => {
                                    this.handleSetPolicyAsActiveError(err);
                                }
                            );
                        },
                        err => { 
                            this.handleSetPolicyAsActiveError(err);
                        }
                    );
                },
            err => {
                if (err && err.status == 404) {
                    // if facility does not already have an active policy, just set this one to active
                    return this.policyService.updateEntity(policyToActivate.id, {active: true}).subscribe(() => {
                            this.close();
                        },
                        err => this.handleSetPolicyAsActiveError(err)
                    );
                }
                this.handleSetPolicyAsActiveError(err);
            });
    }

    public get isCreateMode(): boolean {
        return this.mode === CreateAndEditPolicyModalDialogMode.Create;
    }

    public onAuditCustomQuestionChecked(){
        //console.log(JSON.stringify(this.policy));
        
        if(this.currentStage == 'Questions' && this.policy != undefined){

            if(this.customQuestionCount == 0 && this.policy.customQuestions.length == 0){
                    

                    this.customQuestionCount ++;
                    this.customQuestionChecked == true;
                //this.customQuestionButtonLabel = "Add/Edit Question";
                    
                    const newQuestion = {};
                
                    this.customQuestions.push(newQuestion);  

            }else if(this.customQuestionCount == 0 && this.policy.customQuestions.length>0){ 

                for (const customQuestion of this.policy.customQuestions) {
                    //answersMap.set(answer.questionAnswerId, answer);
                    
                    this.customQuestions.push(customQuestion);
                    this.customQuestionCount ++;
                    this.customQuestionChecked= true;
                    this.customQuestionButtonLabel = "Add Question";
                    //customQuestionsMap.set(customQuestion.id,customQuestion);
                    
                }
                
                            
            }else if(this.customQuestionCount > 0){

                if(this.customQuestionChecked == true){

                    this.customQuestionCount = 0;
                    this.customQuestions =[];
                    this.customQuestionChecked = false;
                    
                }
            }
             //this.showCustomQuestion = true;
             //console.log("Removed checked")
            return;
 
        }else if(this.currentStage == 'Questions' && this.policy == undefined){


            if(this.customQuestionCount == 0 ){ 

                this.customQuestionCount ++;
                this.customQuestionChecked == true
              
                const newQuestion = {}
                this.customQuestions.push(newQuestion);
                
                            
            }else if(this.customQuestionCount > 0){

                if(this.customQuestionChecked == true){

                    this.customQuestionCount = 0;
                    this.customQuestions =[];
                    this.customQuestionChecked = false;
                    
                }
            }
        }
         
    }
   
    public onCutomQuestionPressed(){


        if(this.customQuestionCount >= 3){
            if(this.showCustomQuestion == false){
                this.showCustomQuestion = true;
                
                return;
               
            }else{

                this.notificationService.error('', "You may only add up to 3 custom questions");
                return;

            }
        }

        if(this.customQuestionCount > 0 && this.showCustomQuestion == false){

            this.showCustomQuestion = true;

        }else{
            console.log(JSON.stringify(this.customQuestions));
            if(this.customQuestionCount > 0 && this.showCustomQuestion == true){

            if(this.customQuestions[this.customQuestionCount - 1].questionText == undefined || this.customQuestions[this.customQuestionCount - 1].forEveryPatientOrCatheter  == undefined){
                    this.notificationService.error('', "All the fields  are required and options must be selected.");
                    return;
            }
            //console.log(this.customQuestionCount);
            //console.log(this.customQuestions[0].QuestionText);
            }
            
            this.customQuestionCount ++;
            this.customQuestionButtonLabel = "Add Question";
                
            const newQuestion = {}
            //if(this.showCustomQuestion == false && this.customQuestionCount > 0 ){
                console.log(this.customQuestionCount);
                this.customQuestions.push(newQuestion);   
            //}
            
            this.showCustomQuestion = true;

        }

        
        
    }
    public onCutomQuestionDonePressed(){
       
        if(this.customQuestionCount > 0 ){

            if(this.customQuestions[this.customQuestionCount - 1].questionText == undefined || this.customQuestions[this.customQuestionCount - 1].forEveryPatientOrCatheter  == undefined){
                 this.notificationService.error('', "All the fields  are required ");
                
                 return;
            }
            
            var xyz =false;
            if(this.customQuestions.length>1){
            
            if (this.customQuestions[0].questionText==this.customQuestions[1].questionText){
                this.notificationService.error('', "Custom qs should be different ");
                return;
            }
        }
        if(this.customQuestions.length>2){
            if(this.customQuestions[1].questionText==this.customQuestions[2].questionText){
                this.notificationService.error('', "Custom qs should be different "); 
                return;
            }
            if(this.customQuestions[0].questionText==this.customQuestions[2].questionText){
                this.notificationService.error('', "Custom qs should be different ");
                return;
            }
        }
        
         }
        this.showCustomQuestion = false;
    }
    public onRemoveQuestionPressed(cq){        

        var index = this.customQuestions.indexOf(cq);
        this.customQuestions.splice(index,1);
        this.customQuestionCount --;
        if( this.customQuestionCount == 0){
            console.log(this.customQuestionCount);
            this.showCustomQuestion = false;
            this.customQuestionChecked= false;
        }

    }
    public updateCustomQuestionInput(cq, event: any): void {
        this.checkTextAreaLimit(event);
        const response = this.customQuestions.indexOf(cq);
        this.handleFormErrors;
   
       // this.customQuestions.push([cq.CustomQuestionInput,cq.customQuestionType,cq.patientCatheter]);
        this.customQuestions[cq] = {"questionText":cq.questionText,"answerType":cq.answerType,"forEveryPatientOrCatheter":cq.forEveryPatientOrCatheter};
        //console.log(this.customQuestions);
      // return this.customQuestions[cq]
        
        
    }

    public get isCustomQuestionChecked(): boolean {

        if(this.customQuestionCount>0){

            this.customQuestionChecked= true;
            
        }

        return this.customQuestionChecked;
        
        
    }    
    public loadHealthSystem(){
        this.healthSystemService.getHealthSystem()
            .subscribe((healthSystem) => {
                          
                var hSName:HealthSystem[]=[];
                var jsonText = JSON.stringify(healthSystem);             
                var obj =  JSON.parse(jsonText);
                var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                      
                this.healthSystemValues= [];  
                arr.forEach(a=>{
                    this.healthSystemValues.push(a.name);
                    hSName.push({
                        id:Number(a.id),
                        name:a.name,
                    });               
               })
               this.healthSystem = hSName;  
        
            });



    }
    public checkHealtSystem(){
             
      //  this.selectForHealthSystem ? (this.selectForHealthSystem = false) : (this.selectForHealthSystem = true);
        let facility:Facility[];
        
        if(this.selectForHealthSystem == true){

           let facility:Facility = this.facility.find(_=>_.id == this.activeFacilityId);

           this.selectedHealthSystem = this.healthSystem.find(_hs=>_hs.id == facility.healthSystemId).name; 

           console.log("facility: " + JSON.stringify(this.facility.find(_=>_.id == this.activeFacilityId)));
  
             
        }else{

            this.selectedHealthSystem = undefined;
        }       

    }
    private loadFacility(): Promise<void> {
        return new Promise(resolve => {
            this.facilityService.getAllEntities().subscribe(           
                _ => {                  
                    this.facility=_;
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });
    }

    public isFacilityInHealthSystem():boolean{

       /* let facility:Facility = this.facility.find(_=>_.id == this.activeFacilityId);
        if(facility.healthSystemId == undefined){
            return false;
        }*/
        return true;
    }

    public checkTextAreaLimit(comment:string){

        if(comment.length == 50 ){
            console.log('Comment:'+comment+','+ 'Length:'+comment.length);
            alert("You reached the Max limit of 50.");
          }
    }
 

}
