import {Component, OnInit} from '@angular/core';
import {TranslateService} from 'ng2-translate';
import {ActivatedRoute, Router} from '@angular/router';
import {ErrorService} from '../shared/service/error-service';
import {ForgetPasswordDetails} from './forgetPasswordDetails';
import {TokenService} from '../shared/service/token.service';
import {NotificationService} from '../shared/service/notification.service';
import {EmitterService} from '../shared/service/emitter.service';
import {MESSAGES} from '../shared/constants/index';
import {User} from "../shared/model/user";

// TODO: NL - audit this class thoroughly

@Component({
  selector: 'reset-password',
  templateUrl: './resetpassword.component.html'
})

export class ResetPassword implements OnInit {

    public termsAndConditions: Boolean = false;
    public token:any;
    public state:String="";
    public user: User = undefined;
    public isAuthorised:Boolean;
    public forgetPass: ForgetPasswordDetails = new ForgetPasswordDetails;
    public spinner: Boolean = false;
    public isValidToken:Boolean=false;
    public userType:String="";
    public errorMessage:String="";
  constructor(private router: Router,
    private tokenService: TokenService,
    private notificationService: NotificationService,
    private emitterService: EmitterService,
    private translate: TranslateService,
    private errorService: ErrorService,private route: ActivatedRoute) {
        this.route.params.subscribe(params => { this.token = params['token']; });

  }

  public ngOnInit() {
      this.validateTokenForReset();
  }
  public validateTokenForReset() {
    this.spinner = true;
    //TODO(refactoring): create required method in UserService
      /*this.userService.validateToken(this.token)
      .subscribe(data => {
          this.spinner = false;

          let response = data.json();
          if (response.tokenValid) {
            this.isValidToken = true;
            this.userType = response.userType;
            this.forgetPass.username = response.emailId;
          } else {
            this.isValidToken = false;
            this.errorMessage = MESSAGES.ERROR.TOKEN_NOT_VALID;
            this.notificationService.error(MESSAGES.INFO.HEADER.TOKEN_EXPIRED, MESSAGES.INFO.HEADER.TOKEN_EXPIRED);
          }
        },
        err => {
          this.spinner = false;
          this.errorService.handleErrorStatusCode(err);
        }
      );*/
  }

  updatePassword(forgetPasswordDetails) {
    this.spinner = true;
    let updatepassword: ForgetPasswordDetails = forgetPasswordDetails;
    let validPassword: boolean = false;
    let reg = /^(.*^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,}).*)$/;
    if (reg.test(updatepassword.newPassword)) {
      validPassword = true;
    }
    if (validPassword) {
      if (updatepassword.newPassword === updatepassword.oldPassword) {
        forgetPasswordDetails.token = this.token;
        //TODO(refactoring): rework forgot password
        /*this.userService.passwordUpdate(forgetPasswordDetails)
          .subscribe(data => {
           this.spinner = false;
           let response = data.json();

           if (!response.passwordUpdateStatus) {
             this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE,MESSAGES.ERROR.SOMETHING_WRONG);
          } else {
            this.notificationService.success(MESSAGES.SUCCESS.HEADER.SUCCESS_MESSAGE, MESSAGES.SUCCESS.PASSWORD_SET);
            if (!response.isMobile) {
              this.router.navigate([Route.LOGIN]);
            } else {
              this.isValidToken = false;
              this.errorMessage = MESSAGES.INFO.MOBILE_LOGIN;
            }
          }
         },
         err => {
           this.spinner = false;
           this.errorService.handleErrorStatusCode(err);
         }
       );*/
      } else {
          this.spinner = false;
          this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.PASSWORD_MISMATCH);
      }
    } else {
      this.spinner = false;
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.PASSWORD_VALIDATION);
    }
  }
}
