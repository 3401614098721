export enum AnswerType {
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    STRING = 'STRING',
    FREE_FORM = 'FREE_FORM',
    NOT_APPLICABLE = 'NOT_APPLICABLE'
}

export function isAnswerType(x: any): x is AnswerType {
    const options: AnswerType[] = [
        AnswerType.NUMBER,
        AnswerType.BOOLEAN,
        AnswerType.STRING,
        AnswerType.FREE_FORM,
        AnswerType.NOT_APPLICABLE,
    ];
    if (options.indexOf(x) !== -1) {
        return true;
    }
    return false;
}

export interface IQuestionAnswer {
    id?: number;
    type: AnswerType;
    label: string;
    code: string;
    showAuditSetupWarning: boolean;
}

export class QuestionAnswer implements IQuestionAnswer {
    id?: number;
    type: AnswerType;
    label: string;
    code: string;
    showAuditSetupWarning: boolean;

    constructor(args: IQuestionAnswer) {
        console.assert(args !== undefined && args !== null, 'QuestionAnswer constructor passed null or undefined args object');
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'QuestionAnswer constructor passed args with invalid id');
        }
        console.assert(typeof args.code === 'string', 'QuestionAnswer constructor passed args with invalid code');
        console.assert(typeof args.label === 'string', 'QuestionAnswer constructor passed args with invalid label');
        console.assert(isAnswerType(args.type), 'QuestionAnswer constructor passed args with invalid type');
        console.assert(typeof args.showAuditSetupWarning === 'boolean', 'QuestionAnswer constructor passed args with invalid showAuditSetupWarning');

        this.id = args.id || undefined;
        this.code = args.code;
        this.type = args.type;
        this.label = args.label;
        this.showAuditSetupWarning = args.showAuditSetupWarning;
    }
}
