import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Http} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MultiSelectModule} from 'primeng/primeng';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';
import {AccountWidgetModule} from '../../account-widget/account-widget.module';
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import {FacilityListComponent} from './facility-list.component';
import {FacilityRoutes} from '../facility.route';
import {FilterPipe} from './facility-pipes';
import {DirectiveModule} from "../../shared/directive/directive.module";


export function translateLoader(http: Http) {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
    declarations: [FacilityListComponent, FilterPipe],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        RouterModule.forRoot(FacilityRoutes),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
        BrowserAnimationsModule,
        MultiSelectModule,
        AngularMultiSelectModule,
        MyDatePickerModule,
        AccountWidgetModule,
        DirectiveModule,
        SharedComponentsModule,
    ],
})
export class FacilityListModule {}
