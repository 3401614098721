import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OauthComponent } from './oauth.component';
import {LoginModule} from "../login/login.module";

@NgModule({
  declarations: [
    OauthComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LoginModule,
  ],
  providers: [
    OauthComponent
  ]
})
export class OauthModule {
}
