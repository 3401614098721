import {Routes} from '@angular/router';
import {CommonGuard} from '../shared/guard/common.guard';
import {SummaryReportsComponent} from './summary-reports.component';
import {RoutePath} from '../shared/constants/index';

export const SummaryReportsRoutes: Routes = [
  {
    path: RoutePath.SUMMARY_REPORTS,
    component: SummaryReportsComponent,
    canActivate: [CommonGuard]
  }
];
