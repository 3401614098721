import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { AppBlurService } from '../../shared/service/app-blur.service';
import { ROLES_INFO_DIALOG as UI_CONSTANTS } from '../../shared/constants/uiConstants';

export interface RolesInfoMode {}
export interface RolesInfoResult {}

@Component({
  selector: 'roles-info-dialog',
  templateUrl: 'roles-info-dialog.component.html',
  styleUrls: ['./roles-info-dialog.component.scss'],
})
export class RolesInfoDialog extends SimpleModalComponent<RolesInfoMode, RolesInfoResult> implements AfterViewInit, OnDestroy {
  public UI = UI_CONSTANTS;
  constructor(private appBlurService: AppBlurService) {
    super();
  }

  public ngAfterViewInit() {
    this.appBlurService.pushBlurCount();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.appBlurService.popBlurCount();
  }
}