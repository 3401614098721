import {CrudApiService} from "./common/crud.api.service";
import {Unit, IUnit} from "../model/unit";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

import { Patient, IPatient, PatientAnswer, PatientCatheter } from '../model/patient';

/* @Injectable()
export class PatientService extends CrudApiService<Unit, IUnit, Partial<Unit>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/patient");
    }

    protected assembleSingleInstance(_: any): Unit {
        return new Unit(_);
    }
} */

interface CatheterAndAnswers {
    line: string;
    location: string;
    comment: string;
    answers: PatientAnswer[];
  }
  
  class ModifiedPatient {
    identifier?: string;
    comment?: string;
    policyId?: number;
    timeShift?: 'DAY' | 'NIGHT' | 'BOTH';
    unitId?: number;
    auditDate: string;
  
    answers: PatientAnswer[] = [];
    catheters: CatheterAndAnswers[] = [];
  
    constructor(patient: Patient) {
      this.identifier = patient.identifier;
      this.comment = patient.comment;
      this.policyId = patient.policyId;
      this.timeShift = patient.timeShift;
      this.unitId = patient.unitId;
      this.auditDate = patient.auditDate;
    
      const cathGroups: { catheter: PatientCatheter, answers: PatientAnswer[]}[] = [];
  
      for (let answer of patient.answers) {

        
        if (undefined == answer.catheter) {
          
          this.answers.push(answer);
          continue;
        }
  
        let added: boolean = false;
        for (let group of cathGroups) {
          if (added) {
            continue;
          }
          if (group.catheter === answer.catheter) {

            console.log("Catheter:"+JSON.stringify(answer.catheter));
            group.answers.push(answer);
            added = true;
          }
        }
        if (false === added) {
          cathGroups.push({
              catheter: answer.catheter,
              answers: [answer],
          });
        }
      }
  
      for (let group of cathGroups) {
        const tmp: CatheterAndAnswers = {
          line: group.catheter.line,
          location: group.catheter.location,
          comment: group.catheter.comment,
          answers: group.answers,
        };
        this.catheters.push(tmp);
      }
    }
  }
   

/* 
    @Injectable()
    export class AuditService extends CrudApiService<Patient, Partial<IPatient>, Partial<IPatient>> {
        constructor(http: HttpClient, tokenService: TokenService) {
            super(http, tokenService, "api/patient");
        }
    

        protected assembleSingleInstance(_: any): Patient {
            return new Patient(_);
        }
        
     /*    public createEntity(_: Patient): Observable<Patient> {
            const transformedObj: ModifiedPatient = new ModifiedPatient(_);
            return this.post<Patient>('', transformedObj);
        }  */
      //public createAudit(_:Patient):
        


        
   /* } */

@Injectable()
export class AuditService extends CrudApiService<Patient,Partial<IPatient>, Partial<IPatient>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/patients");
    }

    protected assembleSingleInstance(_: any): Patient {
        return new Patient(_);
    }

    createAuditEntity(_: Patient): Observable<Patient> {
     // console.log(JSON.stringify(_));
      const transformedObj: ModifiedPatient = new ModifiedPatient(_);
      //console.log(JSON.stringify(transformedObj));
      return this.post<Patient>('', transformedObj);
      //return;
    
    }
    
}
  
    
  