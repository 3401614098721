//import assert from 'assert';
import * as assert from 'assert';

export default class AssertInClass {
    public readonly className?: string;

    constructor(className?: string) {
        this.className = className;
    }

    public inFunction = (caller: string) =>
        new class {
            public readonly superThis: AssertInClass;
            public readonly functionName: string;

            constructor(superThis: AssertInClass) {
                this.superThis = superThis;
                this.functionName = caller;
            }

            public chain = (root: any) => (...chain: string[]) => {
                let node = root;
                chain.forEach(leafName => {
                    node = node[leafName];
                    assert(
                        node != null,
                        `${this.prefix} ${leafName} is null or undefined`
                    );
                });
            }

            public allNotNull = <T>(root: T) => (...propNames: (keyof T)[]) => {
                propNames.forEach(propName => {
                    const prop = root[propName];
                    assert(
                        prop != null,
                        `${this.prefix} ${propName} is null or undefined`
                    );
                });
            }

            public notNull<T>(targetName: string, target: T): void {
                assert(
                    target != null,
                    `${this.prefix} ${targetName} is null or undefined`
                );
            }

            public typeOf = (type: string) => <T>(target: T, targetName: string) => {
                assert(
                    typeof target === type,
                    `${this.prefix} ${targetName} is not of type ${type}`
                );
            }

            public matchesTypeWhenNotNull = (type: string) => <T>(target: T, targetName: string) => {
                if (target != null) {
                    assert(
                        typeof target === type,
                        `${this.prefix} ${targetName} is defined but not of type ${type}`
                    );
                }
            }

            public isArray(arrayName: string, array: any[]): void {
                assert(
                    Array.isArray(array),
                    `${this.prefix} ${arrayName} is not an array`
                );
            }

            public isNonEmptyArray<T>(targetName: string, target: T[]): void {
                this.notNull(targetName, target);
                this.condition(
                    Array.isArray(target),
                    `${this.prefix} ${targetName} is not an array`
                );
                this.condition(
                    target.length >= 1,
                    `${this.prefix} ${targetName} is an empty array`
                );
            }

            public isIndexInArrayBounds(indexName: string, index: number, arrayName: string, array: any[]): void {
                this.notNull(indexName, index);
                this.isArray(arrayName, array);
                assert(
                    index >= 0 && index < array.length,
                    `${this.prefix} ${indexName}:${index} is out of bounds of array ${arrayName} which has a length of ${
                        array.length
                    }`
                );
            }

            public condition(condition: boolean, message: string): void {
                assert(
                    condition,
                    `${this.prefix} ${message}`
                );
            }

            public fail(message: string): void {
                assert(
                    false,
                    `${this.prefix} ${message}`
                );
            }

            private get prefix(): string {
                return `${this.superThis.className ? this.superThis.className + '#' : ''}${this.functionName}:`;
            }
        }(this)
}
