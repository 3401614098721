import { Pipe, PipeTransform } from '@angular/core';
import {User} from "../../shared/model/user";

@Pipe({
    name: 'FilterPipe',
})
export class FilterPipe implements PipeTransform {
    transform(userArray: User[], input: string) {
        if (input) {
            input = input.toLowerCase();
            return userArray.filter(user => {
                let fullName = `${user.firstName} ${user.lastName}`.toLowerCase();

                return (
                    fullName.toLowerCase().indexOf(input) > -1 || user.username.toLowerCase().indexOf(input) > -1
                );
            });
        }
        return userArray;
    }
}
