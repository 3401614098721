import { DELAY_UNTIL_SHOW_SKELETON_MS } from '../constants/animationConstants';

export default abstract class AbstractSkeletonLoader {
    public shouldShowSkeleton: boolean = false;
    public shouldShowContent: boolean = false;
    private showSkeletonTimer?: number;

    protected showSkeletonAfterDelay(delay: number = DELAY_UNTIL_SHOW_SKELETON_MS) {
        if (true == this.shouldShowContent) {
            this.shouldShowSkeleton = false;
            this.shouldShowContent = false;
        }
        if (undefined != this.showSkeletonTimer) {
            clearTimeout(this.showSkeletonTimer);
            this.showSkeletonTimer = undefined;
        }
        this.showSkeletonTimer = window.setTimeout(() => {
            this.shouldShowSkeleton = true;
            this.showSkeletonTimer = undefined;
        }, delay);
    }

    protected hideSkeletonAndShowContent() {
        if (undefined != this.showSkeletonTimer) {
            clearTimeout(this.showSkeletonTimer);
            this.showSkeletonTimer = undefined;
        }
        this.shouldShowSkeleton = false;
        this.shouldShowContent = true;
    }
}
