import {UserRoleString} from "../model/role";

const SUPER_ADMIN_3M_ID = 1;
const ADMIN_3M_ID = 2;
const HEALTH_SYSTEM_ADMIN_ID = 6;
const FACILITY_ADMIN_ID = 3;
const REP_ROLE_ID = 4;
const AUDITOR_ID = 5;

export const ROLES = {
  ROLE: {
    SUPER_ADMIN_3M: 'ROLE_SUPER_ADMIN' as UserRoleString,
    ADMIN_3M: 'ROLE_ADMIN' as UserRoleString,
    HEALTH_SYSTEM_ADMIN: 'HEALTH_SYSTEM_ADMIN' as UserRoleString,
    ROLE_REP: 'ROLE_REP' as UserRoleString,
    FACILITY_ADMIN: 'ROLE_FACILITY_ADMIN' as UserRoleString,
    AUDITOR: 'ROLE_AUDITOR' as UserRoleString
  },
  ROLE_ID: {
    SUPER_ADMIN_3M: SUPER_ADMIN_3M_ID,
    ADMIN_3M: ADMIN_3M_ID,
    HEALTH_SYSTEM_ADMIN: HEALTH_SYSTEM_ADMIN_ID,
    REP_ROLE:REP_ROLE_ID,
    FACILITY_ADMIN: FACILITY_ADMIN_ID,
    AUDITOR: AUDITOR_ID
  },
  ROLES: {
    [SUPER_ADMIN_3M_ID + '']: {
      description: ''
    },
    [ADMIN_3M_ID + '']: {
      description: ''
    },
    /*[PARENT_ADMIN_ID + '']: {
      description: ''
    },*/
    [REP_ROLE_ID + '']: {
      description: ''
    },
    [FACILITY_ADMIN_ID + '']: {
      description:
        // tslint:disable-next-line:max-line-length
        'Facility Administrators are able to set audit parameters, add Auditors and Facility Administrators, and review reports in the PEAK™ Assessment Tool web portal. Facility Administrators can also enter audit data through the PEAK™ Assessment Tool app.'
    },
    [AUDITOR_ID + '']: {
      description: 'Auditors are able to enter audit data through the PEAK™ Assessment Tool app.'
    }
  }
};
