import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface LabelOption {
    indentPixels?: number;
    secondaryLabel?: string;
    onDeletePressedAtIndex?: (number) => void;
}


@Component({
    selector: 'app-selector',
    templateUrl: './selector.component.html',
    styleUrls: [
        './selector.component.scss',
        '../shared-components-styles.scss'
    ],
})
export class SelectorComponent<T, K extends keyof T> {
    private _currentSelection: T;
    @Input() public sourceArray: Array<T>;
    @Input() public labelKey: K;
    @Input() public nullLabel: string = '';
    @Input() public disabled?: boolean = false;  
    @Output() public currentSelectionChange = new EventEmitter<T | undefined>();

    @Input() public shouldShowArrow?: boolean = true;
    @Input() public labelOptions?: Array<LabelOption>;
    @Input() public getCustomLabelForCurrentSelection?: (selection: T, array: Array<T>)=>string;
    @Input() public shouldShowArrowDown?: boolean = false;

    public isShowingSelector: boolean = false;

    
    @Input()
    get currentSelection(): T {
        return this._currentSelection;
    }

    set currentSelection(value: T) {
        this._currentSelection = value;
        this.currentSelectionChange.emit(this._currentSelection);
    }

    get labelForCurrentSelection(): T[K] | string {
        if (this._currentSelection != null) {
            if (this.getCustomLabelForCurrentSelection != null) {
                return this.getCustomLabelForCurrentSelection(this._currentSelection, this.sourceArray);
            } else {
                const label = this._currentSelection[this.labelKey]
                return this.getLabelForSource(label);    
            }
        } else {
            return this.nullLabel;
        }
    }

    get filteredSourceArray(): Array<T> {
        console.assert(
            this.sourceArray != null,
            'SelectorComponent#get filteredSourceArray - this.sourceArray was null'
        );

        if (this._currentSelection != null) {
            return [this._currentSelection, ...this.sourceArray.filter(source => source !== this._currentSelection)];
        } else {
            return this.sourceArray;
        }
    }

    public onSourceClicked(event: Event, source: T) {
        event.stopPropagation();
        this.currentSelection = source;
        this.isShowingSelector = false;
    }

    //changes for data entry selector

    private getLabelForSource(source: T[K]): string {
       // if (isString(source)) {
        if(typeof source === 'string'){   
            return source;
        } else {
            if (source == null) {
                return this.nullLabel;
            } else {
                //const assert = this.assert.inFunction('getLabelForSource');
                //assert.fail('source is expected to be of type string, but it is type ' + typeof source);    
            }
        }
    }

}
