export const LANGUAGE_LIST = [
  {
    'short_name': 'en',
    'long_name': 'English',
  },
  {
    'short_name': 'fr',
    'long_name': 'French',
  },
  {
    'short_name': 'sp',
    'long_name': 'Espanol',
  }
];
