import {CrudApiService} from "./common/crud.api.service";
import {PolicyType, IPolicyType} from "../model/policy-type";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

@Injectable()

export class PolicyFilterService extends CrudApiService<PolicyType, IPolicyType, Partial<PolicyType>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/policyTypesFilter/custom");
    }
    protected assembleSingleInstance(_: any): PolicyType {
        return new PolicyType(_);
    }
    getPolicy(facilityId: number): Observable<Array<PolicyType>> {
        return this.get<Array<PolicyType>>(`/${facilityId}`);
    }
    getPolicyById(policyId: number): Observable<Array<PolicyType>> {
        return this.get<Array<PolicyType>>(`/policy/${policyId}`);
    }
   
}