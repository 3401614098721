export enum TimeShift {
    SELECT = "SELECT", DAY = "DAY", NIGHT = "NIGHT", BOTH = "BOTH"
}

export function GetDisplayStringForTimeShift(timeShift: TimeShift) {
    switch (timeShift) {
        case TimeShift.SELECT:
        return 'Select';
        case TimeShift.DAY:
        return 'Day';
        case TimeShift.NIGHT:
        return 'Night';
        case TimeShift.BOTH:
        return 'Both';
        default:
        return 'Unknown';
    }
};
