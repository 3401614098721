export const NUMBER_OF_IV_ACCESS_POINTS = '# IV Access Points';

export const DATA_ENTRY_PAGE = Object.freeze({
    /*TABS: {
        ACTIVE: 'Active Facilities',
        INACTIVE: 'Inactive Facilities',
    },
    CREATE_FACILITY: 'Create Facility',
    MAKE_INACTIVE: 'Make Inactive',
    MAKE_ACTIVE: 'Make Active',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EDIT: 'Edit',
    SAP: 'SAP#',
    SEARCH_PLACEHOLDER: 'Facilities Search',
    EMPTY_MESSAGE: 'No facilities to show.',
    SUCCESS: {
        MAKE_ACTIVE: 'The facility was successfully made active.',
        MAKE_INACTIVE: 'The facility was successfully made inactive.',
    },*/
    TEXT_AREA_FIELD_LENGTH: {
        NAME: {
            MAX: 100,
        },
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        DATE_REQUIRED: 'Please select a date',
        FACILITY_EXISTS: 'A facility of this name already exists. Please choose a unique name.',
        TOKEN_INVALID: 'Authentication Failure, Token expired.'
    },

    START: 'Start'
});
export const LOGIN_PAGE = Object.freeze({
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        FORGOT_PASSWORD: 'Please enter email',
        PASSWORD_RESET_MSG: 'We\'ve sent a password reset link to your registered email address. Please check your inbox.'
    },
});

export const FACILITY_LIST_PAGE = Object.freeze({
    TABS: {
        ACTIVE: 'Active Facilities',
        INACTIVE: 'Inactive Facilities',
    },
    CREATE_FACILITY: 'Create Facility',
    CREATE_EDIT: 'Create and Edit',
    MAKE_INACTIVE: 'Make Inactive',
    MAKE_ACTIVE: 'Make Active',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EDIT: 'Edit',
    SAP: 'SAP#',
    SEARCH_PLACEHOLDER: 'Facilities Search',
    EMPTY_MESSAGE: 'No facilities to show.',
    SUCCESS: {
        MAKE_ACTIVE: 'The facility was successfully made active.',
        MAKE_INACTIVE: 'The facility was successfully made inactive.',
    },
    PRINT_MENU_OPTIONS: [
        'Create Facility', 'Create Health System' ,'Edit Health System'
    ],
});
export const CREATE_AND_EDIT_HEALTH_SYSTEM_MODAL_DIALOG = Object.freeze({
    FIELD_LENGTH: {
        NAME: {
            MAX: 50,
        },
        
    },
    TITLE: 'Create Health System',
    CANCEL: 'Cancel',
    CREATE: 'Create',
    SAVE: 'Save',
    FIELDS: {
        NAME: 'Name',
        
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        HEALTH_SYSTEM_EXISTS: 'A Health System of this name already exists. Please choose a unique name.',
        
    },
    SUCCESS: {
        HEALTH_SYSTEM_CREATED: 'The health system was created successfully.',
        HEALTH_SYSTEM_MODIFIED: 'The  health system was modified successfully.',
    },
});

export const CREATE_AND_EDIT_FACILITY_MODAL_DIALOG = Object.freeze({
    FIELD_LENGTH: {
        NAME: {
            MAX: 100,
        },
        SAP: {
            MAX: 10,
        },
        CITY: {
            MAX: 100,    
        },
        ZIP_CODE: {
            MAX: 5,
        },
        ADDRESS_1: {
            MAX: 200,
        },
        ADDRESS_2: {
            MAX: 200,
        },    
    },
    TITLE: 'Facility',
    CANCEL: 'Cancel',
    CREATE: 'Create',
    SAVE: 'Save',
    FIELDS: {
        NAME: 'Name',
        SAP_NUMBER: 'Facility SAP#',
        ADDRESS_LINE_1: 'Address Line 1',
        ADDRESS_LINE_2: 'Address Line 2',
        CITY: 'City',
        STATE: 'State',
        ZIP_CODE: 'Zip Code',
        HEALTH_SYSTEM:'Select Health System',
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        FACILITY_EXISTS: 'A facility of this name already exists. Please choose a unique name.',
        SAP_EXISTS: 'A SAP of that value already exists. Please choose a unique value.',
        ZIP_CODE_INVALID: 'Please enter a valid zip code.',
    },
    SUCCESS: {
        FACILITY_CREATED: 'The facility was created successfully.',
        FACILITY_MODIFIED: 'The facility was modified successfully.',
    },
});

export const UNIT_PAGE = Object.freeze({
    TABS: {
        ACTIVE: 'Active Units',
        INACTIVE: 'Inactive Units',
    },
    CREATE_UNIT: 'Create Unit',
    MAKE_INACTIVE: 'Make Inactive',
    MAKE_ACTIVE: 'Make Active',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EDIT: 'Edit',
    SEARCH_PLACEHOLDER: 'Units Search',
    EMPTY_MESSAGE: 'No units for this selection.',
    SELECT_FACILITY_TO_USE_FEATURE: 'Please select a facility to use this feature.',
    NO_PERMISSION: 'Sorry, your user does not have permission to do this. Please contact your facility administrator.',
    ERRORS: {
        NO_FACILITY_SELECTED: 'Please select a facility before creating a unit.',
        
       
        
    },
    SUCCESS: {
        UNIT_CREATED: 'The unit was created successfully.',
        UNIT_MODIFIED: 'The unit was modified successfully.',
    },
});

export const CREATE_AND_EDIT_UNIT_MODAL_DIALOG = Object.freeze({
    FIELD_LENGTH: {
        NAME: {
            MAX: 50,
        },
    },
    TITLE: 'Unit',
    CANCEL: 'Cancel',
    CREATE: 'Create',
    SAVE: 'Save',
    MAKE_INACTIVE: UNIT_PAGE.MAKE_INACTIVE,
    MAKE_ACTIVE: UNIT_PAGE.MAKE_ACTIVE,
    FIELDS: {
        NAME: 'Name',
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        
        UNIT_NAME_NOT_UNIQUE: 'This unit name is already used for this facility. Please choose a unique name.',
    },
});

export const USER_LIST_PAGE = Object.freeze({
    TABS: {
        ACTIVE: 'Active Users',
        INACTIVE: 'Inactive Users',
    },
    CREATE_USER: 'Create User',
    ROLES: 'Roles',
    FACILITY: 'Facility',
    MAKE_INACTIVE: 'Make Inactive',
    MAKE_ACTIVE: 'Make Active',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    VIEW: 'View',
    SEARCH_PLACEHOLDER: 'Users Search',
    EMPTY_MESSAGE: 'No users for this selection.',
    NONE: '',
    SUCCESS: {
        MAKE_ACTIVE: 'The user was successfully made active.',
        MAKE_INACTIVE: 'The user was successfully made inactive.',
    },
});

export const ROLES_INFO_DIALOG = Object.freeze({
  HEADER: 'Role Definitions',
  AUDITORS_SECTION: 'Auditors',
  AUDITORS_EXPLANATION: 'are able to enter audit data through the PEAK™ Assessment Tool app.',
  FACILITY_ADMINISTRATORS_SECTION: 'Facility Administrators',
  FACILITY_ADMINISTRATORS_EXPLANATION: 'are able to set audit parameters, add Auditors and Facility Administrators, and review reports in the PEAK™ Assessment Tool web portal. Facility Administrators can also enter audit data through the PEAK™ Assessment Tool app.'
});

export const USER_DETAIL_PAGE = Object.freeze({
    MAKE_USER_INACTIVE: 'Make User Inactive',
    MAKE_USER_ACTIVE: 'Make User Active',
    USER_ROLES: 'User Roles',
    ADD_ROLE: 'Add Role',
    FACILITY: 'Facility',
    FACILITY_NOT_APPLICABLE: 'N/A',
    ROLE: 'Role',
    EDIT: 'Edit',
    NO_PERMISSION_TO_MODIFY_USER: 'You do not have permission to modify this user.',
    EMPTY_MESSAGE: 'This user has no roles.',
    SUCCESS: {
        MAKE_ACTIVE: 'The user was successfully made active.',
        MAKE_INACTIVE: 'The user was successfully made inactive.',
        USER_MODIFIED: 'The user was modified successfully.',
    },
});

export const ADD_AND_EDIT_USER_ROLE_MODAL_DIALOG = Object.freeze({
    TITLE: 'Role',
    CANCEL: 'Cancel',
    ADD: 'Add',
    SAVE: 'Save',
    DELETE: 'Delete',
    FIELDS: {
        FACILITY: 'Facility',
        ROLE: 'Role',
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
    },
    TOOLTIPS: {
        DELETE_DISABLED: 'A user must have at least one role. Please alter this role instead of deleting it.',
    },
});

export const CREATE_AND_EDIT_USER_MODAL_DIALOG = Object.freeze({
    FIELD_LENGTH: {
        USERNAME: {
            MAX: 100,
        },
        FIRST_NAME: {
            MAX: 100,
        },
        LAST_NAME: {
            MAX: 100,
        },
    },
    TITLE: 'User',
    CANCEL: 'Cancel',
    CREATE: 'Create',
    SAVE: 'Save',
    MAKE_USER_INACTIVE: 'Make User Inactive',
    MAKE_USER_ACTIVE: 'Make User Active',
    FIELDS: {
        FIRST_NAME: 'First Name',
        LAST_NAME: 'Last Name',
        EMAIL_ADDRESS: 'Email Address',
        ROLE: 'Role',
        FACILITY: 'Facility'
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        EMAIL_ADDRESS_INVALID: 'The email address entered is invalid.',
        EMAIL_ADDRESS_EXISTS: 'The email address entered is already in use. Please enter a unique email address.',
        LETTER_REQUIRED: 'Name must be letters only',
    },
    SUCCESS: {
        USER_CREATED: 'The user was created successfully.',
        MAKE_ACTIVE: 'The user was successfully made active.',
        MAKE_INACTIVE: 'The user was successfully made inactive.',
        USER_MODIFIED: 'The user was modified successfully.',
    },
    TOOLTIPS: {
        EMAIL_ADDRESS_DISABLED: 'Sorry, the email address cannot be modified after a user is created.',
    },
});

export const FACILITY_SELECTOR_COMPONENT = Object.freeze({
    NO_FACILITIES_EXIST_NOTIFICATION: {
        // NL - This probably should be moved somewhere else when it becomes clear where it should live
        TITLE: 'No Facilities Exist',
        MESSAGE:
            'There are no facilities or audits to show at this time. Please setup a facility and audit policy and perform your first audit.',
    },
    LOADING: 'Loading…',
    NO_FACILITIES_AVAILABLE: 'No Active Facilities Available',
    ALL_FACILITIES: 'All Facilities',
});

export const ACCOUNT_WIDGET_COMPONENT = Object.freeze({
    CHANGE_PASSWORD: 'Change Password',
    LOGOUT: 'Logout',
    APP_MANUAL: 'Facility Auditor Guide',
    WEB_MANUAL: 'Facility Admin Guide',
    FAQ: 'FAQs',
    CONTACT_US: 'Contact Us',
    CONTACT_US_CLOSE_CONFIRMATION: {
        title: 'Contact Us',
        message: "For Support, contact <a href='mailto:PeakSupport@Solventum.com'>PeakSupport@Solventum.com</a> or 1-800-228-3957(Option 6)",
        //defaultChoiceLabel: '',
       // alternateChoiceLabel: '',
       oneChoice:'true'
    },
});

export const TWO_CHOICE_MODAL_DIALOG_COMPONENT = Object.freeze({
    DEFAULT_CHOICE_DEFAULT_LABEL: 'OK',
    ALTERNATE_CHOICE_DEFAULT_LABEL: 'Cancel',
});

export const CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT = Object.freeze({
    FIELD_LENGTH: {
        NAME: {
            MAX: 255,
        },
        GREEN_RATE: {
            MAX: 11,
        },
        YELLOW_RATE: {
            MAX: 11,
        },
    },
    TITLE_CREATE: 'Create Policy',
    TITLE_EDIT: 'Copy Policy',
    CANCEL: 'Cancel',
    PREVIOUS: 'Previous',
    NEXT: 'Next',
    FINISH: 'Finish',
    FINISH_AND_MAKE_ACTIVE: 'Finish and Make Active',
    FIELDS: {
        POLICY_NAME: 'Policy Name',
        AUDIT_TYPE: 'Audit Type',
        HEALTH_SYSTEM:'Health System',
        ASSIGN_TO_HEALTH_SYSTEM:'Assign to Health System',
    },
    ERRORS: {
        FIELDS_REQUIRED: 'Please fill in all required fields underlined in red.',
        PATTERN_REQUIRED: 'Please use only characters or numbers as required field .',
        POLICY_NAME_NOT_UNIQUE: 'This policy name is already used for this facility. Please choose a unique name.',
    },
    CLOSE_CONFIRMATION: {
        title: 'Close Policy?',
        message: `You will lose all the data you've entered so far.`,
    },
    SUMMARY: 'Summary',
    COMPLIANCE_RATES: {
        YELLOW: 'Minimum compliance rate % that should display on reports as yellow:',
        YELLOW_SUB: 'Any compliance rate below this value will display as red.',
        GREEN: 'Minimum compliance rate % that should display on reports as green:',
        HEADER: 'All sections require at least one answer.',
        // tslint:disable-next-line:quotemark
        FOOTER: "Note: Default values for dashboard colors are based a study that demonstrated that the use of an intervention including disinfecting port protectors at a compliance rate of 85% or greater decreased the rate of CLABSIs by 87% (p=0.03).¹ Dashboard colors are for visual identification purposes only and do not reflect 3M assessment of compliance rates. You may modify the default compliance rates associated with each color at any time.\n(1) Sweet MA, Briggs F, Craig M, Hamadani M. Impact of alcohol-impregnated port protectors and needleless connectors on central line-associated bloodstream infections and contamination of blood cultures in an inpatient oncology unit. Am J Infect Control. 2012; 40(10): 931-934.",
    },
});
export const DATA_ENTRY_PAGE_COMPONENT = Object.freeze({


    CLOSE_CONFIRMATION: {
            title: 'Close Audit?',
            message: `If you exit this screen before completing all fields required for the current patient, you will lose any incomplete data. Do you want to proceed with exiting now?`,
            defaultChoiceLabel: 'Yes',
            alternateChoiceLabel: 'No',
        },
        REMOVE_AUDIT_CLOSE_CONFIRMATION: {
            title: 'Remove Audit?',
            message: `Are you sure want to remove this Audit?`,
            defaultChoiceLabel: 'Yes',
            alternateChoiceLabel: 'No',
        },

});


export const AUDIT_SETUP_PAGE_COMPONENT = Object.freeze({
    ACTIVE_POLICY: 'Active Policy',
    TABS: {
        POLICIES: 'Policies',
        HISTORY: 'History',
        ARCHIVE: 'Archive',
    },
    NAME: 'Name',
    AUDIT_TYPE: 'Audit Type',
    START_DATE: 'Start Date',
    CREATED: 'Created',
    UPDATED: 'Updated',
    END_DATE: 'End Date',
    PRINT: 'Print',
    COPY: 'Copy',
    EDIT: 'Edit',
    VIEW: 'View',
    CREATE_POLICY: 'Create Policy',
    NO_PERMISSION: 'Sorry, your user does not have permission to do this. Please contact your facility administrator.',
    SELECT_FACILITY_WARNING: 'Please select a facility.',
    SELECT_FACILITY_TO_USE_FEATURE: 'Please select a facility to use this feature.',
    UNKNOWN: 'Unknown',
    NO_POLICIES: {
        MESSAGE: `You'll need to set up an audit before you can collect data from the facility.`,
        BUTTON_LABEL: 'Create Policy',
    },
    NO_ACTIVE_POLICY_SELECTED: 'None',
    PRINT_MENU_OPTIONS: [
        'Policy',
        'Audit Form',
    ],
    POLICY_SETUP_WARNING_TITLE: 'Warning',
    POLICY_SETUP_WARNING: 'The PEAK™ Program is unable to audit compliance to this protocol at this time. Please use facility auditing systems to audit compliance to this protocol.',
});

export const PEAK_DATE_PICKER_COMPONENT = Object.freeze({
    NULL_DATE_SELECTED: 'Select',
});

export const REPORTS_PAGE_COMPONENT = Object.freeze({
    HINTS: {
        SELECT_FACILITY: 'Please select a facility from above.',
        SELECT_OTHER_FACILITY: 'Please select any other facility from above.',
        //SELECT_FACILITY: 'Page is under contruction.',
        SELECT_OPTIONS: 'Please choose from the options above.',
        NO_POLICY: 'No active policy could be retrieved for this facility.',
        NO_DATA: 'There is no data to show for the selected options.',
        COMP_TRENDS: 'Compliance Trends',
        ACTIVE_USERS: 'Active Users',
        ACTIVE_POLICIES: 'Active Policies',
        AUDIT_TRENDS: 'Audit Trends',
        AUDIT_COUNT: 'Audit Count',
        AUDIT_BY: 'Audit By',
        PORT_COMPLIANCE: 'Port Compliance',
        PATIENT_COMPLIANCE: 'Patient Compliance',
        BOTH_COMPLIANCE: 'Both',
        PORT_COMPLIANCE_All: 'Overall Port Compliance'
    }
});



