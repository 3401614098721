import {throwError as observableThrowError, Observable} from 'rxjs';


 


import {catchError, map} from 'rxjs/operators';

import 'rxjs/Rx';

import {Headers, Http, RequestOptions} from '@angular/http';

import {ConstantService} from './constant-service';

import {Response} from "@angular/http/src/static_response";

import {TokenService} from "./token.service";

import {Injectable} from "@angular/core";

import {WebserviceUrl} from "./webservice-url";


 


export enum Method {

    Get,

    Post,

    Put,

    Delete,

}


 


export type PossibleValues = number | string | boolean | UnknownObject;


 


export interface UnknownObject {

    [x: string]: PossibleValues | PossibleValues[];

}


 


export const CONTENT_TYPE_HEADER_NAME: string = 'Content-Type';

// export const CONTENT_TYPE_JSON: string = 'application/json';

export const CONTENT_TYPE_JSON: string = 'text/plain';

export const AUTH_TOKEN_HEADER_NAME: string = 'X-AUTH-TOKEN';


 


@Injectable()

export abstract class AbstractBackendService {

    protected constantService: ConstantService;

    protected http: Http;

    protected tokenService: TokenService;


 


    constructor(constantService: ConstantService,

                http: Http,

                tokenService: TokenService

    ) {

        this.constantService = constantService;

        this.http = http;

        this.tokenService = tokenService;

    }


 


    protected makeAuthenticatedRequestObjectResponse(path: string, method: Method, params?: Object): Observable<UnknownObject> {

        return this.makeAuthenticatedRequest(path, method, params).pipe(

            map((res: UnknownObject | UnknownObject[]) => {

                if (Array.isArray(res)) {

                    throw new Error('AbstractBackendService - got array response when expecting object');

                }


 


                console.log(res);

                return res;

            }));

    }


 


    protected makeAuthenticatedRequestArrayResponse(path: string, method: Method, params?: Object): Observable<UnknownObject[]> {

        return this.makeAuthenticatedRequest(path, method, params).pipe(

            map((res: UnknownObject | UnknownObject[]) => {

                if (Array.isArray(res)) {

                    return res;

                }

                throw new Error('AbstractBackendService - got object response when expecting array');

            }));

    }


 


    // TODO: NL - see if we can move to a promise based flow instead of using Observable

    // TODO: NL - allow the use of generics to support stricter typing

    protected makeAuthenticatedRequest(path: string, method: Method, params?: Object): Observable<UnknownObject | UnknownObject[]> {

        const options = this.getAuthRequestConfig();

        console.assert(undefined != options,

            'AbstractBackendService#makeAuthenticatedRequest - failed to get auth request config');

        const url: string = WebserviceUrl.WEBSERVICE_URL + "api/key/getPubKey";


 


        let request: Observable<Response>;

        switch (method) {

            case Method.Get:

                console.assert(undefined == params,

                    'AbstractBackendService#makeAuthenticatedRequest - GET requests with params are not supported');

                request = this.http.get(url, options);

                break;

            case Method.Post:

                // TODO: some calls seem to stringify the params before sending, their server may be expecting that

                request = this.http.post(url, params, options);

                break;

            case Method.Put:

                request = this.http.put(url, params, options);

                break;

            case Method.Delete:

                console.assert(undefined == params,

                    'AbstractBackendService#makeAuthenticatedRequest - DELETE requests with params are not supported');

                request = this.http.get(url, options);

                break;

            default:

                throw new Error('AbstractBackendService#makeAuthenticatedRequest - unknown request type: ' + method);

        }


 


        return request.pipe(

            map(res => {

                var data = res.json();

                console.log(res.json());

                return data;

            }),

            catchError(error => {

                return observableThrowError(error);

            }),);

    }


    /*protected getAuthRequestConfig(): RequestOptions {

        const headers = new Headers();

        //headers.append(CONTENT_TYPE_HEADER_NAME, CONTENT_TYPE_JSON);

        headers.append(CONTENT_TYPE_HEADER_NAME, 'text/plain');

        if (this.tokenService.get()) {

            headers.append(AUTH_TOKEN_HEADER_NAME, this.tokenService.get());

        }

        const options = new RequestOptions({

            headers: headers,

            withCredentials: false

        });


        return options;

    }*/




    protected getAuthRequestConfig(): RequestOptions {



        if (this.tokenService.get()) {

            var headerLogin=new Headers({"X-AUTH-TOKEN":this.tokenService.get()});

            headerLogin.append(CONTENT_TYPE_HEADER_NAME, 'text/plain');

            return new RequestOptions({

                headers: headerLogin,
                withCredentials: false

            });

        }
        

        var headers= new Headers();

        //headers.append(CONTENT_TYPE_HEADER_NAME, CONTENT_TYPE_JSON);

        headers.append(CONTENT_TYPE_HEADER_NAME, 'text/plain');

        const options= new RequestOptions({

            headers: headers,
            withCredentials: false

        });
        

        return options;

    }





}