import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {DataService} from '../../shared/service/data.service';
import {NotificationService} from '../../shared/service/notification.service';
import {TokenService} from '../../shared/service/token.service';
import {ErrorService} from '../../shared/service/error-service';
import {USER_LIST_PAGE as UI_CONSTANTS} from '../../shared/constants/uiConstants';
import {SimpleModalService} from 'ngx-simple-modal';
import {CreateAndEditUserModalDialogMode, UserAddComponent} from '../user-add/user-add.component';
import {Route, RoutePath} from '../../shared/constants/index';
import { trigger, style, animate, transition } from '@angular/animations';
import {MODAL_DIALOG_FADE_DURATION_MS} from '../../shared/constants/animationConstants';
import AbstractSkeletonLoader from '../../shared/animations/AbstractSkeletonLoader';
import {User} from '../../shared/model/user';
import {FacilityWorker} from '../../shared/model/facility-worker';
import {Facility} from '../../shared/model/facility';
import {UserService, FacilityService} from '../../shared/api';
import { RolesInfoDialog } from '../roles-info/roles-info-dialog.component';
import {ROLES} from '../../shared/constants/index';
import { NgxSpinnerService } from 'ngx-spinner';

type UserListTabs = 'Active' | 'Inactive';

@Component({
    templateUrl: 'user-list.component.html',
    styleUrls: ['user-list.component.scss'],    
    animations: [
        trigger('opacityFade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.3s', style({ opacity: 0 })),
    ])])],
})
export class UserListComponent extends AbstractSkeletonLoader implements OnInit {
    public activeUserList: User[] = [];
    public inactiveUserList: User[] = [];
    public searchText: string;
    public selectedTab: UserListTabs = 'Active';
    public UI = UI_CONSTANTS;
    private selectedFacility: Facility;
    private facilityIdToFacilityWorkersMap: Map<number, FacilityWorker[]> = new Map<number, FacilityWorker[]>();
    public readonly permissions = {
        create: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN, ROLES.ROLE.ROLE_REP],
    };

    constructor(
        private router: Router,
        private tokenService: TokenService,
        private dataService: DataService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private simpleModalService: SimpleModalService,
        private userService: UserService,
        private facilityService: FacilityService,
        private spinner: NgxSpinnerService
    ) {
        super();
    }

    ngOnInit() {
        this.loadAllUsers();
    }

    public mayCurrentUserEditUser(user: User): boolean {
        //return this.userService.mayActiveUserAdministerUser(user);
        return this.userService.hasPermisssToChangeForCurrentUser(user);
    }

    // This gets called on page initialization and whenever the facility selector selection changes
    public onSelectedFacilityChanged(newFacility: Facility | undefined) {
        this.selectedFacility = newFacility;
        this.loadFacilityWorkersForFacility(newFacility);
    }

    private loadAllUsers() {
        this.showSkeletonAfterDelay();
        this.spinner.show();
        // Load all users
        this.userService.getAllEntities().subscribe(users => {
            this.spinner.hide();
            this.activeUserList = users.filter(u => u.active);
            this.inactiveUserList = users.filter(u => !u.active);

            this.hideSkeletonAndShowContent();
        }, err => this.errorService.handleErrorStatusCode(err));            
    }

    private loadFacilityWorkersForFacility(facility?: Facility) {
        // Facility ID 0 is a special case for "All Facilities"
        if (facility == null || facility.id === 0) {
            return;
        } else {
            if(this.userService.isUserAdminAtFacility(facility.id) ==false){
                this.notificationService.warn('','You are not the Admin for selected facility. Please select any other facility.');
                return;
            }
                
            if (false === this.facilityIdToFacilityWorkersMap.has(facility.id)) {
                this.showSkeletonAfterDelay();
            }

            this.facilityService.getWorkersForFacilityId(facility.id).subscribe(facilityWorkers => {
                this.hideSkeletonAndShowContent();
                this.facilityIdToFacilityWorkersMap.set(facility.id, facilityWorkers);
            });    
        }
    }

    public setUserActiveFlagOnBackend(user: User, makeActive: boolean): void {
        user.active = makeActive;
        this.userService.updateEntity(user.id, {
            active: makeActive
        }).subscribe(() => {
            if (makeActive) {
                this.notificationService.success('', UI_CONSTANTS.SUCCESS.MAKE_ACTIVE);
            } else {
                this.notificationService.success('', UI_CONSTANTS.SUCCESS.MAKE_INACTIVE);
            }
        }, err => this.errorService.handleErrorStatusCode(err));
    }

    public selectTab(tab: UserListTabs): void {
        this.selectedTab = tab;
    }

    public isTabSelected(tab: UserListTabs): boolean {
        return this.selectedTab === tab;
    }

    public get shouldShowEmptyListIndicator() {
        return this.userListForCurrentlySelectedTab.length == 0;
    }

    public filterUserListBySelectedFacility(users: User[]): User[] {

        if (this.selectedFacility == null || this.selectedFacility.id === 0) {
            return users;
        } else {
            
            if (this.facilityIdToFacilityWorkersMap.has(this.selectedFacility.id)) {
                const facilityWorkers: FacilityWorker[] = this.facilityIdToFacilityWorkersMap.get(this.selectedFacility.id);
                return users.filter(user => {
                    return facilityWorkers.find(facilityWorker => facilityWorker.userId === user.id) !== undefined;
                });
            } else {
                return [];
            }
        }
    }

    public get userListForCurrentlySelectedTab(): User[] {
        
        
        switch (this.selectedTab) {
            case 'Active':
                return this.filterUserListBySelectedFacility(this.activeUserList);
            case 'Inactive':
                return this.filterUserListBySelectedFacility(this.inactiveUserList);
        }
        console.assert(
            false,
            'UserListComponent#get getUserListForCurrentlySelectedTab - this.selectedTab has unexpecrted value'
        );
        return [];
    }

    public get labelForToggleActiveHeader(): string {
        switch (this.selectedTab) {
            case 'Active':
                return this.UI.MAKE_INACTIVE;
            case 'Inactive':
                return this.UI.MAKE_ACTIVE;
        }
        console.assert(
            false,
            'UserListComponent#get labelForToggleActiveHeader - this.selectedTab has unexpecrted value'
        );
        return '';
    }

    public getToggleIsUserActiveLabelForUser(user: User): string {
        return user.active ? this.UI.INACTIVE : this.UI.ACTIVE;
    }

    private displayCreateAndEditModal(mode: CreateAndEditUserModalDialogMode): void {
        this.simpleModalService
            .addModal(
                UserAddComponent,
                { mode },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                if (undefined != result) {
                    if (true == result.needsRefresh) {
                        this.loadAllUsers();
                        this.loadFacilityWorkersForFacility(this.selectedFacility);
                    }
                }
            });
    }

    public onViewUserPressed(user: User): void {
        console.assert(user != null, 'UserListComponent#onViewUserPressed user passed was null');
        this.dataService.user = user;
        this.router.navigate([`${Route.USER}/${RoutePath.DETAIL}`]);
    }

    public onCreateUserPressed(): void {
        this.displayCreateAndEditModal(CreateAndEditUserModalDialogMode.Create);
    }

    public showRolesInfoModal(): void {
        this.simpleModalService
            .addModal(
                RolesInfoDialog,
                {},
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            );
    }

    public onToggleActivePressed(user: User): void {
        // Optimistically update the UI
        if (user.active) {
            user.active = false;
            this.activeUserList = this.activeUserList.filter(activeUser => activeUser !== user);
            this.inactiveUserList = [...this.inactiveUserList, user];
        } else {
            user.active = true;
            this.inactiveUserList = this.inactiveUserList.filter(activeUser => activeUser !== user);
            this.activeUserList = [...this.activeUserList, user];
        }

        this.setUserActiveFlagOnBackend(user, user.active);
    }
}
