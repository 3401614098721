import {CrudApiService} from "./common/crud.api.service";
import {ContactUs, IContactUs} from "../model/contact-us";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

@Injectable()
export class ContactUsService extends CrudApiService<ContactUs, IContactUs, Partial<ContactUs>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/contactus");
    }

    protected assembleSingleInstance(_: any): ContactUs {
        return new ContactUs(_);
    }

    add( contactUs: ContactUs): Observable<ContactUs> {
        return this.post<ContactUs>(`/`, contactUs);
    }
}

