import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Http } from '@angular/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateStaticLoader } from 'ng2-translate';
import { SimpleModalModule } from 'ngx-simple-modal';

import { UnitComponent } from './unit.component';
import { UnitRoutes } from './unit.route';
import { FilterPipe } from './unit-pipes';

import { TooltipModule } from 'primeng/tooltip';

import { FacilitySelectorModule } from '../facility-selector/facility-selector.module';
import { AccountWidgetModule } from '../account-widget/account-widget.module';

// services
import { CreateAndEditUnitModalDialogComponent } from './create-and-edit-unit-modal-dialog/create-and-edit-unit-modal-dialog.component';
import {UnitService} from "../shared/api";
import {DirectiveModule} from "../shared/directive/directive.module";

export function translateLoader(http: Http) {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
    declarations: [UnitComponent, FilterPipe, CreateAndEditUnitModalDialogComponent],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        TooltipModule,
        RouterModule.forRoot(UnitRoutes),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
        FacilitySelectorModule,
        SimpleModalModule.forRoot({ container: document.body }),
        AccountWidgetModule,
        DirectiveModule
    ],
    providers: [UnitService],
    entryComponents: [CreateAndEditUnitModalDialogComponent],
})
export class UnitModule {}
