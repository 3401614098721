import {Facility} from "../model/facility";
import {User} from "../model/user";
import {BehaviorSubject} from "rxjs";

export class DataService {
    /**
     * Used by the edit facility modal - list facility
     * passes in the selected facility and the modal
     * can pick it up from here.
     *
     * TODO: Does not seem to require getter/setter but
     * follow the common practice.
     */
    private _editFacility: Facility;
    public readonly defaultLocationSubject: BehaviorSubject<Facility> = new BehaviorSubject<Facility>(undefined);
    private _policy: any;
    private _policyDetailsForReview: any;
    private _user: User;

    public get facility(): Facility { return this._editFacility; }
    public set facility(l) { this._editFacility = l; }

    public get defaultLocation(): Facility {
        return this.defaultLocationSubject.getValue();
    }

    public set defaultLocation(v: Facility) {
        // this._defaultLocation = v;
        this.defaultLocationSubject.next(v);
    }
    public get policy(): any {
        return this._policy;
    }

    public set policy(v: any) {
        this._policy = v;
    }

    public get user(): User {
        return this._user;
    }

    public set user(v: User) {
        this._user = v;
    }

    get policyDetailsForReview(): any {
        return this._policyDetailsForReview;
    }

    set policyDetailsForReview(value: any) {
        this._policyDetailsForReview = value;
    }
}
