import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {Http} from '@angular/http';
import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {DataTableModule, SharedModule} from 'primeng/primeng';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {PolicyListComponent} from './policy-list.component';
import {PolicyRoutes} from '../policy-maintenance.route';
import {DialogModule, OverlayPanelModule} from 'primeng/primeng';
import {OrderrByPipe} from './orderby.pipe';
// services
import {PolicyListService} from './policy-list.service';

export function translateLoader(http: Http) {
  return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
  declarations: [
    PolicyListComponent,OrderrByPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    DataTableModule,
    CommonModule,
    SharedModule,
    DialogModule,
    OverlayPanelModule,
    RouterModule.forRoot(PolicyRoutes),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [Http]
    }),
  ],
  providers: [
    PolicyListService
  ]
})
export class PolicyListModule {
}
