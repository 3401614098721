import {Injectable} from '@angular/core';
import {NotificationService} from './notification.service';
import {TokenService} from './token.service';
import {Route, MESSAGES} from '../../shared/constants/index';
import {Router} from '@angular/router';
import { DATA_ENTRY_PAGE } from '../constants/uiConstants';

@Injectable()
export class ErrorService {

  constructor(private notificationService: NotificationService,
              private tokenService: TokenService,
              private router: Router) {
  }
  // TODO: NL - this is heavily used, but really doesn't need to be it's own service,
  // consider moving into new abstract http service class when implemented, since http services should
  // be the only ones referencing this code

  public handleErrorStatusCode(error): any {
    if(error.error != null && error.error.status == 502){
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_INVALID_CREDENTIAL);
      return;
    }
    if (error.status === 403 || (error.error != null && error.error.status === 403)) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACCESS_DENIED);
    } //else if (error.status === 404) {
     // this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_INVALID_CREDENTIAL);
   // }
    else if (error.status === 406) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_NOT_EXIST);
    } else if (error.status === 409) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.CONFLICTING_RECORD);
    } else if (error.status === 412) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_NOT_EXIST);
    } else if (error.error != null && (error.status === 417 || error.error.status == 404)) {
      this.tokenService.signOutAndClearUserData();
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.USER_EXPIRED);
      this.router.navigate([Route.LOGIN]);
    } else if (error.status === 500) {
     // this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.SERVER_ERROR);
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.INTERNAL_SERVER_ERROR);
      
    } else if (error.status === 501) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.FORGOT_PASSWORD_INITIATED);
    } else if (error.status === 502) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.WRONG_USERNAME_PASSWORD);
    } else if (error.status === 503) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACTIVATE_ACCOUNT);
    } else if (error.status === 504) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACCOUNT_LOCKED);
    } else if (error.status === 505) {
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ACCOUNT_EXPIRED);
    } else {
      //this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ERROR_CONTACT_ADMIN);
      this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.AN_ERROR_OCCURRED);
    }
  }

}
