import {TokenService} from './../service/token.service';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from 'ng2-translate';
import {Router} from '@angular/router';

import {NotificationEmitterService} from '../service/notification-emitter.service';
import {ConstantService} from '../service/constant-service';
import {EmitterService} from '../service/emitter.service';
import {LANGUAGE_LIST, PERMISSIONS, Route} from '../constants/index';
import {Util} from '../../util/util';
import {ROLES} from "../constants/index";
import {UserService} from "../api";
import {UserRoleString} from "../model/role";

import {User} from "../model/user";
interface RouteItem {
    id: number;
    name: string;
    link: string; // TODO: NL - convert Route const into an enum and use it as a type here,
    permissions: string[];
}

interface NavigationBarRoutes {
    FACILITIES: RouteItem;
    UNITS: RouteItem;
    USERS: RouteItem;
    NONE: RouteItem;
    POLICY: RouteItem;
    REPORTS: RouteItem;
    DATA_ENTRY: RouteItem;
    SUMMARY_REPORTS: RouteItem;
}

export const RoutePermissions: { [key: string]: string[] } = {
    [Route.LOCATION]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ROLE_REP],
    [Route.UNIT]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M,ROLES.ROLE.ROLE_REP],
    [Route.USER]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN, ROLES.ROLE.ROLE_REP /**/],
    [Route.POLICY]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M,ROLES.ROLE.AUDITOR,ROLES.ROLE.ROLE_REP],
    [Route.DASHBOARD]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M,ROLES.ROLE.ROLE_REP],
    [Route.USER_DETAILS]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M, ROLES.ROLE.ROLE_REP /* */],
    [Route.DATA_ENTRY]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN, ROLES.ROLE.AUDITOR,ROLES.ROLE.ROLE_REP],
    [Route.SUMMARY_REPORTS]: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M,ROLES.ROLE.ROLE_REP],
};

const NAVIGATION_ROUTES: NavigationBarRoutes = {
    FACILITIES: {
        id: 0,
        name: 'Facilities',
        link: Route.LOCATION,
        permissions: RoutePermissions[Route.LOCATION]
    },
    UNITS: {
        id: 1,
        name: 'Units',
        link: Route.UNIT,
        permissions: RoutePermissions[Route.UNIT]
    },
    USERS: {
        id: 2,
        name: 'Users',
        link: Route.USER,
        permissions: RoutePermissions[Route.USER]
    },
    POLICY: {
        id: 3,
        name: 'Policy',
        link: Route.POLICY,
        permissions: RoutePermissions[Route.POLICY]
    },
    REPORTS: {
        id: 4,
        name: 'Reports',
        link: Route.DASHBOARD,
        permissions: RoutePermissions[Route.DASHBOARD]
    },
    DATA_ENTRY: {
        id: 5,
        name: 'Data Entry',
        link: Route.DATA_ENTRY,
        permissions: RoutePermissions[Route.DATA_ENTRY]
    },
    SUMMARY_REPORTS: {
        id: 6,
        name: 'Summary Reports',
        link: Route.SUMMARY_REPORTS,
        permissions: RoutePermissions[Route.SUMMARY_REPORTS]
    },
    NONE: {
        id: -1,
        name: 'NONE',
        link: '',
        permissions: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN, ROLES.ROLE.AUDITOR]
    },
};

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public isAuthorised: Boolean;
    public selectedLan: any;
    public header: any = {};
    public items: any;
    public manageSystem = NAVIGATION_ROUTES;
    public currentAction: any;
    public LANGUAGES = LANGUAGE_LIST;
    public routes = Route;
    public dashboardFlag: Boolean;
    public policyFlag: Boolean;
    public UserRoleString:UserRoleString;

    constructor(
        private _notificationService: NotificationEmitterService,
        private _constantService: ConstantService,
        private translate: TranslateService,
        private router: Router,
        private tokenService: TokenService,
        private userService: UserService,
        private emitterService: EmitterService
    ) {
        this.isAuthorised = false;
        this._notificationService.success('Success Message', 'login is successful');
        this.selectedLan = this.LANGUAGES[0].short_name;
        this.manageSystem = NAVIGATION_ROUTES;
        this.currentAction = this.manageSystem.REPORTS;
      
    }

    public ngOnInit() {
        if (this.tokenService.get()) {
            this.isAuthorised = true;
        }
        this.emitterService.onSigninEvent.subscribe((obj: any) => {
            if (!Util.isEmptyObject(obj) && !Util.isUndefinedOrNull(obj.user) && !Util.isEmptyArray(obj.user.roles)) {
                this.isAuthorised = obj.flag;
                const role = obj.user.roles[0].role;
                this.dashboardFlag = Util.includes(PERMISSIONS.DASHBOARD.HEADER, role);
                this.policyFlag = Util.includes(PERMISSIONS.POLICY.HEADER, role);
            } else {
                this.isAuthorised = false;
            }
        });
        this.emitterService.onSignOutEvent.subscribe(() => {
            this.isAuthorised = false;
        });
        this.checkUserRoleAndRedirectToDefaultRoute();
    }

    public get isUserAdmin(): boolean {
        return this.userService.isUserAdmin;
    }

    public langChange(lang: any) {
        this.selectedLan = lang.target.value;
        this.translate.use(lang.target.value);
    }

    public resetToManageAccount() {
        this.currentAction = this.manageSystem.FACILITIES;
    }

    public setCurrentRoute(route: RouteItem) {
        
        this.currentAction = route;
    }
    public getPrimaryRoleForCurrentUser(): UserRoleString | undefined {
        const loggedUser = this.tokenService.getUserToken();
        return this.getPrimaryRoleFromUser(loggedUser);
    }
    public getPrimaryRoleFromUser(user: User): UserRoleString | undefined {
        if (undefined == user
            || undefined == user.roles
            || user.roles.length == 0
        ) {
            return undefined;
        }

        const rolesInOrderOfRank: UserRoleString[] = [
            ROLES.ROLE.SUPER_ADMIN_3M,
            ROLES.ROLE.ADMIN_3M,
            //ROLES.ROLE.PARENT_ADMIN,
            ROLES.ROLE.ROLE_REP,
            ROLES.ROLE.FACILITY_ADMIN,
            ROLES.ROLE.AUDITOR,
        ];

        for (let r of rolesInOrderOfRank) {
            let role = user.roles.find(userRole => userRole.role === r);
            if (role) {
                return role.role;
            }
        }
    }
    public checkUserRoleAndRedirectToDefaultRoute() {
        const loggedUser = this.tokenService.getUserToken();
        const role = this.getPrimaryRoleForCurrentUser();

      
        if (role === ROLES.ROLE.SUPER_ADMIN_3M ||
            role === ROLES.ROLE.ADMIN_3M ||
            //role === ROLES.ROLE.PARENT_ADMIN 
            role === ROLES.ROLE.FACILITY_ADMIN||
            role === ROLES.ROLE.FACILITY_ADMIN) {

            

            if (role === ROLES.ROLE.ADMIN_3M) {
                //this.router.navigate([Route.POLICY]);
                this.currentAction = this.manageSystem.POLICY;
                return;
            
            }
            //this.router.navigate([Route.DASHBOARD]);
            this.currentAction = this.manageSystem.REPORTS;
            return;

        } else if (role === ROLES.ROLE.AUDITOR) {
     
            //this.router.navigate([Route.POLICY]);
            
            this.currentAction = this.manageSystem.POLICY;
            return;

        }

        
    }
}
