import { Injectable } from '@angular/core';

@Injectable()
export class AppBlurService {
    private _blurCount: number = 0;

    public get shouldBlur(): boolean {
        return this._blurCount > 0;
    }

    public get blurCount(): number {
        return this._blurCount;
    }

    public pushBlurCount() {
        // Changing this inside setTimeout avoids ExpressionChangedAfterItHasBeenCheckedError as suggested in https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
        window.setTimeout(() => {
            this._blurCount++;
        });
    }

    public popBlurCount() {
        window.setTimeout(() => {
            this._blurCount--;
            console.assert(this._blurCount >= 0, 'AppBlurService#popBlurCount - popped too many times, blurCount is less than zero');
        });
    }
}
