
export interface IFacilityLocation {
    address1?: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;
}

export class FacilityLocation implements IFacilityLocation {
    address1?: string;
    address2?: string;
    city: string;
    state: string;
    zip: string;

    constructor(args: IFacilityLocation) {
        console.assert(args !== undefined && args !== null, 'FacilityLocation constructor passed null or undefined args object');
        if (args.address1 !== undefined && args.address1 !== null) {
          console.assert(typeof args.address1 === 'string', 'FacilityLocation constructor passed args with invalid value');
        }
        if (args.address2 !== undefined && args.address2 !== null) {
          console.assert(typeof args.address2 === 'string', 'FacilityLocation constructor passed args with invalid value');
        }
        console.assert(typeof args.city === 'string', 'FacilityLocation constructor passed args with invalid value');
        console.assert(typeof args.state === 'string', 'FacilityLocation constructor passed args with invalid value');
        console.assert(typeof args.zip === 'string', 'FacilityLocation constructor passed args with invalid value');

        this.address1 = args.address1 || undefined;
        this.address2 = args.address2 || undefined;
        this.city = args.city;
        this.state = args.state;
        this.zip = args.zip;
    }
}
