import {CrudApiService} from "./common/crud.api.service";

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

import { Patient, IPatient, PatientAnswer, PatientCatheter,IPatientCatheter } from '../../shared/model/patient';
import { QuestionAnswer, IQuestionAnswer } from '../model/question-answer';

 @Injectable()
export class CatheterService extends CrudApiService<PatientCatheter, IPatientCatheter, Partial<PatientCatheter>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/catheter");
    }

    protected assembleSingleInstance(_: any): PatientCatheter {
        return new PatientCatheter(_);
    }

    getCatheters(patientID: number): Observable<Array<PatientCatheter>> {
      return this.get<Array<PatientCatheter>>(`/${patientID}`);
  }

} 

