import {CrudApiService} from "./common/crud.api.service";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import { Observable } from 'rxjs';
import { Unit } from "../model/unit";

interface IReportDetails {
    policyType: number;
    timeShiftAvailable: number;
    availableDates: Array<IReportDate>;
    
  }
  interface IReportDate{
    day:number;
    month:number;
    year:number;
    shift: string;

  }

  export class ReportDetails implements IReportDetails {
    policyType: number;
    timeShiftAvailable: number;
    availableDates: Array<ReportDate>;
    units: Array<Unit>;

    constructor(args: IReportDetails) {
        
        this.policyType = args.policyType;
        this.timeShiftAvailable = args.timeShiftAvailable;
        this.availableDates = args.availableDates;
    }

}

export class ReportDate implements IReportDate {
    day:number;
    month:number;
    year:number;
    shift: string;

    constructor(args: IReportDate) {
        
        this.day = args.day;
        this.month = args.month;
        this.year = args.year;
        this.shift = args.shift;
    }

}

  /*


{"policyType":2,"timeShiftAvailable":1,"availableDates":[{"day":18,"month":9,"year":2019},{"day":18,"month":9,"year":2019},{"day":9,"month":6,"year":2019}]} */

@Injectable()
export class ReportDetailsService extends CrudApiService<ReportDetails, IReportDetails, Partial<ReportDetails>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        //super(http, tokenService, "/api/reports/getReportDetails?facilityId=");
        super(http, tokenService, "api/reports/getReportDetails?policyId=");
        
    }

    getReportDetails(policyId: number,isHealthSystemPolicy:boolean): Observable<ReportDetails> {        
        return this.get<ReportDetails>(`${policyId}&healthsystem=${isHealthSystemPolicy}`);
    }
   
    protected assembleSingleInstance(_: any): ReportDetails {
        return new ReportDetails(_);
    }
}
