import { Pipe, PipeTransform } from '@angular/core';
import {Facility} from "../../shared/model/facility";

@Pipe({
    name: 'FilterPipe',
})
export class FilterPipe implements PipeTransform {
    transform(facilityArray: Facility[], input: string) {
        if (input) {
            input = input.toLowerCase();
            return facilityArray.filter(facility => {
                return (
                    facility.name.toLowerCase().indexOf(input) > -1 ||
                    (facility.sap && facility.sap.toLowerCase().indexOf(input) > -1)
                );
            });
        }
        return facilityArray;
    }
}
