import {CrudApiService} from "./common/crud.api.service";
import {Question, IQuestion} from "../model/question";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";

@Injectable()
export class QuestionService extends CrudApiService<Question, IQuestion, Partial<Question>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/questions")
    }
    protected assembleSingleInstance(_: any): Question {
        return new Question(_);
    }
}