import {Injectable} from '@angular/core';
import 'rxjs/Rx';
import {Observable} from 'rxjs';
import {AbstractBackendService, Method, UnknownObject} from '../../shared/service/AbstractBackendService';

@Injectable()
export class UserAddService extends AbstractBackendService {

    public getPublicKey(): Observable<UnknownObject> {
        const path: string = 'api/key/getPubKey';
        return this.makeAuthenticatedRequestObjectResponse(path, Method.Get);
    }
}
