import { Component,ViewChild, OnInit } from '@angular/core';

import { SimpleModalService } from 'ngx-simple-modal';
//import {NgControl, NgForm} from '@angular/forms';
//import {User} from '../shared/model/user';
import {RoleService, UserService} from '../shared/api';
import {ROLES} from '../shared/constants';
import {UserRole} from '../shared/model/role';
import { NotificationService } from '../shared/service/notification.service';
import { ErrorService } from '../shared/service/error-service';
import { DATA_ENTRY_PAGE as UI_CONSTANTS } from '../shared/constants/uiConstants';
import {CreateAndEditDataEntryModalDialogMode, DataEntryAddComponent} from './data-entry-add/data-entry-add.component';
import {MODAL_DIALOG_FADE_DURATION_MS} from '../shared/constants/animationConstants';
import AbstractSkeletonLoader from '../shared/animations/AbstractSkeletonLoader';
import { FacilityService,UnitService } from '../shared/api';
import { Facility } from '../shared/model/facility';
import {Unit} from "../shared/model/unit";
import {IMyDateModel} from 'mydatepicker';
import {IMyDpOptions,IMyDate} from 'mydatepicker';
import {DATEFORMAT, MESSAGES} from '../shared/constants/';
import { NgControl, NgForm } from '@angular/forms';
import { Policy } from '../shared/model/policy';
import { PolicyService} from '../shared/api/policy.service';
import { PolicyFilterService } from '../shared/api/policy.filter.service';
import {PolicyTypeService} from '../shared/api/policy.type.service';
import { PolicyType } from '../shared/model/policy-type';
import { Audit } from '../shared/model/audit';
import {Patient,PatientAnswer} from "../shared/model/patient";
import {PatientService} from '../shared/api/patient.service';
import { TokenService } from '../shared/service/token.service';
import { Token } from '@angular/compiler';


export enum AuditTarget {
  Patient = 'Patient',
  Catheter = 'PatientCatheter'
}



export interface IMyDateRange {
    begin: IMyDate;
    end: IMyDate;
}
@Component({
  selector: 'app-data-entry',
  templateUrl: './data-entry.component.html',
  styleUrls: ['./data-entry.component.scss']
})
export class DataEntryComponent extends AbstractSkeletonLoader implements OnInit {
 

  public UI = UI_CONSTANTS;
  public currentAction: any;
  public readonly permissions = {
    create: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN],
    update: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN]
  };
  
  public roleList: UserRole[] = [];
  public selectedRole: UserRole = null;
  public activeUnitList: Unit[];
  public inactiveUnitList: Unit[];
  public selectedFacility: Facility = null;
  private _availableFacilities: Facility[] = [];
  public selectedShift: String;
  public selectedUnit: Unit;
  
  public policyID: number;

  public activePolicy?: Policy;
  private _policies: Policy[] = [];
  private _policyTypes:PolicyType[];
  private policyType:PolicyType;
  public audit?: Audit;
  public patients : Patient[];
  public loading : boolean=true;
  public activePolicyNotFound : boolean=false;
  public hasFacilityLoaded : boolean= false;
  public shouldDsiableStartButton : boolean= true;
  public shouldShowSpinner :  boolean= false;
  public isPolicyLoaded :  boolean= false;

  


  
  private today = new Date();
  public auditDate: IMyDateModel;
  public auditDatePickerOptions: IMyDpOptions = {
    dateFormat: DATEFORMAT.mmmdd_yyyy,
    firstDayOfWeek: 'su',    
    disableSince: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1},
    markCurrentDay:true,
  };
  public selectedDate: any= { date: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() } }; 
  
  @ViewChild('formContainer') public formContainer: NgForm;
  @ViewChild('facilitySelect') public facilitySelect: NgControl;
  @ViewChild('unitSelect') public unitSelect: NgControl;
  @ViewChild('shiftSelect') public shiftSelect: NgControl;
  @ViewChild('dateSelect') public dateSelect: NgControl;

  constructor(
      private notificationService: NotificationService,
      private errorService: ErrorService,
      private facilityService: FacilityService,
      private userService: UserService,
      private unitService: UnitService,
      
      private roleService: RoleService,
      private policyService:PolicyService,
      private policyTypeService:PolicyTypeService,
      private patientService:PatientService,  
      private policyFilterService:PolicyFilterService,   

   
      private simpleModalService: SimpleModalService,

      private tokenService:TokenService
    
  ) {
      super();
  }

  public get availableFacilities(): Facility[] {
    //return this._availableFacilities.filter(f => this.userService.isUserAdminAtFacility(f.id));
    return this._availableFacilities;
  }

  ngOnInit() {
    
    this.showSkeletonAfterDelay();
 
    this.facilityService.getAllActive().then(records => {
      this._availableFacilities = records;
      this.hasFacilityLoaded = true;
      this.selectedFacility = this._availableFacilities[0];
    
      this.getUnitsFromBackend();
      //this.loadPolicies();
      this.getActivePolicy(this.selectedFacility.id);
      console.log(JSON.stringify(this.activePolicy));

    });

        

  }


/*Get Policy */

private getActivePolicy(facilityId:number): Promise<void> {
  return new Promise(resolve => {          
    this.facilityService.activePolicy(facilityId).subscribe(
     
        policy => {
            this.activePolicy  = policy;    
            if(!this.activePolicy){
              this.activePolicyNotFound = true;    
            
            } 
            this.loadPolicyType();
            this.isPolicyLoaded = true;
            
            resolve();
          },
          err => {
            if(err.status== 500){
              //this.notificationService.info('','Not found any active policy for selected Facility.');
              this.notificationService.info('','Sorry, there are not active Policy in your selected Facility. Please contact your administrator.');
              console.log(JSON.stringify(err));
            }
            else
            {
              this.errorService.handleErrorStatusCode(err);
            }
              
          }
      );
  });
}


private setActivePolicy(): void {
  const activePolicy = this._policies.filter(p => p.facilityId === this.selectedFacility.id);          
  this.activePolicy = activePolicy.find(p => p.active);
  if(!this.activePolicy){
    this.activePolicyNotFound = true;    
  
  }
  //console.log(JSON.stringify(this.activePolicy));

  
} 
public isUserAdmin(facilityId?: number): boolean {

  if (undefined != facilityId) {
      return this.userService.isUserAdminAtFacility(facilityId);
  }
  // TODO should block FacilityAdmin from creating new facility ?
  return this.userService.isUserAdmin;
 
}

 private loadPolicies(): Promise<void> {
  return new Promise(resolve => {
      this.policyService.getAllEntities().subscribe(
          policies => {             
             this._policies = policies;                         
              this.setActivePolicy();
              this.loadPolicyType();
              this.isPolicyLoaded = true;
              resolve();
          },
          err => {
              this.errorService.handleErrorStatusCode(err);
          }
      );
  });
} 
private loadPolicyType(): Promise<void> {
  return new Promise(resolve => {
      //this.policyTypeService.getAllEntities().subscribe(
      this.policyFilterService.getPolicy(this.selectedFacility.id).subscribe(
      //this.policyFilterService.getPolicyById(this.activePolicy.id).subscribe(
        policyTypes => {
              this._policyTypes = policyTypes;      
              if(this.activePolicyNotFound){this.shouldDsiableStartButton = false;}  
              if(this.activePolicy!== null && this.activePolicy !== undefined)
              this.policyType = this._policyTypes.find(t => t.id == this.activePolicy.policyTypeId);

              this.loading =false;
              this.shouldDsiableStartButton = false
              //this.setActivePolicy();
             //console.log(JSON.stringify(this.policyType));
              resolve();
          },
          err => {
              this.errorService.handleErrorStatusCode(err);
          }
      );
  });
}

/*End Get Policy */ 


  private displayCreateAndEditModal(mode: CreateAndEditDataEntryModalDialogMode): void {
    this.simpleModalService
        .addModal(
          DataEntryAddComponent,
            { mode,
              
              shift: this.selectedShift,
              unit: this.selectedUnit,
              selectedDate: this.selectedDate,
              //auditForGroups:this.audit,
              //existingPoliciesOfFacility: this.policies,
              policy:this.activePolicy,
              policyType:this.policyType,
                          
            },
            {
                animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
            }
        )
       ;
  }

  public onCreateDataEntryPressed(): void {

    console.assert(
      this.formContainer != null,
      'DataEntryComponent#onCreateDataEntryPressed - this.formContainer was null'
    );

    if (false == this.formContainer.form.valid) {
     
      this.handleFormErrors();
      return;
    } 
    
    if(this.activePolicy==undefined || this.loading == true )
    {
      //console.log("Please wait...");
      if(this.activePolicyNotFound == true){
        this.notificationService.warn('','No active Policy');

      }else if( this.activePolicyNotFound == false || this.loading == true){
        this.notificationService.warn('','Data loading is in progress, Please wait and try again');
        this.shouldDsiableStartButton= true;
      }    
      return;

    }
   
    this.displayCreateAndEditModal(CreateAndEditDataEntryModalDialogMode.Create);
  }
  public get shouldRequireFacility(): boolean {
   return true;  
  }

  public getUnitsFromBackend() {
      /* console.assert(
          null != this.selectedFacility,
          'UnitComponent#getUnitsFromBackend called but there is no selectedFacility'
      ); */

      this.showSkeletonAfterDelay();
      let source = null;
      if (this.selectedFacility.id === 0) {
          source = this.unitService.getAllEntities();
      } else {
          source = this.facilityService.getUnits(this.selectedFacility.id);
      }
      source.subscribe(units => {
          this.activeUnitList = units.filter(unit => unit.active);
          if(this.activeUnitList.length ==0){
            this.selectedUnit= null;
            this.shouldShowSpinner=false;
            //this.notificationService.warn('','Not found any active unit for selected Facility.')
            this.notificationService.warn('','Sorry, there are not active units in your selected Facility \'NO UNITS\'. Please contact your administrator.')
          }else       
          this.selectedUnit = this.activeUnitList[0];
          this.inactiveUnitList = units.filter(unit => !unit.active);
          this.hideSkeletonAndShowContent();
      }, err => {
          this.errorService.handleErrorStatusCode(err);
      });


  }


  public onSelectedFacilityChanged(newFacility: Facility) { 
    if(!this.hasFacilityLoaded){

      return;
    }   
    this.selectedFacility = newFacility;          
    this.activePolicy=null;   
    this.activePolicyNotFound = false;
    this.shouldDsiableStartButton = true;
    this.getUnitsFromBackend();
    /*
    if(this.isPolicyLoaded == false){
      this.loadPolicies();
    }else{
      this.setActivePolicy();
      this.loadPolicyType();
    } */
    this.getActivePolicy(this.selectedFacility.id)
    
   

  }

  public getUnitListForCurrentlySelectedFacility(): Unit[] {

          
            return this.activeUnitList;       
  }
  public onSelectedUnitChanged(){

    
  }
  public get hasSubmitted(): boolean {
      console.assert(
          this.formContainer != null,
          'CreateAndEditUnitModalDialogComponent#hasSubmitted - this.formContainer was null'
      );     
    
      return this.formContainer.submitted;
  }

  public get facilitySelected(): boolean {
    if(this.selectedFacility != null){

      return true;
    }else{

      return false;
    }
    

  }

  public shouldControlShowErrorStyle(control: NgControl) {
    console.assert(
        control != null,
        'DataEntryComponent#shouldControlShowErrorStyle - control argument was null'
    );

    if (false === control.touched   && false === this.hasSubmitted) {
        
        return false;
    }
    if (control === this.dateSelect) {

    }
    
    
    return control.errors != null;
}

private handleFormErrors(): void {
    let areAllRequiredFieldsFilled: boolean = true;

    if (this.facilitySelect.errors && this.facilitySelect.errors.required) {
        areAllRequiredFieldsFilled = false;
    }
    if (this.dateSelect.errors && this.dateSelect.errors.required) {
      //areAllRequiredFieldsFilled = false;
      this.notificationService.error('', UI_CONSTANTS.ERRORS.DATE_REQUIRED);
    }
    if (this.unitSelect.errors && this.unitSelect.errors.required) {
      areAllRequiredFieldsFilled = false;
    }
    if (this.shiftSelect.errors && this.shiftSelect.errors.required) {
      areAllRequiredFieldsFilled = false;
    }

    if (false === areAllRequiredFieldsFilled) {
        this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
    }
   
}

public checkForAuditStatus():boolean{

  if (false != this.formContainer.form.valid && this.selectedDate != null && this.selectedUnit != null && this.selectedShift !=null && !this.shouldDsiableStartButton) {
    this.shouldShowSpinner=false;
    return true;
  }else{
      if(false != this.formContainer.form.valid && this.selectedDate != null && this.selectedUnit != null && this.selectedShift !=null && this.shouldDsiableStartButton){

        this.shouldShowSpinner=true;
      }
    return false;
  } 
}
   
  

}
