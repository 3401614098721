import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {WebserviceUrl} from './webservice-url';

@Injectable()
export class ConstantService extends WebserviceUrl {

  public LOGENABLE = true;
  public permissionObj: any;

  constructor(private router: Router) {
    super();
    // TODO: NL - remove unused params
    // TODO: NL - this isn't a service, move into a constants file to simplify references

    // TODO: NL - somehow try to make this type safe, a single typo anywhere in this file could cause problems
    this.permissionObj = {
      'ClientId': '58b67070551b23ca4748b5ef',
      'UserId': '58b675f8551b23ca4748b5f3',
      'Permissions': [{
        'sequenceNo': 10,
        'menuName': 'Dashboard',
        'routeKey': 'dashboard',
        'iconClass': 'fa fa-dashboard fa-2x',
        'PageActions': 'YNNN',
        'componentName': 'DashboardComponent',
        'MenuPermission': {
          'View': 'Yes',
          'Add': 'No',
          'Edit': 'No',
          'Delete': 'No'
        },
        'subMenus': [{
          'sequenceNo': 1010,
          'subMenuName': 'Message Dashboard',
          'routeKey': 'dashboard/dashboard-message',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          }
        }, {
          'sequenceNo': 1020,
          'subMenuName': 'Asset Dashboard',
          'routeKey': 'dashboard/dashboard-asset',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          }
        }, {
          'sequenceNo': 1030,
          'subMenuName': 'Test Dashboard',
          'routeKey': 'dashboard/dashboard-test',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          }
        }, {
          'sequenceNo': 1040,
          'subMenuName': 'Documents Dashboard',
          'routeKey': 'dashboard/dashboard-documents',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          }
        }]
      }, {
        'sequenceNo': 20,
        'menuName': 'Assets',
        'routeKey': 'assets',
        'iconClass': 'fa fa-book fa-2x',
        'PageActions': 'YNNN',
        'componentName': 'AssetsComponent',
        'MenuPermission': {
          'View': 'No',
          'Add': 'No',
          'Edit': 'No',
          'Delete': 'No'
        },
        'subMenus': [{
          'sequenceNo': 2010,
          'subMenuName': 'Manage Assets',
          'routeKey': 'assets/manageassets',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 201010,
            'subsubMenuName': 'Assets List',
            'routeKey': 'assets/manageassets/assetslist',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 201020,
            'subsubMenuName': 'Add Edit Assets',
            'routeKey': 'assets/manageassets/assetsaddedit',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 201030,
            'subsubMenuName': 'Manage Asset Groups',
            'routeKey': 'assets/manageassets/assetgroups',
            'iconClass': '',
            'PageActions': 'YYYY',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }]
        }, {
          'sequenceNo': 2020,
          'subMenuName': 'Asset Manuals',
          'routeKey': 'assets/manuals',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'Yes',
            'Delete': 'Yes'
          },
          'subsubMenus': [{
            'sequenceNo': 202010,
            'subsubMenuName': 'Author Asset Manual',
            'routeKey': 'assets/manuals/auther',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 202020,
            'subsubMenuName': 'Release Asset Manual',
            'routeKey': 'assets/manuals/release',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 202030,
            'subsubMenuName': 'Search Asset Manual',
            'routeKey': 'assets/manuals/search',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 202040,
            'subsubMenuName': 'View Asset Manual',
            'routeKey': 'assets/manuals/view',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }]
        }]
      }, {
        'sequenceNo': 30,
        'menuName': 'Tests',
        'routeKey': 'tests',
        'iconClass': 'fa fa-inbox fa-2x',
        'PageActions': 'YNNN',
        'componentName': 'TestsComponent',
        'MenuPermission': {
          'View': 'Yes',
          'Add': 'No',
          'Edit': 'No',
          'Delete': 'No'
        },
        'subMenus': [{
          'sequenceNo': 3010,
          'subMenuName': 'Test Alerts',
          'routeKey': 'tests/alerts',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 301010,
            'subsubMenuName': 'List Test Alerts',
            'routeKey': 'tests/alerts/list',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 301020,
            'subsubMenuName': 'Test Alerts Reports',
            'routeKey': 'tests/alerts/reports',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 3020,
          'subMenuName': 'Test Schedule',
          'routeKey': 'tests/schedule',
          'iconClass': 'fa fa-industry fa-2x',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 302010,
            'subsubMenuName': 'Standard Schedule',
            'routeKey': 'tests/schedule/standard',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 302020,
            'subsubMenuName': 'List Scheduled Tests',
            'routeKey': 'tests/schedule/list',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 302030,
            'subsubMenuName': 'List Plannded Tests',
            'routeKey': 'tests/schedule/planned',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 302040,
            'subsubMenuName': 'List WIP Tests',
            'routeKey': 'tests/schedule/wip',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 302050,
            'subsubMenuName': 'Plan Test',
            'routeKey': 'tests/schedule/plan',
            'iconClass': '',
            'PageActions': 'YYYY',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }]
        }, {
          'sequenceNo': 3030,
          'subMenuName': 'Asset Test Reports',
          'routeKey': 'tests/reports',
          'iconClass': 'fa fa-file-excel-o fa-2x',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 303010,
            'subsubMenuName': 'Test Results',
            'routeKey': 'tests/reports/results',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 303020,
            'subsubMenuName': 'Test Analytics',
            'routeKey': 'tests/reports/analytics',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 3040,
          'subMenuName': 'Test Manuals',
          'routeKey': 'tests/manuals',
          'iconClass': 'fa fa-file-powerpoint-o fa-2x',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 304010,
            'subsubMenuName': 'Author Test Manual',
            'routeKey': 'tests/manuals/auther',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 304020,
            'subsubMenuName': 'Release Test Manual',
            'routeKey': 'tests/manuals/release',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 304030,
            'subsubMenuName': 'Search Test Manual',
            'routeKey': 'tests/manuals/search',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 304040,
            'subsubMenuName': 'View Test Manual',
            'routeKey': 'tests/manuals/view',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }]
        }]
      }, {
        'sequenceNo': 40,
        'menuName': 'Messages',
        'routeKey': 'messages',
        'iconClass': 'fa fa-file-text fa-2x',
        'PageActions': 'YNNN',
        'componentName': 'MessagesComponent',
        'MenuPermission': {
          'View': 'Yes',
          'Add': 'No',
          'Edit': 'No',
          'Delete': 'No'
        },
        'subMenus': [{
          'sequenceNo': 4010,
          'subMenuName': 'Message Types',
          'routeKey': 'messages/messagetypes',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 401010,
            'subsubMenuName': 'Manage Send List',
            'routeKey': 'messages/messagetypes/sendlist',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 401020,
            'subsubMenuName': 'Manage Report Grouping',
            'routeKey': 'messages/messagetypes/reportgrouping',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 4020,
          'subMenuName': 'Message Reports',
          'routeKey': 'messages/reports',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 402010,
            'subsubMenuName': 'Manage Report Groups',
            'routeKey': 'messages/reports/reportgroups',
            'iconClass': '',
            'PageActions': 'YYYY',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 402020,
            'subsubMenuName': 'Show Report - Pivot',
            'routeKey': 'messages/reports/pivot',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 402030,
            'subsubMenuName': 'Show Report - List',
            'routeKey': 'messages/reports/list',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 402040,
            'subsubMenuName': 'Show Report - Maps',
            'routeKey': 'messages/reports/map',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }]
      }, {
        'sequenceNo': 50,
        'menuName': 'Configure',
        'routeKey': 'configure',
        'iconClass': 'fa fa-gear fa-2x',
        'PageActions': 'YNNN',
        'componentName': 'ConfigureComponent',
        'MenuPermission': {
          'View': 'Yes',
          'Add': 'No',
          'Edit': 'No',
          'Delete': 'No'
        },
        'subMenus': [{
          'sequenceNo': 5010,
          'subMenuName': 'Enterprise',
          'routeKey': 'configure/enterprise',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 501010,
            'subsubMenuName': 'Geography Setup',
            'routeKey': 'configure/enterprise/geography',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 501020,
            'subsubMenuName': 'Departments',
            'routeKey': 'configure/enterprise/departments',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 501030,
            'subsubMenuName': 'Office Types',
            'routeKey': 'configure/enterprise/officetypes',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 501040,
            'subsubMenuName': 'Office Locations',
            'routeKey': 'configure/enterprise/officelocations',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 501050,
            'subsubMenuName': 'Organization Hierarchy',
            'routeKey': 'configure/enterprise/organizationhierarchy',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 501060,
            'subsubMenuName': 'Employees',
            'routeKey': 'configure/enterprise/Employees',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 5020,
          'subMenuName': 'Assets',
          'routeKey': 'configure/assets',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 502010,
            'subsubMenuName': 'Asset Group Types',
            'routeKey': 'configure/assets/assetgrouptypes',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 502020,
            'subsubMenuName': 'Asset Types',
            'routeKey': 'configure/assets/assettypes',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 502030,
            'subsubMenuName': 'Asset Type Manuals',
            'routeKey': 'configure/assets/assettypemanual',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 5030,
          'subMenuName': 'Asset Tests',
          'routeKey': 'configure/assettests',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 503010,
            'subsubMenuName': 'Test Setup',
            'routeKey': 'configure/assettests/setup',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 503020,
            'subsubMenuName': 'Asset Test Templates',
            'routeKey': 'configure/assettests/templates',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 503030,
            'subsubMenuName': 'Asset Test Manuals',
            'routeKey': 'configure/assettests/manuals',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 503040,
            'subsubMenuName': 'Asset Test Frequencies',
            'routeKey': 'configure/assettests/frequencies',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 503050,
            'subsubMenuName': 'Asset Test Alerts',
            'routeKey': 'configure/assettests/alerts',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 5040,
          'subMenuName': 'Messages',
          'routeKey': 'configure/messages',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'Yes',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 504010,
            'subsubMenuName': 'Message Type Categories',
            'routeKey': 'configure/messages/messagetypecategories',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 504020,
            'subsubMenuName': 'Message Types',
            'routeKey': 'configure/messages/messagetypes',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 504030,
            'subsubMenuName': 'Message Type Alerts',
            'routeKey': 'configure/messages/alerts',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 504040,
            'subsubMenuName': 'Message Type Schedule',
            'routeKey': 'configure/messages/schedule',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 5050,
          'subMenuName': 'Document Library',
          'routeKey': 'configure/documentlibrary',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 505010,
            'subsubMenuName': 'Document Categories',
            'routeKey': 'configure/documentlibrary/documentcategories',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 505020,
            'subsubMenuName': 'Document Formats',
            'routeKey': 'configure/documentlibrary/documentformats',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }, {
          'sequenceNo': 5060,
          'subMenuName': 'Other',
          'routeKey': 'configure/other',
          'iconClass': '',
          'PageActions': 'YNNN',
          'componentName': '',
          'MenuPermission': {
            'View': 'Yes',
            'Add': 'No',
            'Edit': 'No',
            'Delete': 'No'
          },
          'subsubMenus': [{
            'sequenceNo': 506010,
            'subsubMenuName': 'Geo Hierarchy Setup',
            'routeKey': 'configure/other/geohierarchysetup',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'Yes',
              'Edit': 'Yes',
              'Delete': 'Yes'
            }
          }, {
            'sequenceNo': 506020,
            'subsubMenuName': 'Frequency Setup',
            'routeKey': 'configure/other/frequencysetup',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }, {
            'sequenceNo': 506030,
            'subsubMenuName': 'List Values Setup',
            'routeKey': 'configure/other/listvaluessetup',
            'iconClass': '',
            'PageActions': 'YNNN',
            'componentName': '',
            'MenuPermission': {
              'View': 'Yes',
              'Add': 'No',
              'Edit': 'No',
              'Delete': 'No'
            }
          }]
        }]
      }]
    };
  }

  // TODO: NL - move to a utility file, it doesn't belong in this class
  public getDateInFormat(date?: any, format?: string) {
    if (date && format) {
      return moment(date).format(format);
    } else if (date && !format) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    } else if (format && !date) {
      return moment(new Date()).format(format);
    } else {
      return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }
  }
  public getDateString(date?: any,currentFormat?:string, format?: string) {
    if (date && format && currentFormat) {
      return moment(date,currentFormat).format(format);
    }
    else {
      return moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
    }
  }

  public getHealthSystemWiseFacility(list: any): any {
    let facilityList = [];
    for (let i=0 ; i < list.length; i++) {
        for (let j = 0; j < list[i].facilities.length; j++) {
          facilityList.push({
            "healthSystemId": list[i].healthSystemId,
            "healthSystemName": list[i].healthSystemName,
            "facilityId": list[i].facilities[j].facilityId,
            "facilityName": list[i].facilities[j].facilityName,
            "id": list[i].facilities[j].facilityId,
            "itemName": list[i].facilities[j].facilityName
          });
        }
    }
    return facilityList;
  }

  public getFacility(list: any): any {
    let facilityList = [];
    for (let i=0 ; i < list.length; i++) {
        facilityList.push({
          "facilityId": list[i].facilityId,
          "facilityName": list[i].facilityName,
          "id": list[i].facilityId,
          "itemName": list[i].facilityName
        });
    }
    return facilityList;
  }
}
