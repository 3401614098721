import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { NotificationService } from "../../shared/service/notification.service";
import { LOGIN_PAGE as UI_CONSTANTS } from "../../shared/constants/uiConstants";
import { MESSAGES } from "../../shared/constants/message";

@Injectable()
export class ForgotPasswordService {
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService
  ) { }

  forgotPassword(userName) {
    this.http
      .post(environment.forgot_pw_api, {
        action: "anonymousForgotPassword",
        actionParameters: {
          userEmail: userName,
        },
      })
      .subscribe((data: any) => {
        if (data.messages.warnings.checkEmail.length > 0) {
          this.notificationService.info("", UI_CONSTANTS.ERRORS.PASSWORD_RESET_MSG);
        } else {
          this.notificationService.error("", MESSAGES.ERROR.AN_ERROR_OCCURRED);
        }
      });
  }
}
