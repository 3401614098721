import {Routes} from '@angular/router';
import {CommonGuard} from '../shared/guard/common.guard';
import {RoutePath} from '../shared/constants/index';
import {PolicyReviewComponent} from './policy-review/policy-review.component';
import {AuditSetupPageComponent} from '../audit-setup/audit-setup-page/audit-setup-page.component';

export const PolicyRoutes: Routes = [
  {path: RoutePath.POLICY, component: AuditSetupPageComponent, canActivate: [CommonGuard]},
  {
    path: RoutePath.POLICY, children: [
    {
      path: RoutePath.REVIEW,
      component: PolicyReviewComponent,
      canActivate: [CommonGuard]
    },
    {
      path: RoutePath.PRINT,
      component: PolicyReviewComponent,
      canActivate: [CommonGuard]
    },
    {
      path: RoutePath.ARCHIVE,
      component: PolicyReviewComponent,
      canActivate: [CommonGuard]
    },
    {
      path: RoutePath.AUDIT_QUESTIONS,
      component: PolicyReviewComponent,
      canActivate: [CommonGuard]
    }
  ]
  }
];
