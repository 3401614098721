import {Routes} from '@angular/router';
import {CommonGuard} from '../shared/guard/common.guard';
import {UnitComponent} from './unit.component';
import {RoutePath} from '../shared/constants/index';

export const UnitRoutes: Routes = [
  {
    path: RoutePath.UNIT,
    component: UnitComponent,
    canActivate: [CommonGuard]
  }
];
