export const Route = {
  DASHBOARD: '/dashboard',
  POLICY: '/policy',
  CLABSI: '/clabsi',
  HEALTHSYSTEM: '/healthsystem',
  LOCATION: '/location',// TODO refactor to say facility
  UNIT: '/unit',
  USER: '/user',
  USER_DETAILS: '/user/detail',
  ADD: '/add',
  DETAIL: '/detail',
  REVIEW: '/review',
  PRINT: '/print',
  ARCHIVE: '/archive',
  AUDIT_QUESTIONS: '/audit-questions',
  MODIFY: '/modify',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  BLANK: '/',
  OAUTH_CALLBACK: '/oauth-callback',
  DATA_ENTRY: '/data-entry',
  SUMMARY_REPORTS: '/summary-reports',
};
// TODO: NL - make these string enums for better type checking
// TODO: NL - try to figure out why we have both of these, and why some entries are slightly different
// Route could be generated from RoutPath, but FORGOT_PASSWORD adds a route param...
export const RoutePath = {
  DASHBOARD: 'dashboard',
  POLICY: 'policy',
  LOCATION: 'location',
  UNIT: 'unit',
  USER: 'user',
  ADD: 'add',
  DETAIL: 'detail',
  REVIEW: 'review',
  PRINT: 'print',
  ARCHIVE: 'archive',
  AUDIT_QUESTIONS: 'audit-questions',
  MODIFY: 'modify',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password/:token',
  BLANK: '/',
  OAUTH_CALLBACK: 'oauth-callback',
  DATA_ENTRY: 'data-entry',
  SUMMARY_REPORTS: 'summary-reports'
};
