import {Router} from '@angular/router';
import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DataService} from '../../shared/service/data.service';
import {PolicyListService} from './policy-list.service';
import {CommonEvent, IMAGES, Route} from '../../shared/constants/index';
import {ErrorService} from '../../shared/service/error-service';
import {Util} from '../../util/util';
import {RoutePath} from '../../shared/constants/routes';
import {ConstantService} from '../../shared/service/constant-service';
import {TokenService} from '../../shared/service/token.service';
import * as jsPDF from 'jspdf';
import {FacilityService} from "../../shared/api/facility.service";
import {Facility} from "../../shared/model/facility";
import {Unit} from "../../shared/model/unit";

const specialElementHandlers = {
    '#editor': function (element, renderer) {
        return true;
    }
};

// TODO: NL - general clean up of this file, once we're able to test it a bit.
// TODO: NL - the template code for this component is really complex, see if it can be simplified or broken up into sub-components

@Component({
    templateUrl: 'policy-list.component.html',
    styleUrls: ['policy-list.scss'],
})

export class PolicyListComponent implements OnInit, AfterViewInit {
    public policy: any[];
    public policyType: any[];
    public spinner: Boolean = false;
    public printablePolicyType: any = {};
    public auditQuestions: any;
    public radio = IMAGES.RADIO_IMAGE;
    public printActionType = CommonEvent;
    public policyRowDataForPrint: any = {};
    public facilityList: Facility[];
    public facility: Facility = undefined;
    public loggedInUserRole: any;
    public healthAdminRole: any;
    public locationAdminRole: any;
    public unitList: Array<Unit>;
    public records: Array<any>;
    public isDesc: boolean = false;
    public type: any;
    public column: string = 'CategoryName';
    public direction: number;
    public checkbox = IMAGES.CHECKBOX_IMAGE;
    public input = IMAGES.INPUT_IMAGE;

    constructor(
        private router: Router,
        private policyListService: PolicyListService,
        private dataService: DataService,
        private errorService: ErrorService,
        private constantService: ConstantService,
        private tokenService: TokenService,
        private facilityService: FacilityService
    ) { }

    public ngOnInit() {
        this.getPoliciesDetails();
        this.policyRowDataForPrint.isPaperAudit = false;
    }
    public ngAfterViewInit() {
        //this.getHealthSysWiseFacilities();
        this.getLocationsByUser();

    }

    public getPoliciesDetails() {
        this.getPolicyList();
        this.getPolicyTypeList();
    }

    public sort(property, type) {

        this.isDesc = !this.isDesc; //change the direction
        this.column = property;
        this.direction = this.isDesc ? 1 : -1;
        this.type = type;
    }

    public getPolicyList() {
        this.spinner = true;
        //TODO(refactoring): rework or remove
        /*this.policyListService.getPolicyListByUserId().subscribe(data => {
            this.spinner = false;
            this.policy = data as any;
        }, err => {
            this.spinner = false;
            this.errorService.handleErrorStatusCode(err);
        });*/
    }

    public getPolicyTypeList() {
        this.spinner = true;
        //TODO(refactoring): rework or remove
        /*this.policyListService.getPolicyTypeList().subscribe(data => {
            this.spinner = false;
            this.policyType = data as any[];
            const loggedUser = this.tokenService.getUserToken();
            const role = Util.isDefinedAndNotNull(loggedUser) ? loggedUser.roles[0].role : '';

            this.healthAdminRole = ROLES.ROLE.PARENT_ADMIN;
            this.locationAdminRole = ROLES.ROLE.FACILITY_ADMIN;
            this.loggedInUserRole = role;
        }, err => {
            this.spinner = false;
            this.errorService.handleErrorStatusCode(err);
        });*/
    }

    public changePolicy(policyType) {
        this.spinner = true;
        if (policyType['isEnabled']) {
            //It's already enabled , so we have to disable it
            //TODO(refactoring): rework or remove
            /*this.policyListService.disablePolicyType(policyType['facilityPolicyJsonId']).subscribe(() => {
                this.spinner = false;
                this.getPolicyTypeList();
            }, err => {
                this.spinner = false;
                this.getPolicyTypeList();
                this.errorService.handleErrorStatusCode(err);
            });*/
        } else {
            //It's disabled ,let's enable it
            //TODO(refactoring): rework or remove
            /*this.policyListService.enablePolicyType(policyType['disableFacilityPolicyJsonId'], policyType['facilityPolicyJsonId']).subscribe(data => {
                this.spinner = false;
                this.getPolicyTypeList();
            }, err => {
                this.spinner = false;
                this.getPolicyTypeList();
                this.errorService.handleErrorStatusCode(err);
            });*/
        }
    }

    public modifyPolicy(event: any, row: any) {
        this.setPolicyJSON(row);
        if (row.isPolicyExist) {
            this.reviewPolicy(row);
        } else {
            this.router.navigate([Route.POLICY + '/' + Route.ADD]);
        }
    }

    public viewArchivePolicy(row: any) {
        this.setPolicyJSON(row);
        this.router.navigate([Route.POLICY + Route.ARCHIVE]);
    }

    public setPolicyJSON(policyJson) {
        this.dataService.policy = policyJson;
    }

    public onClickPrint(row: any) {
        this.printablePolicyType = row;
        this.onClickPrintType(CommonEvent.printPolicy);
    }

    public onClickPrintType(printType) {
        if (Util.isDefinedAndNotNull(this.printablePolicyType)) {
            this.printablePolicyType = this.policyRowDataForPrint;
            this.printablePolicyType.printType = printType;
            this.dataService.policy = this.printablePolicyType;
            this.router.navigate([RoutePath.POLICY + Route.PRINT]);
        }
    }

    public printPolicyQuestions() {
        this.router.navigate([Route.POLICY + Route.AUDIT_QUESTIONS]);
    }

    public downloadPDf(policyRowData) {
        const doc = new jsPDF();
        doc.fromHTML($('#policyReviewPrint').html(), 15, 15, {
            'width': 170,
            'elementHandlers': specialElementHandlers
        }, function () {
            doc.save(policyRowData.policyType + '.pdf');
        });
    }

    public reviewPolicy(policyJson) {
        if (policyJson.hasOwnProperty) {
            const auditPolicyValue = JSON.parse(policyJson.auditPolicyJson);

            this.dataService.policyDetailsForReview = {
                auditPolicyValue: this.policyListService.createFormattedPolicyJSON(auditPolicyValue),
                policyType: policyJson.policyType,
                policyTypeId: policyJson.policyTypeId,
                facilityPolicyJsonId: policyJson.facilityPolicyJsonId,
            };

            this.router.navigate([Route.POLICY + Route.REVIEW]);
        }
    }

    public getLocationsByUser() {
        this.facilityService.getAllEntities().subscribe(list => {
            this.facilityList = list.filter(f => f.active);

            if (Util.isDefinedAndNotNull(this.dataService.defaultLocation)) {
                for (let i = 0; i < this.facilityList.length; i++) {
                    if (this.facilityList[i].id == this.dataService.defaultLocation.id) {
                        this.facility = this.facilityList[i];
                        break;
                    }
                }
            } else {
                this.facility = this.facilityList[0];
                this.getPoliciesDetails();
            }

            this.getUnits();
        });
    }

    public getUnits() {
        this.facilityService.getUnits(this.facility.id).subscribe(units => {
            this.unitList = units;
        }, err => 
            {
                this.errorService.handleErrorStatusCode(err);
            });
    }

    public getPolicyListByFacilityId() {

        this.dataService.defaultLocation = this.facility;
        this.spinner = true;

        //TODO(refactoring): use FacilityService#getPolicies
        /*this.policyListService.getPolicyListByFacilityId(this.facility.id).subscribe(data => {
            this.spinner = false;
            this.policy = data as any[];
        }, err => {
            this.spinner = false;
            this.errorService.handleErrorStatusCode(err);
        });*/
    }

    public getPolicyTypeListByFacilityId() {

        this.dataService.defaultLocation = this.facility;
        this.spinner = true;
        //TODO(refactoring): use PolicyTypeService
        /*this.policyListService.getPolicyTypeListByFacilityId(this.facility.id).subscribe(data => {
            this.spinner = false;
            this.policyType = data as any[];
        }, err => {
            this.spinner = false;
            this.errorService.handleErrorStatusCode(err);
        });*/
    }
}
