import {NgModule} from "@angular/core";
import {CanAccessDirective} from "../security/can.access.directive";
import {TokenService} from "../service/token.service";
import {PermissionCheckService} from "../security/permission.check.service";

@NgModule({
    declarations: [CanAccessDirective],
    exports: [CanAccessDirective],
    providers: [TokenService, PermissionCheckService]
})
export class DirectiveModule {

}