import { Component, OnInit } from '@angular/core';
import { SimpleModalService } from 'ngx-simple-modal';
import {
    CreateAndEditUnitModalDialogComponent,
    CreateAndEditUnitModalDialogMode,
} from './create-and-edit-unit-modal-dialog/create-and-edit-unit-modal-dialog.component';
import { NotificationService } from '../shared/service/notification.service';
import { ErrorService } from '../shared/service/error-service';
import { UNIT_PAGE as UI_CONSTANTS } from '../shared/constants/uiConstants';
import { MODAL_DIALOG_FADE_DURATION_MS } from '../shared/constants/animationConstants';
import AbstractSkeletonLoader from '../shared/animations/AbstractSkeletonLoader';
import {Facility} from "../shared/model/facility";
import {Unit} from "../shared/model/unit";
import {FacilityService, UnitService, UserService} from "../shared/api";
import {ROLES} from "../shared/constants";
import {FacilitySelectorComponent} from "../facility-selector/facility-selector.component";
import { trigger, style, animate, transition } from '@angular/animations';

@Component({
    templateUrl: 'unit.component.html',
    styleUrls: ['unit.component.scss'],
    animations: [
        trigger('opacityFade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.3s', style({ opacity: 0 })),
        ])])],
})
export class UnitComponent extends AbstractSkeletonLoader implements OnInit {
    public activeUnitList: Unit[];
    public inactiveUnitList: Unit[];
    public units: Unit[];
    private _units: Unit[]=[];
    public selectedFacility?: Facility;
    public searchText: string;
    public readonly UnitTabs = {
        Active: 'Active',
        Inactive: 'Inactive',
    };
    public selectedTab: string = this.UnitTabs.Active;
    public UI = UI_CONSTANTS;
    public readonly permissions = {
        create: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M,ROLES.ROLE.ROLE_REP],
        update: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ADMIN_3M,ROLES.ROLE.ROLE_REP]
    };

    constructor(
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private unitService: UnitService,
        private simpleModalService: SimpleModalService,
        private facilityService: FacilityService,
        private userService: UserService,
    ) {
        super();
    }

    public ngOnInit() {
        this.showSkeletonAfterDelay();
        //this.loadUnits();
    }

    public isUserAdmin(unit: Unit): boolean {
       //console.log(JSON.stringify(unit));
        if (undefined != this.selectedFacility
            && this.selectedFacility.id != FacilitySelectorComponent.ALL_FACILITIES_ID
        ) {
            return this.userService.isUserAdminAtFacilityToEditAudit(this.selectedFacility.id);
        }
        return this.userService.isUserAdminAtFacilityToEditAudit(unit.facilityId);
        //return this.userService.isUserAdmin;

    }
    public get shouldDisableCreateUnitButton():boolean{
        if (undefined != this.selectedFacility
            && this.selectedFacility.id != FacilitySelectorComponent.ALL_FACILITIES_ID 
        ){
            if(!this.userService.isUserAdminAtFacilityToEditAudit(this.selectedFacility.id))
                return true;
            return false;
        }
        return true;



    }
    private loadUnits(): Promise<void> {
        return new Promise(resolve => {
            //this.unitService.getAllEntities().subscribe(
            this.facilityService.getUnits(this.selectedFacility.id).subscribe(
                units => {
                    this._units = units;
                    //this.setActivePolicy();
                    resolve();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });
    }

    public getUnitsFromBackend() {
        console.assert(
            null != this.selectedFacility,
            'UnitComponent#getUnitsFromBackend called but there is no selectedFacility'
        );

        this.showSkeletonAfterDelay();
        let source = null;
        if (this.selectedFacility.id === 0) {
            source = this.unitService.getAllEntities();
        } else {
            source = this.facilityService.getUnits(this.selectedFacility.id);
            
        }
        source.subscribe(units => {
            this.activeUnitList = units.filter(unit => unit.active);
            this.inactiveUnitList = units.filter(unit => !unit.active);
            this.hideSkeletonAndShowContent();
        }, err => {
            this.errorService.handleErrorStatusCode(err);
        });
    }

    public updateUnitOnBackend(unit: Unit) {
        console.assert(unit != null, 'UnitComponent#updateUnitOnBackend - unit parameter cannot be null');

        this.unitService.updateEntity(unit.id, unit).subscribe(
            () => {
                this.notificationService.success('', UI_CONSTANTS.SUCCESS.UNIT_MODIFIED);
                this.getUnitsFromBackend();
            },
            err => {
                this.errorService.handleErrorStatusCode(err);
                this.getUnitsFromBackend();
                
            }
        );
    }

    public createUnitOnBackend(unitName: string) {
        if (this.selectedFacility != null) {
            const unit: Unit = {
                name: unitName,
                active: true
            };
            this.facilityService.addUnit(this.selectedFacility.id, unit).subscribe( () => {
                    this.notificationService.success('', UI_CONSTANTS.SUCCESS.UNIT_CREATED);
                    this.getUnitsFromBackend();
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                });
        } else {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.NO_FACILITY_SELECTED);
        }
    }
    //public unitsForFacilityId(facilityId: number | undefined): Unit[] {
    public unitsForFacilityId(){
               
        //return this._units.filter(unit=> unit.facilityId === facilityId);
        return this._units;
        //return this.facilityService.getUnits(this.selectedFacility.id);
    }


    public toggleIsUnitActive(unit: Unit, shouldUpdateOnBackend: boolean = true) {
        console.assert(unit != null, 'UnitComponent#toggleIsUnitActive - unit parameter cannot be null');

        // Optimistically update the UI
        if (unit.active) {
            unit.active = false;
            this.activeUnitList = this.activeUnitList.filter(activeUnit => activeUnit !== unit);
            this.inactiveUnitList = [...this.inactiveUnitList, unit];
        } else {
            unit.active = true;
            this.inactiveUnitList = this.inactiveUnitList.filter(activeUnit => activeUnit !== unit);
            this.activeUnitList = [...this.activeUnitList, unit];
        }

        if (shouldUpdateOnBackend) {
            this.updateUnitOnBackend(unit);
        }
    }

    public selectTab(tab: string) {
        this.selectedTab = tab;
    }

    public isTabSelected(tab: string) {
        return this.selectedTab === tab;
    }

    public get shouldShowEmptyListIndicator() {
        return this.getUnitListForCurrentlySelectedTab().length == 0;
    }

    public getUnitListForCurrentlySelectedTab(): Unit[] {
        switch (this.selectedTab) {
            case this.UnitTabs.Active:
                return this.activeUnitList;
            case this.UnitTabs.Inactive:
                return this.inactiveUnitList;
        }
        console.assert(false, 'UnitComponent#getUnitListForCurrentlySelectedTab - this.selectedTab unknown value');
        return [];
    }

    public get labelForToggleActiveHeader(): string {
        switch (this.selectedTab) {
            case this.UnitTabs.Active:
                return this.UI.MAKE_INACTIVE;
            case this.UnitTabs.Inactive:
                return this.UI.MAKE_ACTIVE;
        }
        return '';
    }

    public getToggleIsUnitActiveLabelForUnit(unit) {
        if (unit.active) {
            return this.UI.INACTIVE;
        } else {
            return this.UI.ACTIVE;
        }
    }

    public onSelectedFacilityChanged(newFacility: Facility) {
        this.selectedFacility = newFacility;
        this.loadUnits();           
        this.getUnitsFromBackend();
    }

    public displayEditUnitModal(unit: Unit): void {
        console.assert(undefined != unit, 'UnitComponent#displayEditUnitModal - unit passed in was undefined');
        const mode: CreateAndEditUnitModalDialogMode = CreateAndEditUnitModalDialogMode.Edit;
        this.simpleModalService
            .addModal(
                CreateAndEditUnitModalDialogComponent,
                {
                    mode:CreateAndEditUnitModalDialogMode.Edit,
                    unitName: unit.name,
                    unit,
                    existingUnitsOfFacility: this.unitsForFacilityId(),
                   
                },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                if (undefined != result) {
                    console.assert(
                        undefined != result.unit,
                        'UnitComponent#displayEditUnitModal - result.unit received was undefined'
                    );
                    this.updateUnitOnBackend(result.unit);
                }
            });
    }

    public onEditUnitPressed(unit): void {
        this.displayEditUnitModal(unit);
    }

    public displayCreateUnitModal(): void {
        if (null == this.selectedFacility) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.NO_FACILITY_SELECTED);
            return;
        }
        const mode: CreateAndEditUnitModalDialogMode = CreateAndEditUnitModalDialogMode.Create;
        this.simpleModalService
            .addModal(
                CreateAndEditUnitModalDialogComponent,
                {
                    mode,
                    unitName: '',
                    existingUnitsOfFacility: this.unitsForFacilityId(),
                    
                },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                if (undefined != result) {
                    this.createUnitOnBackend(result.unitName);
                }
            });
    }

    public onCreateUnitPressed(): void {
        this.displayCreateUnitModal();
    }

    public onToggleActivePressed(unit): void {
        this.toggleIsUnitActive(unit);
    }
    public get tooltipForDisabledFeatureDueToFacilitySelection(): string {
        if (undefined != this.selectedFacility
            && this.selectedFacility.id == FacilitySelectorComponent.ALL_FACILITIES_ID 
        ) {
            return UI_CONSTANTS.SELECT_FACILITY_TO_USE_FEATURE;
        } else {
            return '';
        }
    } 


    public get tooltipForNoPermission(): string {
        if (undefined != this.selectedFacility && false == this.userService.isUserAdminAtFacility(this.selectedFacility.id)) {
            return UI_CONSTANTS.NO_PERMISSION;
        } else {
            return '';
        }
    }
}
