import {FullReport, MetricValue, UnitReport} from "../model/unit.report";
import {Metric} from "../model/report.type";
import * as moment from "moment";
import {Unit} from "../model/unit";

export function reportTypes(): ReportType[] {
    return [
        new ComprehensiveType(),
        new TwentyOneDayChallengeType(),
        //new SingleMetricType()
    ];
}

let headerSM: string; 
export function setSingleMetricsHeader(headerSM: string): void {
    this.headerSM = headerSM;
    console.log(this.headerSM);
}

export abstract class TableDataAware {
    prepare(context: DashboardContext): Table | null {
        if (this.canPrepare(context)) {
            return this.prepareTable(context);
        } else {
            return null;
        }
    }

    abstract canPrepare(context: DashboardContext): boolean;
    abstract prepareTable(context: DashboardContext): Table;
}

export abstract class ReportType extends TableDataAware {
    public static TITLE: string;
    public readonly rawDataKey: string;
    readonly title: string;
    views?: ReportView[];
    metrics?: Metric[];

    abstract selectView(view: ReportView);
    abstract getSelectedView(): ReportView;
}

export abstract class ReportView extends TableDataAware {
    public abstract readonly title: string;
    public abstract readonly tabs?: ViewTab[];
    metrics?: string[];

    abstract selectTab(tab: ViewTab);

    abstract getSelectedTab(): ViewTab;
    
    abstract isTabSelected(tab: ViewTab);

    abstract canPrepare(context: DashboardContext): boolean;

    abstract prepareTable(context: DashboardContext): Table;
}

export interface Row {
    cells: Cell[];
}

export interface Cell {
    header: boolean;
    value: string;
    metaValue?: number;
    total?:number;
    tooltip?: string;
}

export abstract class ViewTab extends TableDataAware {
    public readonly id: string;
    public readonly title: string;

    protected constructor(id: string, title: string) {
        super();
        this.id = id;
        this.title = title;
    }
}

interface DashboardContext {
    metric?: Metric;
    view?: ReportView;
    data: FullReport;
}

export interface Table {
    header: Cell[];
    rows: Row[];
}

const METRICS: Metric[] = [
    {name: 'Insertion Site Visible', code: 'IVSiteVisible', metricGroup: ''},
    {name: 'Dressings within Date', code: 'Dressing', metricGroup: ''},
    {name: 'Clean', code: 'Clean', metricGroup: ''},
    {name: 'Dry', code: 'Dry', metricGroup: ''},
    {name: 'Intact', code: 'Intact', metricGroup: ''},
    {name: 'All Tubing within Date', code: 'TubingWithinDate', metricGroup: ''},
    {code: 'Dressing Integrity Maintained', metricGroup: 'IV Dressings', name: 'Dressing' },
    {code: 'Dressing Application', metricGroup: 'IV Dressings', name: 'DressingApplication' },
    {name: 'Custom Question 1', code: 'Custom1', metricGroup: ''},
    {name: 'Custom Question 2', code: 'Custom2', metricGroup: ''},
    {name: 'Custom Question 3', code: 'Custom3', metricGroup: ''},
];

class MasterIvDressingTab extends ViewTab {
    private _metrics: Set<string> = new Set<string>([
        'IVSiteVisible', 'AntimicrobialPresent', 'AntimicrobialPlacement',  'Clean', 'Dry', 'Intact',
         'Dressing Integrity Maintained', 'Dressing', 'DressingApplication', 'Custom1',
        'Custom2', 'Custom3', 
    ]);

    constructor() {
        super("dressingTabId", "IV Dressings");
    }

    prepareTable(context: DashboardContext): Table {
        let header: Cell[] = [
            _headerCell('Unit'),
            _headerCell('# IV Sites', "Total number of IV sites audited."),
            // _headerCell("Insertion Site Visible"),
            // _headerCell('Dressings within Date'),
            // _headerCell('Dry'),
            // _headerCell('Intact'),
            // _headerCell('Clean'),
            // _headerCell('Antimicrobial Present'),
            // _headerCell('Antimicrobial Placement in Alignment with Facility Protocol'),
            // _headerCell('Dressing Integrity Maintained'),

        ];

        let unitReports: Map<string, UnitReport[]> = _groupBy<UnitReport, string>(context.data.unitReports, 'unit.name');
        var isHeaderAdded=false;
        let isIvSitesNotThere=false;
        let isFirstAdded = false;
        let isSecondAdded = false;
        let isThirdAdded = false;
        let rows: Row[] = [];
        unitReports.forEach((reports, unit, _) => {
            let row: Cell[] = [_headerCell(unit)];

            let catheterSites = reports.map(r => r.catheterSites).reduce((acc, curr) => acc + curr, 0);
            
            if(catheterSites < 0){
                isIvSitesNotThere = true;
            }else{
                row.push(_headerCell(catheterSites.toString()));
            }
            
            for (let m of Array.from(this._metrics)) {
                let values = reports.reduce((acc, curr) => acc.concat(curr.values), [] as MetricValue[])
                    .filter(v => v.metric.code == m);
                    if(values.filter(x=> x.metric.code === m).length === 0 ){
                        continue;
                    }
                    if(!isHeaderAdded)
                    {
                        console.log("Header"+JSON.stringify(values));
                        header.push(_headerCell(values.map(x=>x.metric.name)[0], values.map(x=>x.metric.tooltip)[0]));
                    }
                    

                if (values.length > 0) {
                    const avg = values.reduce((acc, curr) => acc + curr.value, 0) / values.length;
                    let totalAns = values.reduce((acc, curr) => acc + curr.totalAnswers, 0);
                    
                        // var customQuest = values.filter(x =>x.metric.code === "Custom1")[0];
                        // if(customQuest !== null && customQuest !== undefined && !isFirstAdded){
                        //     header.push(_headerCell( customQuest.metric.name));
                        //     isFirstAdded = true;
                        // }

                        // customQuest = values.filter(x =>x.metric.code === "Custom2")[0];
                        // if(customQuest !== null && customQuest !== undefined && !isSecondAdded){
                        //     header.push(_headerCell( customQuest.metric.name));
                        //     isSecondAdded = true;
                        // }

                        // customQuest = values.filter(x =>x.metric.code === "Custom3")[0];
                        // if(customQuest !== null && customQuest !== undefined && !isThirdAdded){
                        //     header.push(_headerCell( customQuest.metric.name));
                        //     isThirdAdded = true;
                        // }

                    row.push(_isValidNum(totalAns, avg));
                } else {
                    row.push(_emptyCell());
                }
            }
            isHeaderAdded = true;
            rows.push({cells: row});
        });
        //console.log(header);
        if(isIvSitesNotThere){
            header.splice(1,1);
        }
       // Calculating total average row for each metric
        const avgRow: Cell[] = [_headerCell('Total Facility')];
        const totalSites = context.data.unitReports.reduce((acc, ur) => acc + ur.catheterSites, 0);
        
        if(totalSites < 0){
            isIvSitesNotThere = true;
        }else{
            avgRow.push(_headerCell(totalSites.toString()));
        }

        const allMetricValues = context.data.unitReports
            .map(ur => ur.values || [])
            .reduce((acc, arr) => [...acc, ...arr], []);

        for (const m of Array.from(this._metrics)) {
            const currMetricValues = allMetricValues.filter(v => v.metric.code === m);
            if(allMetricValues.filter(v => v.metric.code === m).length === 0){
                continue;
            }
            const positiveAnswers = currMetricValues.reduce((acc, v) => acc + v.positiveAnswers, 0);
            const totalAnswers = currMetricValues.reduce((acc, v) => acc + v.totalAnswers, 0);
            
            if (totalAnswers > 0) {
                avgRow.push(_isValidNum(totalAnswers, positiveAnswers * 100 / totalAnswers));
            } else {
                avgRow.push(_emptyCell());
            }
        }
        rows = [{cells: avgRow}, ...rows];

        // Adjusting rows length according to header length
        for (let r of rows) {
            const cellsNum = header.length - r.cells.length;
            for (let i = 0; i < cellsNum; i++) {
                r.cells.push(_emptyCell());
            }
        }

        return {header: header, rows: rows}
    }

    canPrepare(context: DashboardContext): boolean {
        return context.data!= null && context.data.unitReports != null && context.data.unitReports.length > 0;
    }
}
class DisinfectingPortTab extends ViewTab {
    private metrics: Set<string> = new Set(
        ['TotalPatCompliance','NeedlelessConnPortCompliance',
            'MaleLuerPortCompliance',
            'OpenFemaleLuerPortCompliance',  'TotalPortCompliance', 'PortProtectorAccess','TubingWithinDate','CustomPat1',
            'CustomPat2', 'CustomPat3']
    );

    constructor() {
        super('disigTabId', 'Disinfecting Port Protectors & Tubing');
    }
    prepareTable(context: DashboardContext): Table {
        let header: Cell[]
         = [
            _headerCell('Unit'),
            _headerCell("# Patients", "Total number of patients audited."),
             _headerCell('# IV Access Points', "Total number of unaccessed IV access points audited."),
        //     _headerCell('All Tubing within Date'),
        //     _headerCell('Needleless Connector Port Compliance'),
        //     _headerCell('Male Luer Port Compliance'),
        //     _headerCell('Open Female Luer Port Compliance'),
        //     _headerCell('Total Patient Compliance'),
        //     _headerCell('Total Port Compliance'),
        //     _headerCell('Port Protector Bedside Accessibility'),
        //     _headerCell('Custom Question 1'),
        //     _headerCell('Custom Question 2'),
        //     _headerCell('Custom Question 3'),
         ];

        let map: Map<string, UnitReport[]> = _groupBy(context.data.unitReports, 'unit.name');

        var isheaderPortprotectorAdded = false;
        let rows: Row[] = [];
        var totalPorts = 0;
        var totalPatients = 0;

        map.forEach((reports, unit, _) => {
            let row: Cell[] = [_headerCell(unit)];
            let patients = reports.reduce((acc, curr) => acc + curr.patients, 0);
            let ports = reports.reduce((acc, curr) => acc + curr.ports, 0);

            totalPorts =totalPorts +ports;
            totalPatients = totalPatients + patients;

            row.push(_headerCell(patients.toString()));
            if(ports > -1)
            row.push(_headerCell(ports.toString()));
            
            for (let m of Array.from(this.metrics)) {
                let values = reports.reduce((acc, curr) => acc.concat(curr.values), [] as MetricValue[])
                    .filter(v => v.metric.code == m);
                    if(values.filter(x=> x.metric.code === m).length === 0 ){
                        continue;
                    }
                if(!isheaderPortprotectorAdded){
                    console.log("Header"+JSON.stringify(values));
                    header.push(_headerCell(values.map(x=>x.metric.name)[0], values.map(x=>x.metric.tooltip)[0]));
                }
                let totalAns = values.reduce((acc, curr) => acc + curr.totalAnswers, 0);

                let avg = values.length ? values.reduce((acc, curr) => acc + curr.value, 0) / values.length : 0.0;
                if (values.length > 0) {
                    row.push(_isValidNum(totalAns, avg));
                } else {
                    row.push(_emptyCell());
                }
            }
            isheaderPortprotectorAdded = true;
            rows.push({cells: row});
        });
        if(totalPorts < 0){
            var index = header.indexOf(header.filter(x => x.value === '# IV Access Points')[0]);
            header.splice(index, 1);
        }

        const avgRow: Cell[] = [_headerCell('Total Facility')];
        avgRow.push(_headerCell(totalPatients.toString()));
        if(totalPorts > -1)
            avgRow.push(_headerCell(totalPorts.toString()));

        const allMetricValues = context.data.unitReports
            .map(ur => ur.values || [])
            .reduce((acc, arr) => [...acc, ...arr], []);
        for (const m of Array.from(this.metrics)) {
            const currMetricValues = allMetricValues.filter(v => v.metric.code === m);
            if(allMetricValues.filter(v => v.metric.code === m).length === 0){
                continue;
            }
            const positiveAnswers = currMetricValues.reduce((acc, v) => acc + v.positiveAnswers, 0);
            const totalAnswers = currMetricValues.reduce((acc, v) => acc + v.totalAnswers, 0);
            
            if (totalAnswers > 0) {
                avgRow.push(_isValidNum(totalAnswers, positiveAnswers * 100 / totalAnswers));
            } else {
                avgRow.push(_emptyCell());
            }
        }
        rows = [{cells: avgRow}, ...rows];
        // Adjusting rows length according to header length
        for (let r of rows) {
            const cellsNum = header.length - r.cells.length;
            for (let i = 0; i < cellsNum; i++) {
                r.cells.push(_emptyCell());
            }
        }

        return {header: header, rows: rows};
    }

    canPrepare(context: DashboardContext): boolean {
        return context.data!= null && context.data.unitReports != null && context.data.unitReports.length > 0;
    }
}

class MasterView extends ReportView {
    public readonly title: string = 'Master';
    tabs: ViewTab[] = [new MasterIvDressingTab(), new DisinfectingPortTab()];
    metrics: string[];

    private currTab?: ViewTab = undefined;

    selectTab(tab: ViewTab) {
        if (tab != this.currTab && this.tabs.find(t => t.title == tab.title)) {
            this.currTab = tab;
        }
    }

    getSelectedTab(): ViewTab {
        return this.currTab;
    }

    isTabSelected(tab: ViewTab) {
        return this.currTab === tab;
    }

    prepareTable(context: DashboardContext): Table {
        console.assert(this.currTab != undefined, "currTab should be present. 'selectTab' method should be called before this");
        if (this.currTab) {
            return this.currTab.prepare(context);
        } else {
            throw new Error("Current tab is not selected");
        }
    }

    canPrepare(context: DashboardContext): boolean {
        return this.currTab && this.currTab.canPrepare(context);
    }
}
class CatheterTypeView extends ReportView {
    metrics: string[];
    readonly tabs: ViewTab[] = [];
    readonly title: string = 'IV Dressings By Catheter Type';

    getSelectedTab(): ViewTab {
        return undefined;
    }

    prepareTable(context: DashboardContext): Table {
        const data = context.data.catheterReports;
        //let header = data[0].values.map(v => _headerCell(METRICS.find(m => m.code == v.metric.code).name));
        let header = data[0].values.map(v => _headerCell(v.metric.name,v.metric.tooltip));
        let rows: Row[] = data.map(d => {
            return {cells: [_headerCell(d.name), ...d.values.map(v =>v.metric.code == "IVSites" ? _headerCell(d.values[0].value.toString()):  _isValidNum(v.totalAnswers, v.value))]}}
        );

        var isIvPres = header.find(x => x.value == '#IV Sites') != null;
        const avgRow: Cell[] = [_headerCell('Total Facility')];
 
        const allMetricValues = context.data.catheterReports
            .map(ur => ur.values || [])
            .reduce((acc, arr) => [...acc, ...arr], []);
            var i = 0;
        for (const m of header) {
            const currMetricValues = allMetricValues.filter(v => v.metric.name === m.value);
            const positiveAnswers = currMetricValues.reduce((acc, v) => acc + v.positiveAnswers, 0);
            const totalAnswers = currMetricValues.reduce((acc, v) => acc + v.totalAnswers, 0);
            
            console.log(positiveAnswers * 100 / totalAnswers);
            if (totalAnswers > 0) {
                if(isIvPres){                     
                    avgRow.push(_headerCell(totalAnswers.toString(), m.tooltip));
                    isIvPres = false;               
                }
                else
                    avgRow.push(_isValidNum(totalAnswers, positiveAnswers * 100 / totalAnswers));

            } else {
                avgRow.push(_emptyCell());
            }
        }
        rows = [{cells: avgRow}, ...rows];
 
        // Adjusting rows length according to header length
        for (let r of rows) {
            const cellsNum = header.length - r.cells.length;
            for (let i = 0; i < cellsNum; i++) {
                r.cells.push(_emptyCell());
            }
        }
 

        return {header: [_headerCell("Catheter / Device Type"), ...header], rows: rows}
    }

    selectTab(tab: ViewTab) {
    }

    isTabSelected(tab: ViewTab) {
        return false;
    }

    canPrepare(context: DashboardContext): boolean {
        return context.data!= null && context.data.unitReports != null && context.data.catheterReports.length > 0;
    }

}

export class SingleMetricType extends ReportType {
    public static TITLE = 'Single Metric Over Time';
    public readonly rawDataKey: string = 'comprehensive';
    title: string = SingleMetricType.TITLE;
    views: ReportView[] = [];

    metrics: Metric[] = [
        {name: 'Insertion Site Visible', code: 'IVSiteVisible', metricGroup: ''},
        {name: 'Dressings within Date', code: 'Dressing', metricGroup: ''},
        {name: 'Clean', code: 'Clean', metricGroup: ''},
        {name: 'Dry', code: 'Dry', metricGroup: ''},
        {name: 'Intact', code: 'Intact', metricGroup: ''},
        {name: 'All Tubing within Date', code: 'TubingWithinDate', metricGroup: ''},
        {name: 'Custom Question 1', code: 'Custom1', metricGroup: ''},
        {name: 'Custom Question 2', code: 'Custom2', metricGroup: ''},
        {name: 'Custom Question 3', code: 'Custom3', metricGroup: ''},
    ];

    private _filterByMetric = (m: MetricValue, context: DashboardContext) => m.metric.code == context.metric.code;

    prepareTable(context: DashboardContext): Table {

        console.assert(context.metric != undefined, "Metric should be present in context");
        if (context.metric) {
            let days: string[] = context.data.unitReports.filter(v => v.values.find(mv => this._filterByMetric(mv, context)))
                .map(v => v.date)
                .sort((a, b) => new Date(a).getMilliseconds() - new Date(b).getMilliseconds());

            if (days.length <= 0) {
                return {header: [], rows: []};
            }

            let map: Map<string, UnitReport[]> = _groupBy<UnitReport, string>(context.data.unitReports, 'unit.name');

            let rows: Row[] = [];
            for (let unit of Array.from(map.keys())) {
                let row: Cell[] = [_headerCell(unit)];
                for (let d of days) {
                    let rep = map.get(unit).find(r => new Date(r.date).getTime() == new Date(d).getTime());
                    if (rep && rep.values) {
                        let val = rep.values.find(mv => this._filterByMetric(mv, context)).value;
                        let totalAns = rep.values.find(mv => this._filterByMetric(mv, context)).totalAnswers;
                        row.push(_isValidNum(totalAns, val));                        
                    }
                }
                rows.push({cells: row});
            }
            
            return {header: [_headerCell("Unit")], rows: rows};
        } else {
            throw new Error("Metric is not selected");
        }
    }

    selectView(view: ReportView) {}
    getSelectedView(): ReportView {
        return undefined;
    }

    canPrepare(context: DashboardContext): boolean {
        let data = context.data.unitReports.filter(v => v.values.find(mv => this._filterByMetric(mv, context)));
        return context.data!= null && context.data.unitReports != null && context.data.unitReports.length > 0 && data.length > 0;
    }
}
export class ComprehensiveType extends ReportType {
    public static TITLE: string = 'Comprehensive Audit';
    public readonly rawDataKey: string = 'comprehensive';
    title: string = ComprehensiveType.TITLE;
    views: ReportView[] = [
        new MasterView(),
        new CatheterTypeView(),
    ];
    metrics: Metric[];

    private currView?: ReportView;

    selectView(view: ReportView) {
        if (this.currView != view) {
            this.currView = view;
        }
    }

    getSelectedView(): ReportView {
        return this.currView;
    }

    prepareTable(context: DashboardContext): Table {
        console.assert(this.currView != undefined,
            'currView should be defined before this method call. Use `selectView` before this method call.'
        );
        if (this.currView) {
            return this.currView.prepare(context);
        } else {
            throw new Error('No current view selected');
        }
    }

    canPrepare(context: DashboardContext): boolean {
        return this.currView && this.currView.canPrepare(context);
    }
}

interface DateColumn {
    dateString: string;
    dateValue: Date;
    header: string;
}

export class TwentyOneDayChallengeType extends ReportType {
    public static TITLE = '21-Days Challenge';
    public readonly rawDataKey: string = 'challenge';
    title: string = TwentyOneDayChallengeType.TITLE;
    views: ReportView[] = [];
    metrics: Metric[];

    private _metric = 'TwentyOneDayChallenge';

    prepareTable(context: DashboardContext): Table {
        let header: Cell[] = [_headerCell('Unit')];
        let groups: Map<string, UnitReport[]> = _groupBy<UnitReport, string>(context.data.unitReports, 'unit.name');

        let rows: Row[] = [];

        let dateColumns: DateColumn[] = [];

        groups.forEach((reports) => {
            let onDateReports: Map<string, UnitReport[]> = _groupBy<UnitReport, string>(reports, 'date');
            //console.log(JSON.stringify(onDateReports));


            onDateReports.forEach((reps, date) => {
                if(dateColumns.length == 0)
                reps[0].values.forEach(dateMetric => {
                        let tmp: DateColumn = {
                            dateString: dateMetric.metric.name,
                            dateValue: new Date(dateMetric.metric.name),
                            header: moment(dateMetric.metric.name).format('MM/DD'),
                        };

                        dateColumns.push(tmp);
                    });

                let values = reps.reduce((acc, curr) => acc.concat(curr.values), [] as MetricValue[])
                    .filter(v => v.metric.code == this._metric);
            });
        });

        dateColumns = dateColumns.sort((a , b) => {
            if (a.dateValue > b.dateValue) {
                return 1
            }
            return -1;
        });
        
        // complete header row with sorted dates
        dateColumns.forEach(c => header.push(_headerCell(c.header)));

        context.data.unitReports.forEach(unitData => {
            let row: Cell[] = [_headerCell(unitData.unit.name)];
            dateColumns.forEach((col) => {
                var data = unitData.values.filter(x => x.metric.name == col.dateString)[0]
                if(data.totalAnswers !== 0){
                    row.push(_isValidNum(data.totalAnswers, data.value));
                } else {
                    row.push(_emptyCell());
                }
            });

            rows.push({cells: row});
        });

        const avgRow: Cell[] = [_headerCell("Total Facility")];
        dateColumns.forEach((col) => {
            let totalPositiveAnswers = 0;
            let totalAnswers = 0;
            context.data.unitReports.forEach(unitData => {
                let metricsOnDate = unitData.values.filter(x => x.metric.name === col.dateString);

            totalPositiveAnswers = totalPositiveAnswers + metricsOnDate.reduce((acc, val) => acc + val.positiveAnswers, 0);
            totalAnswers = totalAnswers + metricsOnDate.reduce((acc, val) => acc + val.totalAnswers, 0);
            });

            if (totalAnswers > 0) {
                avgRow.push(_isValidNum(totalAnswers, totalPositiveAnswers * 100/ totalAnswers));
            } else {
                avgRow.push(_emptyCell());
            }
        });


       
        rows = [{cells: avgRow}, ...rows];
        return {rows: rows, header: header};
    }

    selectView(view: ReportView) {
    }

    getSelectedView(): ReportView {
        return undefined;
    }

    canPrepare(context: DashboardContext): boolean {
        return context.data!= null && context.data.unitReports != null && context.data.unitReports.length > 0
            && context.data.unitReports.find(r => r.values.filter(v => v.metric.code == this._metric).length > 0) != undefined;
    }
}

function _groupBy<T, S>(arr: T[], propPath: string): Map<S, Array<T>> {
    let _getProp = (obj: any, names: string[]): S => names.reduce((acc, curr) => acc ? acc[curr] : undefined, obj);
    let names: string[] = propPath.split(".");

    return arr.reduce((acc, currVal) => {
        let prop = _getProp(currVal, names);

        if (acc.has(prop)) {
            acc.get(prop).push(currVal);
        } else {
            acc.set(prop, [currVal]);
        }
        return acc;
    }, new Map<S, T[]>());
}

function _headerCell(val: string, tooltip?: string): Cell {
    return {header: true, value: val, tooltip: tooltip };
}

function _valueCell(val: number,totalAns:number): Cell {
//console.log("In valuecell: "+ val);
    if(Number.isNaN(val)){
        return {header: false, value: "", metaValue: Number.NaN};
    }

    if (val === Math.round(val)) {
        return {header: false, value: val.toFixed(0) + '%', metaValue: val,total:totalAns};
    } else {
        return {header: false, value: val.toFixed(2) + '%', metaValue: val,total:totalAns};
    }
}

function _isValidNum(totalAns: number, val: number): Cell{
   // console.log("In comparision: "+ totalAns);
   
    if(totalAns === 0 || totalAns === Number.NaN)
        return _valueCell(Number.NaN,totalAns);

        
        return _valueCell(Math.round(val),totalAns);
}

function _emptyCell(): Cell {
    return {header: false, value: ''};
}
