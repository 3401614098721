export enum StorageKeys {
  USER_NAME = "username",
  PASSWORD = "password",
  REMEMBER_ME = "rememberMe",
  AuthToken = "X-Auth-Token",
  OAUTH_ACCESS_TOKEN = "OAUTH_ACCESS_TOKEN",
  OAUTH_REFRESH_TOKEN = "OAUTH_REFRESH_TOKEN",
  JWT_AUTH_TOKEN = "JWT_AUTH_TOKEN",
}

export class LocalStorageManager {
  public get(key: StorageKeys) {
    return localStorage.getItem(key);
  }

  public set(key: StorageKeys, value: string) {
    return localStorage.setItem(key, value);
  }

  public clear(key: StorageKeys) {
    return localStorage.removeItem(key);
  }

  public get isRememberMe(): boolean {
    const setting: string | null = localStorage.getItem(StorageKeys.REMEMBER_ME);
    if (setting === 'true') {
      return true;
    }
    return false;
  }

  public set isRememberMe(value: boolean) {
    this.set(StorageKeys.REMEMBER_ME, value.toString());
  }
}
