export const COUNTRY = [
  {code: 'CA', name: 'Canada'},
  {code: 'US', name: 'United States'},
];

export const STATES = {
  CANADA: [
    {country: 'CA', code: 'AB', name: 'Alberta'},
    {country: 'CA', code: 'BC', name: 'British Columbia'},
    {country: 'CA', code: 'MB', name: 'Manitoba'},
    {country: 'CA', code: 'NB', name: 'New Brunswick'},
    {country: 'CA', code: 'NL', name: 'Newfoundland'},
    {country: 'CA', code: 'NS', name: 'Nova Scotia'},
    {country: 'CA', code: 'NU', name: 'Nunavut'},
    {country: 'CA', code: 'ON', name: 'Ontario'},
    {country: 'CA', code: 'PE', name: 'Prince Edward Island'},
    {country: 'CA', code: 'QC', name: 'Quebec'},
    {country: 'CA', code: 'SK', name: 'Saskatchewan'},
    {country: 'CA', code: 'NT', name: 'Northwest Territories'},
    {country: 'CA', code: 'YT', name: 'Yukon Territory'}
  ],
  US: [
    {country: 'US', code: 'AA', name: 'Armed Forces Americas'},
    {country: 'US', code: 'AE', name: 'Armed Forces Europe, Middle East, & Canada'},
    {country: 'US', code: 'AK', name: 'Alaska'},
    {country: 'US', code: 'AL', name: 'Alabama'},
    {country: 'US', code: 'AP', name: 'Armed Forces Pacific'},
    {country: 'US', code: 'AR', name: 'Arkansas'},
    {country: 'US', code: 'AS', name: 'American Samoa'},
    {country: 'US', code: 'AZ', name: 'Arizona'},
    {country: 'US', code: 'CA', name: 'California'},
    {country: 'US', code: 'CO', name: 'Colorado'},
    {country: 'US', code: 'CT', name: 'Connecticut'},
    {country: 'US', code: 'DC', name: 'District of Columbia'},
    {country: 'US', code: 'DE', name: 'Delaware'},
    {country: 'US', code: 'FL', name: 'Florida'},
    {country: 'US', code: 'FM', name: 'Federated States of Micronesia'},
    {country: 'US', code: 'GA', name: 'Georgia'},
    {country: 'US', code: 'GU', name: 'Guam'},
    {country: 'US', code: 'HI', name: 'Hawaii'},
    {country: 'US', code: 'IA', name: 'Iowa'},
    {country: 'US', code: 'ID', name: 'Idaho'},
    {country: 'US', code: 'IL', name: 'Illinois'},
    {country: 'US', code: 'IN', name: 'Indiana'},
    {country: 'US', code: 'KS', name: 'Kansas'},
    {country: 'US', code: 'KY', name: 'Kentucky'},
    {country: 'US', code: 'LA', name: 'Louisiana'},
    {country: 'US', code: 'MA', name: 'Massachusetts'},
    {country: 'US', code: 'MD', name: 'Maryland'},
    {country: 'US', code: 'ME', name: 'Maine'},
    {country: 'US', code: 'MH', name: 'Marshall Islands'},
    {country: 'US', code: 'MI', name: 'Michigan'},
    {country: 'US', code: 'MN', name: 'Minnesota'},
    {country: 'US', code: 'MO', name: 'Missouri'},
    {country: 'US', code: 'MP', name: 'Northern Mariana Islands'},
    {country: 'US', code: 'MS', name: 'Mississippi'},
    {country: 'US', code: 'MT', name: 'Montana'},
    {country: 'US', code: 'NC', name: 'North Carolina'},
    {country: 'US', code: 'ND', name: 'North Dakota'},
    {country: 'US', code: 'NE', name: 'Nebraska'},
    {country: 'US', code: 'NH', name: 'New Hampshire'},
    {country: 'US', code: 'NJ', name: 'New Jersey'},
    {country: 'US', code: 'NM', name: 'New Mexico'},
    {country: 'US', code: 'NV', name: 'Nevada'},
    {country: 'US', code: 'NY', name: 'New York'},
    {country: 'US', code: 'OH', name: 'Ohio'},
    {country: 'US', code: 'OK', name: 'Oklahoma'},
    {country: 'US', code: 'OR', name: 'Oregon'},
    {country: 'US', code: 'PA', name: 'Pennsylvania'},
    {country: 'US', code: 'PR', name: 'Puerto Rico'},
    {country: 'US', code: 'PW', name: 'Palau'},
    {country: 'US', code: 'RI', name: 'Rhode Island'},
    {country: 'US', code: 'SC', name: 'South Carolina'},
    {country: 'US', code: 'SD', name: 'South Dakota'},
    {country: 'US', code: 'TN', name: 'Tennessee'},
    {country: 'US', code: 'TX', name: 'Texas'},
    {country: 'US', code: 'UT', name: 'Utah'},
    {country: 'US', code: 'VA', name: 'Virginia'},
    {country: 'US', code: 'VI', name: 'Virgin Islands'},
    {country: 'US', code: 'VT', name: 'Vermont'},
    {country: 'US', code: 'WA', name: 'Washington'},
    {country: 'US', code: 'WV', name: 'West Virginia'},
    {country: 'US', code: 'WI', name: 'Wisconsin'},
    {country: 'US', code: 'WY', name: 'Wyoming'}
  ]
};