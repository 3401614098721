
import {map} from 'rxjs/operators';
import {ApiService} from "./api.service";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TokenService} from "../../service/token.service";

export abstract class CrudApiService<T, C, U> extends ApiService {
    protected assembleInstances(response: any[]): T[] {
        return response.map(_ => this.assembleSingleInstance(_));
    }
    protected abstract assembleSingleInstance(response: any): T;
    protected constructor(http: HttpClient, tokenService: TokenService, basePath: string) {
        super(http, tokenService, basePath)
    }

    getAllEntities(): Observable<Array<T>> {
        return this.get<Array<T>>().pipe(map(_ => {
            if (Array.isArray(_)) {
                return this.assembleInstances(_);
            } else {
                console.assert(false, 'CrudApiService: getAllEntities expected array from server');
            }
        }));
    }
    getAllPatientEntities(id: number): Observable<Array<T>> {
        return this.get<Array<T>>(`/${id}`);
    }

    getEntity(id: number): Observable<T> {
        return this.get<T>(`/${id}`);
    }

    createEntity(obj: C): Observable<T> {        
        return this.post<T>('', obj).pipe(map(_ => {
            return this.assembleSingleInstance(_);
        }));
    }

    updateEntity(id: number, obj: U): Observable<T> {
        return this.put<T>(`/${id}`, obj).pipe(map(_ => this.assembleSingleInstance(_)));
    }

    deleteEntity(id: number): Observable<void> {
        return this.delete(`/${id}`);
    }
}