import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from 'ng2-translate';
import { TokenService } from '../shared/service/token.service';
import { ACCOUNT_WIDGET_COMPONENT as UI_CONSTANTS } from '../shared/constants/uiConstants';
import { PopupMenuService } from '../shared/components/popup-menu/popup-menu.service';
import {LoginService} from "../shared/api";
import {SimpleModalComponent,SimpleModalService} from 'ngx-simple-modal';
import { TwoChoiceModalDialogComponent } from '../shared/components/two-choice-modal-dialog/two-choice-modal-dialog.component';
import { MODAL_DIALOG_FADE_DURATION_MS } from '../shared/constants/animationConstants';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../shared/service/notification.service';
import { ForgotPasswordService } from '../shared/api/forgot-password.service';
enum AccountMenuOptions {
    ChangePassword = 0,
    Logout = 1,
    UserManualApp = 2,
    UserManualWeb = 3,
    FAQ = 4,
    ContactUs = 5,
}

const APP_USER_MANUAL_LINK: string = 'https://multimedia.3m.com/mws/media/1598698O/3m-peak-clinical-outcomes-program-web-portal-user-guide.pdf';
const WEB_USER_MANUAL_LINK: string = 'https://multimedia.3m.com/mws/media/1598697O/3m-peak-clinical-outcomes-program-app-user-guide.pdf';
const FAQ_LINK: string = '../../assets/FAQs.pdf';

@Component({
    selector: 'account-widget',
    templateUrl: './account-widget.component.html',
    styleUrls: ['./account-widget.component.scss'],
})
export class AccountWidgetComponent implements OnInit {
    public UI = UI_CONSTANTS;

    @ViewChild('badge', { read: ViewContainerRef } ) badgeViewContainerRef: ViewContainerRef;

    private _loggedInUserInitials: string = '';
    public shouldBlurComponent: boolean = false;

    public get loggedInUserInitials() {
        return this._loggedInUserInitials;
    }

    // prettier-ignore
    constructor(
        //TODO(aleksey): tokenservice should not be injected here
        //TODO(aleksey): need to discuss which service should return current logged user (UserService | LoginService)
        private router: Router,
        private tokenService: TokenService,
        private popupMenuService: PopupMenuService,
        private loginService: LoginService,
        private simpleModalService: SimpleModalService,
        private http: HttpClient,
        private notificationService: NotificationService,
        private forgotPwService: ForgotPasswordService
    ) {}

    ngOnInit() {
        const loggedInUser = this.tokenService.getUserToken();

        if (null != loggedInUser) {
            if (loggedInUser.firstName != null && loggedInUser.firstName.length > 0) {
                const firstInitial = loggedInUser.firstName[0];

                if (loggedInUser.lastName != null && loggedInUser.lastName.length > 0) {
                    const lastInitial = loggedInUser.lastName[0];

                    this._loggedInUserInitials = firstInitial + lastInitial;
                }
            }
        }
    }

    onAccountWidgetClicked() {
        const menuOptions: string[] = [
            UI_CONSTANTS.CHANGE_PASSWORD,
            UI_CONSTANTS.LOGOUT,
            UI_CONSTANTS.APP_MANUAL,
            UI_CONSTANTS.WEB_MANUAL,
            UI_CONSTANTS.FAQ,
            UI_CONSTANTS.CONTACT_US,
        ];
        this.popupMenuService.openMenu(this.badgeViewContainerRef, menuOptions, (indexSelected) => {
            switch (indexSelected) {
                case AccountMenuOptions.ChangePassword:
                    this.onChangePasswordOptionClicked();
                break;
                case AccountMenuOptions.Logout:
                    this.onLogoutOptionClicked();
                break;
                case AccountMenuOptions.UserManualApp:
                    window.open(APP_USER_MANUAL_LINK);
                break;
                case AccountMenuOptions.UserManualWeb:
                    window.open(WEB_USER_MANUAL_LINK);
                break;
                case AccountMenuOptions.FAQ:
                    window.open(FAQ_LINK);
                break;
                case AccountMenuOptions.ContactUs:
                    this.contactUs()
                break;
                default:
                console.assert(false, 'AccountWidgetComponent#onAccountWidgetClicked - invalid indexSelected');
                break;
            }
        });
    }

    onChangePasswordOptionClicked() {
        const userName = this.tokenService.getUserToken().username;
        this.forgotPwService.forgotPassword(userName);
    }

    onLogoutOptionClicked() {
        this.loginService.logout();
    }
    public contactUs(): void {

        this.shouldBlurComponent = true;
        let removeAuditCloseConfirmation = UI_CONSTANTS.CONTACT_US_CLOSE_CONFIRMATION;
       
        this.simpleModalService
        .addModal(
            TwoChoiceModalDialogComponent,
            { ...removeAuditCloseConfirmation },
            {
                animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
            }
        )
        .subscribe(result => {
            this.shouldBlurComponent = false;
            if (result.didSelectDefaultChoice) {
                
            }
        });

}
}
