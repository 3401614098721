import { Component, ElementRef, AfterViewInit, DoCheck, OnDestroy, IterableDiffers, NgZone} from '@angular/core';
import { Growl, DomHandler } from 'primeng/primeng';
import { MessageService } from '../../../node_modules/primeng/components/common/messageservice';
import { Message } from '../../../node_modules/primeng/components/common/api';

// The purpose of this component is to provide a custom template to the existing Growl class
@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends Growl {  
    constructor(
        el: ElementRef,
        domHandler: DomHandler,
        differs: IterableDiffers,
        messageService: MessageService,
        zone: NgZone
    ) {
        super(el, domHandler, differs, messageService, zone);
    }
}
