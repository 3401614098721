import {Component, OnInit, ViewChild} from '@angular/core';
import {ErrorService} from '../shared/service/error-service';
import {TokenService} from '../shared/service/token.service';
import {EmitterService} from '../shared/service/emitter.service';
import {UserAddService} from './../user/user-add/user-add.service';
import { NgForm, NgControl } from '@angular/forms';
import {JSEncrypt} from 'jsencrypt';
import {LocalStorageManager, StorageKeys} from "../shared/service/LocalStorageManager";
import {OauthService} from "../shared/service/oauth.service";
import {HeaderConstants} from "../shared/constants/HeaderConstants";
import {Util} from "../util/util";
import {TermsComponent, TermsAndConditionsEvent} from "./terms-and-conditions/terms.component";
import {LoginCredentials} from "../shared/model/user";
import {LoginService, UserService} from "../shared/api";
import { NotificationService } from '../shared/service/notification.service';
import { LOGIN_PAGE as UI_CONSTANTS } from '../shared/constants/uiConstants';
import { TimeoutError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ForgotPasswordService } from '../shared/api/forgot-password.service';

// TODO: NL - audit this class thoroughly
// 1. Check subscriptions, should they be unsubscribed from?
// 2. Check rest of app, see where constants [DomSelectors, HeaderConstants, LocalStorageConstants] can be utilized elsewhere in the codebase
// 3. Somehow figure out to get rid off ALL the 'any's. Basically EVERY callback returns an 'any' object. 

const OAUTH_LOCAL_CALLBACK_PATH = '/oauth-callback';
const MMM_OAUTH_CLIENT_ID = '1db52f2e-a2b0-4887-8734-9c367f8e2c04';

function getOauthCallbackUrl(): string {
    const url = window.location.origin + OAUTH_LOCAL_CALLBACK_PATH;
    return encodeURIComponent(url);
}

function getFullOauthUrl(): string {
    return `https://wstestdev.3m.com/masl/auth/oauth/v2/authorize?response_type=code&client_id=${MMM_OAUTH_CLIENT_ID}&redirect_uri=${getOauthCallbackUrl()}&scope=profile&access_type=online&approval_prompt=force`;
}


@Component({
    selector: 'user-login',
    templateUrl: './login.component.html',
    styleUrls: ['loginPage.scss'],
})

export class LoginComponent implements OnInit {
    @ViewChild('terms') termsComponent: TermsComponent;
    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('userNameInput') public userNameInput: NgControl;
    @ViewChild('passwordInput') public passwordInput: NgControl;


    public user: LoginCredentials;
    public shouldShowSpinner: boolean = false;

    private encryptedUser: LoginCredentials;
    private publicKey: string = '';
    private usingEncryptedPassword: boolean;

    private hasAuthenticated: boolean = false;
    private hasAcceptedTerms: boolean = false;
    private hasSubmitted: boolean = false;

    private defaultPasswordOnInit: string = '****************';
    private userService: UserService;
    private tokenService: TokenService;
    private emitterService: EmitterService;
    private errorService: ErrorService;
    private userAddService: UserAddService;
    private localStorageManager: LocalStorageManager;
    private oauthService: OauthService;
    public UI = UI_CONSTANTS;

    constructor(
        userService: UserService,
        tokenService: TokenService,
        emitterService: EmitterService,
        errorService: ErrorService,
        userAddService: UserAddService,
        localStorageManager: LocalStorageManager,
        oauthService: OauthService,
        private loginService: LoginService,
        private notificationService: NotificationService,
        private http: HttpClient,
        private forgotPwService: ForgotPasswordService
    ) {

        this.userService = userService;
        this.tokenService = tokenService;
        this.emitterService = emitterService;
        this.errorService = errorService;
        this.userAddService = userAddService;
        this.localStorageManager = localStorageManager;
        this.oauthService = oauthService;
        this.usingEncryptedPassword = false;

        this.listenForTermsAcceptedEvent();
        this.listenForSignOutEvent();
        // Init property to be able to bind to those in template.
        this.user = {
            username: '',
            password: ''
        };
        this.encryptedUser = {
            username: '',
            password: ''
        };
    }

    public ngOnInit() {
        this.oauthService.tryRefreshStoredToken()
            .then((success: boolean) => {
                if (success) {
                    this.userService.checkUserRoleAndRedirectToDefaultRoute();
                }
            });

        this.userAddService.getPublicKey()
            .subscribe(data => {
                console.log(JSON.stringify( data));
                this.publicKey = data.publicKey as string;
                },
                error => {
                    this.errorService.handleErrorStatusCode(error);
                });

        const userName = this.localStorageManager.get(StorageKeys.USER_NAME);
        const password = this.localStorageManager.get(StorageKeys.PASSWORD);

        if (this.localStorageManager.isRememberMe && userName != null && password != null) {
            this.user.username = userName;
            this.encryptedUser.password = password;

           // this.user.password = this.defaultPasswordOnInit;
            this.usingEncryptedPassword = true;
        }

        this.hasAcceptedTerms = false;
    }

    public get isRememberMe(): boolean {
        return this.localStorageManager.isRememberMe;
    }

    public onRememberMeClicked() {
        this.localStorageManager.isRememberMe = !this.localStorageManager.isRememberMe;
    }

    public onClickVSRM() {
        window.location.assign(getFullOauthUrl());
    }
    
    public onClickLogin(): void {
        if (undefined == this.publicKey || this.publicKey.length == 0) {
            this.errorService.handleErrorStatusCode(500);
        }
         if (this.userNameInput.errors || this.passwordInput.errors) {
            this.handleFormErrors();
            this.hasSubmitted = true;
            return;
        } 
        /* if(this.user.username == "" || this.user.password == ""){
            this.handleFormErrors();
            return;
        }  */ 
        
        
        this.shouldShowSpinner = true;
        this.prepareEncryptedUserObject();

        this.loginService.login(this.encryptedUser).subscribe(loggedUser => {
            
            // TODO: (PeterStJ) figure out when this is possible - to not have an used when logged in 
            // as this confition does not have an 'else' clause
            if (Util.isDefinedAndNotNull(loggedUser)) {
                this.hasAuthenticated = true;
                if (loggedUser.termsAccepted === true) {
                    this.finishAuthAndMoveOn();
                } else if (this.hasAcceptedTerms) { 
                    this.userService.acceptTermsAndConditions().subscribe(() => {
                            this.finishAuthAndMoveOn();
                        },
                        err => {
                            console.error(err);
                            this.cancelAuthFlow();
                        });
                } else { 
                    this.shouldShowSpinner = false;
                    this.openTermsAndConditions();
                }
            }
        }, error => {
            console.log(JSON.stringify(error));
            this.errorService.handleErrorStatusCode(error);
            this.shouldShowSpinner = false;
        });
    }

    /**
     * Record the event once the user accepts the terms&conditions of the app.
     *
     * If the user is already logged in notify server about the acceptance and
     * once that is completed finish the login process.
     * 
     * If not yet logged in, we would remember the choice and once logged in
     * we would notify server if needed.
     */
    private listenForTermsAcceptedEvent() {
        this.emitterService.agreeToTermsAndConditions.subscribe((data: TermsAndConditionsEvent) => {
            //console.log(JSON.stringify(data));
            //return;
            if (data.status === true) {
                this.hasAcceptedTerms = true;
                
                this.onClickLogin();
                
                if (this.hasAuthenticated) {
                    this.userService.acceptTermsAndConditions()
                        .subscribe(() => {
                                this.finishAuthAndMoveOn();
                            },
                            () => {
                                this.cancelAuthFlow();
                            });
                }
            } else {
                this.cancelAuthFlow();
            }
        });
    }
    private listenForSignOutEvent() {
        this.emitterService.onSignOutEvent.subscribe(() => {
            this.hasAcceptedTerms = false;
        });
    }

    private cancelAuthFlow() {
        this.shouldShowSpinner = false;
        this.hasAuthenticated = false;
        this.hasAcceptedTerms = false;
        this.tokenService.signOutAndClearUserData();
        // this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.ERROR_CONTACT_ADMIN);
    }

    private finishAuthAndMoveOn() {
        this.saveCredentialsIfNeeded();
        this.userService.checkUserRoleAndRedirectToDefaultRoute();
        this.shouldShowSpinner = false;
    }

    private prepareEncryptedUserObject() {
        const jsEncrypt = new JSEncrypt;
        jsEncrypt.setPublicKey(this.publicKey);
        this.encryptedUser.username = jsEncrypt.encrypt(this.user.username);

        if (false === this.usingEncryptedPassword) {
            this.encryptedUser.password = jsEncrypt.encrypt(this.user.password);
        } else if (this.localStorageManager.get(StorageKeys.PASSWORD)) {
            this.encryptedUser.password = this.localStorageManager.get(StorageKeys.PASSWORD);
        }
    }

    private saveCredentialsIfNeeded() {
        if (this.isRememberMe) {
            this.localStorageManager.set(StorageKeys.USER_NAME, this.user.username);
            this.localStorageManager.set(StorageKeys.PASSWORD, this.encryptedUser.password);
        } else {
            this.localStorageManager.clear(StorageKeys.USER_NAME);
            this.localStorageManager.clear(StorageKeys.PASSWORD);
        }
    }

    public clearPassword() {
        this.user.password = '';
        this.usingEncryptedPassword = false;
    }

    public openTermsAndConditions() {
        console.assert(undefined != this.termsComponent, 'LoginComponent#openTermsAndConditions - terms child component is undefined');
        this.termsComponent.showTermsAndConditions = true;
    }

    public onChangePasswordOptionClicked() {
        if (this.user.username == '' || this.user.username == null || this.user.username == undefined) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FORGOT_PASSWORD);
        }
        else {
            this.forgotPwService.forgotPassword(this.user.username);
        }
    }


    //public get hasSubmitted(): boolean {
        

        //return this.formContainer.submitted;

      //  return true;
    //}
   
    public shouldControlShowErrorStyle(control: NgControl): boolean {
        console.assert(control != null, 'LoginComponent#shouldControlShowErrorStyle - control argument was null');

        //if (!control.touched &&!control.dirty) {
        if (false === control.touched && false === this.hasSubmitted) {    
            return false;
        }

         switch (control) {
            case this.userNameInput:
               if (this.userNameInput.errors) {
                 
                   return true;
               }            
                break;
            case this.passwordInput:
               if (this.passwordInput.errors) {
                   
                   return true;
               }            
                break;    
        } 
        /* if(control.invalid){

            this.handleFormErrors();
            return;
        } */

        //this.handleFormErrors();
        return control.errors != null; // This will return true if the control is required and the input missing.
    }
   
    private handleFormErrors(): void {
        let areAllRequiredFieldsFilled: boolean = true;

        if (this.userNameInput.errors && this.userNameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }
        if (this.passwordInput.errors && this.passwordInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }     
      

        if (false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
        }
    }
    public get copyRightText(): string {        
        let currentYear = new Date().getFullYear();
        return '© 3M '+ currentYear +'. All Rights Reserved.';
    }
}
