import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { UserRole } from '../model/role'
import { sortByQuestionCode } from '../../util/alphaNumericSort';
import { FacilityWorkerRole, FacilityWorker } from '../model/facility-worker';
import { FacilityService } from '../api';

/**
 * These roles are always associated with a facility or facilities
 */
const rolesThatRequireFacility = [
  'ROLE_AUDITOR',
  'ROLE_FACILITY_ADMIN',
  'ROLE_REP'
  
];

@Injectable()
export class Utils {
  constructor(private dataService: DataService) {

  }

  /**
   * Given one of the enims in UserRole model roles, converts it into
   * a role as used in the FacilityWorker role field.
   * 
   * Note that we will throw if the role cannot be converted. (i.e. fail early).
   */
  convertUserRoleToWorkerRole(
      userrole: 'ROLE_SUPER_ADMIN' | 'ROLE_ADMIN' | 'ROLE_FACILITY_ADMIN' | 'ROLE_REP' | 'ROLE_AUDITOR'): 'AUDITOR' | 'ADMIN' |'REP' {
    switch(userrole) {
      case 'ROLE_AUDITOR': 
        return 'AUDITOR';
      case 'ROLE_FACILITY_ADMIN':    
        return 'ADMIN';
      case 'ROLE_REP':    
        return 'REP';
      default: throw new Error('User role cannot be converted to facility worker role: ' + userrole);
    }
  }

  /**
   * Given an UserRole model, determine if we need to attach a facility record
   * for that specific user role type.
   */
  doesRoleRequireFacility(role: UserRole): boolean {
    return rolesThatRequireFacility.indexOf(role.role) !== -1;
  }

  /**
   * Map the facility worker role records to strings that we can display
   * in the UI.
   */
  mapWorkerFacilityToRoleName(worker: FacilityWorker): string {
    if (worker.role.role === 'ADMIN') return 'Admin';
    if (worker.role.role === 'AUDITOR') return 'Auditor';
    if (worker.role.role === 'REP') return 'Rep';
    return '';
  }

  // getAllowedRoles(roles: UserRole[]): UserRole[] {
  //   const hasSelectedFacility = this.dataService.defaultLocation && 
  //       this.dataService.defaultLocation.id !== 0;
  //   if (hasSelectedFacility) return roles;
  //   else return roles.filter(_ => {

  //   });
  // }
}