import {Routes} from '@angular/router';
import {CommonGuard} from '../shared/guard/common.guard';
import {DataEntryComponent} from './data-entry.component';
import {DataEntryAddComponent} from './data-entry-add/data-entry-add.component';
import {RoutePath} from '../shared/constants/index';

export const DataEntryRoutes: Routes = [
  {
    path: RoutePath.DATA_ENTRY,
    component: DataEntryComponent,
    canActivate: [CommonGuard]
  },
  {
    path: RoutePath.DATA_ENTRY, children: [
    {
      path: RoutePath.ADD,
      component: DataEntryAddComponent,
      canActivate: [CommonGuard],
      canDeactivate: ['SaveFormsGuardComponent']
    }]
  }, 
];
