import {DATEFORMAT} from './dateFormat';

export const SETTINGS = {
  MULTISELECT_DROPDOWN: {
    singleSelection: false,
    text: '--Please Select--',
    selectAllText: 'Select All',
    unSelectAllText: 'Deselect All',
    enableSearchFilter: true,
    classes: 'myclass custom-class',
    badgeShowLimit: 2
  },
  MY_DATEPICKER: {
    dateFormat: DATEFORMAT.mmmdd_yyyy,
    showInputField:false,
    showClearDateBtn:false,
    editableDateField: false,
    firstDayOfWeek : 'su',
    showTodayBtn: false,
    dayLabels: {su: 'S', mo: 'M', tu: 'T', we: 'W', th: 'T', fr: 'F', sa: 'S'},
    monthLabels: { 1: 'January', 2: 'February', 3: 'March', 4: 'April', 5: 'May', 6: 'June', 7: 'July', 8: 'August', 9: 'September', 10: 'October', 11: 'November', 12: 'December' },
    sunHighlight: false,
  }
};
// TODO: NL - these config objects are much too specific in their use case to be sitting in this file,
// find a better place for them.