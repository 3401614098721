import { PolicyType } from "./policy-type";
import { Patient, PatientAnswer, PatientCatheter } from './patient';
//import { Patient, PatientAnswer, PatientCatheter, PatientCatheterFinalAns,PatientCatheterAns, CathAnswer } from './patient';
import {Policy, PolicyQuestionAnswer} from "./policy";
import { QuestionType, Question, InputType } from "./question";
import { IQuestionGroup, QuestionGroup, QuestionGroupsMap } from "./questionGroup";
import AssertInClass from '../../util/AssertInClass';

export enum UserInterfaceType {
    Select = 'Select', // This type will probably go away in favor of ChoiceSheet
    Number = 'Number',
    Boolean = 'Boolean',
    Buttons = 'Buttons', // Looks similar to Boolean Yes/No buttons but in a neutral color. It must be explicitely told to be used with Question.userInterfaceHint, otherwise it will be a Select
    String = 'String'
}
export enum AuditTarget {
    Patient = 'Patient',
    Catheter = 'PatientCatheter'
}


export class Audit {
    private policyType: PolicyType;
    private policy: Policy;
    public auditTarget?: AuditTarget;
    public catheterNumber?: number;
    public patient?: Patient;
    public patientAnswersMap: Map<number, PatientAnswer>;
    private _questionGroups: QuestionGroup[] = [];
    private policyAnswerMap: Map<number, PolicyQuestionAnswer>;// maps questionAnswerId to PolicyQuestionAnswer

    public questionGroupToCommentMap: Map<QuestionGroup, string>;
    private catheterDependent: Boolean;
    public hasChanged: Boolean;
    public isNoAntimicrobialSelected:Boolean=false;
    public isCatheterTypeSelected:Boolean=false;
    

   

    constructor(policyType: PolicyType, policy: Policy,patient?: Patient,auditTarget?: AuditTarget,catheterNumber?: number) {
        this.policyType = policyType;
        this.policy = policy;
        this.catheterNumber = catheterNumber;
        this.auditTarget= auditTarget;
        this.patient=patient;

        //this.policyAnswerMap = new Map();
         // Build a map of answers that we can easily refer to for checking conditional Questions, QuestionAnswers, and insert dynamic Question.information dependent on other QuestionAnswers
        this.policyAnswerMap = new Map<number, PolicyQuestionAnswer>();
        for (let answer of policy.answers) {
            this.policyAnswerMap.set(answer.questionAnswerId, answer);
        }


        if(this.patient != null){
            this.patientAnswersMap = this.buildFilteredAnswerMap(patient.answers);
        }
      
        if(policy.customQuestions != null && policy.customQuestions.length > 0){
            if(policy.customQuestions.filter(f => f.forEveryPatientOrCatheter == 1).length > 0) {
                this.catheterDependent = true;
            }
            else{
                this.catheterDependent = false;
            }
        }

        QuestionGroupsMap.forEach((group: IQuestionGroup) => {
            const questionsInGroup: Question[] = this.policyType.questions.filter(q => q.questionGroupId === group.id && q.type === QuestionType.AUDIT);
            if (questionsInGroup.length > 0) {
                const groupWithQuestions = new QuestionGroup(group, questionsInGroup);
                this._questionGroups.push(groupWithQuestions);
            }
        }); 
        /*if(this._questionGroups.filter(group=>group.dependsOnCatheter == true).length>0 && this._questionGroups.filter(group=>group.id == 1).length==0 ){

            const questionsInGroup: Question[] = this.policyType.questions.filter(q => q.questionGroupId === 1 && q.type === QuestionType.AUDIT);
            
                const groupWithQuestions = new QuestionGroup(QuestionGroupsMap.filter(group=>group.id==1)[0], questionsInGroup);
                this._questionGroups.push(groupWithQuestions);
           

        }*/
        /*QuestionGroupsMap.forEach((group: IQuestionGroup) => {
            const questionsInGroup: Question[] = this.policyType.questions.filter(q => q.questionGroupId === group.id && q.type === QuestionType.AUDIT);        
            const customQuestionsInGroup: Question[] = policy.customQuestions.filter(q => q.questionGroupId === group.id && q.type === QuestionType.AUDIT)
            const allQuestions = questionsInGroup.concat(customQuestionsInGroup);
            if (allQuestions.length > 0) {
                const groupWithQuestions = new QuestionGroup(group, allQuestions);
                this._questionGroups.push(groupWithQuestions);
            }
        });*/

        this.questionGroupToCommentMap = new Map<QuestionGroup, string>();
    }
    public getQuestionById(id: number): Question {
        for (let questionGroup of this.questionGroups) {
            for (let question of questionGroup.questions) {
                if (question.id === id) {
                    return question;
                }
            }
        }
    }
 
    public get questionGroups(): QuestionGroup[] {
        
        let filteredQuestionGroups: QuestionGroup[] = this._questionGroups.slice();

        // If the audit is an extra catheter then only show catheter questions
        switch (this.auditTarget) {
            case AuditTarget.Patient:
                filteredQuestionGroups = this._questionGroups.slice();
                break;
            case AuditTarget.Catheter:
                filteredQuestionGroups = this._questionGroups.filter(questionGroup => true == questionGroup.dependsOnCatheter);
                break;
            default:
                //console.assert("Bad value of this.auditTarget: ${this.auditTarget}");
            break;
        }
        
        // Remove groups that don't have any questions to display.
        // This happens when the policy has answers that mean that the audit doesn't focus on those topics
        const calledQuestionGroups = filteredQuestionGroups.filter(questionGroup => {
            for (let question of questionGroup.questions) {
                if (this.shouldShowQuestion(question)) {
                    return true;
                }
            }
            return false;
        });
        // Sort by 'seqNum' field to get groups in the correct order because they arrive from the server in an undefined order.
        const sortedQuestionGroups = calledQuestionGroups.sort((a, b) => {
            return a.seqNum - b.seqNum;
        });
        return sortedQuestionGroups;
    }

    public get patientCatheterQuestionGroups(): Array<string>{
        //let questionGroup:QuestionGroup[][]=[];
        
        let groupString:string[]=[];
        if(this.auditTarget == AuditTarget.Patient){

            let patientTypeQuestionGroups:QuestionGroup[] =this._questionGroups.filter(questionGroup => false == questionGroup.dependsOnCatheter);

            const filterPatientTypeQuestionGroups = patientTypeQuestionGroups.filter(questionGroup => {
                for (let question of questionGroup.questions) {
                    if (this.shouldShowQuestion(question)) {
                        return true;
                    }
                }
                return false;
            });

            if(filterPatientTypeQuestionGroups.length > 0){
                /*  const sortPatientTypeQuestionGroups = filterPatientTypeQuestionGroups.sort((a, b) => {
                     return a.seqNum - b.seqNum;
                 });
                 questionGroup.push(sortPatientTypeQuestionGroups); */
                 groupString.push('Patient');
     
             }

            let catheterTypeQuestionGroups:QuestionGroup[] = this._questionGroups.filter(questionGroup => true == questionGroup.dependsOnCatheter);   
    
            const filterCatheterTypeQuestionGroups = catheterTypeQuestionGroups.filter(questionGroup => {
                for (let question of questionGroup.questions) {
                    if (this.shouldShowQuestion(question)) {
                        return true;
                    }
                }
                return false;
            });
            if(filterCatheterTypeQuestionGroups.length > 0){
                /* const sortCatheterTypeQuestionGroups = filterCatheterTypeQuestionGroups.sort((a, b) => {
                    return a.seqNum - b.seqNum;
                });
                questionGroup.push(sortCatheterTypeQuestionGroups); */
                groupString.push('Catheter');
            }


        }

        if(this.auditTarget == AuditTarget.Catheter){

            let catheterTypeQuestionGroups:QuestionGroup[] = this._questionGroups.filter(questionGroup => true == questionGroup.dependsOnCatheter);   
    
            const filterCatheterTypeQuestionGroups = catheterTypeQuestionGroups.filter(questionGroup => {
                for (let question of questionGroup.questions) {
                    if (this.shouldShowQuestion(question)) {
                        return true;
                    }
                }
                return false;
            });
            if(filterCatheterTypeQuestionGroups.length > 0){
                /* const sortCatheterTypeQuestionGroups = filterCatheterTypeQuestionGroups.sort((a, b) => {
                    return a.seqNum - b.seqNum;
                });
                questionGroup.push(sortCatheterTypeQuestionGroups); */
                groupString.push('Catheter');
            }
        }

        

        return groupString;
    }
    public get patientGroup():QuestionGroup[]{


        let patientTypeQuestionGroups:QuestionGroup[] =this._questionGroups.filter(questionGroup => false == questionGroup.dependsOnCatheter);
        let filterPatientTypeQuestionGroups = patientTypeQuestionGroups.filter(questionGroup => {
            for (let question of questionGroup.questions) {
                if (this.shouldShowQuestion(question)) {
                    return true;
                }
            }
            return false;
        });
     
        if(filterPatientTypeQuestionGroups.length > 0){
            let sortPatientTypeQuestionGroups = filterPatientTypeQuestionGroups.sort((a, b) => {
                return a.seqNum - b.seqNum;
            });
            return sortPatientTypeQuestionGroups;
        }


    }
    public get catheterGroup():QuestionGroup[]{

        let catheterTypeQuestionGroups:QuestionGroup[] = this._questionGroups.filter(questionGroup => true == questionGroup.dependsOnCatheter);
        let filterCatheterTypeQuestionGroups = catheterTypeQuestionGroups.filter(questionGroup => {
            for (let question of questionGroup.questions) {
                if (this.shouldShowQuestion(question)) {
                    return true;
                }
            }
            return false;
        });
        if(filterCatheterTypeQuestionGroups.length > 0){
            let sortCatheterTypeQuestionGroups:QuestionGroup[] = filterCatheterTypeQuestionGroups.sort((a, b) => {
                return a.seqNum - b.seqNum;
            });
           return sortCatheterTypeQuestionGroups;
        }


    }

    


    public getQuestionByCode(code: string): Question {
     //   console.log(JSON.stringify( this.questionGroups));
        for (let questionGroup of this.questionGroups) {
            for (let question of questionGroup.questions) {
                if (question.code === code) {
                    return question;
                }
            }
        }
    }

    public shouldShowQuestion(question: Question): boolean {
        if(this.catheterDependent == true && question.questionGroupId == 1){
            return true;
        }
        
        if (question.conditions && question.conditions.length > 0) {
            let out: boolean = false;
            
            for (let condition of question.conditions) {
                if (true === out) {
                    // conditions are a logical OR, so if we find one that matches we don't need to check the rest.
                    break;
                }

                if (this.policyAnswerMap.has(condition.conditionAnswerId)) {
                    out = true;
                }
            }
            if(this.policy.id == 1229 && out == true){
              //  console.log(JSON.stringify("Question:"+question.title));
            }
            return out;
        } else {
           return true;
        }
    }

    public getUserInterfaceTypeForQuestion(question: Question): UserInterfaceType {
        switch (question.inputType) {
            case InputType.TEXT:
                return UserInterfaceType.String;
            case InputType.NUMBER:
                return UserInterfaceType.Number;
            case InputType.BOOLEAN:
                return UserInterfaceType.Boolean;
            case InputType.RADIO_GROUP:
                return UserInterfaceType.Select;
            case InputType.CHECKBOX:
                return UserInterfaceType.Buttons;
            default:
                console.assert(false, `Question of code '${question.code}' has unsupported inputType: ${question.inputType}`);
        }
    }
    private buildFilteredAnswerMap(patientAnswers: PatientAnswer[]): Map<number, PatientAnswer> {
        const answerMap = new Map<number, PatientAnswer>();
        for (let patientAnswer of patientAnswers) {
            answerMap.set(patientAnswer.id, patientAnswer);
        }

        return answerMap;
    }
    public get doesAuditTypeHaveCatheterQuestions(): boolean {
        // Search through the culled and sorted groups, not the raw _questionGroups otherwise it'll most always return true for audits that *could* have catheter questions
        for (let questionGroup of this.questionGroups) {
            if (questionGroup.dependsOnCatheter) {
                return true;
            }
        }
        return false;
    }

    public get firstQuestionGroupIndexWithCatheterQuestions(): number {
        return this.questionGroups.findIndex(questionGroup => questionGroup.dependsOnCatheter == true);
    }
       // TODO - Kai: figure our why this is being done, we shouldn't need to do anything like this
    // Jon's old comment: "It looks like when I post a new patient to the database the number of catheters is defined by the number of unique combinations of the line and location provided. Are we sure we can assume that there is only one catheter per line/location combination?"
    static areCathetersEquivalent(a: PatientCatheter, b: PatientCatheter): boolean {
        if (a == null || b == null) {
            return false;
        }
        return a.line == b.line && a.location == b.location;
        // && a.side == b.side;
    }


    public get areAnyAnswersEntered(): boolean {
        return this.patientAnswersMap.size > 0;
    }
   



    public getLineString(): string {
        return this.getAnswerStringValueOfQuestionWithCode('Audit.Q10');
    }

    public getLocationString(): string {
        return this.getAnswerStringValueOfQuestionWithCode('Audit.Q11');
    }

    public getQuestionGroupThatContainsQuestionId(id: number) {
        for (let questionGroup of this.questionGroups) {
            for (let question of questionGroup.questions) {
                if (question.id === id) {
                    return questionGroup;
                }
            }
        }
        return undefined;
    }
    public getAnswerStringValueOfQuestionWithCode(code: string): string {
        const question = this.getQuestionByCode(code);
        if (question == null) {
            // The question isn't included with this particular policy we are auditing with.
            // This isn't neccessarily an error, for instance, a 21 day audit doesn't have this question.
            return '';
        }
        for (let answer of question.answers) {
            const answerForId = this.patientAnswersMap.get(answer.id);
            if (answerForId != null) {
                return answerForId.value;
            }
        }
        // The question is there but it is unaswered. That is probably an error.
        // TODO: (Jon) This should probably be an assertion fail. Leaving it now before deadline.
        return '';
    }
}
 