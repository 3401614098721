
export interface IUnit {
    id?: number;
    name: string;
    facilityId?: number;
    active?: boolean;
}

export class Unit implements IUnit {
    id?: number;
    name: string;
    facilityId?: number;
    active?: boolean;

    constructor(args: IUnit) {
        
        console.assert(args !== undefined && args !== null, 'Unit constructor passed null or undefined args object');
        console.assert(typeof args.name === 'string', 'Unit constructor passed args with invalid value');
        if (args.active !== undefined && args.active !== null) {
            console.assert(typeof args.active === 'boolean', 'Unit constructor passed args with invalid value');
        }
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'Unit constructor passed args with invalid value');
        }

        this.id = args.id || undefined;
        this.name = args.name;
        this.facilityId = args.facilityId;
        this.active = args.active || undefined;
    }
}
