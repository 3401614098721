import {Component, OnInit, ViewChild} from '@angular/core';
import {NgControl, NgForm} from '@angular/forms';
import {DataService} from '../../shared/service/data.service';
import {NotificationService} from '../../shared/service/notification.service';
import {STATES} from '../../shared/constants/index';
import {ErrorService} from '../../shared/service/error-service';
import {CREATE_AND_EDIT_HEALTH_SYSTEM_MODAL_DIALOG as UI_CONSTANTS} from '../../shared/constants/uiConstants';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {AppBlurService} from '../../shared/service/app-blur.service';
import {Facility} from "../../shared/model/facility";
import {FacilityLocation} from "../../shared/model/facility-location";
import {FacilityService,HealthSystemService} from "../../shared/api";
import {UserService} from "../../shared/api";
import {ROLES} from "../../shared/constants/index";
import {HealthSystem} from "../../shared/model/health-system";
import AbstractSkeletonLoader from '../../shared/animations/AbstractSkeletonLoader';
import { exit } from 'process';
import { TokenService } from '../../shared/service/token.service';



const HTTP_CONFLICT_STATUS: number = 409;

export enum CreateAndEditHealthSystemModalDialogMode {
    Create,
    Edit,
}

export interface ICreateAndEditHealthSystemModalDialog {
    mode: CreateAndEditHealthSystemModalDialogMode;
}

export interface ICreateAndEditHealthSystemModalDialogResult {
    needsRefresh: boolean;
}
export interface HealthSystemAddComponent extends AbstractSkeletonLoader {
   // needsRefresh: boolean;
}
@Component({
    templateUrl: 'health-system-add.component.html',
    styleUrls: ['./health-system-add.scss'],
})
export class HealthSystemAddComponent
    extends SimpleModalComponent<ICreateAndEditHealthSystemModalDialog, ICreateAndEditHealthSystemModalDialogResult>
    implements OnInit {

    public facility: Facility = <Facility>{
        location: <FacilityLocation>{},
        active: true, // TODO: add explicit input for this?
    };
    public locationAlreadyExists: Boolean = false;
    public sapAlreadyExists: Boolean = false;
    public stateList: any;
    public UI = UI_CONSTANTS;
    public mode: CreateAndEditHealthSystemModalDialogMode;
 
    public healthSystemValues=[];
    public selectedHealthSystem:string = undefined;
    public healthSystemName:string = undefined;
    public healthSystem: HealthSystem []=[];    
    public shouldBlurComponent: boolean = false;

    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('nameInput') public nameInput: NgControl;
    @ViewChild('addressLine1Input') public addressLine1Input: NgControl;
    @ViewChild('cityInput') public cityInput: NgControl;
    @ViewChild('zipCodeInput') public zipCodeInput: NgControl;
    @ViewChild('sapInput') public sapInput: NgControl;
    @ViewChild('addressLine2Input') public addressLine2Input: NgControl;
    @ViewChild('stateSelect') public stateSelect: NgControl;
    @ViewChild('healthSystemSelect') public healthSystemSelect: NgControl;
    @ViewChild('nameInputHealthSystem') public nameInputHealthSystem: NgControl;

    constructor(
        private dataService: DataService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private appBlurService: AppBlurService,
        private facilityService: FacilityService,
        private userService: UserService,
        private healthSystemService: HealthSystemService,
        private tokenService: TokenService
        //private abstractSkeletonLoader: AbstractSkeletonLoader,
    ) {
        super();
        this.stateList = STATES.US;
    }

    public ngAfterViewInit() {
        this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }

    public ngOnInit() {
        if (CreateAndEditHealthSystemModalDialogMode.Edit == this.mode) {
            console.assert(
                this.dataService.defaultLocation != null,
                'LocationAddComponent#ngOnInit - this.dataService.defaultLocation was null but mode is Edit'
            );
            this.facility = this.dataService.facility;
        }
       /* console.assert(this.formContainer != null, 'LocationAddComponent#ngOnInit - this.formContainer was null');
        console.assert(this.nameInput != null, 'LocationAddComponent#ngOnInit - this.nameInput was null');
        console.assert(
            this.addressLine1Input != null,
            'LocationAddComponent#ngOnInit - this.addressLine1Input was null'
        );
        console.assert(this.cityInput != null, 'LocationAddComponent#ngOnInit - this.cityInput was null');
        console.assert(this.zipCodeInput != null, 'LocationAddComponent#ngOnInit - this.zipCodeInput was null');
        console.assert(this.sapInput != null, 'LocationAddComponent#ngOnInit - this.sapInput was null');
        console.assert(
            this.addressLine2Input != null,
            'LocationAddComponent#ngOnInit - this.addressLine2Input was null'
        );
        console.assert(this.stateSelect != null, 'LocationAddComponent#ngOnInit - this.stateSelect was null');
        
*/
        this.updateData();

       // this.LoadHealtSystem();
    }

    public get hasSubmitted(): boolean {
        console.assert(this.formContainer != null, 'LocationAddComponent#hasSubmitted - this.formContainer was null');

        return this.formContainer.submitted;
    }

    public get isEditing(): boolean {
        return this.mode == CreateAndEditHealthSystemModalDialogMode.Edit;
    }

    
    public isFacilityAdmin():boolean{
        if(this.userService.getPrimaryRoleForCurrentUser()=='ROLE_FACILITY_ADMIN'){
            return true;
        }
        return false;
    }
    public isUserRepRole(): boolean {
        if(this.userService.getPrimaryRoleForCurrentUser()==ROLES.ROLE.ROLE_REP)
            return true;
        return false;
    }


    public onCreatePressed(): void {
        console.assert(
            this.formContainer != null,
            'LocationAddComponent#onCreatePressed - this.formContainer was null'
        );

        if (false == this.formContainer.form.valid) {
            this.handleFormErrors();
            return;
        }

        this.createHealthSystem(true);
    }

  

    private handleFormErrors(): void {
        let areAllRequiredFieldsFilled: boolean = true;

        if (this.nameInput.errors && this.nameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.addressLine1Input.errors && this.addressLine1Input.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.cityInput.errors && this.cityInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.zipCodeInput.errors && this.zipCodeInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.zipCodeInput.invalid) {
            //this.notificationService.error('', UI_CONSTANTS.ERRORS.ZIP_CODE_INVALID);
        }

        if (this.sapInput.errors && this.sapInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.addressLine2Input.errors && this.addressLine2Input.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.stateSelect.errors && this.stateSelect.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
        }
    }
    public updateData(): void {
        //this.showSkeletonAfterDelay(10);
        this.shouldBlurComponent = true;
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
           // this.hideSkeletonAndShowContent();
           this.shouldBlurComponent = false;
        });

    }

    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
                    .subscribe((healthSystem) => {
                        console.log(JSON.stringify(healthSystem));

                        if(healthSystem != null){
                            var hSName:HealthSystem[]=[];                       
                            var jsonText = JSON.stringify(healthSystem);                               
                               
                            var obj =  JSON.parse(jsonText);
                            var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                                    
                            this.healthSystemValues= [];  
                            arr.forEach(a=>{
                                
                                if(a.name!=undefined){
                            
                                    hSName.push({
                                        id:Number(a.id),
                                        name:a.name,
                                    });
                                }                 
                    
                            })  
                            if(this.isUserAdmin()){
                                console.log("User1:"+this.userService.getPrimaryRoleForCurrentUser()); 
                                this.healthSystem = hSName;
                            }else{
                                if(this.userService.getCurrentLoggedUser().healthSystemIdList.length > 0){
                                    this.healthSystem = hSName.filter(_=>{
                                        return this.userService.getCurrentLoggedUser().healthSystemIdList.includes(_.id);
                                    });
                                }
                            }  
                        }  
                    resolve();
                
            });
        });
           // console.log("Health System:"+JSON.stringify(this.healthSystemValues));
 
    }
    public createHealthSystem(shouldCloseDialogOnSuccess: boolean = true): void {
        //console.log(this.HealthSystem_name);
        this.healthSystemService.createHealthSystem(this.healthSystemName).subscribe(healthsystem => {
           // this.healthsystem = healthsystem;
            this.notificationService.success('', UI_CONSTANTS.SUCCESS.HEALTH_SYSTEM_CREATED);
            if (shouldCloseDialogOnSuccess) {
                this.result = { needsRefresh: true };
                this.close();
            }

        }, err => {
            if (err.status === HTTP_CONFLICT_STATUS) {
                this.sapAlreadyExists = true;
                //this.notificationService.error('', UI_CONSTANTS.ERRORS.SAP_EXISTS);
                console.log(true);
            } else {
                this.errorService.handleErrorStatusCode(err);
            }
        });
    }
    public editHealthSystem(shouldCloseDialogOnSuccess: boolean = true): void {
        //console.log(this.HealthSystem_name);
        var healthSystemId:number;
        this.healthSystem.forEach(a=>{
            // this.healthSystemValues.push(a.name);
            if(a.name==this.selectedHealthSystem){
             healthSystemId = a.id;
            }  

        })  
        console.log(healthSystemId);    

        
        this.healthSystemService.editHealthSystem(healthSystemId,this.healthSystemName).subscribe(healthsystem => {
           // this.healthsystem = healthsystem;
            this.notificationService.success('', UI_CONSTANTS.SUCCESS.HEALTH_SYSTEM_MODIFIED);
            if (shouldCloseDialogOnSuccess) {
                this.result = { needsRefresh: true };
                this.close();
            }

        }, err => {
            if (err.status === HTTP_CONFLICT_STATUS) {
                this.sapAlreadyExists = true;
                //this.notificationService.error('', UI_CONSTANTS.ERRORS.SAP_EXISTS);
                console.log(true);
            } else {
                this.errorService.handleErrorStatusCode(err);
            }
        });
    }
    public onSavePressed(): void {
        console.assert(
            this.formContainer != null,
            'LocationAddComponent#onCreatePressed - this.formContainer was null'
        );

        if (false == this.formContainer.form.valid) {
            this.handleFormErrors();
            return;
        }

        this.editHealthSystem(true);
    }
    public onHealthSystemChange(){
        this.healthSystemName = this.selectedHealthSystem;

    }
    public isUserAdmin():boolean{

        const role = this.userService.getPrimaryRoleForCurrentUser();  

        if (role === ROLES.ROLE.SUPER_ADMIN_3M || role === ROLES.ROLE.ADMIN_3M ) {

            return true;
        }
        return false;

     }

}
