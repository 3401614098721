import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgControl, NgForm} from '@angular/forms';
import {DataService} from '../../shared/service/data.service';
import {NotificationService} from '../../shared/service/notification.service';
import {Util} from '../../util/util';
import {UserRole} from '../../shared/model/role';
import {ErrorService} from '../../shared/service/error-service';
//import {UserAddService} from './user-add.service';
import {ConstantService} from '../../shared/service/constant-service';
import {CREATE_AND_EDIT_USER_MODAL_DIALOG as UI_CONSTANTS} from '../../shared/constants/uiConstants';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {TokenService} from '../../shared/service/token.service';
import {AppBlurService} from '../../shared/service/app-blur.service';
import {User} from '../../shared/model/user';
import {RoleService, UserService, HealthSystemService} from '../../shared/api';
import {ROLES} from '../../shared/constants';
import { FacilityService } from '../../shared/api/facility.service';
import { Facility } from '../../shared/model/facility';
import { Utils } from '../../shared/service/utils';
import { FacilityWorker, FacilityWorkerRole } from '../../shared/model/facility-worker';
import { ReturnStatement } from '@angular/compiler';
import { HealthSystem } from '../../shared/model/health-system';
import { ActivatedRoute, Router } from '@angular/router';
import { Route } from '../../shared/constants/index';


export enum AddUserHealthSystemModalDialogMode {
    Add,
   
}

export interface IAddUserHealthSystemModalDialogMode {
    mode: AddUserHealthSystemModalDialogMode;
 
}

export interface IAddUserHealthSystemModalDialogResult {
    needsRefresh: boolean;
    modifiedUser?: User;
}

@Component({
    templateUrl: 'add-user-health-system.html',
    styleUrls: ['./add-user-health-system.scss']
})
export class AddUserHealthSystemComponent
    extends SimpleModalComponent<IAddUserHealthSystemModalDialogMode, IAddUserHealthSystemModalDialogResult>
    implements OnInit, AfterViewInit, OnDestroy {
    public mode: AddUserHealthSystemModalDialogMode;
    public user: User;
    public shouldShowEmailAddressExistsError: boolean = false;
    public isAPICalled: boolean = false;
    public UI = UI_CONSTANTS;
    public roleList: UserRole[] = [];
    public selectedRole: UserRole = null;
    public selectedFacility: Facility = null;
    private _availableFacilities: Facility[] = [];
    public healthSystemValues=[];
    public selectedHealthSystem:string = undefined;
    public healthSystem: HealthSystem []=[];
    public activeUserList: User[] = [];
    public inactiveUserList: User[] = [];   
    public shouldBlurComponent = false;

    @ViewChild('formContainer') public formContainer: NgForm;
   
    constructor(
        private dataService: DataService,
        private tokenService: TokenService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        //private userAddService: UserAddService,
        private constantService: ConstantService,
        private userService: UserService,
        private appBlurService: AppBlurService,
        private roleService: RoleService,
        private utilService: Utils,
        private facilityService: FacilityService,
        private healthSystemService: HealthSystemService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) {
        super();
    }

    public get availableFacilities(): Facility[] {
        return this._availableFacilities.filter(f => this.userService.isUserAdminAtFacilityToEditFaclility(f.id));
    }

    public ngOnInit() {
        // TODO There seems to be some initialization ordering problems so the ViewChild isn't available right away because it now is conditionally shown depending on create/edit mode
        // For now, just disable the assertion.
        //console.assert(this.roleSelect != null, 'UserAddComponent#ngOnInit - this.roleSelect was null');

        this.facilityService.getAllActive().then(records => {
            this._availableFacilities = records;
        });

        //Load Health System list        
        this.updateData();
    }

    public ngAfterViewInit() {
        this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }
   
      
    public updateData(): void {
        this.shouldBlurComponent = true;
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
        this.shouldBlurComponent = false;
        });

    }
    
    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
             .subscribe((healthSystem) => {
                           
                 var hSName: HealthSystem[]=[];   
                 var jsonText = JSON.stringify(healthSystem);             
                 var obj =  JSON.parse(jsonText);
                 var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                       
                 this.healthSystemValues= [];  
                 arr.forEach(a=>{
                     this.healthSystemValues.push(a.name);
                     hSName.push({
                         id:Number(a.id),
                         name:a.name,
                     });
                    /* if(this.facility.healthSystemId == Number(a.id)){
                         this.selectedHealthSystem = a.name;
                     }*/
                 //console.log(a.name);
                })  
                
                if(this.isUserAdmin()){
                    console.log("User1:"+this.userService.getPrimaryRoleForCurrentUser()); 
                    this.healthSystem = hSName;
                }else{
                    if(this.userService.getCurrentLoggedUser().healthSystemIdList != undefined){
                        this.healthSystem = hSName.filter(_=>{
                            return this.userService.getCurrentLoggedUser().healthSystemIdList.includes(_.id);
                        });
                    }
                } 
                resolve();              
         
            });
        });           
  
    }
    public onHealthSystemChange(){

        this.healthSystem.find(_=>_.name == this.selectedHealthSystem);
    }

    public onAddPressed(){     
        const promises = [];
        let healthSystemID:number=undefined   
        if (Util.isDefinedAndNotNull(this.dataService.user)) {

            this.user = new User(this.dataService.user);

            console.log("User:"+JSON.stringify(this.user));
            //return;
            
            if(this.selectedHealthSystem != undefined){
                
                this.healthSystem.forEach(a=>{

                    if(a.name == this.selectedHealthSystem){
                      
                        healthSystemID= Number(a.id);
                    } 
                })           
                
            }

        }     
        promises.push(new Promise((resolve, reject) => {
            this.healthSystemService.addHealthSystemToUser(this.user.id, healthSystemID).subscribe(
                _ => resolve(null),
                _ => reject(_));
        }));

        Promise.all(promises).then(_ => {
            this.isAPICalled = false;
            this.notificationService.success('', UI_CONSTANTS.SUCCESS.USER_MODIFIED);
            this.result = { needsRefresh: true, modifiedUser: this.user };
            this.close();
            this.router.navigate([Route.USER]);  
        }).catch(_ => {
            this.isAPICalled = false;
           // this.handleResponseError(_);
        });
    }
    public isUserAdmin():boolean{

        const role = this.userService.getPrimaryRoleForCurrentUser();  
        console.log("Role:"+JSON.stringify(role));
        if (role === ROLES.ROLE.SUPER_ADMIN_3M || role === ROLES.ROLE.ADMIN_3M ) {

            return true;
        }
        return false;

     }
    
}
