import { FacilityLocation, IFacilityLocation } from './facility-location';

export interface IFacility {
    id?: number;
    active?: boolean;
    location: IFacilityLocation;
    name: string;
    sap?: string;
    healthSystemId?: number;
}

export class Facility implements IFacility {
    id?: number;
    active?: boolean;
    location: FacilityLocation;
    name: string;
    sap?: string;
    healthSystemId?: number;

    constructor(args: IFacility) {
        console.assert(args !== undefined && args !== null, 'facility constructor passed null or undefined args object');
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'facility constructor passed args with invalid value');
        }
        if (args.active !== undefined && args.active !== null) {
            console.assert(typeof args.active === 'boolean', 'facility constructor passed args with invalid value');
        }
        console.assert(args.location !== undefined && args.location !== null, 'facility constructor passed args with invalid value');
        console.assert(typeof args.name === 'string', 'facility constructor passed args with invalid value');
        if (args.sap !== undefined && args.sap !== null) {
            console.assert(typeof args.sap === 'string', 'facility constructor passed args with invalid sap number');
        }
        if (args.healthSystemId !== undefined && args.healthSystemId !== null) {
            console.assert(typeof args.healthSystemId === 'number', 'health system constructor passed args with invalid value');
        }

        this.active = args.active || undefined;
        this.id = typeof args.id === 'number' ? args.id : undefined;
        this.location = new FacilityLocation(args.location);
        this.name = args.name;
        this.sap = args.sap;
        this.healthSystemId = typeof args.healthSystemId === 'number' ? args.healthSystemId : undefined;
    }
}
