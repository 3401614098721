import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TwoChoiceModalDialogComponent } from './two-choice-modal-dialog/two-choice-modal-dialog.component';
import { SelectorComponent } from './selector/selector.component';
import { PopupMenuComponent } from './popup-menu/popup-menu.component';
import { PopupMenuService } from './popup-menu/popup-menu.service';
import { PopupMenuFacilityComponent } from './popup-menu-facility/popup-menu-facility.component';
import { PopupMenuFacilityService } from './popup-menu-facility/popup-menu-facility.service';
import { ContactUsService } from "../../shared/api";


@NgModule({
    declarations: [
       
        TwoChoiceModalDialogComponent,
        SelectorComponent,
        PopupMenuComponent,
        PopupMenuFacilityComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
    ],
    entryComponents: [TwoChoiceModalDialogComponent, PopupMenuComponent,PopupMenuFacilityComponent],
    exports: [

        TwoChoiceModalDialogComponent,
        SelectorComponent,
        PopupMenuComponent,
        PopupMenuFacilityComponent,
    ],
    providers: [
        PopupMenuService,
        PopupMenuFacilityService,
        ContactUsService,
       
    ]
})
export class SharedComponentsModule {}

