import {Routes} from '@angular/router';
import {CommonGuard} from '../shared/guard/common.guard';
import {FacilityListComponent} from './facility-list/facility-list.component';
import {FacilityAddComponent} from './facility-add/facility-add.component';
import {RoutePath} from '../shared/constants/index';
import {HealthSystemAddComponent} from './health-system-add/health-system-add.component';

export const FacilityRoutes: Routes = [
  {path: RoutePath.LOCATION, component: FacilityListComponent, canActivate: [CommonGuard]},
  {
    path: RoutePath.LOCATION, children: [
      {
        path: RoutePath.ADD,
        component: FacilityAddComponent,
        canActivate: [CommonGuard],
        canDeactivate: ['SaveFormsGuardComponent']
      },
      {
        path: RoutePath.MODIFY,
        component: FacilityAddComponent,
        canActivate: [CommonGuard],
        canDeactivate: ['SaveFormsGuardComponent']
      },
      {
        path: RoutePath.ADD,
        component: HealthSystemAddComponent,
        canActivate: [CommonGuard],
        canDeactivate: ['SaveFormsGuardComponent']
      },
      {
        path: RoutePath.MODIFY,
        component: HealthSystemAddComponent,
        canActivate: [CommonGuard],
        canDeactivate: ['SaveFormsGuardComponent']
      }
    ]
  }
];
