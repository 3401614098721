import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AUDIT_SETUP_PAGE_COMPONENT as UI_CONSTANTS } from '../../../shared/constants/uiConstants';
import {Question} from "../../../shared/model/question";
import {QuestionAnswer} from "../../../shared/model/question-answer";
import {sortByQuestionCode} from "../../../util/alphaNumericSort";
import {NotificationService} from "../../../shared/service/notification.service";
import { CreateAndEditPolicyModalDialogComponent} from "../create-and-edit-policy-modal-dialog.component";

export interface QuestionResponseChangeEvent {
    questionId: number;
    responses: QuestionResponse[];
}

export interface QuestionResponse {
    question: Question;
    answer: QuestionAnswer;
    freeText?: string;
    index?:string;

}

@Component({
    selector: 'policy-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.scss'],
})
export class QuestionComponent {
    @Input() public question: Question;
    @Input() public recordedResponses: QuestionResponse[];
    @Output('onResponseChange') public responseChange = new EventEmitter<QuestionResponseChangeEvent>();
   
    public UI = UI_CONSTANTS;
    private _responses: Map<number, QuestionResponse> = new Map<number, QuestionResponse>();

    // used to manage state of checkboxes and radio groups
    // because angular's change detections system can't handle Map correctly.
    public selectedMap: any = {};

    constructor(
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        this.recordedResponses.forEach(r => {
            this.selectedMap[r.answer.code] = true;
            this._responses.set(r.answer.id, r);
        });
    }

    public storedText(answer: QuestionAnswer): string | undefined {
        const storedResponse: QuestionResponse | undefined = this._responses.get(answer.id);
        if (undefined != storedResponse) {
            return storedResponse.freeText;
        }
        return undefined;
    }

    public get sortedAnswers(): QuestionAnswer[] {
        if (this.question != null) {
            return sortByQuestionCode(this.question.answers);
        } else {
            return [];
        }
    }

    public selectOption(answer: QuestionAnswer): void {
        this._responses.clear();
        this.selectedMap = {
            [answer.code]: true,
        };
        const response: QuestionResponse = {
            question: this.question,
            answer: answer,
        };
        this._responses.set(answer.id, response);

        this.emitUpdate();
        if (answer.showAuditSetupWarning) {
            this.showSetupWarning();
        }
    }

    public toggleOption(answer: QuestionAnswer): void {        
         
        if (this._responses.has(answer.id)) {
            this._responses.delete(answer.id);
        } else {
            const response: QuestionResponse = {
                question: this.question,
                answer: answer,
            };
            this._responses.set(answer.id, response);
            if (answer.showAuditSetupWarning) {
                this.showSetupWarning();
            }
        }

        this.emitUpdate();
    }

    private showSetupWarning(): void {
        this.notificationService.warn(this.UI.POLICY_SETUP_WARNING_TITLE, this.UI.POLICY_SETUP_WARNING);
    }

    private emitUpdate(): void {
        this.responseChange.emit({
            questionId: this.question.id,
            responses: Array.from(this._responses.values()),
        });
    }
    public getAnswerFreeText(answerId: number):string{
        const response = this._responses.get(answerId);
        return response.freeText;
    }

    public updateFreeTextAnswer(answerId: number, event: any): void {
        const response = this._responses.get(answerId);
        if(response.freeText == null)
            console.log(JSON.stringify(event));
        if (response) {
            console.assert(undefined != event && undefined != event && typeof event === 'string', 'QuestionComponent#updateFreeTextAnswer - event object did not match expected interface.');
            response.freeText = event;
            this._responses.set(answerId, response);
        } else {
            
            console.assert(false, 'QuestionComponent#updateFreeTextAnswer - tried to update text response for answer that is not selected, answerId was: ' + answerId);
        }

        this.emitUpdate();
    }

    public isSelected(answer: QuestionAnswer): boolean {
        return this._responses.has(answer.id);
    }
}
