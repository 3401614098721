import {NgModule} from '@angular/core';
import {Http} from '@angular/http';
import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {RouterModule} from '@angular/router';
import {PolicyListModule} from './policy-list/policy-list.module';
import {PolicyRoutes} from './policy-maintenance.route';
import {PolicyReviewModule} from './policy-review/policy-review.module';

export function translateLoader(http: Http) {
  return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
  declarations: [],
  imports: [
    PolicyListModule,
    PolicyReviewModule,
    RouterModule.forRoot(PolicyRoutes, {useHash: true}),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [Http]
    }),
  ],
  providers: [
  ]
})
export class PolicyMaintenanceModule {
}
