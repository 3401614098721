import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Facility, IFacility} from "../model/facility";
import {CrudApiService} from "./common/crud.api.service";
import {Policy} from "../model/policy";
import {Observable} from "rxjs";
import {Unit} from "../model/unit";
import {TokenService} from "../service/token.service";
import { FacilityWorker, IFacilityWorker } from "../model/facility-worker";
import {WebserviceUrl} from "../service/webservice-url";

@Injectable()
export class FacilityService extends CrudApiService<Facility, IFacility, Partial<Facility>> {

    constructor(httpClient: HttpClient, tokenService: TokenService) {
        super(httpClient, tokenService, "api/facilities");
    }

    assembleInstances(response: any[]): Facility[] {
        const facilities = super.assembleInstances(response);
        return facilities.sort((facilityA, facilityB) => {
            const nameA = facilityA.name.toLowerCase();
            const nameB = facilityB.name.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    /**
     * Retieve all facilities for the currently logged in user, but return only 
     * the ones that are active.
     */
    getAllActive(): Promise<Facility[]> {
        return new Promise((resolve, reject) => {
            this.getAllEntities().subscribe(_ => {
                const active = _.filter(_ => _.active);
                resolve(active);
            });
        })
    } 

    getAll(): Observable<Facility[]> {
        return this.get<Array<Facility>>(`/all`);
    }
    addPolicy(facilityId: number, policy: Policy): Observable<Policy> {
        return this.post<Policy>(`/${facilityId}/policies`, policy);
    }

    addUnit(facilityId: number, unit: Unit): Observable<Unit> {
        return this.post<Unit>(`/${facilityId}/units`, unit);
    }

    getUnits(facilityId: number): Observable<Array<Unit>> {
        return this.get<Array<Unit>>(`/${facilityId}/units`);
    }

    getPolicies(facilityId: number): Observable<Array<Policy>> {
        return this.get<Array<Policy>>(`/${facilityId}/policies`);
    }

    activePolicy(facilityId: number): Observable<Policy> {
        return this.get<Policy>(`/${facilityId}/policies/active`);
    }

    /**
     * This is a promise because we do nothing with this and it
     * will not actually run if we do not subscribe...
     */
    createWorker(id: number, worker: FacilityWorker): Promise<FacilityWorker> {
        return new Promise((resolve, reject) => {
            this.post<FacilityWorker>(`/${id}/workers`, worker).subscribe(
                _ => resolve(_),
                _ => reject(_)
            );
        });
    }

    updateWorker(worker: FacilityWorker): Promise<FacilityWorker> {
        return new Promise((resolve, reject) => {
            this.put<FacilityWorker>(`/${worker.facilityId}/workers/${worker.id}`, worker).subscribe(
                _ => resolve(_),
                _ => reject(_)
            );
        });
    }

    deleteWorker(worker: FacilityWorker): Promise<null> {
        return new Promise((resolve, reject) => {
            this.delete<FacilityWorker>(`/${worker.facilityId}/workers/${worker.id}`).subscribe(
                _ => resolve(null),
                _ => reject(_)
            );
        });
    }
    
    getWorkersForFacilityId(facilityId: number): Observable<Array<FacilityWorker>> {
        return this.get<Array<FacilityWorker>>(`/${facilityId}/workers`);
    }

    getWorkersForUserId(id: number): Promise<Array<FacilityWorker>> {
        return new Promise((resolve, reject) => {
            this.http.get<Array<IFacilityWorker>>(`${WebserviceUrl.WEBSERVICE_URL}api/users/${id}/positions`, {
                headers: this.commonHeaders()
            }).subscribe(
                _ => resolve(_.map(_ => new FacilityWorker(_))),
                _ => reject(_)
            );
        });
    }

    protected assembleSingleInstance(_: any): Facility {
        return new Facility(_);
    }
}