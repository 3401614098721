import { Routes } from '@angular/router';
import { CommonGuard } from '../shared/guard/common.guard';
import { UserListComponent } from './user-list/user-list.component';
import { UserDetailComponent } from './user-detail/user-detail.component';

import { RoutePath } from '../shared/constants/index';

export const UserRoutes: Routes = [
    { path: RoutePath.USER, component: UserListComponent, canActivate: [CommonGuard] },
    {
        path: RoutePath.USER,
        children: [
            {
                path: RoutePath.DETAIL,
                component: UserDetailComponent,
                canActivate: [CommonGuard],
            },
        ],
    },
];
