import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification.component';
import { DomHandler } from 'primeng/primeng';
import { MessageService } from '../../../node_modules/primeng/components/common/messageservice';

@NgModule({
    imports: [CommonModule],
    declarations: [NotificationComponent],
    exports: [NotificationComponent],
    providers: [DomHandler, MessageService]
})
export class NotificationModule {}
