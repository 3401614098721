import {Injectable} from '@angular/core';
import {Logger} from '../service/default-log.service';
import {DataService} from '../../shared/service/data.service';
import {EmitterService} from './emitter.service';
import {LocalStorageManager, StorageKeys} from "./LocalStorageManager";
import {User} from "../model/user";
import { Router } from '@angular/router';
import { Route } from '../constants';
import { DATA_ENTRY_PAGE } from '../constants/uiConstants';

@Injectable()
export class TokenService {
  private logger: Logger;
  private dataService: DataService;
  private emitterService: EmitterService;
  private localStorageManager: LocalStorageManager;
  private router: Router;

  private userToken: User;

  public constructor(
    logger: Logger,
    dataService: DataService,
    emitterService: EmitterService,
    localStorageManager: LocalStorageManager,
    router: Router
  ) {
    this.logger = logger;
    this.dataService = dataService;
    this.emitterService = emitterService;
    this.localStorageManager = localStorageManager;
    this.router = router;
  }

  public set(token: string, user: string): void {
    this.localStorageManager.set(StorageKeys.AuthToken, user.toString());

    //Saving User Token Info
    this.localStorageManager.set(StorageKeys.JWT_AUTH_TOKEN, token.toString());

    this.userToken = JSON.parse(atob(user.split('.')[0]));
    this.emitterService.onSigninEvent.emit({flag: true, user: this.userToken, imgPath: null});
  }

  public get(): string | null {
    return this.localStorageManager.get(StorageKeys.AuthToken);
  }

  //Add the function getJwtCodeToken under get function:
  public getJwtCodeToken(): string | null{
    return this.localStorageManager.get(StorageKeys.JWT_AUTH_TOKEN);
  }

  public setUserToken(user: User) {
    this.userToken = user;
  }

  public getUserToken(): User {
    return this.userToken;
  }

  private clearLocalStorage(): void {
    if (false == this.localStorageManager.isRememberMe) {
      this.localStorageManager.clear(StorageKeys.USER_NAME);
      this.localStorageManager.clear(StorageKeys.PASSWORD);
    }
    this.localStorageManager.clear(StorageKeys.AuthToken);
    this.localStorageManager.clear(StorageKeys.OAUTH_ACCESS_TOKEN);
    this.localStorageManager.clear(StorageKeys.OAUTH_REFRESH_TOKEN);
    this.localStorageManager.clear(StorageKeys.JWT_AUTH_TOKEN);
  }

  signOutAndClearUserData(): void {
    this.userToken = null;
    this.clearLocalStorage();

    const facilityObj = {
      id: null,
      name: null
    };
    this.dataService.defaultLocation = null;
    this.dataService.policy = null;
    this.dataService.user = null;
   
    this.dataService.policyDetailsForReview = null;
    this.emitterService.changeFacility.emit({facility: facilityObj});
    this.dataService.facility = null;
    this.emitterService.onSignOutEvent.emit({});
    
  }
}