import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {DataService} from '../../shared/service/data.service';
import {PERMISSIONS} from '../../shared/constants/index';
import {NotificationService} from '../../shared/service/notification.service';
import {Util} from '../../util/util';
import {TokenService} from '../../shared/service/token.service';
import {ErrorService} from '../../shared/service/error-service';
import {FACILITY_LIST_PAGE as UI_CONSTANTS} from '../../shared/constants/uiConstants';
import {SimpleModalService} from 'ngx-simple-modal';
import {CreateAndEditFacilityModalDialogMode, FacilityAddComponent} from '../facility-add/facility-add.component';
import {CreateAndEditHealthSystemModalDialogMode, HealthSystemAddComponent} from '../health-system-add/health-system-add.component';
import {MODAL_DIALOG_FADE_DURATION_MS} from '../../shared/constants/animationConstants';
import AbstractSkeletonLoader from '../../shared/animations/AbstractSkeletonLoader';
import {FacilityService} from "../../shared/api/facility.service";
import {Facility} from "../../shared/model/facility";
import {ROLES} from "../../shared/constants/index";
import { trigger, style, animate, transition } from '@angular/animations';

import { PopupMenuFacilityService } from '../../shared/components/popup-menu-facility/popup-menu-facility.service';
import {FacilitySelectorComponent} from "../../facility-selector/facility-selector.component";

import {UserService,HealthSystemService} from "../../shared/api";
import { HealthSystem } from '../../shared/model/health-system';


@Component({
    templateUrl: 'facility-list.component.html',
    styleUrls: ['facility-list.scss'],
    animations: [
        trigger('opacityFade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('0.3s', style({ opacity: 0 })),
        ])])],
})
export class FacilityListComponent extends AbstractSkeletonLoader implements OnInit {
    public spinner: Boolean = false;
    public activeLocationList: Facility[];
    public inactiveLocationList: Facility[];
    public searchText: string;
    public readonly LocationListTabs = {
        Active: 'Active',
        Inactive: 'Inactive',
    };
    public selectedTab: string = this.LocationListTabs.Active;
    public UI = UI_CONSTANTS;
    public readonly permissions = {
        create: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ADMIN_3M],
        update: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN,ROLES.ROLE.ROLE_REP]
    };

    //public healthSystemValues=[];
    //public selectedHealthSystem:string = undefined;
    public healthSystem: HealthSystem []=[];

    //@ViewChild('exportButton', {read: ViewContainerRef}) exportButtonViewContainerRef: ViewContainerRef;
    @ViewChild('createFacilityButton', { read: ViewContainerRef })
    createFacilityButtonViewContainerRef:  ViewContainerRef;
    constructor(
        private tokenService: TokenService,
        private dataService: DataService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private simpleModalService: SimpleModalService,
        private facilityService: FacilityService,
        private userService: UserService,
        private popupMenuFacilityService: PopupMenuFacilityService,
        private healthSystemService: HealthSystemService,
    ) {
        super();
    }

    public isUserAdmin(facilityId?: number): boolean {
        
        if (undefined != facilityId) {
            
            return this.userService.isUserAdminAtFacilityToEditAudit(facilityId);
        }
        // TODO should block FacilityAdmin from creating new facility ?
        if(this.userService.getPrimaryRoleForCurrentUser()==ROLES.ROLE.FACILITY_ADMIN)
            //return false;
        return this.userService.isUserAdmin;
    } 
    public isUserRepRole(): boolean {
        if(this.userService.getPrimaryRoleForCurrentUser()==ROLES.ROLE.ROLE_REP)
            return true;
        return false;
    }
    public isUserAdminToMakeInactive(facilityId?: number): boolean{
        if (undefined != facilityId) {
            if(this.userService.getPrimaryRoleForCurrentUser()==ROLES.ROLE.FACILITY_ADMIN)
                return false;
            if(this.userService.getPrimaryRoleForCurrentUser()==ROLES.ROLE.ROLE_REP)
                return false;
        }
        return this.userService.isUserAdmin;
    }
    public ngOnInit() {
        this.activeLocationList = [];
        this.inactiveLocationList = [];
        this.getLocationListFromBackend();  
        this.showSkeletonAfterDelay();
        this.updateData();
    }

    public get labelForToggleActiveHeader(): string {
        switch (this.selectedTab) {
            case 'Active':
                return this.UI.MAKE_INACTIVE;
            case 'Inactive':
                return this.UI.MAKE_ACTIVE;
        }
        console.assert(
            false,
            'FacilityListComponent#get labelForToggleActiveHeader - this.selectedTab has unexpecrted value'
        );
        return '';
    }

    public getToggleActiveLabelForFacility(facility: Facility): string {
        if (facility.active) {
            return this.UI.INACTIVE;
        } else {
            return this.UI.ACTIVE;
        }
    }

    public onToggleActivePressed(facility: Facility) {
        console.assert(facility != null, 'FacilityListComponent#onToggleActivePressed - facility argument is null');
        // Optimistically update UI and reload on error
        if (facility.active) {
            facility.active = false;
            this.activeLocationList = this.activeLocationList.filter(activeFacility => activeFacility !== facility);
            this.inactiveLocationList = [...this.inactiveLocationList, facility];
        } else {
            facility.active = true;
            this.inactiveLocationList = this.inactiveLocationList.filter(inactiveFacility => inactiveFacility !== facility);
            this.activeLocationList = [...this.activeLocationList, facility];
        }

        this.facilityService.updateEntity(facility.id, facility).subscribe(() => {
            const message = facility.active ? UI_CONSTANTS.SUCCESS.MAKE_ACTIVE : UI_CONSTANTS.SUCCESS.MAKE_INACTIVE;
            this.notificationService.success('', message);
        }, err => {
            this.errorService.handleErrorStatusCode(err);
            this.getLocationListFromBackend();
        });
    }

    private displayCreateAndEditModal(mode: CreateAndEditFacilityModalDialogMode): void {
        this.simpleModalService
            .addModal(
                FacilityAddComponent,
                { mode },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                if (result && result.needsRefresh === true) {
                    this.getLocationListFromBackend();
                }
            });
    }
    private displayCreateAndEditHealthSystemModal(mode: CreateAndEditHealthSystemModalDialogMode): void {
        this.simpleModalService
            .addModal(
                HealthSystemAddComponent,
                { mode },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                if (result && result.needsRefresh === true) {
                    this.getLocationListFromBackend();
                }
            });
    }

    public getLocationListFromBackend() {
        this.facilityService.getAllEntities().subscribe((list: Array<Facility>) => {
            this.updateLocationList(list);
            this.hideSkeletonAndShowContent();
        }, err => {
            this.errorService.handleErrorStatusCode(err);
        });
    }

    public selectTab(tab: string) {
        this.selectedTab = tab;
    }

    public isTabSelected(tab: string) {
        return this.selectedTab === tab;
    }

    public get shouldShowEmptyListIndicator() {
        return this.getLocationListForCurrentlySelectedTab().length == 0;
    }

    public onCreateFacilityPressed() {
        this.displayCreateAndEditModal(CreateAndEditFacilityModalDialogMode.Create);
    }

    public onEditFacilityPressed(facility: Facility) {
        this.dataService.facility = facility;
        this.displayCreateAndEditModal(CreateAndEditFacilityModalDialogMode.Edit);
    }

    public getLocationListForCurrentlySelectedTab(): Facility[] {
        switch (this.selectedTab) {
            case this.LocationListTabs.Active:
                return this.activeLocationList;
            case this.LocationListTabs.Inactive:
                return this.inactiveLocationList;
        }
        return [];
    }
    public getNameOfFacility(facility:Facility):string{

        if(facility.healthSystemId != undefined){

            return facility.name +" - "+ this.healthSystem.find(_=>_.id == facility.healthSystemId).name; 
        }

        return facility.name;
    }

    private updateLocationList(newLocationList: Facility[]) {
        this.activeLocationList = newLocationList.filter(location => location.active);
        this.inactiveLocationList = newLocationList.filter(location => !location.active);
    }

   /* public onExportClicked() {
        // To add menu options like Excel and PDF in the future just add to the menuOptions string array and respond to the corresponding indexSelected.
        const menuOptions = ['Create Facility', 'Create Health System' ,'Edit Health System'];
        this.popupMenuService.openMenu(this.exportButtonViewContainerRef, menuOptions, (indexSelected) => {
            switch (indexSelected) {
                case 0:
                //  this.exportAll(this.formats.ppt);
                // this.exportCurrent(this.formats.ppt);
                // this.exportAllShifts(this.formats.ppt);
                break;
                case 1:
                // this.exportData();                    
                break;
                default:
                console.assert(false, 'DashboardComponent#onExportClicked - Invalid index selected');
                break;
            }
        });
    }*/
    public onCreateFacilityClicked() {
        //this.policyIndexForPrint=this.indexOfActivePolicy;
        console.log('Create Facility Clicked');
        this.popupMenuFacilityService.openMenu(
            this.createFacilityButtonViewContainerRef,
            UI_CONSTANTS.PRINT_MENU_OPTIONS,
            indexSelected => {
                if (UI_CONSTANTS.PRINT_MENU_OPTIONS[indexSelected] === 'Create Facility') {
                    // TODO: use string enum to share definitions.
                   // this.printPolicy();
                  // this.updateDataPrintPolicy(this.indexOfActivePolicy);
                  console.log('Create Facility');
                  this.onCreateFacilityPressed();
                 
                } else if (UI_CONSTANTS.PRINT_MENU_OPTIONS[indexSelected] === 'Create Health System') {
                   // this.printForm();
                  //this.updateDataPrintForm(this.indexOfActivePolicy);
                  this.displayCreateAndEditHealthSystemModal(CreateAndEditHealthSystemModalDialogMode.Create);
                  console.log('Create Health System');
                
                } else{
                    console.log('Edit Health System');
                    this.displayCreateAndEditHealthSystemModal(CreateAndEditHealthSystemModalDialogMode.Edit);

                }
            }
        );
    }

    public updateData(): void {
        //this.showSkeletonAfterDelay(10);
        //
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
           // this.hideSkeletonAndShowContent();
          // this.shouldBlurComponent = false;
        });
        

    }
    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
             .subscribe((healthSystem) => {
                           
                 var hSName: HealthSystem[]=[];   
                 var jsonText = JSON.stringify(healthSystem);             
                 var obj =  JSON.parse(jsonText);
                 var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                       
                // this.healthSystemValues= [];  
                 arr.forEach(a=>{
                  //   this.healthSystemValues.push(a.name);                    
                     hSName.push({
                         id:Number(a.id),
                         name:a.name,
                     });
         
                })  
                this.healthSystem = hSName;
                /*if(this.isUserAdmin()){
                    console.log("User1:"+this.userService.getPrimaryRoleForCurrentUser()); 
                    this.healthSystem = hSName;
                }else{
                    if(this.userService.getCurrentLoggedUser().healthSystemIdList != undefined){
                        this.healthSystem = hSName.filter(_=>{
                            return this.userService.getCurrentLoggedUser().healthSystemIdList.includes(_.id);
                        });
                    }
                }    
                */
         
            });
        });         
  
    }
}