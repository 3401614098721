import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from "@angular/core";
import {PermissionCheckService} from "./permission.check.service";

/**
 * This directive allows to hide DOM elements according to user permissions.
 */
@Directive({
    selector: '[appCanAccess]'
})
export class CanAccessDirective implements OnInit {

    @Input('appCanAccess') public appCanAccess: string[];

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private permissionChecker: PermissionCheckService) {
    }

    ngOnInit(): void {
        this.applyPermission();
    }

    private applyPermission(): void {
        if (this.permissionChecker.check(this.appCanAccess)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}