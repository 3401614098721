import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { DataEntryRoutes } from './data-entry.route';
import { DataEntryComponent  } from "./data-entry.component";
import { DataEntryAddModule  } from "./data-entry-add/data-entry-add.module";
import { AccountWidgetModule } from '../account-widget/account-widget.module';
import { PeakDatePickerModule } from '../peak-date-picker/peak-date-picker.module';
//import { DataEntryProgressIndicatorComponent } from "./data-entry-progress-indicator/data-entry-progress-indicator.component";
import { SharedComponentsModule } from '../shared/components/shared-components.module';




@NgModule({
  declarations: [DataEntryComponent],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DataEntryAddModule,
    AccountWidgetModule,
    PeakDatePickerModule,
    SharedComponentsModule,
   

    RouterModule.forRoot(DataEntryRoutes),
  ]
})
export class DataEntryModule { }
