import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacilitySelectorComponent } from './facility-selector.component';
import { FormsModule } from '@angular/forms';
import { FilterPipe } from './facility-selector-pipes';

@NgModule({
    imports: [CommonModule, FormsModule],
    declarations: [FacilitySelectorComponent, FilterPipe],
    exports: [FacilitySelectorComponent],
})
export class FacilitySelectorModule {}
