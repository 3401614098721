import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'FilterPipe' })
export class FilterPipe implements PipeTransform {
    transform(facilityArray: any, input: string) {
        if (input != null && facilityArray != null) {
            input = input.toLowerCase();
            return facilityArray.filter(facility => facility.name.toLowerCase().indexOf(input) > -1);
        } else {
            return facilityArray;
        }
    }
}
