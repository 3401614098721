import { Component, OnInit } from '@angular/core';
import {SimpleModalComponent,SimpleModalService} from 'ngx-simple-modal';
//import {NgControl, NgForm} from '@angular/forms';
import { trigger, style, animate, transition } from '@angular/animations';
import {IMyDateModel,IMyMarkedDates} from 'mydatepicker';
import {IMyDpOptions, IMyDate} from 'mydatepicker';
import {DATEFORMAT, MESSAGES} from '../../shared/constants/';
import {ExportFormat, ReportService} from '../../shared/service/report/report.service';

export enum DisplayExportAnalyticDataModalDialogMode {
  Create,
  Edit,
}


export interface IDisplayExportAnalyticDataModalDialogResult {
  needsRefresh: boolean;
}

export interface IDisplayExportAnalyticDataModalDialog {
  mode: DisplayExportAnalyticDataModalDialogMode;
  
}

@Component({
  selector: 'app-export-analytic-data',
  templateUrl: './export-analytic-data.component.html',
  styleUrls: ['./export-analytic-data.component.scss'],
  animations: [
    trigger('opacityFade', [
        transition(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
        transition(':leave', [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))])
    ])
]
})
export class ExportAnalyticDataComponent extends SimpleModalComponent<IDisplayExportAnalyticDataModalDialog, IDisplayExportAnalyticDataModalDialogResult>
implements OnInit {
  public mode: DisplayExportAnalyticDataModalDialogMode;
  public fromDate: IMyDateModel;
  public toDate: IMyDateModel;
  //public fromDatePickerOptions: IMyDpOptions;
  //public toDatePickerOptions: IMyDpOptions;
  private today = new Date();
  public auditDate: IMyDateModel;
  public fromDatePickerOptions: IMyDpOptions = {
    dateFormat: DATEFORMAT.mmmdd_yyyy,
    firstDayOfWeek: 'su',    
    disableSince: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1},
    markCurrentDay:true,
  };
  public toDatePickerOptions: IMyDpOptions = {
    dateFormat: DATEFORMAT.mmmdd_yyyy,
    firstDayOfWeek: 'su',    
    disableSince: {year: this.today.getFullYear(), month: this.today.getMonth() + 1, day: this.today.getDate() + 1},
    markCurrentDay:true,
  };
  public formats = {
      excel: ExportFormat.EXCEL,
      ppt: ExportFormat.PPT,
      pdf: ExportFormat.PDF
  };
  public disableExportButton: boolean=true;


  constructor(private simpleModalService: SimpleModalService,private reportService: ReportService,) { super();   }

  ngOnInit() {
    
  }

  public onExportPressed(){

    console.log(JSON.stringify(this.toDate));
    console.log(JSON.stringify(this.fromDate));
  }
  public onChangeOfFromDate(){
    //this.onExportPressed();
    if(this.fromDate != undefined && this.toDate != undefined){
      this.disableExportButton= false;
      
    }
    if (this.fromDate) {
        this.toDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            editableDateField: false,
            disableUntil: this.fromDate.date,
            //disableUntil:{year: this.fromDate.date.year, month: this.fromDate.date.month, day: this.fromDate.date.day - 1},
            showInputField: false,
            showClearDateBtn: false,
        };

        if (this.toDate) {
            // Clamp the toDate so that it is always the same or after fromDate
            if (this.toDate.jsdate < this.fromDate.jsdate) {
                this.toDate = this.fromDate;
            }
        }

    }
  }
 public onChangeToDate(){
 // this.onExportPressed();
    if(this.fromDate != undefined && this.toDate != undefined){
      this.disableExportButton= false;
     
    }
 }
 private convertDate(date): Date | null {
    if (date) {
        const dateObj = new Date();
        dateObj.setFullYear(date.date.year, date.date.month - 1, date.date.day);
        return dateObj;
    }

    return null;
  }

  public exportFilterAnalyticData() {
          
  
      this.reportService.exportFilterAnalytic(this.convertDate(this.fromDate),this.convertDate(this.toDate));
    
  }
 
}
