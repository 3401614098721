import {CrudApiService} from "./common/crud.api.service";
import {Unit, IUnit} from "../model/unit";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";

@Injectable()
export class UnitService extends CrudApiService<Unit, IUnit, Partial<Unit>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/units");
    }

    protected assembleSingleInstance(_: any): Unit {
        return new Unit(_);
    }
}