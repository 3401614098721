import {CrudApiService} from "./common/crud.api.service";
import {Unit, IUnit} from "../model/unit";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

import { Patient, IPatient, PatientAnswer, PatientCatheter } from '../model/patient';
import { Audit } from '../model/audit';

/* @Injectable()
export class PatientService extends CrudApiService<Unit, IUnit, Partial<Unit>> {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/patient");
    }

    protected assembleSingleInstance(_: any): Unit {
        return new Unit(_);
    }
} */

@Injectable()
export class PatientAnswerService extends CrudApiService<Patient,Partial<IPatient>, Partial<IPatient>> {

    constructor(http: HttpClient, tokenService: TokenService) {
       super(http, tokenService, "api/editPatientAnswer");


    }

   
     protected assembleSingleInstance(response:any): Patient {
        return new Patient(response);
    } 

    getAllPatientAnswer(policyId:number,unitId:number,auditDate:string,timeShift:string): Observable<any> {
        //return this.get<Array<any>>(`/${facilityId}/policies/active`);
        return this.get<any>(`?policyId=${policyId}&unitId=${unitId}&auditDate=${auditDate}&timeShift=${timeShift}`);
    }

  /*   createAuditEntity(_: Patient): Observable<Patient> {
      const transformedObj: ModifiedPatient = new ModifiedPatient(_);
      console.log(JSON.stringify(transformedObj));
      return this.post<Patient>('', transformedObj);
    //return;
    
    } */
    
}
  
    
  