import {ROLES} from '../../shared/constants/roles';

export const PERMISSIONS = {
  DASHBOARD: {
    HEADER: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP, ROLES.ROLE.FACILITY_ADMIN]
  },

  FACILITY: {
    HEADER: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP, ROLES.ROLE.FACILITY_ADMIN],
    CREATE: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP],
    GENERATE_TOKEN: [ROLES.ROLE.ROLE_REP, ROLES.ROLE.FACILITY_ADMIN],
    MERGE_TOKEN: [ROLES.ROLE.ROLE_REP]
  },

  PARENT: {
    HEADER: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP],
    CREATE: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M]
  },

  USER: {
    HEADER: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.FACILITY_ADMIN],
    COPY_USER: [ROLES.ROLE.ROLE_REP, ROLES.ROLE.FACILITY_ADMIN, ROLES.ROLE.AUDITOR]
  },

  POLICY: {
    HEADER: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP, ROLES.ROLE.FACILITY_ADMIN]
  },

  REPORT: {
    HEADER: [ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP, ROLES.ROLE.FACILITY_ADMIN]
  },
  DATA_ENTRY:  {
    HEADER: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M, ROLES.ROLE.ROLE_REP],
    CREATE: [ROLES.ROLE.ADMIN_3M, ROLES.ROLE.SUPER_ADMIN_3M]
  }
};
