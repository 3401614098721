import {NgModule} from '@angular/core';
import {Http} from '@angular/http';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {RouterModule} from '@angular/router';
import {FacilityAddModule} from './facility-add/facility-add.module';
import {FacilityListModule} from './facility-list/facility-list.module';
import {FacilityRoutes} from './facility.route';
import {FacilityAddComponent} from './facility-add/facility-add.component';
import {SaveFormsGuard} from '../shared/guard/save-forms.guard';

import {HealthSystemAddComponent} from './health-system-add/health-system-add.component';
import {HealthSystemAddModule} from './health-system-add/health-system-add.module';

export function translateLoader(http: Http) {
  return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

export function saveFormsGuardHttpFactory() {
  return new SaveFormsGuard<FacilityAddComponent>()
}
export function saveFormsGuardHealthSystemHttpFactory() {
  return new SaveFormsGuard<HealthSystemAddComponent>()
}

@NgModule({
  declarations: [],
  imports: [
    FacilityAddModule,
    FacilityListModule,
    HealthSystemAddModule,
    RouterModule.forRoot(FacilityRoutes, {useHash: true}),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [Http]
    }),
  ],
  providers: [
    {provide: 'SaveFormsGuardComponent', useFactory: saveFormsGuardHttpFactory},
    {provide: 'SaveFormsGuardComponent', useFactory: saveFormsGuardHealthSystemHttpFactory},
  ]
})
export class FacilityModule {
}
