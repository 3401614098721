import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgControl, NgForm} from '@angular/forms';
import {DataService} from '../../shared/service/data.service';
import {NotificationService} from '../../shared/service/notification.service';
import {Util} from '../../util/util';
import {UserRole} from '../../shared/model/role';
import {ErrorService} from '../../shared/service/error-service';
import {UserAddService} from './user-add.service';
import {ConstantService} from '../../shared/service/constant-service';
import {CREATE_AND_EDIT_USER_MODAL_DIALOG as UI_CONSTANTS} from '../../shared/constants/uiConstants';
import {SimpleModalComponent} from 'ngx-simple-modal';
import {TokenService} from '../../shared/service/token.service';
import {AppBlurService} from '../../shared/service/app-blur.service';
import {User} from '../../shared/model/user';
import {RoleService, UserService, HealthSystemService} from '../../shared/api';
import {ROLES} from '../../shared/constants';
import { FacilityService } from '../../shared/api/facility.service';
import { Facility } from '../../shared/model/facility';
import { Utils } from '../../shared/service/utils';
import { FacilityWorker, FacilityWorkerRole } from '../../shared/model/facility-worker';
import { ReturnStatement } from '@angular/compiler';
import { HealthSystem } from '../../shared/model/health-system';

export enum CreateAndEditUserModalDialogMode {
    Create,
    Edit,
}

export interface ICreateAndEditUserModalDialog {
    mode: CreateAndEditUserModalDialogMode;
}

export interface ICreateAndEditUserModalDialogResult {
    needsRefresh: boolean;
    modifiedUser?: User;
}

@Component({
    templateUrl: 'user-add.component.html',
    styleUrls: ['./user-add.component.scss']
})
export class UserAddComponent
    extends SimpleModalComponent<ICreateAndEditUserModalDialog, ICreateAndEditUserModalDialogResult>
    implements OnInit, AfterViewInit, OnDestroy {
    public mode: CreateAndEditUserModalDialogMode;
    public user: User;
    public shouldShowEmailAddressExistsError: boolean = false;
    public isAPICalled: boolean = false;
    public UI = UI_CONSTANTS;
    public roleList: UserRole[] = [];
    public selectedRole: UserRole = null;
    public selectedFacility: Facility = null;
    private _availableFacilities: Facility[] = [];
    public healthSystemValues=[];
    public selectedHealthSystem:string = undefined;
    public healthSystem: HealthSystem []=[];

    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('firstNameInput') public firstNameInput: NgControl;
    @ViewChild('emailAddressInput') public emailAddressInput: NgControl;
    @ViewChild('lastNameInput') public lastNameInput: NgControl;
    @ViewChild('roleSelect') public roleSelect: NgControl;
    @ViewChild('facilitySelect') public facilitySelect: NgControl;

    constructor(
        private dataService: DataService,
        private tokenService: TokenService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        private userAddService: UserAddService,
        private constantService: ConstantService,
        private userService: UserService,
        private appBlurService: AppBlurService,
        private roleService: RoleService,
        private utilService: Utils,
        private facilityService: FacilityService,
        private healthSystemService: HealthSystemService,
    ) {
        super();
    }

    public get availableFacilities(): Facility[] {
        return this._availableFacilities.filter(f => this.userService.isUserAdminAtFacilityToEditFaclility(f.id));
    }

    public ngOnInit() {
        console.assert(this.formContainer != null, 'UserAddComponent#ngOnInit - this.formContainer was null');
        console.assert(this.firstNameInput != null, 'UserAddComponent#ngOnInit - this.firstNameInput was null');
        console.assert(this.emailAddressInput != null, 'UserAddComponent#ngOnInit - this.emailAddressInput was null');
        console.assert(this.lastNameInput != null, 'UserAddComponent#ngOnInit - this.lastNameInput was null');
        // TODO There seems to be some initialization ordering problems so the ViewChild isn't available right away because it now is conditionally shown depending on create/edit mode
        // For now, just disable the assertion.
        //console.assert(this.roleSelect != null, 'UserAddComponent#ngOnInit - this.roleSelect was null');

        this.facilityService.getAllActive().then(records => {
            this._availableFacilities = records;
        });

        switch (this.mode) {
            case CreateAndEditUserModalDialogMode.Edit:
                if (Util.isDefinedAndNotNull(this.dataService.user)) {
                    // Make a shallow copy so that if we edit the name and press cancel, the UI on the user detail page doesn't show the new value that was cancelled.
                    // There is probably a better way to do this.
                    this.user = new User(this.dataService.user);
                    console.log(JSON.stringify(this.user));
                    if(this.user.healthSystemIdList != undefined){
                        console.log("HS Name: Start")
                        this.healthSystem.forEach(a=>{

                            if(Number(a.id) == this.user.healthSystemIdList[0]){
                                console.log("HS Name:"+a.name)
                                this.selectedHealthSystem= a.name;
                            }

                        });
                    }
                } else {
                    this.user = {
                        username: '',
                        firstName: '',
                        lastName: ''
                    };
                }
                // This will not work - why even we have editing for non-existing user?
                console.assert(
                    this.user.roles != null && this.user.roles.length > 0,
                    'UserAddComponent#ngOnInit - this.user.roles was null or empty'
                );
                //TODO(refactoring): rework
                // this.user.newRoleId = this.user.roles[0].roleId; // The backend expects newRoleId even if you're modifying a user...
                break;
            case CreateAndEditUserModalDialogMode.Create:
                this.user = {
                    username: '',
                    firstName: '',
                    lastName: ''
                };
                break;
        }

        this.roleService.getAllEntities().subscribe(
                roles => { console.log("ROLES"+JSON.stringify(roles));
                    if (this.userService.getPrimaryRoleForCurrentUser() == ROLES.ROLE.FACILITY_ADMIN) {
                        this.roleList = roles.filter(r => {
                            return r.role == ROLES.ROLE.FACILITY_ADMIN || r.role == ROLES.ROLE.AUDITOR;
                            //return r.role == ROLES.ROLE.AUDITOR ||r.role == ROLES.ROLE.FACILITY_ADMIN;
                        });
                    } else if (this.userService.getPrimaryRoleForCurrentUser() == ROLES.ROLE.ADMIN_3M) {
                        this.roleList = roles.filter(r => {
                            return r.role == ROLES.ROLE.FACILITY_ADMIN || r.role == ROLES.ROLE.ROLE_REP||r.role == ROLES.ROLE.AUDITOR;
                            
                        });
                    } 
                     else {
                        this.roleList = roles;
                    }
                    if (this.user.roles && this.user.roles.length > 0) {
                        const index = this.roleList.findIndex(_ => _.id === this.user.roles[0].id);
                        if (index !== -1) {
                            this.selectedRole = this.roleList[index];
                        }
                    }
                },
                err => this.errorService.handleErrorStatusCode(err));
        //Load Health System list        
        this.updateData();
    }

    public ngAfterViewInit() {
        this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }

    /**
     * Should return true if the facility selector should be enabled in the
     * view. This is - when the currently selected role requires facility.
     */
    public get shouldRequireFacility(): boolean {
        if (this.mode === CreateAndEditUserModalDialogMode.Edit) {
            return false;            
        }
        if (!!this.selectedRole) {

           // console.log(JSON.stringify(this.selectedRole.id));
            return this.utilService.doesRoleRequireFacility(this.selectedRole);
        } else {
            return false;
        }
    }
    public onRoleSystemChange(){

        this.selectedHealthSystem=undefined;
       // console.log(JSON.stringify(this.selectedRole));
      //  console.log(JSON.stringify(this.selectedHealthSystem));

    }
    public get shouldRequireHealthSystem(): boolean {
       /* if (this.mode === CreateAndEditUserModalDialogMode.Edit) {
            return false;            
        }*/
        if (!!this.selectedRole) {

                       if(this.selectedRole.id == 6)
                return true;
            else
                return false;
        } else {
            return false;
        }
    }

    public get shouldShowRoleAndFacilityUI(): boolean {
        return this.mode === CreateAndEditUserModalDialogMode.Create;
    }

    public get isInCreateMode() {
        return this.mode === CreateAndEditUserModalDialogMode.Create;
    }

    public get isInEditMode() {
        return this.mode === CreateAndEditUserModalDialogMode.Edit;
    }

    public get isEmailAddressInputDisabled() {
        //TODO(aleksey): discuss the reason of 'isAPICalled' property
        return this.mode == CreateAndEditUserModalDialogMode.Edit || this.isAPICalled;
    }

    public get emailDisabledTooltip(): string {
        if (CreateAndEditUserModalDialogMode.Edit == this.mode) {
            return UI_CONSTANTS.TOOLTIPS.EMAIL_ADDRESS_DISABLED;
        } else {
            return '';
        }
    }

    public get areInputFieldsDisabled() {
        return this.isAPICalled ;
    }

    public createUserOnBackend() {
        this.user.roles = new Array(this.selectedRole);
        this.user.healthSystemIdList =new Array();

        //Validating facility Id
        if(this.user.roles[0].id != 1 && this.user.roles[0].id != 2)
            this.user.facilityId = this.selectedFacility.id;
        else
            this.user.facilityId = 0;
        
        let healthSystemID:number=undefined
        if(this.selectedHealthSystem != undefined){

          //  console.log("Health System is Defined!"+this.healthSystem.length);
            this.healthSystem.forEach(a=>{

                if(a.name == this.selectedHealthSystem){
                                   
                    healthSystemID= Number(a.id);
                } 
            }) 
           // return;         
            
        }else{

           // console.log("Health System Not Defined!");
           // return;
        }
        console.log("Health System is Defined!"+healthSystemID);
        this.user.healthSystemIdList.push(healthSystemID);
        
        //return;
        this.userService.createEntity(this.user).subscribe(u => {
            this.user = u;
            this.isAPICalled = true;
            this.notificationService.success('', UI_CONSTANTS.SUCCESS.USER_CREATED);
            this.result = { needsRefresh: true };

            if (this.utilService.doesRoleRequireFacility(this.selectedRole)) {
                if (this.selectedFacility !== null) {
                    const worker = new FacilityWorker({
                        facilityId: this.selectedFacility.id,
                        userId: this.user.id,
                        role: new FacilityWorkerRole({
                            role: this.utilService.convertUserRoleToWorkerRole(this.selectedRole.role)
                        })
                    });
                    this.facilityService.createWorker(this.selectedFacility.id, worker)
                        .then(() => { this.close(); });
                } else {
                    throw new Error('Added new role that required facility, but no facility selected');
                }
            } else {
                this.close();
            }
        }, err => {
            
            //if(err.error.status === 400 || err == null)
            //    this.close(); 
            this.isAPICalled = false;
            this.handleResponseError(err);
            //TODO(aleksey): add 409 error handling, in case when username already exists
        });
    }

    public modifyUserOnBackend() {
        const promises = [];
        let healthSystemID:number=undefined
        if(this.selectedHealthSystem != undefined){
            
            this.healthSystem.forEach(a=>{

                if(a.name == this.selectedHealthSystem){
                    // this.healthSystemValues[a.id] = a.name;
                    healthSystemID= Number(a.id);
                } 
            })           
            
        }
        if(this.selectedHealthSystem != undefined)
            this.user.healthSystemIdList.push(healthSystemID);
        console.log(JSON.stringify(this.user));
        // Check if the role that was selected is already existing in the
        // user record
        if (this.selectedRole !== null) {         
            const hasThisRole = this.user.roles.find(_ => _.id === this.selectedRole.id);
            // If we have a selected role and the user does not have it already, add it
            // before submitting the 
            if (hasThisRole === undefined) {
                this.user.roles = [this.selectedRole, ...this.user.roles];
                // If we are adding a new role and it requires facility
                // we need to crete a worker record for it.
                if (this.utilService.doesRoleRequireFacility(this.selectedRole)) {
                    if (this.selectedFacility !== null) {
                        const worker = new FacilityWorker({
                            facilityId: this.selectedFacility.id,
                            userId: this.user.id,
                            role: new FacilityWorkerRole({
                                role: this.utilService.convertUserRoleToWorkerRole(this.selectedRole.role)
                            })
                        });
                        promises.push(this.facilityService.createWorker(this.selectedFacility.id, worker));
                    } else {
                        throw new Error('Added new role that required facility, but no facility selected');
                    }
                }
            }
        }

        this.isAPICalled = true;
        promises.push(new Promise((resolve, reject) => {
            this.userService.updateEntity(this.user.id, this.user).subscribe(
                _ => resolve(null),
                _ => reject(_));
        }));

        Promise.all(promises).then(_ => {
            this.isAPICalled = false;
            this.notificationService.success('', UI_CONSTANTS.SUCCESS.USER_MODIFIED);
            this.result = { needsRefresh: true, modifiedUser: this.user };
            this.close();
        }).catch(_ => {
            this.isAPICalled = false;
            this.handleResponseError(_);
        });
    }

    private handleResponseError(error: number): void {
        this.errorService.handleErrorStatusCode(error);
    }

    public get labelForSubmitButton(): string {
        switch (this.mode) {
            case CreateAndEditUserModalDialogMode.Create:
                return UI_CONSTANTS.CREATE;
            case CreateAndEditUserModalDialogMode.Edit:
                return UI_CONSTANTS.SAVE;
        }
    }

    public onSubmitPressed() {
        console.assert(this.formContainer != null, 'UserAddComponent#onSubmitPressed - this.formContainer was null');

        if (false == this.formContainer.form.valid) {
            this.handleFormErrors();
            return;
        }
        if (this.roleSelect != null && this.roleSelect.value=="Select") {
            //areAllRequiredFieldsFilled = false;
            this.notificationService.error('','Please select a valid Role from the list');
            return;
        }
        if (this.facilitySelect != null && this.facilitySelect.value=="Select") {
            //areAllRequiredFieldsFilled = false;
            this.notificationService.error('','Please select a valid Facility Name from the list');
            return;
        }
        
        switch (this.mode) {
            case CreateAndEditUserModalDialogMode.Create:
                this.createUserOnBackend();
                break;
            case CreateAndEditUserModalDialogMode.Edit:
                this.modifyUserOnBackend();
                break;
        }
    }

    public get hasSubmitted(): boolean {
        console.assert(this.formContainer != null, 'UserAddComponent#hasSubmitted - this.formContainer was null');

        return this.formContainer.submitted;
    }

    public shouldControlShowErrorStyle(control: NgControl) {
        console.assert(control != null, 'UserAddComponent#shouldControlShowErrorStyle - control argument was null');

        if (false === control.touched && false === this.hasSubmitted) {
            return false;
        }

        if (control === this.emailAddressInput) {
            if (this.shouldShowEmailAddressExistsError) {
                return true;
            }
            if (control.invalid) {
                return true;
            }
        }

        return control.errors != null;
    }

    private handleFormErrors() {
        let areAllRequiredFieldsFilled: boolean = true;

        console.log(this.lastNameInput);

        if (this.firstNameInput.errors && this.firstNameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.emailAddressInput.errors && this.emailAddressInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }
        
        if (this.facilitySelect.errors && this.facilitySelect.errors.required) {
            //areAllRequiredFieldsFilled = false;
            this.notificationService.error('','Please select a Facility for this User');
        }
        

        if (this.emailAddressInput.invalid) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.EMAIL_ADDRESS_INVALID);
        }

        if (this.lastNameInput.errors && this.lastNameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.roleSelect && this.roleSelect.errors && this.roleSelect.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.FIELDS_REQUIRED);
        }
         if (this.lastNameInput.errors != null && this.lastNameInput.errors.pattern) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.LETTER_REQUIRED);
        }
        if (this.firstNameInput.errors != null && this.firstNameInput.errors.pattern) {
            this.notificationService.error('', UI_CONSTANTS.ERRORS.LETTER_REQUIRED);
        } 
    } 
  
    public updateData(): void {
        //this.showSkeletonAfterDelay(10);
        const loadTasks: Promise<void>[] = [this.LoadHealtSystem()];
        Promise.all(loadTasks).then(() => {
           // this.hideSkeletonAndShowContent();
        });

    }
    
    public LoadHealtSystem(): Promise<void> {
        return new Promise(resolve => {
            this.healthSystemService.getHealthSystem()
             .subscribe((healthSystem) => {
                           
                 var hSName: HealthSystem[]=[];   
                 var jsonText = JSON.stringify(healthSystem);             
                 var obj =  JSON.parse(jsonText);
                 var arr = Object.keys(obj).map(key => ({id: key, name: obj[key]}));   
                       
                 this.healthSystemValues= [];  
                 arr.forEach(a=>{
                     this.healthSystemValues.push(a.name);
                     hSName.push({
                         id:Number(a.id),
                         name:a.name,
                     });
                    /* if(this.facility.healthSystemId == Number(a.id)){
                         this.selectedHealthSystem = a.name;
                     }*/
                 //console.log(a.name);
                })  
                if(this.isUserAdmin()){
                    console.log("User1:"+this.userService.getPrimaryRoleForCurrentUser()); 
                    this.healthSystem = hSName;
                }else{
                    if(this.userService.getCurrentLoggedUser().healthSystemIdList != undefined){
                        this.healthSystem = hSName.filter(_=>{
                            return this.userService.getCurrentLoggedUser().healthSystemIdList.includes(_.id);
                        });
                    }
                }    
         
            });
        });           
  
    }
    public isUserAdmin():boolean{

        const role = this.userService.getPrimaryRoleForCurrentUser();  

        console.log("Role:"+JSON.stringify(role));
        if (role === ROLES.ROLE.SUPER_ADMIN_3M || role === ROLES.ROLE.ADMIN_3M ) {

            return true;
        }
        return false;

    }
}
