import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountWidgetComponent } from './account-widget.component';

@NgModule({
    imports: [CommonModule],
    declarations: [AccountWidgetComponent],
    exports: [AccountWidgetComponent],
})
export class AccountWidgetModule {}
