import {Injectable} from "@angular/core";
import {TokenService} from "../service/token.service";

/**
 * Provides method which allows to know if permission is assigned for current user.
 */
@Injectable()
export class PermissionCheckService {

    constructor(private tokenService: TokenService) {}

    check(permission: string[]): boolean {
        return this.runCheck(permission);
    }

    private runCheck(permissions: string[]): boolean {
        // nothing to check in this case
        if (!permissions.length) {
            return true;
        }

        const user = this.tokenService.getUserToken();

        for (let perm of permissions) {
            if (user.roles.some(r => r.role == perm)) {
                return true;
            }
        }

        return false;
    }
}