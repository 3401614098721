import {CrudApiService} from "./common/crud.api.service";
import {User, IUser} from "../model/user";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "../../../../node_modules/rxjs";
import {MESSAGES, ROLES, Route} from "../constants";
import {Router} from "@angular/router";
import {EmitterService} from "../service/emitter.service";
import {NotificationService} from "../service/notification.service";
import {UserRole, UserRoleString} from "../model/role";
import {FacilityService} from "./facility.service";
import {FacilityWorker, FacilityWorkerRole} from "../model/facility-worker";

@Injectable()
export class UserService extends CrudApiService<User, IUser, Partial<User>> {

    constructor(http: HttpClient,
                tokenService: TokenService,
                private facilityService: FacilityService,
                private router: Router,
                private emitterService: EmitterService,
                private notificationService: NotificationService
    ) {
        super(http, tokenService, "api/users");
        this.emitterService.onSigninEvent.subscribe(() => this.loadFacilityWorkerRoles());
    }
    private currentUserFacilityWorkerRecords?: FacilityWorker[];
    // maps user_id to FacilityWorker, should only contain entries from facilities for which the current user is an admin
    private administeredFacilityWorkersMap?: Map<number, FacilityWorker>;

    /**
     * Notify server that the user has accepted the terms&conditions for the app.
     */
    public acceptTermsAndConditions(): Observable<void> {
        const loggedInUser = this.tokenService.getUserToken();
        const path: string = `/${loggedInUser.id}/terms/accept`;
        return this.get(path);
    }

    public checkUserRoleAndRedirectToDefaultRoute() {
        const loggedUser = this.tokenService.getUserToken();
        const role = this.getPrimaryRoleForCurrentUser();

        if (loggedUser != null && loggedUser.termsAccepted == false) {
            this.router.navigate([Route.LOGIN]);
            return;
        }

        if (role === ROLES.ROLE.SUPER_ADMIN_3M ||
            role === ROLES.ROLE.ADMIN_3M ||
            role === ROLES.ROLE.ROLE_REP ||
            role === ROLES.ROLE.FACILITY_ADMIN) {

            this.emitterService.onSigninEvent.emit({flag: true, user: loggedUser, imgPath: null});

            if (role === ROLES.ROLE.ADMIN_3M) {
                this.router.navigate([Route.POLICY]);
                return;
            }

            this.router.navigate([Route.DASHBOARD]);
            return;

        } else if (role === ROLES.ROLE.AUDITOR) {
            this.emitterService.onSigninEvent.emit({flag: true, user: loggedUser, imgPath: null});
            this.router.navigate([Route.POLICY]);
            return;
        } else if (undefined == role) {
            this.notificationService.warn(MESSAGES.INFO.HEADER.NOT_AUTHORISED, MESSAGES.INFO.NO_PERMISSIONS);
        }

        this.tokenService.signOutAndClearUserData();
        this.router.navigate([Route.LOGIN]);
    }

    private loadFacilityWorkerRoles() {
        const loggedInUser = this.tokenService.getUserToken();
        this.facilityService.getWorkersForUserId(loggedInUser.id)
            .then(workers => {
                this.currentUserFacilityWorkerRecords = workers;

                if (this.getPrimaryRoleForCurrentUser() == ROLES.ROLE.FACILITY_ADMIN) {
                    this.loadFacilityWorkersForAdministeredFacilities();
                }
            });
    }

    private loadFacilityWorkersForAdministeredFacilities() {
        this.administeredFacilityWorkersMap = new Map();

        this.currentUserFacilityWorkerRecords.forEach(w => {
            if (this.isUserAdminAtFacility(w.facilityId)) {
                this.facilityService.getWorkersForFacilityId(w.facilityId)
                    .subscribe((records: FacilityWorker[]) => {
                        records.forEach(r => this.administeredFacilityWorkersMap.set(r.userId, r));
                        // this over-writes each time,
                        // fine for now since we only need to know that they have an association with a facility
                        // we're an admin for, but we could do more with the info we're loading here later.
                    });
            }
        });
    }

    public mayActiveUserAdministerUser(userToBeAdministered: User): boolean {
        const role = this.getPrimaryRoleForCurrentUser();
        switch (role) {
            case ROLES.ROLE.AUDITOR:
                return false;
            case ROLES.ROLE.FACILITY_ADMIN:            
                if (undefined != this.administeredFacilityWorkersMap
                    && this.administeredFacilityWorkersMap.has(userToBeAdministered.id)
                ) {
                    return true;
                }
                return false;
            case ROLES.ROLE.ROLE_REP:
                if (undefined != this.administeredFacilityWorkersMap
                    && this.administeredFacilityWorkersMap.has(userToBeAdministered.id)
                ) {
                    return true;
                }
                return false;
            case ROLES.ROLE.ADMIN_3M:
            case ROLES.ROLE.SUPER_ADMIN_3M:
                return true;
            default:
                console.assert(false, 'UserService#mayActiveUserAdministerUser - unknown role: ' + role);
                return false;
        }
    }

    public getPrimaryRoleForCurrentUser(): UserRoleString | undefined {
        const loggedUser = this.tokenService.getUserToken();
        return this.getPrimaryRoleFromUser(loggedUser);
    }   
    public getUserRoleID(user:User):number | undefined{
        let role:UserRoleString=this.getPrimaryRoleFromUser(user);
        let userId:number;        
        switch (role) {
            case ROLES.ROLE.AUDITOR:
                userId=5;
                break;          
            case ROLES.ROLE.ROLE_REP:
                userId=4;
                break;               
            case ROLES.ROLE.FACILITY_ADMIN:
                userId=3;
                break;
            case ROLES.ROLE.HEALTH_SYSTEM_ADMIN:
                userId=6;
                break;
            case ROLES.ROLE.ADMIN_3M:
                userId=2;
                break;
            case ROLES.ROLE.SUPER_ADMIN_3M:
                userId=1;
                break;    
            default:   
                userId=9;    
               
        }
        return userId;
    }
    public hasPermisssToChangeForCurrentUser(user:User):boolean{

        let loggedUserId:number=this.getUserRoleID(this.tokenService.getUserToken());
        let userId:number=this.getUserRoleID(user);

        if(loggedUserId==1){
            return true;
        }

        if(loggedUserId==2){
            if(loggedUserId<userId){
                return true;
            }else{
                return false;
            }
        }

        //Validating Facility Admin and 3M Rep roles
        // They can modify Facility Admin and Facility Auditors
        if(loggedUserId==3 || loggedUserId==4){
            if(userId == 3 || userId == 5)
                return true;
            else
                return false;
        }

        if(loggedUserId<userId){
            return true;
        }else{
            return false;
        }

    }

    public get isUserAdmin(): boolean {
        const role = this.getPrimaryRoleForCurrentUser();
        if (undefined == role || role == ROLES.ROLE.AUDITOR) {
            return false;
        }
        return true;
    }

    public isUserAdminAtFacility(facilityId: number): boolean {
        const role = this.getPrimaryRoleForCurrentUser();
       
        switch (role) {
            case ROLES.ROLE.AUDITOR:
            case ROLES.ROLE.FACILITY_ADMIN: 
            case ROLES.ROLE.ROLE_REP:                    
                if (this.currentUserFacilityWorkerRecords
                    && undefined != this.currentUserFacilityWorkerRecords.find(w => w.facilityId == facilityId && w.role.role == 'ADMIN' || w.role.role == 'REP')
                ) {
                    return true;
                }
                return false;            
            case ROLES.ROLE.ADMIN_3M:
            case ROLES.ROLE.SUPER_ADMIN_3M:
                return true;
            default:
                console.assert(false, 'UserService#isUserAdminAtFacility - unknown role: ' + role);
                return false;
        }
    }
    public isUserAdminAtFacilityToEditFaclility(facilityId: number): boolean {
        const role = this.getPrimaryRoleForCurrentUser();
       
        switch (role) {
            case ROLES.ROLE.AUDITOR:
            case ROLES.ROLE.FACILITY_ADMIN: 
            case ROLES.ROLE.ROLE_REP:                    
                if (this.currentUserFacilityWorkerRecords
                    && undefined != this.currentUserFacilityWorkerRecords.find(w => w.facilityId == facilityId && w.role.role == 'ADMIN' 
                        || w.role.role == 'REP'
                    )
                ) {
                    return true;
                }
                return false;            
            case ROLES.ROLE.ADMIN_3M:
            case ROLES.ROLE.SUPER_ADMIN_3M:
                return true;
            default:
                console.assert(false, 'UserService#isUserAdminAtFacility - unknown role: ' + role);
                return false;
        }
    }
    public isUserAdminAtFacilityToEditAudit(facilityId: number): boolean {
        const role = this.getPrimaryRoleForCurrentUser();
       
        switch (role) {
            case ROLES.ROLE.AUDITOR:  
            case ROLES.ROLE.FACILITY_ADMIN: 
            case ROLES.ROLE.ROLE_REP:                    
                if (this.currentUserFacilityWorkerRecords
                    && undefined != this.currentUserFacilityWorkerRecords.find(w => w.facilityId == facilityId && w.role.role != 'AUDITOR' )
                ) {
                    return true;
                }
                return false;            
            case ROLES.ROLE.ADMIN_3M:
            case ROLES.ROLE.SUPER_ADMIN_3M:
                return true;
            default:
                console.assert(false, 'UserService#isUserAdminAtFacility - unknown role: ' + role);
                return false;
        }
    }

    // This is similar to isUserAdminAtFacility() but also includes Auditors who are assigned to that facility
    public canActiveUserViewFacility(facilityId: number): boolean {
        if (this.isUserAdminAtFacility(facilityId)) {
            return true;
        }
    
        if (this.currentUserFacilityWorkerRecords
            && undefined != this.currentUserFacilityWorkerRecords.find(facilityWorker => facilityWorker.facilityId == facilityId)
        ) {
            return true;
        } else {
            return false;
        }
    }

    public getPrimaryRoleFromUser(user: User): UserRoleString | undefined {
        if (undefined == user
            || undefined == user.roles
            || user.roles.length == 0
        ) {
            return undefined;
        }

        const rolesInOrderOfRank: UserRoleString[] = [
            ROLES.ROLE.SUPER_ADMIN_3M,
            ROLES.ROLE.ADMIN_3M,            
            ROLES.ROLE.FACILITY_ADMIN,
            ROLES.ROLE.ROLE_REP,
            ROLES.ROLE.AUDITOR,
            ROLES.ROLE.HEALTH_SYSTEM_ADMIN,
        ];

        for (let r of rolesInOrderOfRank) {
            let role = user.roles.find(userRole => userRole.role === r);
            if (role) {
                return role.role;
            }
        }

        console.assert(false, 'UserService#getPrimaryRoleFromUser - failed to find matching role for user: ' + JSON.stringify(user));
        return undefined;
    }

    protected assembleSingleInstance(_: any): User {
        return new User(_);
    }
    public getCurrentLoggedUser(): User {
        const loggedUser = this.tokenService.getUserToken();
        return loggedUser;
    } 
}