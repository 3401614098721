
export interface IContactUs {
    id?: number;
    name?: string;
    userName?: string;
    email?: string;
    facility?: string;
    mode?: string;
    message?: string;
 
}

export class ContactUs  implements IContactUs  {
    id?: number;
    name?: string;
    userName?: string;
    email?: string;
    facility?: string;
    mode?: string;
    message?: string;

    constructor(args: IContactUs) {
        
        console.assert(args !== undefined && args !== null, 'Unit constructor passed null or undefined args object');
  
        this.id = args.id || undefined;
        this.name= args.name || undefined;
        this.userName= args.userName || undefined;
        this.email= args.email || undefined;
        this.facility= args.facility || undefined;
        this.mode= args.mode || undefined;
        this.message= args.message || undefined;
    }
}
