import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ConfirmationService, ConfirmDialogModule, MegaMenuModule} from 'primeng/primeng';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {Http} from '@angular/http';
import {MyDatePickerModule} from 'mydatepicker';

import {ConstantService} from './shared/service/constant-service';
import {NotificationService} from './shared/service/notification.service';
import {DataService} from './shared/service/data.service';
import {Logger} from './shared/service/default-log.service';
import {ConsoleLogService} from './shared/service/log.service';
import {TokenService} from './shared/service/token.service';
import {NotificationEmitterService} from './shared/service/notification-emitter.service';
import {appRoutes} from './app.route';
import {CommonGuard} from './shared/guard/common.guard';
import {SaveFormsGuard} from './shared/guard/save-forms.guard';
import {EmitterService} from './shared/service/emitter.service';
import {ErrorService} from './shared/service/error-service';
import {PolicyMaintenanceModule} from './policy-maintenance/policy-maintenance.module';
import {DashboardModule} from './dashboard/dashboard.module';
import {AppBlurService} from './shared/service/app-blur.service';

import {AppComponent} from './app.component';
import {HeaderComponent} from './shared/header/header.component';
import {NotificationModule} from './notification/notification.module';
import {FooterComponent} from './shared/footer/footer.component';
import {FacilityModule} from './facility/facility.module';
import {UnitModule} from './unit/unit.module';
import {UserModule} from './user/user.module';


import {DashboardService} from './dashboard/dashboard.service';
import {ExcelService} from './shared/service/report/excel.service';
import {PdfService} from './shared/service/report/pdf.service';
import {LocalStorageManager} from './shared/service/LocalStorageManager';
import {OauthModule} from './oauth/oauth.module';
import {OauthService} from './shared/service/oauth.service';
import {LoginModule} from './login/login.module';
import * as API from "./shared/api";
import {DirectiveModule} from "./shared/directive/directive.module";
import {AuditSetupModule} from "./audit-setup/audit-setup.module";
//import { DataEntryComponent } from './data-entry/data-entry.component';
import { DataEntryModule } from "./data-entry/data-entry.module";
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import { SummaryReportsModule } from "./summary-reports/summary-reports.module";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';



export function translateLoader(http: Http) {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
    declarations: [AppComponent, HeaderComponent, FooterComponent],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        HttpClientModule,
        ConfirmDialogModule,
        MegaMenuModule,
        NgIdleKeepaliveModule,
        DataEntryModule,
        SummaryReportsModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
        NotificationModule,
        BrowserAnimationsModule,
        MyDatePickerModule,
        PolicyMaintenanceModule,
        FacilityModule,
        UnitModule,
        UserModule,
        DashboardModule,        
        OauthModule,
        LoginModule,
        AuditSetupModule,
        DirectiveModule,
        NgxSpinnerModule,
        NgxDatatableModule
 
    ],
    exports: [NgxSpinnerModule],
    providers: [ 
        NotificationService,
        ConstantService,
        DataService,
        { provide: Logger, useClass: ConsoleLogService },
        TokenService,
        NotificationEmitterService,
        CommonGuard,
        SaveFormsGuard,
        EmitterService,
        ErrorService,
        ConfirmationService,
        DashboardService,
        ExcelService,
        PdfService,
        LocalStorageManager,
        OauthService,
        AppBlurService,
        API.FacilityService,
        API.PolicyService,
        API.PolicyTypeService,
        API.QuestionService,
        API.UnitService,
        API.UserService,
        API.PolicyTypeService,
        API.LoginService,
        API.PatientService,
        API.CatheterService,
        API.AuditService,
        API.PatientAnswerService,
        API.CatheterLocationService,
        API.PolicyFilterService,
        API.ReportDetailsService,
        API.HealthSystemService,
        API.ContactUsService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
