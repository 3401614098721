export type UserRoleString = 'ROLE_SUPER_ADMIN' | 'ROLE_ADMIN' | 'ROLE_FACILITY_ADMIN' | 'ROLE_REP' | 'ROLE_AUDITOR';

export interface IUserRole {
    id?: number;
    role: UserRoleString;
    description: string;
}


export class UserRole implements IUserRole {
    id: number;
    description: string;
    role: UserRoleString;
    
    constructor(args: IUserRole) {
        console.assert(args !== undefined && args !== null, 
                'IServerRole constructor passed null or undefined args object');
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 
                'UserRole constructor passed args with invalid value');
        }
        console.assert(typeof args.description === 'string', 'UserRole constructor passed args with invalid value');
        console.assert(typeof args.role === 'string' && ['ROLE_SUPER_ADMIN', 'ROLE_ADMIN', 'ROLE_FACILITY_ADMIN', 'ROLE_REP', 'ROLE_AUDITOR'].indexOf(args.role) !== -1, 'UserRole constructor passed args with invalid value');

        this.id = args.id || undefined;
        this.description = args.description;
        this.role = args.role;
    }
}
