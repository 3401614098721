
export interface IQuestionCondition {
    id: number;
    conditionAnswerId: number;
    questionId: number;
}

export class QuestionCondition implements IQuestionCondition {
    id: number;
    conditionAnswerId: number;
    questionId: number;

    constructor(args: IQuestionCondition) {
        console.assert(args !== undefined && args !== null, 'QuestionCondition constructor passed null or undefined args object');
        console.assert(typeof args.id === 'number', 'QuestionCondition constructor passed args with invalid id');
        console.assert(typeof args.conditionAnswerId === 'number', 'QuestionCondition constructor passed args with invalid conditionAnswerId');
        console.assert(typeof args.questionId === 'number', 'QuestionCondition constructor passed args with invalid questionId');

        this.id = args.id;
        this.conditionAnswerId = args.conditionAnswerId;
        this.questionId = args.questionId;
    }
}
