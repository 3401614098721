import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Http} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateModule, TranslateLoader, TranslateStaticLoader} from 'ng2-translate';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MultiSelectModule} from 'primeng/primeng';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';

import {PolicyReviewComponent} from './policy-review.component';
import {PolicyRoutes} from '../policy-maintenance.route';

export function translateLoader(http: Http) {
  return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
  declarations: [
    PolicyReviewComponent,
    // PrintedFormComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    RouterModule.forRoot(PolicyRoutes),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [Http]
    }),
    BrowserAnimationsModule,
    MultiSelectModule,
    AngularMultiSelectModule,
    MyDatePickerModule
  ],
  providers: []
})
export class PolicyReviewModule {
}
