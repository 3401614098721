// Combine all APIs service in one export
export * from './facility.service';
export * from './policy.service';
export * from './policy.type.service';
export * from './question.service';
export * from './unit.service';
export * from './user.service';
export * from './login.service';
export * from './policy.type.service';
export * from './role.service';
export * from './patient.service';
export * from './catheter.service';
export * from './audit.service';
export * from './patient.answer.service';
export * from './catheter.location.service';
export * from './policy.filter.service';
export * from './report.details.service';
export * from './health.system.service';
export * from './contact.us.service';


