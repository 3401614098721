import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Http} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MultiSelectModule} from 'primeng/primeng';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';
import {TooltipModule} from 'primeng/tooltip';

import {UserDetailComponent} from './user-detail.component';
import {AddAndEditUserRoleModalDialogComponent} from '../add-and-edit-user-role-modal-dialog/add-and-edit-user-role-modal-dialog.component';
import {UserRoutes} from '../user.route';
import {FacilitySelectorModule} from '../../facility-selector/facility-selector.module';
import {AccountWidgetModule} from '../../account-widget/account-widget.module';
import {SimpleModalModule} from 'ngx-simple-modal';
import {RoleService} from '../../shared/api';
import {DirectiveModule} from '../../shared/directive/directive.module';
import { AddUserHealthSystemComponent } from "../add-user-health-system/add-user-health-system";

export function translateLoader(http: Http): TranslateStaticLoader {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
    declarations: [
        UserDetailComponent, 
        AddAndEditUserRoleModalDialogComponent,
        AddUserHealthSystemComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        CommonModule,
        RouterModule.forRoot(UserRoutes),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
        BrowserAnimationsModule,
        MultiSelectModule,
        AngularMultiSelectModule,
        MyDatePickerModule,
        FacilitySelectorModule,
        AccountWidgetModule,
        SimpleModalModule.forRoot({ container: document.body }),
        TooltipModule,
        DirectiveModule,
    ],
    providers: [RoleService],
    entryComponents: [AddAndEditUserRoleModalDialogComponent,AddUserHealthSystemComponent],
})
export class UserDetailModule {}
