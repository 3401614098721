import { Routes } from '@angular/router';

import { CommonGuard } from './shared/guard/common.guard';
import { LoginComponent } from './login/login.component';
import { ResetPassword } from './login/resetPassword.component';
import { RoutePath } from './shared/constants/routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OauthComponent } from './oauth/oauth.component';

export const appRoutes: Routes = [
    { path: RoutePath.OAUTH_CALLBACK, component: OauthComponent },
    { path: '', component: LoginComponent, canActivate: [CommonGuard] },
    { path: RoutePath.FORGOT_PASSWORD, component: ResetPassword, canActivate: [CommonGuard] },
    { path: RoutePath.LOGIN, component: LoginComponent, canActivate: [CommonGuard] },
    { path: RoutePath.DASHBOARD, component: DashboardComponent, canActivate: [CommonGuard] },
];
