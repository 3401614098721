import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PeakDatePickerComponent } from './peak-date-picker.component';
import { MyDatePickerModule } from '../../../node_modules/mydatepicker/dist/my-date-picker.module';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [CommonModule, FormsModule, MyDatePickerModule],
    declarations: [PeakDatePickerComponent],
    exports: [PeakDatePickerComponent],
})
export class PeakDatePickerModule {}
