export function extractQueryParams(queryString): Map<string, string> {
  const paramsMap = new Map();

  if (undefined == queryString || queryString.length == 0) {
    return paramsMap;
  }

  const splitString = queryString.slice(1).split('&');

  if (splitString.length < 1) {
    return paramsMap;
  }

  splitString.map((entry) => {
    const splitEntry = entry.split('=');
    if (splitEntry.length !== 2) {
      return;
    }
    const key = decodeURIComponent(splitEntry[0]);
    const value = decodeURIComponent(splitEntry[1]);
    paramsMap.set(key, value);
  });

  return paramsMap;
}