import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {TermsComponent} from "../login/terms-and-conditions/terms.component";
import {LoginComponent} from "./login.component";
import {ResetPassword} from "./resetPassword.component";
import {Http} from "@angular/http";
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from "ng2-translate";
import {UserService} from "../shared/api";
import { ForgotPasswordService } from '../shared/api/forgot-password.service';
export function translateLoader(http: Http) {
  return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
  declarations: [
    LoginComponent,
    TermsComponent,
    ResetPassword
  ],
  exports: [
    TermsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [Http]
    }),
  ],
  providers: [
    UserService,
    ForgotPasswordService
  ]
})
export class LoginModule {
}
