import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {Http} from '@angular/http';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DialogModule, MultiSelectModule} from 'primeng/primeng';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import {MyDatePickerModule} from 'mydatepicker';

import {FacilityAddComponent} from './facility-add.component';
import {FacilityRoutes} from '../facility.route';
import {FacilityService} from "../../shared/api";

export function translateLoader(http: Http) {
  return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
  declarations: [
    FacilityAddComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    RouterModule.forRoot(FacilityRoutes),
    TranslateModule.forRoot({
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [Http]
    }),
    BrowserAnimationsModule,
    MultiSelectModule,
    AngularMultiSelectModule,
    MyDatePickerModule,
    DialogModule
  ],
  providers: [FacilityService]
})
export class FacilityAddModule {
}
