
/* export interface IPatientCatheterFinalAns {
    
    line: string;
    location: string;
    answers: Array<CathAnswer>;
}
    export class PatientCatheterFinalAns implements IPatientCatheterFinalAns {
        line: string;
        location: string;
        answers: Array<CathAnswer>;
        constructor(args: IPatientCatheterFinalAns) {
            this.line = args.line;
            this.location = args.location;
            this.answers = args.answers.map(_ => new CathAnswer(_));;
        }
    }

export interface IPatientCatheterAns {
    
    line: string;
    location: string;}
    export class PatientCatheterAns implements IPatientCatheterAns {
        line: string;
        location: string;
        constructor(args: IPatientCatheterAns) {
            this.line = args.line;
            this.location = args.location;
        }
    }

        export interface ICathAnswer {
            answerCode: string;
            catheter?: PatientCatheterAns;
            questionId: number;
            value: string;
        }
        
        export class CathAnswer implements ICathAnswer {
            answerCode: string;
            catheter?: PatientCatheterAns;
            questionId: number;
            value: string;
            constructor(args: ICathAnswer) {
                this.answerCode = args.answerCode;
                this.catheter = args.catheter;
                this.questionId = args.questionId;
                this.value = args.value;
            }
        } */
export interface IPatientCatheter {
    id?: number;
    comment?: string;
    line: string;
    location: string;
    // answers: 
}

export class PatientCatheter implements IPatientCatheter {
    id?: number;
    comment?: string;
    line: string;
    location: string;
    constructor(args: IPatientCatheter) {
        console.assert(args !== undefined && args !== null, 'PatientCatheter constructor passed null or undefined args object');
        if (args.comment !== undefined && args.comment !== null) {
            console.assert(typeof args.comment === 'string', 'PatientCatheter constructor passed args with invalid value');
        }
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'PatientCatheter constructor passed args with invalid value');
        }
        console.assert(typeof args.line === 'string', 'PatientCatheter constructor passed args with invalid value');
        console.assert(typeof args.location === 'string', 'PatientCatheter constructor passed args with invalid value');

        this.id = args.id || undefined;
        this.comment = args.comment || undefined;
        this.line = args.line;
        this.location = args.location;
    }
}

export interface IPatientAnswer {
    id?: number;
    answerCode: string;
    catheter?: PatientCatheter;
    questionId: number;
    value: string;

}

export class PatientAnswer implements IPatientAnswer {
    id?: number;
    answerCode: string;
    catheter?: PatientCatheter;
    questionId: number;
    value: string;


    constructor(args: IPatientAnswer) {
        console.assert(args !== undefined && args !== null, 'PatientAnswer constructor passed null or undefined args object');
        console.assert(typeof args.answerCode === 'string', 'PatientAnswer constructor passed args.answerCode was not a string');
        if (args.catheter !== undefined && args.catheter !== null) {
            // Assertions for the catheter args are handled by the PatientCatheter constructor
            this.catheter = new PatientCatheter(args.catheter);
        }
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'PatientAnswer constructor passed args.id was not a number');
        }
        console.assert(typeof args.questionId === 'number', 'PatientAnswer constructor passed args.questionId was not a number');
        // TODO: (Jon) It looks like the server doesn't pass back the value
        //assert(typeof args.value === 'string', 'PatientAnswer constructor passed args.value was not a string');

        this.id = args.id || undefined;
        this.answerCode = args.answerCode;
        this.catheter = args.catheter;
        this.questionId = args.questionId;
        this.value = args.value;
       
    }
}


export interface IPatient {
    id?: number;
    answers: Array<PatientAnswer>;
    identifier?: string;
    comment?: string;
    policyId: number;
    timeShift: 'DAY' | 'NIGHT' | 'BOTH';
    unitId: number;
    auditDate: string;
   // catheters?: Array<PatientCatheterFinalAns>;
}

export class Patient implements IPatient {
    id?: number;
    answers: Array<PatientAnswer>;
    identifier?: string;
    public comment?: string;
    policyId: number;
    timeShift: 'DAY' | 'NIGHT' | 'BOTH';
    unitId: number;
    public auditDate: string;
  //  catheters?: Array<PatientCatheterFinalAns>;

    constructor(args: IPatient) {
        console.assert(args !== undefined && args !== null, 'patient constructor passed null or undefined args object');
        console.assert(Array.isArray(args.answers), 'patient constructor passed args with invalid value');
        if (args.id !== undefined && args.id !== null) {
            console.assert(typeof args.id === 'number', 'patient constructor passed args with invalid value');
        }
        if (args.identifier !== undefined && args.identifier !== null) {
            console.assert(typeof args.identifier === 'string', 'patient constructor passed args with invalid value');
        }
        if (args.comment !== undefined && args.comment !== null) {
            console.assert(typeof args.comment === 'string', 'patient constructor passed args with invalid comment');
        }
        console.assert(typeof args.policyId === 'number', 'patient constructor passed args with invalid value');
        console.assert(typeof args.timeShift === 'string' && ['DAY', 'NIGHT', 'FULL_DAY'].indexOf(args.timeShift) !== -1, 'patient constructor passed args with invalid value');
        console.assert(typeof args.unitId === 'number', 'patient constructor passed args with invalid value');
        console.assert(args.auditDate !== undefined && args.auditDate !== null, 'patient constructor passed null or undefined auditDate');
        console.assert(typeof args.auditDate === 'string', 'patient constructor passed args with invalid auditDate');

        this.id = args.id || undefined;
        this.answers = args.answers.map(_ => new PatientAnswer(_));
        this.identifier = args.identifier || undefined;
        this.policyId = args.policyId;
        this.timeShift = args.timeShift;
        this.unitId = args.unitId;
        this.comment = args.comment;
        this.auditDate = args.auditDate;
    }
}
