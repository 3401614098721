import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataEntryAddComponent } from './data-entry-add.component';
import { DataEntryProgressIndicatorComponent } from "../data-entry-progress-indicator/data-entry-progress-indicator.component";
import { SharedComponentsModule } from '../../shared/components/shared-components.module';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [DataEntryAddComponent,DataEntryProgressIndicatorComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedComponentsModule,
    TooltipModule
  ]
})
export class DataEntryAddModule { }
