import { Component, Input } from '@angular/core';
import { MESSAGES } from '../../../shared/constants';
import {PolicyType} from "../../../shared/model/policy-type";
import {Question, IQuestion, InputType, QuestionType} from "../../../shared/model/question";
import {Policy,PolicyCuatomQuestion,PolicyQuestionAnswer} from "../../../shared/model/policy";
import {Audit,AuditTarget} from "../../../shared/model/audit";
import {Patient,PatientAnswer,PatientCatheter, } from "../../../shared/model/patient";
import { QuestionGroup } from '../../../shared/model/questionGroup';
import {FacilityService, PolicyTypeService, HealthSystemService} from '../../../shared/api';
import { PolicyFilterService } from '../../../shared/api/policy.filter.service';



const TWENTY_ONE_DAY_POLICY_ID: number = 1;
// TODO: see if there's a better / more consistent way to handle this identification
const COMPREHENSIVE_POLICY_ID: number = 2;

@Component({
  templateUrl: 'printed-form.component.html',
  selector: 'app-printed-form'
})


export class PrintedFormComponent {
  @Input() public policyTypes?: PolicyType[];
  @Input() public activePolicy?: Policy;
  @Input() public policyIndex?: Number;  
  //public static readonly DOM_ID: string = 'printed_form_component';
  //public readonly _id: string = PrintedFormComponent.DOM_ID;  
  public static  DOM_ID: string = 'printed_form_component';
  public  _id: string = PrintedFormComponent.DOM_ID;

  public policyType?: PolicyType;
  public audit?: Audit;
  public policyFilterType?: PolicyType;
  

  public readonly SINGLE_IV_CATHETER_LABEL = MESSAGES.PRINTED_FORM.SINGLE_IV_CATHETER_LABEL;
  public readonly SINGLE_IV_CATHETER_INSTRUCTION = MESSAGES.PRINTED_FORM.SINGLE_IV_CATHETER_INSTRUCTION;
  public readonly MULTIPLE_IV_CATHETER_LABEL = MESSAGES.PRINTED_FORM.MULTIPLE_IV_CATHETER_LABEL;
  public readonly MULTIPLE_IV_CATHETER_INSTRUCTION = MESSAGES.PRINTED_FORM.MULTIPLE_IV_CATHETER_INSTRUCTION;
  //public _policyTypes: PolicyType[];
  //public _policyType: PolicyType;  
  public patientCustomQuestions:PolicyCuatomQuestion[]=[];
  public catheterCustomQuestions:PolicyCuatomQuestion[]=[];
  
  
  public _policyTypes:PolicyType[];

  constructor(private facilityService: FacilityService,private policyTypeService: PolicyTypeService,private policyFilterService:PolicyFilterService){

    
    
  }


  public ngOnInit(): void {
      if(this.policyIndex != undefined){
          this._id=this.policyIndex.toString();
      }
      console.assert(undefined != this.policyTypes, 'PrintedFormComponent#ngOnInit - policyTypes is undefined');
      const policyType = this.policyTypes.find(p => this.activePolicy.policyTypeId === p.id);

      this.loadPolicyType();
     
      if (policyType != null) {
          this.policyType = policyType;
      } else {
          console.assert(false, 'AuditSetupPageComponent#ngOnInit - failed to find policyType matching activePolicy');
      }

      this.patientCustomQuestions = this.activePolicy.customQuestions.filter(c=>c.forEveryPatientOrCatheter == 0);
      this.catheterCustomQuestions = this.activePolicy.customQuestions.filter(c=>c.forEveryPatientOrCatheter == 1);     
      
      this.prepareAuditObject();

  }
  
  private loadPolicyType(): Promise<void> {
    return new Promise(resolve => {
        //this.policyTypeService.getAllEntities().subscribe(
        //this.policyFilterService.getPolicy(this.activePolicy.facilityId).subscribe(
        this.policyFilterService.getPolicyById(this.activePolicy.id).subscribe(
          policyTypes => {
                this._policyTypes = policyTypes;      
                
                if(this.activePolicy!== null && this.activePolicy !== undefined)
                this.policyType = this._policyTypes.find(t => t.id == this.activePolicy.policyTypeId);

                this.prepareAuditObject(); 
                //this.getActivePolicy(this.activePolicy.facilityId);  
                resolve();
            },
            err => {
                //this.errorService.handleErrorStatusCode(err);
            }
        );
    });
  }
  
  
  private prepareAuditObject(): void {
      console.assert(undefined != this.policyType, 'PrintedFormComponent#prepareAuditObject - policyType is undefined');
      console.assert(undefined != this.activePolicy, 'PrintedFormComponent#prepareAuditObject - activePolicy is undefined');

      console.log('Length:'+JSON.stringify(this.activePolicy.customQuestions));
      let policyType:PolicyType=this.policyType;
      this.activePolicy.customQuestions.forEach(element => {
                  
          let custQ : Question = {code :"custom",
                                  id : element.id,
                                  inputType : InputType.BOOLEAN,
                                  questionGroupId : element.forEveryPatientOrCatheter == 0 ? 7 : 1,  
                                  conditions : [],
                                  answers: [],
                                  disclaimer: null,
                                  getFirstAnswerOfType : null,
                                  title : element.questionText,
                                  type : QuestionType.AUDIT,  
                                }; 
          policyType.questions.push(custQ);      

      });
  
        
     /* policyType.questions=this.policyType.questions.filter(q=>

         (q.code !='custom') || ( q.code =='custom' &&  this.activePolicy.customQuestions.filter(c=>c.id == q.id).length>0 ) 
        
       );*/

      //console.log("Active Policy:"+JSON.stringify(this.activePolicy));
      //console.log("policyType.questions:"+JSON.stringify(policyType.questions));
  
      this.audit = new Audit(policyType, this.activePolicy);  
      //console.log("Active Audit:"+JSON.stringify(this.activePolicy.customQuestions));   
      policyType.questions = this.policyType.questions.filter(q=>(q.code !='custom'));
     
     
  }
  
   
  public questionsLength(group:QuestionGroup): number{
    let count=0;
    if(this.audit!== null){
      for( let question of group.questions){

        if(this.audit.shouldShowQuestion(question))
          count++
      }
    }
    return count;
  }

  public setColumnStyleForPaperAudit(title: string): string {
    if (title === 'Comments') {
      return '27%';
    }
  }

  public setPaddingLeftForPaperAudit(title: string): string {
    if (title === 'Comments') {
      return '16%';
    }
  }

  public setPaddingRightForPaperAudit(title: string): string {
    if (title === 'Comments') {
      return '150px';
    }
  }
  public shouldCustomQuestionsDisplay():boolean{

   
    return false;
    if(this.activePolicy.customQuestions.length > 0){
    //console.log('shouldCustomQuestionsDisplay'+JSON.stringify(this.activePolicy.customQuestions.filter(c=>c.forEveryPatientOrCatheter == 1).length));
    //if(this.activePolicy.customQuestions.length > 0 && this.activePolicy.customQuestions.filter(c=>c.forEveryPatientOrCatheter == 0).length > 0){

      return true;
    }else{
      return false;
    }

  }

  public get isComprehensiveAudit(): boolean {
    if (this.policyType
        && this.policyType.id === COMPREHENSIVE_POLICY_ID
    ) {
      return true;
    }

    return false;
  }
}
