import {CrudApiService} from "./common/crud.api.service";
import {Policy, IPolicy} from "../model/policy";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";

@Injectable()
export class PolicyService extends CrudApiService<Policy, IPolicy, Partial<Policy>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/policies");
    }

    public getPolicyByIndex(index: number): Observable<Array<Policy>> {
        return this.get<Array<Policy>>(`/${index}`);
    }
    public getPolicyHistory(): Observable<Array<any>> {
        return this.get<Array<any>>(`/history`);
    }

    protected assembleSingleInstance(_: any): Policy {
        return new Policy(_);
    }
}
