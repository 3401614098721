import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'question-progress-indicator',
    templateUrl: './question-progress-indicator.component.html',
    styleUrls: ['./question-progress-indicator.component.scss'],
})
export class QuestionProgressIndicatorComponent {
    @Output('onBubbleClicked') public onBubbleClickedEvent = new EventEmitter<number>();

    private _currentIndex: number = 0;
    private _completeCount: number = 0;
    private _totalCount: number = 0;
    private _bubbleArray: Array<number> = [];

    get bubbleArray(): Array<number> {
        return this._bubbleArray;
    }

    @Input()
    set totalCount(totalCount: number) {
        this._totalCount = totalCount;
        this._bubbleArray = new Array<number>(this._totalCount).fill(0);
    }

    get totalCount(): number {
        return this._totalCount;
    }

    @Input()
    set currentIndex(currentIndex: number) {
        this._currentIndex = currentIndex;
    }

    get currentIndex(): number {
        return this._currentIndex;
    }

    @Input()
    set completedCount(completedCount: number) {
        this._completeCount = completedCount;
    }

    get completedCount(): number {
        return this._completeCount;
    }

    public isIndexHighlighted(index: number): boolean {
        return index === this._currentIndex;
    }

    public isIndexEmpty(index: number): boolean {
        return index >= this._completeCount + 1;
    }

    public onBubbleClicked(index: number) {
        this.onBubbleClickedEvent.emit(index);
    }
}
