import { PolicyTypeSettings, IPolicyTypeSettings } from './policy-type-settings';
import {Question, IQuestion} from "./question";

export interface IPolicyType {
    id?: number;
    description: string;
    name: string;
    settings: IPolicyTypeSettings;
    questions: IQuestion[];
}

export class PolicyType {
    description: string;
    id?: number;
    name: string;
    settings: PolicyTypeSettings;
    questions: Question[];

    constructor(args: IPolicyType) {
        console.assert(args !== undefined && args !== null, 'PolicyType constructor passed null or undefined args object');
        if (args.id !== undefined && args.id !== null) {
        console.assert(typeof args.id === 'number', 'PolicyType constructor passed args with invalid value');
        }
        console.assert(typeof args.description === 'string', 'PolicyType constructor passed args with invalid value');
        console.assert(typeof args.name === 'string', 'PolicyType constructor passed args with invalid value');
        console.assert(args.settings !== undefined && args.settings != null, 'PolicyType constructor passed args with invalid value');
        console.assert(Array.isArray(args.questions), 'PolicyType constructor passed args with invalid value');

        this.description = args.description;
        this.id = args.id || undefined;
        this.name = args.name;
        this.settings = args.settings;
        this.questions = [];
        args.questions.forEach(q => this.questions.push(new Question(q)));
    }
}
