import {Facility} from "../../model/facility";
import {TimeShift} from "../../model/time.shift";
import {Policy} from "../../model/policy";
import {ReportType, ReportView, Table} from "../../constants/reports";
import {Metric} from "../../model/report.type";
import {FullReport} from '../../model/unit.report';

export class ReportContext {
    public readonly reportType: ReportType;
    public readonly policy: Policy;
    public readonly facility: Facility;
    public readonly data: Table;
    public readonly fullReport: FullReport;
    public readonly fromDate: Date;
    public readonly toDate: Date;
    public readonly timeShift: TimeShift;
    public readonly reportView?: ReportView;
    public readonly reportMetric?: Metric;

    constructor(reportType: ReportType,
                policy: Policy,
                facility: Facility,
                data: Table,
                fullReport: FullReport,
                fromDate: Date,
                toDate: Date,
                timeShift: TimeShift,
                reportView?: ReportView,
                reportMetric?: Metric) {
        this.reportType = reportType;
        this.policy = policy;
        this.facility = facility;
        this.data = data;
        this.fullReport = fullReport;
        this.fromDate = fromDate;
        this.toDate = toDate;
        this.timeShift = timeShift;
        this.reportView = reportView;
        this.reportMetric = reportMetric;
    }
}