import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Http } from '@angular/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader, TranslateStaticLoader } from 'ng2-translate';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MultiSelectModule } from 'primeng/primeng';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown/angular2-multiselect-dropdown';
import { MyDatePickerModule } from 'mydatepicker';
import { TooltipModule } from 'primeng/tooltip';

import { UserAddComponent } from './user-add.component';
import { UserRoutes } from '../user.route';

// services
import { UserAddService } from './user-add.service';
import {RoleService, UserService} from "../../shared/api";
import { Utils } from "../../shared/service/utils";

export function translateLoader(http: Http) {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

@NgModule({
    declarations: [UserAddComponent],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        RouterModule.forRoot(UserRoutes),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
        BrowserAnimationsModule,
        MultiSelectModule,
        AngularMultiSelectModule,
        MyDatePickerModule,
        TooltipModule,
    ],
    providers: [UserAddService, UserService, RoleService, Utils],
})
export class UserAddModule {}
