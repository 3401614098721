import { Injectable } from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {TokenService} from '../service/token.service';
import {NotificationService} from './../service/notification.service';
import {ROLES, Route, RoutePath, MESSAGES} from '../constants/index';
import {Util} from '../../util/util';
import {EmitterService} from '../service/emitter.service';
import {RoutePermissions} from "../header/header.component";


@Injectable()
export class CommonGuard implements CanActivate {

  constructor(private tokenService: TokenService,
              private notificationService: NotificationService,
              private router: Router,
              private emitterService: EmitterService) {
  }

  private isPublicRoute(path: string): boolean {
    if (Util.isEmptyRoute(path)
      || Util.isLoginRoute(path)
      || path === Route.OAUTH_CALLBACK
    ) {
      return true;
    }
    return false;
  }

  private handleUnauthenticatedRouting(path: string): boolean {
    if (this.isPublicRoute(path)) {
      return true;
    }
    this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.PLEASE_LOGIN);
    this.router.navigate([RoutePath.LOGIN]);
    // this.emitterService.onSigninEvent.emit({flag: false, user: {}, imgPath: null});
    // TODO: NL - delegate this sign out behavior to another class.
    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const path = state.url;
    const loggedUser = this.tokenService.getUserToken();

    if (Util.isUndefinedOrNull(loggedUser) || undefined == loggedUser.roles || false == loggedUser.termsAccepted) {
        return this.handleUnauthenticatedRouting(path);
    }

    const permittedRoles: string[] = RoutePermissions[path];
    const userRoles: string[] = [];
    loggedUser.roles.map(role => userRoles.push(role.role));

    let hasPermission: boolean = false;
      userRoles.forEach((userRole: string) => {
      if (permittedRoles != null && permittedRoles.indexOf(userRole) !== -1) {
        hasPermission = true;
      }
    });

    if (hasPermission) {
      return true;
    } else {
      if (userRoles.indexOf(ROLES.ROLE.ADMIN_3M) !== -1) {
        if (path != Route.POLICY) {
          this.router.navigate([Route.POLICY]);
        }
        return false;
      } else if (path != Route.DASHBOARD) {
        this.router.navigate([Route.DASHBOARD]);
        return false;
      }
      return true;
    }

  }
}
