export interface IReportType {
    id: number;
    title: string;
    metrics: Metric[];
    settings: ReportTypeSettings;
}

export class ReportType implements IReportType {
    id: number;
    title: string;
    metrics: Metric[];
    settings: ReportTypeSettings;

    constructor(args: IReportType) {

        console.assert(args !== undefined && args !== null, 'reportType constructor passed null or undefined args object');
        console.assert(typeof args.id === 'number', 'reportType constructor passed args with invalid id');
        console.assert(typeof args.title === 'string', 'reportType constructor passed args with invalid title');
        console.assert(Array.isArray(args.metrics), 'reportType constructor passed args with invalid metrics');
        console.assert(undefined != args.settings, 'reportType constructor passed args with invalid settings');
        console.assert(typeof args.settings.minGreenRate === 'number', 'reportType constructor passed args with invalid settings minGreenRate');
        console.assert(typeof args.settings.minYellowRate === 'number', 'reportType constructor passed args with invalid settings minYellowRate');


        this.id = args.id;
        this.title = args.title;
        this.metrics = args.metrics;
        this.settings = args.settings;
    }
}

export interface ReportTypeSettings {
    minGreenRate: number;
    minYellowRate: number;
}

export interface Metric {
    name: string;
    metricGroup?: string;
    code: string;
    tooltip?: string;
}