import {PolicySettings, IPolicySettings} from './policy-settings';
import { IQuestion, Question } from './question';
import * as moment from 'moment'

export interface IPolicyQuestionAnswer {
    id?: number;
    answerValue: string;
    questionAnswerId: number;
}

export interface IPolicyCuatomQuestion {
    id?: number;  
    questionText: string;
    answerType: number;
    forEveryPatientOrCatheter: number;
}

export class PolicyQuestionAnswer {
    id: number;
    answerValue: string;
    questionAnswerId: number;

    constructor(args: IPolicyQuestionAnswer) {
        console.assert(args !== undefined && args !== null, 'PolicyQuestionAnswer passed null or undefined args object');
        console.assert(args.id != undefined, 'PolicyQuestionAnswer passed args with invalid id');
        //console.assert(typeof args.answerValue === 'string', 'PolicyQuestionAnswer passed args with invalid answerValue');
        //console.assert(typeof args.questionAnswerId === 'number', 'PolicyQuestionAnswer passed args with invalid questionAnswerId');
        //console.log(JSON.stringify(args));

        this.id = args.id;
        this.answerValue = args.answerValue;
        this.questionAnswerId = args.questionAnswerId;
    }
}
export class PolicyCuatomQuestion {
    id?: number;  
    questionText: string;
    answerType: number;
    forEveryPatientOrCatheter: number;
    constructor(args: IPolicyCuatomQuestion) {

        

        this.id = args.id;
        this.questionText = args.questionText;
        this.answerType = args.answerType;
        this.forEveryPatientOrCatheter = args.forEveryPatientOrCatheter;

    }
}

export interface IPolicy {
    active?: boolean;
    facilityId?: number;
    id?: number;
    policyTypeId?: number;
    settings?: IPolicySettings;
    answers?: Array<IPolicyQuestionAnswer>;
    name?: string;
    readonly createdAt?: string;
    readonly updatedAt?: string;
    customQuestions?: Array<IPolicyCuatomQuestion>;
    //customQuestions?: Array<IQuestion>;
    healthSystemId?: number;
}

export class Policy implements IPolicy {
    id?: number;
    name?: string;
    active?: boolean;
    facilityId?: number;
    policyTypeId?: number;
    settings?: PolicySettings;
    answers?: Array<PolicyQuestionAnswer>;
    private _createdAt?: moment.Moment;
    private _updatedAt?: moment.Moment;
    customQuestions?: Array<PolicyCuatomQuestion>;
    //customQuestions?: Array<Question>;
    healthSystemId?: number;

    public get createdAt(): string {
        if (undefined != this._createdAt) {
            return this._createdAt.format('l');
        }
        return '';
    }

    public get updatedAt(): string {
        if (undefined != this._updatedAt) {
            return this._updatedAt.format('l');
        }
        return '';
    }

    public get numericDate(): number {
        if (undefined != this._updatedAt) {
            return this._updatedAt.unix();
        }
        if (undefined != this._createdAt) {
            return this._createdAt.unix();
        }
        return 0;
    }

    constructor(args: IPolicy) {
      console.assert(args !== undefined && args !== null, 'policy constructor passed null or undefined args object');
      if (args.id !== undefined && args.id !== null) {
        console.assert(typeof args.id === 'number', 'policy constructor passed args with invalid value');
      }
      if (args.name !== undefined && args.name !== null) {
        console.assert(typeof args.name === 'string', 'policy constructor passed args with invalid name prop');
      }
      if (args.active !== undefined && args.active !== null) {
        console.assert(typeof args.active === 'boolean', 'policy constructor passed args with invalid value');
      }
      if (args.facilityId !== undefined && args.facilityId !== null) {
        console.assert(typeof args.facilityId === 'number', 'policy constructor passed args with invalid value');
      }
      if (args.policyTypeId !== undefined && args.policyTypeId !== null) {
        console.assert(typeof args.policyTypeId === 'number', 'policy constructor passed args with invalid value');
      }
      if (args.settings !== undefined && args.settings !== null) {
        console.assert(args.settings !== undefined && args.settings !== null, 'policy constructor passed args with invalid settings property');
      }
      if (args.answers !== undefined && args.answers !== null) {
          console.assert(Array.isArray(args.answers), 'policy constructor passed args with invalid answers property');
      }
      if (args.createdAt !== undefined && args.createdAt !== null) {
      // TODO: parse date strings / validate format
      console.assert(typeof args.createdAt === 'string', 'policy constructor passed args with invalid createdAt property');
      }
      if (args.updatedAt !== undefined && args.updatedAt !== null) {
      console.assert(typeof args.updatedAt === 'string', 'policy constructor passed args with invalid updatedAt property');
      }

      this.id = args.id || undefined;
      this.name = args.name || undefined;
      this.active = args.active || undefined;
      this.facilityId = args.facilityId || undefined;
      this.policyTypeId = args.policyTypeId || undefined;
      this.settings = args.settings ? new PolicySettings(args.settings) : undefined;
      this.answers = args.answers.map(_ => new PolicyQuestionAnswer(_)) || undefined;
      this.customQuestions = args.customQuestions.map(_ => new PolicyCuatomQuestion(_)) || undefined;
      //this.customQuestions = args.customQuestions.map(_ => new Question(_)) || undefined;

      if (args.createdAt) {
          this._createdAt = moment(args.createdAt);
      }
      if (args.updatedAt) {
          this._updatedAt = moment(args.updatedAt);
      }
      this.healthSystemId = args.healthSystemId || undefined;
    }
}
