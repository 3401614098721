export function sortByQuestionCode<T extends { code: string; }>(records: T[]): T[] {
    const letterRegex: RegExp = /[^a-zA-Z]/g;
    const numberRegex: RegExp = /[^0-9]/g;

    return records.sort((a: T, b: T) => {
        const codeA: string = a.code;
        const codeB: string = b.code;
        const alphaA: string = codeA.replace(letterRegex, "");
        const alphaB: string = codeB.replace(letterRegex, "");

        if (alphaA === alphaB) {
            const numericA: number = parseInt(codeA.replace(numberRegex, ""), 10);
            const numericB: number = parseInt(codeB.replace(numberRegex, ""), 10);
            if (numericA === numericB) {
                return 0;
            } else if (numericA > numericB) {
                return 1;
            }
            return -1;
        } else {
            if (alphaA > alphaB) {
                return 1;
            }
            return -1;
        }
    });
}