import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-popup-menu-facility',
    templateUrl: './popup-menu-facility.component.html',
    styleUrls: [
        './popup-menu-facility.component.scss',
        '../shared-components-styles.scss'
    ],
})
export class PopupMenuFacilityComponent {
    @Input() public options: string[] = [];

    public onOptionSelected: (number) => void;

    public onOptionClicked(optionIndex: number | null) {
        if (this.onOptionSelected != null) {
            this.onOptionSelected(optionIndex);
        }
    }
}
