import {CrudApiService} from "./common/crud.api.service";

import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";
import { extend } from 'jquery';
import { ApiService } from './common/api.service';
import {User} from "../model/user";

export interface HealthSystem {
    id: number;
    name: string;
}


@Injectable()
export class HealthSystemService extends ApiService {
    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/healthsystem");
    }

    protected assembleSingleInstance() {
        return this.get(`/fetch`);
    }

/*export class FacilityService extends CrudApiService<HealthSystem, HealthSystem, Partial<HealthSystem>> {

    constructor(httpClient: HttpClient, tokenService: TokenService) {
        super(httpClient, tokenService, "api/healthsystem");
    }

  protected assembleSingleInstance(_: any): HealthSystem {
        return new HealthSystem(_);
  }*/
    getHealthSystem() {
      return this.get<Array<any>>(`/fetch`);
    }
    createHealthSystem(healtSystemName:string) {
        return this.get<string>(`/create/${healtSystemName}`);
    }
    editHealthSystem(id:number,healtSystemName:string) {
        return this.get<string>(`/edit/${id}/${healtSystemName}`);
    }//et/vascular/api/healthsystem/adduser/82/25
    addHealthSystemToUser(userId:number,healthSystemId:number) {
        return this.get<any>(`/adduser/${userId}/${healthSystemId}`);
    }
    removeHealthSystemToUser(userId:number,healthSystemId:number) {
        return this.get<any>(`/removeuser/${userId}/${healthSystemId}`);
    }



}
