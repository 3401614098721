import {CrudApiService} from "./common/crud.api.service";
import {IReportType, ReportType} from "../model/report.type";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";

@Injectable()
export class ReportTypeService extends CrudApiService<ReportType, IReportType, Partial<IReportType>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, 'api/reportTypes');
    }
    protected assembleSingleInstance(_: any): ReportType {
        return new ReportType(_);
    }
}