import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DATEFORMAT, MESSAGES } from '../shared/constants/';
import { IMyDpOptions, IMyDate } from 'mydatepicker';
import { NotificationService } from '../shared/service/notification.service';
import { DataService } from '../shared/service/data.service';
import { ErrorService } from '../shared/service/error-service';
import { ConstantService } from '../shared/service/constant-service';
import { Facility } from '../shared/model/facility';
import { ExportFormat, ReportService } from '../shared/service/report/report.service';
import { ReportTypeService } from '../shared/api/report.type.service';
import { Metric } from '../shared/model/report.type';
import { TimeShift, GetDisplayStringForTimeShift } from '../shared/model/time.shift';
import { FacilityService, HealthSystemService } from '../shared/api';
import { ReportDataService } from '../shared/api/report.data.service';
import { Policy, PolicyQuestionAnswer } from '../shared/model/policy';
import { PolicyService } from '../shared/api/policy.service';
import { PolicyTypeService } from '../shared/api/policy.type.service';
import { FullReport } from '../shared/model/unit.report';
import { ReportContext } from '../shared/service/report/report.context';
import exporting from 'highcharts/modules/exporting';
exporting(Highcharts);
import {
    Cell, ComprehensiveType, ReportType, reportTypes, setSingleMetricsHeader, ReportView, Table, TwentyOneDayChallengeType,
    ViewTab,
} from '../shared/constants/reports';
import { FacilitySelectorComponent } from '../facility-selector/facility-selector.component';
import AbstractSkeletonLoader from '../shared/animations/AbstractSkeletonLoader';
import { PopupMenuService } from '../shared/components/popup-menu/popup-menu.service';
import { IMyDateModel, IMyMarkedDates } from 'mydatepicker';
import { REPORTS_PAGE_COMPONENT as UI_CONSTANTS, CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT } from '../shared/constants/uiConstants';
import { trigger, style, animate, transition } from '@angular/animations';
import { ReportDetailsService, ReportDetails } from "../shared/api/report.details.service";
import { UserService } from "../shared/api";

import { SimpleModalService } from 'ngx-simple-modal';
//import { DisplayExportAnalyticDataModalDialogMode, ExportAnalyticDataComponent } from "./export-analytic-data/export-analytic-data.component";
import { MODAL_DIALOG_FADE_DURATION_MS } from '../shared/constants/animationConstants';
import { Question } from '../shared/model/question';
import { QuestionAnswer } from '../shared/model/question-answer';
import { PolicyType } from "../shared/model/policy-type";
import { HealthSystem } from '../shared/model/health-system';
import * as Highcharts from 'highcharts';
import * as moment from 'moment';
import * as $ from 'jquery';
import { Unit } from '../shared/model/unit';
import * as HighchartsMore from 'highcharts/highcharts-more';
import * as HighchartsSolidGauge from 'highcharts/modules/solid-gauge';
HighchartsMore(Highcharts);
HighchartsSolidGauge(Highcharts);

interface TimeShiftDisplay {
    label: string;
    timeShift: TimeShift;
}

interface PolicyResponse {
    question: string;
    answer: string[];
}

interface ReportDetailsLocal {
    policyType: number;
    timeShiftAvailable: number;
    availableDates: Array<ReportDateLocal>;

}
interface ReportDateLocal {
    day: number;
    month: number;
    year: number;

}
export function GetDisplayStringForCompliance(timeShift: TimeShift) {
    switch (timeShift) {
        case TimeShift.SELECT:
            return 'Select';
        case TimeShift.DAY:
            return 'Port Compliance';
        case TimeShift.NIGHT:
            return 'Patient Compliance';
        case TimeShift.BOTH:
            return 'Both';
        default:
            return 'Unknown';
    }
};



@Component({
    selector: 'app-summary-reports',
    templateUrl: './summary-reports.component.html',
    styleUrls: ['./summary-reports.component.scss'],
    animations: [
        trigger('opacityFade', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.3s', style({ opacity: 0 })),
            ])])],
})
export class SummaryReportsComponent extends AbstractSkeletonLoader implements OnInit {

    highcharts = Highcharts;

    updateFlag = false;
    chartOptions = {
        title: {
            text: "Compliance Report"
        },
        xAxis: {
            categories: []

        },
        series: [
            {
                name: 'Port Compliance',
                type: 'line',
                data: []
            },
            {
                name: 'Patient Compliance',
                type: 'line',
                data: []
            }
        ],
        credits: {
            enabled: false
        },
        yAxis: {
            title: {
                text: "Values in %"
            },
        },
    };
    auditTrendsChartOptions = {
        title: {
            text: "IV Sites Monitored"
        },
        xAxis: {
            categories: []

        },
        yAxis: {
            title: {
                text: "Count"
            },
        },
        series: [
            {
                name: 'IV Sites Monitored',
                type: 'line',
                data: []
            }
        ],
        credits: {
            enabled: false
        }
    };

    portComplianceGauge = {
        title: {
            text: "Port Compliance"
        },
        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [1, '#55BF3B'], // green
            ],
        },
        series: [
            {
                name: 'Port Compliance',
                data: [],
                type: 'solidgauge'
            }
        ],
        credits: {
            enabled: false
        }
    }

    patientComplianceGauge = {
        title: {
            text: "Patient Compliance"
        },
        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [1, '#55BF3B'], // green
            ],
        },
        series: [
            {
                name: 'Patient Compliance',
                data: [],
                type: 'solidgauge'
            }
        ],
        credits: {
            enabled: false
        }
    }

    overllPortComplianceGauge = {
        title: {
            text: "Overall Port Compliance"
        },
        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        yAxis: {
            min: 0,
            max: 100,
            stops: [
                [1, '#55BF3B'], // green
            ],
        },
        series: [
            {
                name: 'Overall Port Compliance',
                data: [],
                type: 'solidgauge'
            }
        ],
        credits: {
            enabled: false
        }
    }

    overallPortComplianceChartOptions = {
        title: {
            text: 'Overall Port Compliance'
        },
        xAxis: {
            categories: []

        },
        yAxis: {
            title: {
                text: "Values in %"
            },
        },
        series: [
            {
                name: 'Overall Port Compliance',
                type: 'line',
                data: []
            }
        ],
        credits: {
            enabled: false
        }
    };

    public fromDate: IMyDateModel;
    public toDate: IMyDateModel;
    public resetDate: IMyDateModel;

    public selectedReportType: ReportType;
    public REPORT_TYPES: ReportType[] = [];
    public SELECTED_REPORT_TYPES: ReportType[] = [];
    public facility: Facility;
    public facilities: Facility[] = [];

    public fromDatePickerOptions: IMyDpOptions;
    public toDatePickerOptions: IMyDpOptions;
    private answersMap: Map<number, PolicyQuestionAnswer> = new Map<number, PolicyQuestionAnswer>();

    public formats = {
        excel: ExportFormat.EXCEL,
        ppt: ExportFormat.PPT,
        pdf: ExportFormat.PDF
    };

    public showReportTypeDropdown: boolean = false;
    public showViewDropdown: boolean = false;
    public showShiftDropdown: boolean = false;
    public showMetricDropdown: boolean = false;
    public shiftFilterReportDetails: ReportDetails;
    public selectedTimeShift: TimeShift;
    public timeShifts: TimeShift[] = [TimeShift.BOTH, TimeShift.DAY, TimeShift.NIGHT];
    public UI = UI_CONSTANTS;
    public tooltipmessage: string;

    // TimeShiftDisplay is used for UI only, to give a friendly label instead of an upper case constant
    public timeShiftDisplays: TimeShiftDisplay[] = [
        {
            label: GetDisplayStringForCompliance(TimeShift.SELECT),
            timeShift: TimeShift.SELECT,
        },
        {
            label: GetDisplayStringForCompliance(TimeShift.BOTH),
            timeShift: TimeShift.BOTH,
        },
        {
            label: GetDisplayStringForCompliance(TimeShift.DAY),
            timeShift: TimeShift.DAY,
        },
        {
            label: GetDisplayStringForCompliance(TimeShift.NIGHT),
            timeShift: TimeShift.NIGHT,
        },
    ];
    public selectedTimeShiftDisplay: TimeShiftDisplay = this.timeShiftDisplays[0];

    public fullReport?: FullReport;
    public fullReportDay?: FullReport;
    public fullReportNight?: FullReport;
    public fullReportBoth?: FullReport;
    private isReportLoading: boolean = false;
    private isReportDataErrored: boolean = false;
    public policy?: Policy;
    private isPolicyLoading: boolean = false;
    private isPolicyErrored: boolean = false;
    public table?: Table;
    public selectedReportView?: ReportView;
    public metric?: Metric;
    private _policies: Policy[] = [];
    public policies: Policy[] = [];
    public selectedPolicy: Policy;
    public isFaclityChanged: boolean = false;
    //policyTypes: import("../shared/model/policy-type").PolicyType[];
    public policyTypes: PolicyType[] = [];
    public selectForHealthSystem: boolean = false;
    public selectedHealthSystem: string = undefined;
    public healthSystem: HealthSystem[] = [];
    public healthSystemValues = [];
    public selectedTab = this.UI.HINTS.COMP_TRENDS;
    public activeUsers = [];
    public activePolicies = [];
    public auditLevelData = [];
    public auditByData = [];
    public units: Unit[] = [];
    public selectedUnit: Unit = this.initializeUnit();
    public auditLevelFacCount: number = 0;
    public auditLevelHsCount: number = 0;
    public auditByFacCount: number = 0;
    public auditByHsCount: number = 0;

    @ViewChild('exportButton', { read: ViewContainerRef }) exportButtonViewContainerRef: ViewContainerRef;

    constructor(
        private notificationService: NotificationService,
        private dataService: DataService,
        private errorService: ErrorService,
        private constantService: ConstantService,
        private reportService: ReportService,
        private reportTypeService: ReportTypeService,
        private facilityService: FacilityService,
        private reportDataService: ReportDataService,
        private popupMenuService: PopupMenuService,
        private reportDetailsService: ReportDetailsService,
        private userService: UserService,
        private simpleModalService: SimpleModalService,
        private policyService: PolicyService,
        private policyTypeService: PolicyTypeService,
        private healthSystemService: HealthSystemService,
    ) {
        super();
    }

    public ngOnInit() {
        this.loadPolicyTypes();
        this.loadPolicies();
        this.loadHealthSystem();
        this.exportChart();
        this.loadFacility();
        this.selectedTimeShift = TimeShift.BOTH;
        this.REPORT_TYPES = reportTypes();
        this.selectedReportType = this.REPORT_TYPES[0];
        if (this.selectedReportType.views) {
            this.selectedReportView = this.selectedReportType.views[0];
            this.selectedReportType.selectView(this.selectedReportView);
            if (this.selectedReportView.tabs) {
                this.selectedReportView.selectTab(this.selectedReportView.tabs[0]);
            }
        }

        this.fromDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',


        };

        this.toDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',

        };
        //if(this.isUserAdminForSelectedFacility)
        this.onSelectedFacilityChanged(this.facility);

    }

    public initializeUnit(): Unit {
        return { name: 'Select', active: false, facilityId: 0, id: 0 };
    }

    public selectTab(tab): void {
        this.selectedTab = tab;
        this.fromDate = null;
        this.toDate = null;
        this.clearCalendar('Both');
        setTimeout(() => {
            if (!this.checkUserOrPolicyTab()) {
                this.markDates(this.policy ? this.policy.id : null);
            }
        }, 100);

        if (this.canLoadReport) {
            this.loadReportData(this.selectedTimeShift);
        }
    }

    public isTabClicked(tab): boolean {
        return this.selectedTab === tab;
    }

    loadPolicyTypes() {
        this.policyTypeService.getAllEntities().subscribe(
            policyTypes => {
                this.policyTypes = policyTypes;
            },
            err => {
                this.errorService.handleErrorStatusCode(err);
            }
        );
    }

    public isFacilityValidForReport(facility: Facility): boolean {
        if (facility && facility.id != FacilitySelectorComponent.ALL_FACILITIES_ID) {
            return true;
        } else {
            return false;
        }
    }
    public get isUserAdminForSelectedFacility(): boolean {

        if (this.facility && this.facility.id != FacilitySelectorComponent.ALL_FACILITIES_ID && !this.userService.isUserAdminAtFacilityToEditAudit(this.facility.id)) {
            return false;
        }
        return true;
    }
    public get disableActionBar(): boolean {

        return !this.isUserAdminForSelectedFacility;
    }

    public checkUserOrPolicyTab() {
        if (this.selectedTab == this.UI.HINTS.ACTIVE_USERS || this.selectedTab == this.UI.HINTS.ACTIVE_POLICIES ||
            this.selectedTab == this.UI.HINTS.AUDIT_COUNT || this.selectedTab == this.UI.HINTS.AUDIT_BY || this.selectedTab == this.UI.HINTS.PORT_COMPLIANCE_All) {
            return true;
        }
        return false;
    }

    // Called from facility-selector component
    public onSelectedFacilityChanged(newFacility: Facility) {
        this.isFaclityChanged = true;
        this.policies = this.policiesForFacilityId(newFacility.id);
        this.fromDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',
            markDates: [{ dates: [], color: 'Orange' }],
            disableDateRanges: this.checkUserOrPolicyTab() && newFacility && newFacility.id != FacilitySelectorComponent.ALL_FACILITIES_ID ? [] : [
                {
                    begin: { year: 2000, month: 1, day: 1 },
                    end: { year: 2030, month: 12, day: 31 }
                }],
            minYear: 2000,
            maxYear: 2030,
            enableDays: [],
        };

        this.toDatePickerOptions = {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',
            markDates: [{ dates: [], color: 'Pink' }],
            disableDateRanges: this.checkUserOrPolicyTab() && newFacility && newFacility.id != FacilitySelectorComponent.ALL_FACILITIES_ID ? [] : [
                {
                    begin: { year: 2000, month: 1, day: 1 },
                    end: { year: 2030, month: 12, day: 31 }
                }],
            minYear: 2000,
            maxYear: 2030,
            enableDays: [],
        };
        if (newFacility && newFacility.id != FacilitySelectorComponent.ALL_FACILITIES_ID && !this.userService.isUserAdminAtFacilityToEditAudit(newFacility.id)) {

            this.notificationService.warn('', 'You are not the Admin for selected facility. Please select any other facility.');
        }
        this.facility = newFacility;
        this.fromDate = null;
        this.toDate = null;
        this.selectedTimeShiftDisplay = this.timeShiftDisplays[0];
        this.selectedTimeShift = undefined;
        this.selectedUnit = this.initializeUnit();
        this.clearAllReportData();

        //if(newFacility!=null)
        //    this.markDates(this.policy.id)

        if (this.isFacilityValidForReport(this.facility)) {
            this.loadActivePolicy();
        }
        else {
            this.selectedPolicy = null;
        }


        //this.onFilterChange();
        //this.clearAllReportData();
    }
    public isAdminForSelectedFaclility(facilityId: number): boolean {
        return this.userService.isUserAdminAtFacilityToEditFaclility(facilityId);
    }

    defaultCalendar() {
        return {
            dateFormat: DATEFORMAT.mmmdd_yyyy,
            firstDayOfWeek: 'su',
            minYear: 2000,
            maxYear: 2030,
            markDates: [],
            disableDateRanges: [],
            disableUntil: { year: 0, month: 0, day: 0 },
            disableSince: { year: 0, month: 0, day: 0 }
        };
    }

    clearCalendar(event) {
        if (event == 'Both') { //this.facility.id != FacilitySelectorComponent.ALL_FACILITIES_ID && 
            this.fromDatePickerOptions = this.defaultCalendar();
            this.toDatePickerOptions = this.defaultCalendar();
        }
        else if (event == 'From' && this.fromDate) {
            let dt = new Date(this.fromDate.jsdate);
            dt.setDate(dt.getDate() - 1);
            this.toDatePickerOptions.disableUntil = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() }
        }
        // else if (event == 'To' && this.toDate) {
        //     let dt = new Date(this.toDate.jsdate);
        //     dt.setDate(dt.getDate() + 1);
        //     this.fromDatePickerOptions.disableSince = { year: dt.getFullYear(), month: dt.getMonth() + 1, day: dt.getDate() }
        // }
    }

    public markDates(policyId: number) {
        if ((policyId === 0 || policyId === null) && (this.selectedTab == this.UI.HINTS.COMP_TRENDS || this.selectedTab == this.UI.HINTS.AUDIT_TRENDS))
            return;

        this.timeShiftDisplays = [
            {
                label: GetDisplayStringForCompliance(TimeShift.SELECT),
                timeShift: TimeShift.SELECT,
            },
            {
                label: GetDisplayStringForCompliance(TimeShift.BOTH),
                timeShift: TimeShift.BOTH,
            },
            {
                label: GetDisplayStringForCompliance(TimeShift.DAY),
                timeShift: TimeShift.DAY,
            },
            {
                label: GetDisplayStringForCompliance(TimeShift.NIGHT),
                timeShift: TimeShift.NIGHT,
            },
        ];

        if (this.fromDate !== null && this.toDate !== null)
            setSingleMetricsHeader(this.fromDate.toString() + " to " + this.toDate.toString());

        //this.REPORT_TYPES = reportTypes();    
        //let policy: Policy = this._policies.find(_ => _.id == policyId);
        //let isHealthSystemPolicy: boolean = false;

        // if(policy.healthSystemId != undefined)
        //  isHealthSystemPolicy = true;

        //console.log("Policy:"+policy.healthSystemId + ", HealthSystem:"+isHealthSystemPolicy);
        //return;         
        if (this.checkUserOrPolicyTab()) {
            this.clearCalendar('Both');
        }
        else {
            this.reportDetailsService.getReportDetails(policyId, this.selectForHealthSystem).subscribe(
                reportData => {

                    this.shiftFilterReportDetails = reportData;
                    if (reportData.policyType == 2) {
                        this.selectReportType(this.REPORT_TYPES[0]);
                        this.selectReportView(this.REPORT_TYPES[0].views[0]);
                    } else {
                        this.selectReportType(this.REPORT_TYPES[1]);
                        this.selectReportView(this.REPORT_TYPES[1].views[0]);
                    }

                    let availableDates: Array<IMyDate> = [];
                    reportData.availableDates.forEach(element => {
                        availableDates.push({ year: element.year, month: element.month, day: element.day });

                    });


                    this.fromDatePickerOptions = {
                        dateFormat: DATEFORMAT.mmmdd_yyyy,
                        firstDayOfWeek: 'su',
                        markDates: [{ dates: availableDates, color: 'Orange' }],
                        disableDateRanges: [
                            {
                                begin: { year: 2000, month: 1, day: 1 },
                                end: { year: 2030, month: 12, day: 31 }
                            }],
                        minYear: 2000,
                        maxYear: 2030,
                        enableDays: availableDates,
                    };

                    this.toDatePickerOptions = {
                        dateFormat: DATEFORMAT.mmmdd_yyyy,
                        firstDayOfWeek: 'su',
                        markDates: [{ dates: availableDates, color: 'Pink' }],
                        disableDateRanges: [
                            {
                                begin: { year: 2000, month: 1, day: 1 },
                                end: { year: 2030, month: 12, day: 31 }
                            }],
                        minYear: 2000,
                        maxYear: 2030,
                        enableDays: availableDates,
                    };
                    this.units = reportData.units;
                },
                err => {
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        }
    }

    /* private displayExportAnalyticData(): void {
         this.simpleModalService.addModal(
             ExportAnalyticDataComponent,
             {mode:DisplayExportAnalyticDataModalDialogMode.Create},
             {
                 animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
             }
         );        
       }*/

    public onExportClicked() {
        // To add menu options like Excel and PDF in the future just add to the menuOptions string array and respond to the corresponding indexSelected.
        const menuOptions = ['To PowerPoint', 'Data To Excel'];
        let pptPolicy: Policy;
        this.popupMenuService.openMenu(this.exportButtonViewContainerRef, menuOptions, (indexSelected) => {
            switch (indexSelected) {
                case 0:
                    //  this.exportAll(this.formats.ppt);
                    // this.exportCurrent(this.formats.ppt);
                    if (this.selectedPolicy && this.selectedPolicy.answers) {
                        pptPolicy = this.policies.filter(x => x.id == this.selectedPolicy.id)[0];
                        for (let answer of this.selectedPolicy.answers) {
                            this.answersMap.set(answer.questionAnswerId, answer);
                        }
                    }

                    const questionResponses: Array<PolicyResponse> = [];
                    const compliance = {
                        question: "Compliance reporting settings",
                        answer: ["Minimum compliance rate % that should display on reports as yellow: " + this.selectedPolicy.settings.minYellowRate.toString(),
                        "Minimum compliance rate % that should display on reports as green: " + this.selectedPolicy.settings.minGreenRate,
                        CREATE_AND_EDIT_POLICY_MODAL_DIALOG_COMPONENT.COMPLIANCE_RATES.FOOTER]
                    };
                    questionResponses.push(compliance);

                    if (this.policyTypes) {
                        for (let question of this.policyTypes[this._policyTypeId - 1].questions) {
                            if (question.answers !== null) {
                                const ansArray = [];
                                for (let questionAnswer of question.answers) {
                                    const answer = this.answersMap.get(questionAnswer.id);
                                    if (answer) {
                                        var ansText = question.answers.filter(x => x.id == answer.questionAnswerId)[0].label;
                                        ansArray.push(ansText);
                                    }
                                }
                                if (ansArray.length > 0) {
                                    const response: PolicyResponse = {
                                        question: question.title,
                                        answer: ansArray
                                    };
                                    questionResponses.push(response);
                                }

                            }
                        }

                        var count = 1;
                        if (pptPolicy.customQuestions != null && pptPolicy.customQuestions.length > 0)
                            pptPolicy.customQuestions.forEach(element => {
                                var askRate = element.forEveryPatientOrCatheter == 0 ? ' Once per Patient' : ' Once per Line';
                                const customQues: PolicyResponse = {
                                    question: "Custom Question " + count,
                                    answer: [element.questionText, "How often should this question be asked?" + askRate]
                                };
                                questionResponses.push(customQues);
                                count++;
                            });
                    }

                    this.exportAllShifts(this.formats.ppt, questionResponses);
                    break;
                case 1:
                    this.exportData();
                    break;
                default:
                    console.assert(false, 'DashboardComponent#onExportClicked - Invalid index selected');
                    break;
            }
        });
    }

    public exportData() {
        this.reportService.exportRawReport(
            this.policy.id,
            this.selectedTimeShift,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.selectedReportType,
            this.formats.excel,
            this.selectForHealthSystem,
            this.selectedHealthSystem
        );
    }
    public exportAnalyticData() {

        this.reportService.exportAnalytic();
    }
    public isSuperAdmin(): boolean {
        if (this.userService.getPrimaryRoleForCurrentUser() == "ROLE_SUPER_ADMIN")
            return true;
        return false;
    }

    public is3MAdmin(): boolean {
        if (this.userService.getPrimaryRoleForCurrentUser() == "ROLE_ADMIN")
            return true;
        return false;
    }

    private get currentReportContext(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReport,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.selectedTimeShift,
            this.selectedReportView,
            this.metric
        );
    }
    private get currentReportContextDay(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReportDay,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.timeShifts[1],
            this.selectedReportView,
            this.metric
        );
    }
    private get currentReportContextNight(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReportNight,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.timeShifts[2],
            this.selectedReportView,
            this.metric
        );
    }
    private get currentReportContextBoth(): ReportContext {
        return new ReportContext(
            this.selectedReportType,
            this.policy,
            this.facility,
            this.table,
            this.fullReportBoth,
            this.convertDate(this.fromDate),
            this.convertDate(this.toDate),
            this.timeShifts[0],
            this.selectedReportView,
            this.metric
        );
    }

    //not being used
    public exportCurrent(format: ExportFormat = ExportFormat.PPT) {
        this.reportService.exportReport(this.currentReportContext, format);
    }

    //not being used
    public exportAll(format: ExportFormat = ExportFormat.PPT, questionResponse: Array<PolicyResponse>) {
        this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy);
    }

    //exports report - current usage
    public exportAllShifts(format: ExportFormat = ExportFormat.PPT, questionResponse: Array<PolicyResponse>) {

        if (this.timeShiftDisplays.length > 2) {

            if (this.selectForHealthSystem) {

                this.reportService.exportAllShiftReports(this.currentReportContextDay, this.currentReportContextNight, this.currentReportContextBoth, format, questionResponse, this.selectedPolicy, this.selectedHealthSystem);

            } else {

                this.reportService.exportAllShiftReports(this.currentReportContextDay, this.currentReportContextNight, this.currentReportContextBoth, format, questionResponse, this.selectedPolicy);
            }
        }
        else {
            if (this.selectForHealthSystem) {

                this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy, this.selectedHealthSystem);

            } else {

                this.reportService.exportAllReports(this.currentReportContext, format, questionResponse, this.selectedPolicy);
            }

        }
    }

    public onChangeOfFromDate() {

        if (this.fromDate) {
            this.toDatePickerOptions = {
                dateFormat: DATEFORMAT.mmmdd_yyyy,
                editableDateField: false,
                disableUntil: this.fromDate.date,
                //disableUntil:{year: this.fromDate.date.year, month: this.fromDate.date.month, day: this.fromDate.date.day - 1},
                showInputField: false,
                showClearDateBtn: false,
            };

            if (this.toDate) {
                // Clamp the toDate so that it is always the same or after fromDate
                if (this.toDate.jsdate < this.fromDate.jsdate) {
                    this.toDate = this.fromDate;
                }
            }

        }
        this.onChangeToDate();
        this.clearCalendar('From');
    }
    public createDateObject(myDate: IMyDate): Date {
        let dateString: string = myDate.year + '/' + myDate.month + '/' + myDate.day;
        let newDate = new Date(dateString);
        return newDate;
    }
    public onChangeToDate() {
        this.clearAllReportData();

        if (this.toDate == null || this.toDate == undefined)
            return;
        if (this.fromDate == null || this.fromDate == undefined)
            return;
        let repType = 0;
        let shift: string[] = [];
        if(this.shiftFilterReportDetails)
        this.shiftFilterReportDetails.availableDates.forEach((element) => {
            let repDate: IMyDate;
            repDate = { year: element.year, month: element.month, day: element.day }

            if (this.createDateObject(this.fromDate.date) <= this.createDateObject(repDate) && this.createDateObject(this.toDate.date) >= this.createDateObject(repDate)) {
                shift.push(element.shift);
            } else {
            }

        })

        this.timeShiftDisplays = [
            {
                label: GetDisplayStringForCompliance(TimeShift.SELECT),
                timeShift: TimeShift.SELECT,
            },
            {
                label: GetDisplayStringForCompliance(TimeShift.BOTH),
                timeShift: TimeShift.BOTH,
            },
            {
                label: GetDisplayStringForCompliance(TimeShift.DAY),
                timeShift: TimeShift.DAY,
            },
            {
                label: GetDisplayStringForCompliance(TimeShift.NIGHT),
                timeShift: TimeShift.NIGHT,
            },
        ];
        /*if(shift.includes("DAY")  && !shift.includes("NIGHT")){
            this.timeShiftDisplays.splice(3,1);
            this.timeShiftDisplays.splice(1,1);
        }else if(!shift.includes("DAY")  && shift.includes("NIGHT")){
            this.timeShiftDisplays.splice(2,1);
            this.timeShiftDisplays.splice(1,1);
        }*/

        this.selectedTimeShiftDisplay = this.timeShiftDisplays[0];
        this.selectedTimeShift = undefined;

        if (this.canLoadReport) {
            this.loadReportData(this.selectedTimeShift);
        }
        //this.clearCalendar('To');
    }

    public get areSelectedReportOptionsValid(): boolean {

        if (this.facility != null
            && this.isUserAdminForSelectedFacility
            && this.facility.id !== FacilitySelectorComponent.ALL_FACILITIES_ID
            && this.fromDate != null
            && this.toDate != null
        ) {
            if (this.selectedTab == this.UI.HINTS.COMP_TRENDS) {
                if (this.selectedTimeShift != null && this.selectedTimeShift != undefined) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else if (this.selectedTab == this.UI.HINTS.AUDIT_TRENDS) {
                if (this.selectedUnit && this.selectedUnit.id) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return true;
            }
        }
        else if ((this.selectedTab == this.UI.HINTS.AUDIT_COUNT || this.selectedTab == this.UI.HINTS.AUDIT_BY || this.selectedTab == this.UI.HINTS.PORT_COMPLIANCE_All) && this.fromDate != null && this.toDate != null){
            return true;
        }
         else {
            return false;
        }
    }

    public get canLoadReport(): boolean {
        if(this.selectedTab == this.UI.HINTS.AUDIT_COUNT ||
            this.selectedTab == this.UI.HINTS.AUDIT_BY ||
            this.selectedTab == this.UI.HINTS.PORT_COMPLIANCE_All) {
                return this.areSelectedReportOptionsValid;
            }
            else {
                return this.selectedPolicy != null && this.areSelectedReportOptionsValid;
            }
    }

    public onFilterChange(repType) {

        this.clearAllReportData();
        if (this.canLoadReport) {
            this.loadReportData(repType);
        }
    }

    public clearAllReportData() {
        this.table = undefined;
        this.fullReport = undefined;
        // this.chartOptions.series.remove(true)


        //this.chartRef.ngOnDestroy();  
        // this.selectedTimeShift = undefined ;

        this.chartOptions.xAxis.categories = [];
        this.chartOptions.series[0] = {
            name: "  ",
            type: 'line',
            data: []
        };
        this.chartOptions.series[1] = {
            name: "  ",
            type: 'line',
            data: []
        };

        this.updateFlag = true;

        this.activeUsers = [];
        this.activePolicies = [];

    }

    public getStyleClassForCell(cell: Cell) {
        if (cell.header) {
            return { 'transparent-cell': true };
        } else if (cell.value == '') {
            return { 'empty-cell': true };
        } else if (cell.metaValue >= this.policy.settings.minGreenRate) {
            return { 'green-cell': true };
        } else if (cell.metaValue >= this.policy.settings.minYellowRate) {
            return { 'yellow-cell': true };
        } else {
            return { 'red-cell': true };
        }
    }

    public get shouldShowTable(): boolean {
        return false == this.shouldShowEmptyTableIndicator && this.shouldShowContent && this.arePolicyAndReportLoaded && (this.table != null);
    }

    public get shouldShowActionBar(): boolean {
        return this.table != null || (this.selectedReportType != null && this.selectedReportType.metrics != null);
    }

    public get shouldShowEmptyTableIndicator() {
        if (this.isReportLoading) {
            return false;
        }
        if (false == this.policyAndReportTypeMatch()) {
            return true;
        }
        if (this.areSelectedReportOptionsValid) {
            if (this.isReportDataErrored) {
                return true;
            }
            return this.arePolicyAndReportLoaded && this.table == null;
        } else {
            if (this.isPolicyLoading && this.areSelectedReportOptionsValid) {
                return false;
            } else {
                return true;
            }
        }
    }

    private policyAndReportTypeMatch(): boolean {
        const twentyOneDayReportTypeId: number = 1;
        const comprehensiveReportTypeId: number = 2;
        if (undefined == this.policy) {
            return false;
        }
        if (this.selectedReportType.title == TwentyOneDayChallengeType.TITLE
            && this.policy.policyTypeId != twentyOneDayReportTypeId
        ) {
            return false;
        }
        if (this.selectedReportType.title == ComprehensiveType.TITLE
            && this.policy.policyTypeId != comprehensiveReportTypeId
        ) {
            return false;
        }
        return true;
    }

    public get emptyTableHint() {
        if (this.isPolicyErrored) {
            return UI_CONSTANTS.HINTS.NO_POLICY;
        }

        if (this.isReportDataErrored) {
            return UI_CONSTANTS.HINTS.NO_DATA;
        }
        if (this.disableActionBar) {

            return UI_CONSTANTS.HINTS.SELECT_OTHER_FACILITY;
        }

        if (this.areSelectedReportOptionsValid) {
            return UI_CONSTANTS.HINTS.NO_DATA;
        } else {
            if (this.isFacilityValidForReport(this.facility)) {
                return UI_CONSTANTS.HINTS.SELECT_OPTIONS;
            } else {
                return UI_CONSTANTS.HINTS.SELECT_FACILITY;
            }
        }
    }

    public doesSelectedReportViewHaveTabs(): boolean {
        return this.selectedReportView != null && this.selectedReportView.tabs != null && this.selectedReportView.tabs.length > 0;
    }
    public reportTypeChange(newReportType: ReportType) {
        if (!this.isFaclityChanged)
            this.selectedPolicy = null;
        this.isFaclityChanged = false;
        this.selectReportType(newReportType);
    }

    public selectReportType(newReportType: ReportType) {
        if (this.facility != null)
            this.policies = this.policiesForFacilityId(this.facility.id);
        // this.selectedPolicy = null;

        this.selectedReportView = undefined;
        this.metric = undefined;
        this.selectedReportType = newReportType;
        if (this.selectedReportType.metrics) {
            this.metric = this.selectedReportType.metrics[0];
        }
        if (this.selectedReportType.views) {
            this.selectedReportView = this.selectedReportType.views[0];
            this.selectedReportType.selectView(this.selectedReportView);
        }

        this.showReportTypeDropdown = false;
        this._prepareTableData();
        this.clearAllReportData();
    }

    public selectReportView(view: ReportView) {
        this.selectedReportView = view;
        this.selectedReportType.selectView(this.selectedReportView);
        this.showViewDropdown = false;
        this._prepareTableData();
    }

    public selectTimeShiftDisplay(timeShiftDisplay: TimeShiftDisplay) {
        if (this.selectedTimeShiftDisplay.label == 'Select') {
            //this.clearAllReportData;
            return;
        }
        this.selectedTimeShiftDisplay = timeShiftDisplay;
        if (this.selectedTimeShift !== timeShiftDisplay.timeShift || this.selectedTimeShift == undefined) {
            this.selectedTimeShift = timeShiftDisplay.timeShift;
            this.onFilterChange(this.selectedTimeShift);
        }
    }

    public selectMetric(metric: Metric) {
        this.metric = metric;
        this._prepareTableData();
    }

    private loadFacility() {

        this.facilityService.getAll().subscribe(_ => {
            const active = _.filter(_ => _.active);
            this.facilities = active;
        });



    }

    private loadActivePolicy() {
        if (this.areSelectedReportOptionsValid) {
            this.showSkeletonAfterDelay(0);
        }

        this.policy = null;
        this.isPolicyErrored = false;
        this.isPolicyLoading = true;

        this.facilityService.activePolicy(this.facility.id)
            .subscribe(policy => {
                this.policy = policy;
                this.selectedPolicy = policy;
                this.markDates(this.policy.id);
                this.isPolicyLoading = false;

                if (this.arePolicyAndReportLoaded) {
                    this.hideSkeletonAndShowContent();
                }
            }, () => {
                this.isPolicyLoading = false;
                this.isPolicyErrored = true;
                this.hideSkeletonAndShowContent();
            });
    }

    public isTabSelected(tab: ViewTab): boolean {
        return this.selectedReportView != null && this.selectedReportView.isTabSelected(tab);
    }

    public selectReportViewTab(tab: ViewTab) {
        this.selectedReportView.selectTab(tab);
        this._prepareTableData();
    }

    public get allReportViews(): ReportView[] {
        if (this.selectedReportType == null || this.selectedReportType.views == null) {
            return [];
        } else {
            return this.selectedReportType.views;
        }
    }

    // Used by the UI to enable the footer information
    public get isPolicyLoaded(): boolean {
        return this.selectedPolicy != null;
    }

    public get arePolicyAndReportLoaded(): boolean {
        return this.fullReport != null && this.selectedPolicy != null;
    }


    private loadReportData(complianceType) {
        var comTYpe = "both";
        var comHeader = "Report for both the compliance";
        if (complianceType == "DAY") {
            comTYpe = "portcompliancereport";
            comHeader = "Port Compliance Report";
        } else if (complianceType == "NIGHT") {
            comTYpe = "patientcompliancereport";
            comHeader = "Patient Compliance Report";
        }
        const fromDate = this.convertDate(this.fromDate);
        const toDate = this.convertDate(this.toDate);
        $("#loading").show();
        this.showSkeletonAfterDelay(0);
        this.isReportLoading = true;
        this.isReportDataErrored = false;
        console.assert(this.areSelectedReportOptionsValid, 'DashboardComponent#loadReportData - Trying to load report data for invalid options');

        if (comTYpe == "both" && this.selectedTab == this.UI.HINTS.COMP_TRENDS) {
            //plot both graphs
            this.reportDataService
                .summaryReportData(this.selectedPolicy.id, this.selectedTimeShift, fromDate, toDate, this.selectForHealthSystem, "portcompliancereport")
                .subscribe(dataPort => {
                    this.reportDataService
                        .summaryReportData(this.selectedPolicy.id, this.selectedTimeShift, fromDate, toDate, this.selectForHealthSystem, "patientcompliancereport")
                        .subscribe(dataPat => {
                            var numArrPort = [];
                            var monthArrPort = [];
                            var avgPortCompliance;
                            dataPort = dataPort.filter(x => x.isAudited);
                            var countAuditMonthsPort = dataPort.length;
                            dataPort.forEach((element, index) => {
                                // var shortName = moment.monthsShort(element.month - 1);
                                numArrPort.push(Number.parseFloat(element.portCompliance.toString()));
                                monthArrPort.push(element.month)
                                if (index == dataPort.length - 1) {
                                    avgPortCompliance = this.calcAvg(numArrPort, countAuditMonthsPort);
                                }
                            });
                            this.chartOptions.xAxis.categories = monthArrPort;
                            // this.chartOptions.title.text = comHeader;
                            this.chartOptions.series[0] = {
                                type: 'line',
                                data: numArrPort,
                                name: "Port Compliance"
                            };
                            var numArrPat = [];
                            var monthArrPat = [];
                            var avgPatientCompliance;
                            dataPat = dataPat.filter(x => x.isAudited);
                            var countAuditMonthsPat = dataPat.length;
                            dataPat.forEach((element, index) => {
                                // var shortName = moment.monthsShort(element.month - 1);
                                numArrPat.push(Number.parseFloat(element.portCompliance.toString()));
                                monthArrPat.push(element.month);
                                if (index == dataPat.length - 1) {
                                    avgPatientCompliance = this.calcAvg(numArrPat, countAuditMonthsPat);
                                }
                            });
                            this.chartOptions.series[1] = {
                                type: 'line',
                                data: numArrPat,
                                name: "Patient Compliance"
                            };

                            this.updateFlag = true;
                            const chartComp = Highcharts.chart('container2', this.chartOptions);

                            this.portComplianceGauge.series[0].data[0] = avgPortCompliance;
                            Highcharts.chart('container-port-comp', this.portComplianceGauge);

                            this.patientComplianceGauge.series[0].data[0] = avgPatientCompliance;
                            Highcharts.chart('container-patient-comp', this.patientComplianceGauge);
                            // this.chartOptions.series[0].data = numArr;
                            // this.chartOptions.xAxis.categories = monthArr;
                            this.isReportLoading = false;
                            this.hideSkeletonAndShowContent();
                            $("#loading").hide();
                        },
                            () => {
                                this.isReportLoading = false;
                                this.isReportDataErrored = true;
                                this.hideSkeletonAndShowContent();
                                $("#loading").hide();
                            });



                    // return;
                })
        } else if (this.selectedTab == this.UI.HINTS.COMP_TRENDS) {

            this.reportDataService
                .summaryReportData(this.selectedPolicy.id, this.selectedTimeShift, fromDate, toDate, this.selectForHealthSystem, comTYpe)
                .subscribe(data => {
                    var numArr = [];
                    var monthArr = [];
                    var avgCompliance;
                    data = data.filter(x => x.isAudited);
                    var countAuditMonths = data.length;
                    data.forEach((element, index) => {
                        //var shortName = moment.monthsShort(element.month - 1);
                        numArr.push(Number.parseFloat(element.portCompliance.toString()));
                        monthArr.push(element.month)
                        if (index == data.length - 1) {
                            avgCompliance = this.calcAvg(numArr, countAuditMonths);
                        }
                    });
                    this.chartOptions.xAxis.categories = monthArr;
                    this.chartOptions.series[0] = {
                        name: comHeader,
                        type: 'line',
                        data: numArr
                    };

                    this.updateFlag = true;
                    const chartComp = Highcharts.chart('container2', this.chartOptions);
                    if (comTYpe == "portcompliancereport") {
                        this.portComplianceGauge.series[0].data[0] = avgCompliance;
                        Highcharts.chart('container-port-comp', this.portComplianceGauge);
                    }
                    else if (comTYpe == "patientcompliancereport") {
                        this.patientComplianceGauge.series[0].data[0] = avgCompliance;
                        Highcharts.chart('container-patient-comp', this.patientComplianceGauge);
                    }
                    // this.chartOptions.series[0].data = numArr;
                    // this.chartOptions.xAxis.categories = monthArr;
                    this.isReportLoading = false;
                    this.hideSkeletonAndShowContent();
                    $("#loading").hide();
                },
                    () => {
                        this.isReportLoading = false;
                        this.isReportDataErrored = true;
                        this.hideSkeletonAndShowContent();
                        $("#loading").hide();
                    });

        }
        else if (this.selectedTab == this.UI.HINTS.ACTIVE_USERS ||
            this.selectedTab == this.UI.HINTS.ACTIVE_POLICIES ||
            this.selectedTab == this.UI.HINTS.AUDIT_TRENDS) {
            var selectedUnitId = this.selectedUnit ? this.selectedUnit.id : 0;
            this.reportDataService
                .summaryActiveUsersPolicy(this.selectedTab, this.facility.id, this.selectedPolicy.id, fromDate, toDate, selectedUnitId, this.selectForHealthSystem)
                .subscribe(data => {
                    if (this.selectedTab == this.UI.HINTS.ACTIVE_USERS)
                        this.activeUsers = data;
                    else if (this.selectedTab == this.UI.HINTS.ACTIVE_POLICIES)
                        this.activePolicies = data;
                    else if (this.selectedTab == this.UI.HINTS.AUDIT_TRENDS) {
                        var numArrPort = [];
                        var monthArrPort = [];
                        data.forEach(element => {
                            numArrPort.push(Number.parseFloat(element.count.toString()));
                            monthArrPort.push(element.monthAndYear)
                        });
                        this.auditTrendsChartOptions.xAxis.categories = monthArrPort;
                        this.auditTrendsChartOptions.series[0] = {
                            type: 'line',
                            data: numArrPort,
                            name: "IV Sites Monitored"
                        };
                        Highcharts.chart('container-audit-trends', this.auditTrendsChartOptions);
                    }
                    this.isReportLoading = false;
                    this.hideSkeletonAndShowContent();
                    $("#loading").hide();
                });
        }
        else if (this.selectedTab == this.UI.HINTS.ACTIVE_USERS ||
            this.selectedTab == this.UI.HINTS.ACTIVE_POLICIES ||
            this.selectedTab == this.UI.HINTS.AUDIT_TRENDS ||
            this.selectedTab == this.UI.HINTS.AUDIT_COUNT ||
            this.selectedTab == this.UI.HINTS.AUDIT_BY ||
            this.selectedTab == this.UI.HINTS.PORT_COMPLIANCE_All) {
            var selectedUnitId = this.selectedUnit ? this.selectedUnit.id : 0;
            this.reportDataService
                .summaryActiveUsersPolicy(this.selectedTab, this.facility.id, this.selectedPolicy ? this.selectedPolicy.id : 0, fromDate, toDate, selectedUnitId, this.selectForHealthSystem)
                .subscribe(data => {
                    if (this.selectedTab == this.UI.HINTS.ACTIVE_USERS)
                        this.activeUsers = data;
                    else if (this.selectedTab == this.UI.HINTS.ACTIVE_POLICIES)
                        this.activePolicies = data;
                    else if (this.selectedTab == this.UI.HINTS.AUDIT_TRENDS) {
                        var numArrPort = [];
                        var monthArrPort = [];
                        data.forEach(element => {
                            numArrPort.push(Number.parseFloat(element.count.toString()));
                            monthArrPort.push(element.monthAndYear)
                        });
                        this.auditTrendsChartOptions.xAxis.categories = monthArrPort;
                        this.auditTrendsChartOptions.series[0] = {
                            type: 'line',
                            data: numArrPort,
                            name: "IV Sites Monitored"
                        };
                        Highcharts.chart('container-audit-trends', this.auditTrendsChartOptions);
                    }
                    else if (this.selectedTab == this.UI.HINTS.PORT_COMPLIANCE_All) {
                        var numArr = [];
                        var monthArr = [];
                        var avgCompliance;
                        data = data.filter(x => x.isAudited);
                        var countAuditMonths = data.length;
                        data.forEach((element, index) => {
                            numArr.push(Number.parseFloat(element.portCompliance.toString()));
                            monthArr.push(element.month)
                            if (index == data.length - 1) {
                            avgCompliance = this.calcAvg(numArr, countAuditMonths);
                            }
                        });
                    this.overallPortComplianceChartOptions.xAxis.categories = monthArr;
                    this.overallPortComplianceChartOptions.series[0] = {
                        name: this.UI.HINTS.PORT_COMPLIANCE_All,
                        type: 'line',
                        data: numArr
                    };
                    Highcharts.chart('container-overall-port-compliance', this.overallPortComplianceChartOptions);
                    this.overllPortComplianceGauge.series[0].data[0] = avgCompliance;
                    Highcharts.chart('container-overall-port-guage', this.overllPortComplianceGauge);
                    }
                    else if (this.selectedTab == this.UI.HINTS.AUDIT_COUNT) {
                        this.auditLevelData = data;
                        this.auditLevelFacCount = this.auditLevelData.reduce((prev, curr) =>{ return prev + curr.facilityCount }, 0);
                        this.auditLevelHsCount = this.auditLevelData.reduce((prev, curr) =>{ return prev + curr.healthSystemCount }, 0);
                    }
                    else if (this.selectedTab == this.UI.HINTS.AUDIT_BY) {
                        this.auditByData = data;
                        this.auditByFacCount = this.auditByData.reduce((prev, curr) =>{ return prev + curr.customerAuditCount }, 0);
                        this.auditByHsCount = this.auditByData.reduce((prev, curr) =>{ return prev + curr._3MRepAuditCount }, 0)
                    }
                    this.isReportLoading = false;
                    this.hideSkeletonAndShowContent();
                    $("#loading").hide();
                });
        }
        /*if(this.timeShiftDisplays.length>2){
         
            this.reportDataService
            .reportData(this.selectedPolicy.id, this.timeShifts[1], fromDate, toDate,this.selectForHealthSystem)
            .subscribe(data => {
                
                this.fullReportDay = data;  
                           
                
            });
       
            this.reportDataService
            .reportData(this.selectedPolicy.id, this.timeShifts[2], fromDate, toDate,this.selectForHealthSystem)
            .subscribe(data => {
                
                this.fullReportNight = data;                
                
            });
          
            this.reportDataService
            .reportData(this.selectedPolicy.id, this.timeShifts[0], fromDate, toDate,this.selectForHealthSystem)
            .subscribe(data => {
                
                this.fullReportBoth = data;                
                
            });
    
        }*/
    }

    private _prepareTableData() {
        if (this.fullReport) {
            this.table = this.selectedReportType.prepare({ data: this.fullReport, metric: this.metric, view: this.selectedReportView });
        }
    }

    private convertDate(date): Date | null {
        if (date) {
            const dateObj = new Date();
            dateObj.setFullYear(date.date.year, date.date.month - 1, date.date.day);
            return dateObj;
        }

        return null;
    }
    public showHeaderToolTip(toolTipMsg: string) {

        this.tooltipmessage = toolTipMsg;
    }

    //Policy Services 

    private loadPolicies(): Promise<void> {
        return new Promise(resolve => {
            this.policyService.getAllEntities().subscribe(
                //this.policyService.getPolicyByIndex(this.policyIndex,facilId).subscribe(
                policies => {
                    /*  policies.forEach(data => {
                         this._policies.push(data);
                     }); */
                    this._policies = policies;
                    //this.setActivePolicy();
                    resolve();
                },
                err => {
                    console.log('error in loading policy')
                    this.errorService.handleErrorStatusCode(err);
                }
            );
        });
    }

    public policiesForFacilityId(facilityId: number | undefined): Policy[] {

        if (facilityId != FacilitySelectorComponent.ALL_FACILITIES_ID && this._policies.filter(policy => policy.facilityId === facilityId).length === 0) {
            this.selectedPolicy = null;
            //this.notificationService.error("", 'No acitve policy found for selection, Please select other facility');
        }
        if (this.selectForHealthSystem == true)
            return this._policies.filter(policy => policy.facilityId === facilityId && policy.policyTypeId === this._policyTypeId && policy.healthSystemId != undefined);

        return this._policies.filter(policy => policy.facilityId === facilityId && policy.policyTypeId === this._policyTypeId);
    }
    public selectPolicyName(policy: Policy) {

        this.selectedPolicy = policy;
        this.policy = policy;
        this.fromDate = null;
        this.toDate = null;
        this.selectedTimeShiftDisplay = null;
        this.selectedUnit = this.initializeUnit();
        this.markDates(policy.id);
        //this.onFilterChange();
    }

    public get _policyTypeId(): number {
        if (this.selectedReportType.title == '21-Days Challenge')
            return 1;
        return 2;
    }
    public checkHealtSystem() {
        //this.onSelectedFacilityChanged(this.facility);


        //this.onFilterChange();
        this.clearAllReportData();

        this.selectForHealthSystem ? (this.selectForHealthSystem = false) : (this.selectForHealthSystem = true);
        let facility: Facility[];
        this.selectPolicyName(this.selectedPolicy);

        if (this.selectForHealthSystem == true) {

            this.policies = this._policies.filter(policy => policy.facilityId === this.facility.id && policy.policyTypeId === this._policyTypeId && policy.healthSystemId != undefined);

            //  let facility:Facility = this.facility.find(_=>_.id == this.activeFacilityId);

            this.selectedHealthSystem = this.healthSystem.find(_hs => _hs.id == this.facility.healthSystemId).name;


        } else {
            this.policies = this._policies.filter(policy => policy.facilityId === this.facility.id && policy.policyTypeId === this._policyTypeId);
            this.selectedHealthSystem = undefined;
        }

    }

    public loadHealthSystem() {
        this.healthSystemService.getHealthSystem()
            .subscribe((healthSystem) => {

                var hSName: HealthSystem[] = [];
                var jsonText = JSON.stringify(healthSystem);
                var obj = JSON.parse(jsonText);
                var arr = Object.keys(obj).map(key => ({ id: key, name: obj[key] }));

                this.healthSystemValues = [];
                arr.forEach(a => {
                    this.healthSystemValues.push(a.name);
                    hSName.push({
                        id: Number(a.id),
                        name: a.name,
                    });
                })
                this.healthSystem = hSName;

            });



    }
    public isFacilityInHealthSystem(): boolean {

        if (this.facility.healthSystemId == undefined) {
            return false;
        } else {
            let facilitesInHealthSystem = this.facilities.filter(f => f.healthSystemId == this.facility.healthSystemId);
            let status: boolean = true;
            facilitesInHealthSystem.forEach(f => {
                if (!this.userService.isUserAdminAtFacilityToEditAudit(f.id)) {
                    status = false;
                }
            });

            return status;

        }

    }

    private exportChart() {

        /**
         * Create a global getSVG method that takes an array of charts as an
         * argument
         */
        Highcharts.getSVG = function (charts) {
            let top = 0;
            let width = 0;

            const groups = charts.map(chart => {
                let svg = chart.getSVG();
                // Get width/height of SVG for export
                const svgWidth = +svg.match(
                    /^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/
                )[1];
                const svgHeight = +svg.match(
                    /^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/
                )[1];

                svg = svg
                    .replace(
                        '<svg',
                        '<g transform="translate(0,' + top + ')" '
                    )
                    .replace('</svg>', '</g>');

                top += svgHeight;
                width = Math.max(width, svgWidth);

                return svg;
            }).join('');

            return `<svg height="${top}" width="${width}" version="1.1"
        xmlns="http://www.w3.org/2000/svg">
            ${groups}
        </svg>`;
        };

        /**
         * Create a global exportCharts method that takes an array of charts as an
         * argument, and exporting options as the second argument
         */
        Highcharts.exportCharts = function (charts, options) {

            // Merge the options
            options = Highcharts.merge(Highcharts.getOptions().exporting, options);

            // Post to export server
            Highcharts.post(options.url, {
                filename: options.filename || 'chart',
                type: options.type,
                width: options.width,
                svg: Highcharts.getSVG(charts)
            });
        };

    }

    public selectUnit(selectedUnit) {
        this.selectedUnit = selectedUnit;
        if (this.canLoadReport) {
            this.loadReportData(this.selectedTimeShift);
        }
    }

    public calcAvg(numArr, countAuditMonths) {
        var avg = parseFloat((numArr.reduce((a, b) => a + b, 0) / countAuditMonths).toFixed(2));
        return avg;
    }

}
