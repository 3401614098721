import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'orderBy'})
export class OrderrByPipe implements PipeTransform {

  transform(records: Array<any>, args?: any): any {

     if(args.type=='date')
     {

     if(args.direction==-1)
     {
       records = records || [];
       return records.sort((a: any, b: any) =>
        new Date(a[args.property]).getTime() - new Date(b[args.property]).getTime()
    );
     }
     else
     {
       records = records || [];
        return records.sort((a: any, b: any) =>
        new Date(b[args.property]).getTime() - new Date(a[args.property]).getTime()
    );
     }

     }
     else
     {
       records = records || [];
       return records.sort(function(a, b){



          if(a[args.property] < b[args.property]){

            return -1 * args.direction;
          }
          else if( a[args.property] > b[args.property]){
            return 1 * args.direction;
          }
          else{
            return 0;
          }
        });

     }


    };
}

