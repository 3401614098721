import {CrudApiService} from "./common/crud.api.service";
import {UserRole, IUserRole} from "../model/role";
import {Injectable} from "@angular/core";
import {TokenService} from "../service/token.service";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class RoleService extends CrudApiService<UserRole, IUserRole, Partial<UserRole>> {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, "api/roles");
    }

    protected assembleSingleInstance(_: any): UserRole {
        return new UserRole(_);
    }
}