import {Injectable} from '@angular/core';
import 'rxjs/Rx';
import {AbstractBackendService, Method} from '../shared/service/AbstractBackendService';
import {DashboardListRequestObject} from "../shared/interfaces/report";
// TODO (kai): review merge on this file.
@Injectable()
export class DashboardService extends AbstractBackendService {

  public getDashboardList(data: DashboardListRequestObject, url): any {
    const path: string = url;
    // TODO: nl - move the url selecting logic into this method

    const args = [data];
    // nl - the component used to pass the params in as an array, but it only ever had one element.
    // their server still expects the params to be an array, but there's no use case for it here,
    // so I'll handle this peculiarity of the request here and simplify the component's code

    return this.makeAuthenticatedRequest(path, Method.Post, args);
  }
}
