import {Component, OnInit, Compiler} from '@angular/core';
import {TranslateService} from 'ng2-translate';
import {Router} from '@angular/router';
import {TokenService} from './shared/service/token.service';
import {EmitterService} from './shared/service/emitter.service';
import {Route, MESSAGES} from './shared/constants/index';
import {Message} from 'primeng/components/common/api';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
//import { Keepalive  } from '@ng-idle/keepalive';
import {NotificationService} from './shared/service/notification.service';
import {LANGUAGE_LIST} from './shared/constants/languages';
import {Util} from './util/util';
import { AppBlurService } from './shared/service/app-blur.service';
import {UserService} from "./shared/api";
import {SimpleModalService} from 'ngx-simple-modal';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const IDLE__SECONDS: number = 900;
const IDLE_TIMEOUT_SECONDS: number = 900;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    appInsights:ApplicationInsights;
  public msgs: Message[] = [];
  // define supported languages for application English, French, German
  public LANGUAGES: any[] = LANGUAGE_LIST;
  lastPing?: Date = null;

  constructor(private tokenService: TokenService,
              private router: Router,
              private emitterService: EmitterService,
              private translate: TranslateService,
              private idle: Idle,    
              //private keepalive: Keepalive ,         
              private notificationService: NotificationService,
              private appBlurService: AppBlurService,
              private userService: UserService,
              private _compiler:Compiler,
              private simpleModalService: SimpleModalService) {
    this.appInsights = new ApplicationInsights({ config: {
      connectionString: 'InstrumentationKey=706f6d46-2292-4509-8347-dc2e6009158d;IngestionEndpoint=https://centralus-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralus.livediagnostics.monitor.azure.com/'
      /* ...Other Configuration Options... */
    } });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    translate.addLangs(this.LANGUAGES);
    const browserLang = translate.getBrowserLang();
    const joinLangs: string = this.LANGUAGES.join('|');
    translate.use(browserLang.match(/joinLangs/) ? browserLang : 'en');
    // TODO: NL - test that this use of joinLangs is valid, my IDE inspection doesn't seem to like it.

    const token = this.tokenService.get();

    if (token) {
      // TODO: nl - move this behavior out of this class, it has no reason to be here
      this.tokenService.setUserToken(JSON.parse(atob(token.split('.')[0])));
      const loggedUser = this.tokenService.getUserToken();
      if (Util.isDefinedAndNotNull(loggedUser)) {
        this.userService.checkUserRoleAndRedirectToDefaultRoute();
      }

      // If we're already logged in we won't receive an onSigninEvent, but we still want to check for idle timeout.
      this.startIdleTimeoutFunctionality();
    }
  }

  private startIdleTimeoutFunctionality() {
    
    this.idle.setIdle(IDLE__SECONDS);
    this.idle.setTimeout(IDLE_TIMEOUT_SECONDS);
    //this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    //this.idle.watch();
    /* this.idle.onIdleStart.subscribe(()=>{
      this.idle.clearInterrupts();
      
    }); */
    this.idle.onTimeout.subscribe(() => {
      this.tokenService.signOutAndClearUserData();
      this.notificationService.warn(MESSAGES.INFO.HEADER.SESSION_TIMEOUT, MESSAGES.INFO.SESSION_TIMEOUT);
      this.simpleModalService.removeAll();
      this.router.navigate([Route.LOGIN]);
    });
    

    // sets the ping interval to 15 seconds
  //  this.keepalive.interval(5);

  // this.keepalive.onPing.subscribe(() => this.lastPing = new Date());

    
  
    
    
  }
 

  public ngOnInit() {
    //console.log("Location Host:"+location.host);
    if(location.host =='3mpeakprod.azurewebsites.net') {
      console.log("Host1:"+location.host);
      window.location.href = location.href.replace('https://3mpeakprod.azurewebsites.net', 'https://peak.3m.com');
    }else if(location.host =='localhost:4200') {
      console.log("Host2:"+location.host);
      //window.location.href = location.href.replace('http://localhost:4200', 'http://localhost:4200');
    }else if(location.host == '3mpeakqa.azurewebsites.net'){
      window.location.href = location.href.replace('https://3mpeakqa.azurewebsites.net','https://peakqa.3m.com')
    }
    
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.watch();    
    this.idle.onIdleEnd.subscribe(() => {
    });
    this.emitterService.notificationEmitter.subscribe((obj: any) => this.addNotification(obj));
    
    this.emitterService.onSigninEvent.subscribe(() => {
      this.startIdleTimeoutFunctionality();
    });

    this.emitterService.onSignOutEvent.subscribe(() => {
      this.idle.stop();
      this.idle.ngOnDestroy();
    });
    
    this._compiler.clearCache();
  }

  public get shouldShowNavigation(): boolean {
    const user = this.tokenService.getUserToken();
    return user != null && user.termsAccepted == true;
  }

  public get shouldBlurApp() {
    return this.appBlurService.shouldBlur;
  }

  private addNotification(msg) {
    this.msgs = [];
    this.msgs.push({severity: msg.type, summary: msg.title, detail: msg.msg});
  }
}
