import {Injectable} from '@angular/core';
import {ApiService} from './common/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {TokenService} from '../service/token.service';
import {Observable} from 'rxjs';
import {FullReport, UnitReport} from '../model/unit.report';
import {RawReport} from '../model/raw-report';
import * as moment from 'moment';
import {TimeShift} from '../model/time.shift';
export interface PortComplianceSummaryReport {

    month: number;

    portCompliance: number;
    
    isAudited: boolean;

}
@Injectable()
export class ReportDataService extends ApiService {

    constructor(http: HttpClient, tokenService: TokenService) {
        super(http, tokenService, 'api/reports');
    }

    reportData(policyId: number, shift: TimeShift, fromDate: Date, toDate: Date,isSelectedForHealthSystem: boolean): Observable<FullReport> {
        const params: HttpParams = new HttpParams()
            .set('policyId', policyId.toString())
            .set('fromDate', moment(fromDate).format('YYYY-MM-DD'))
            .set('toDate', moment(toDate).format('YYYY-MM-DD'))
            .set('shift', shift)
            .set('isSelectedForHealthSystem',isSelectedForHealthSystem.toString());

        return this.get<FullReport>(`/generate`, params);
    }
    summaryReportData(policyId: number, shift: TimeShift, fromDate: Date, toDate: Date,isSelectedForHealthSystem: boolean, repType: string): Observable<PortComplianceSummaryReport[]> {

        const params: HttpParams = new HttpParams()

            .set('policyId', policyId.toString())

            .set('fromDate', moment(fromDate).format('YYYY-MM-DD'))

            .set('toDate', moment(toDate).format('YYYY-MM-DD'))

            .set('isSelectedForHealthSystem', isSelectedForHealthSystem.toString())

           



        return this.get<PortComplianceSummaryReport[]>(`/summary/`+repType, params);

    }

    reportRawData(policyId: number, shift: TimeShift, fromDate: Date, toDate: Date, reportTypeKey: string, isSelectedForHealthSystem: boolean): Observable<RawReport[]> {
        const params: HttpParams = new HttpParams()
            .set('policyId', policyId.toString())
            .set('fromDate', moment(fromDate).format('YYYY-MM-DD'))
            .set('toDate', moment(toDate).format('YYYY-MM-DD'))
            .set('shift', shift)
            .set('reportType', reportTypeKey)
            .set('isSelectedForHealthSystem',isSelectedForHealthSystem.toString());

        return this.get<RawReport[]>(`/generate/raw`, params);
    }
    reportAnalyticData(): Observable<any[]> {        

        return this.get<any[]>(`/getAuditReport`);
    }
    reportInitiateExcel(policyId: number, shift: TimeShift, fromDate: Date, toDate: Date, reportTypeKey: string, isSelectedForHealthSystem: boolean): Observable<RawReport[]> {
        const params: HttpParams = new HttpParams()
            .set('policyId', policyId.toString())
            .set('fromDate', moment(fromDate).format('YYYY-MM-DD'))
            .set('toDate', moment(toDate).format('YYYY-MM-DD'))
            .set('shift', shift)
            .set('reportType', reportTypeKey)
            .set('isSelectedForHealthSystem',isSelectedForHealthSystem.toString());

        return this.get<RawReport[]>(`/initiateExcellCall`, params);
    }

    filterReportAnalyticData(fromDate: Date, toDate: Date): Observable<any[]> {     
        const params: HttpParams = new HttpParams()         
            .set('fromDate', moment(fromDate).format('YYYY-MM-DD'))
            .set('toDate', moment(toDate).format('YYYY-MM-DD'));  

        return this.get<any[]>(`/getFilteredAuditReport`,params);
    }

    summaryActiveUsersPolicy(reportType: string, facilityId: number, policyId: number, fromDate: Date, toDate: Date, unitId: number, isSelectedForHealthSystem: boolean): Observable<any> {

        const params: HttpParams = new HttpParams()

            .set('ReportType', reportType)

            .set('FacilityId', facilityId.toString())

            .set('PolicyId', policyId.toString())

            .set('FromDate', moment(fromDate).format('YYYY-MM-DD'))

            .set('ToDate', moment(toDate).format('YYYY-MM-DD'))

            .set('UnitId', unitId.toString())

            .set('IsSelectedForHealthSystem', isSelectedForHealthSystem.toString())

        return this.get<any[]>(`/summary/active-users-policy-report`, params);

    }

    insertReportDownloadLog(policyId: number, reportType: string): Observable<any> {
        let obj = JSON.stringify({'policyId': policyId, 'reportType': reportType});
        return this.post('/insert/log', obj);
    }
}
