export interface IFacilityWorkerRole {
  role: 'AUDITOR' | 'ADMIN' |'REP';
}

export class FacilityWorkerRole implements IFacilityWorkerRole {
  role: 'AUDITOR' | 'ADMIN' |'REP';

  constructor(args: IFacilityWorkerRole) {
    console.assert(args !== undefined && args !== null, 'FacilityWorkerRole passed null or undefined args object');
    console.assert(typeof args.role === 'string' && ['AUDITOR', 'ADMIN','REP'].indexOf(args.role) !== -1,
    'FacilityWorkerRole passed args with invalid role');
  
    this.role = args.role;
  }
}

export interface IFacilityWorker {
  id?: number;
  facilityId?: number;
  userId: number;
  role: IFacilityWorkerRole;
}

export class FacilityWorker implements IFacilityWorker {
  id?: number;
  facilityId?: number;
  userId: number;
  role: IFacilityWorkerRole;

  constructor(args: IFacilityWorker) {
    console.assert(args !== undefined && args !== null, 'FacilityWorker passed null or undefined args object');
    if (args.id !== undefined) {
      console.assert(typeof args.id === 'number', 'FacilityWorker passed args with invalid id');
    }
    if (args.facilityId !== undefined) {
      console.assert(typeof args.facilityId === 'number', 'FacilityWorker passed args with invalid facilityId');
    }
    console.assert(typeof args.userId === 'number', 'FacilityWorker passed args with invalid userId');
    console.assert(!!args.role, 'FacilityWorker passed args with invalid role');

    this.id = args.id;
    this.facilityId = args.facilityId;
    this.userId = args.userId;
    this.role = new FacilityWorkerRole(args.role);
  }
}