
import { Component,OnInit, ViewChild,ElementRef, ViewChildren, QueryList, AfterViewInit, OnDestroy } from '@angular/core';

import {SimpleModalComponent,SimpleModalService} from 'ngx-simple-modal';
import {NgControl, NgForm} from '@angular/forms';
import {DataService} from '../../shared/service/data.service';
import {NotificationService} from '../../shared/service/notification.service';
import {AppBlurService} from '../../shared/service/app-blur.service';
import {ErrorService} from '../../shared/service/error-service';
import {Unit} from "../../shared/model/unit";
import {Audit,AuditTarget,UserInterfaceType} from "../../shared/model/audit";
import {PolicyType} from "../../shared/model/policy-type";
import {PolicyTypeService} from '../../shared/api/policy.type.service';
import {PatientService} from '../../shared/api/patient.service';
import { trigger, style, animate, transition } from '@angular/animations';
import {Patient,PatientAnswer,PatientCatheter, } from "../../shared/model/patient";
//import {Patient,PatientAnswer,PatientCatheter, CathAnswer, PatientCatheterAns, PatientCatheterFinalAns} from "../../shared/model/patient";
import {Question,InputType} from "../../shared/model/question";
import { AnswerType, QuestionAnswer } from "../../shared/model/question-answer";
import { IQuestionGroup,QuestionGroup } from "../../shared/model/questionGroup";
import { CatheterService } from "../../shared/api/catheter.service";
import { PatientAnswerService } from "../../shared/api/patient.answer.service";
import { CatheterLocationService } from "../../shared/api/catheter.location.service";
import TIME_SHIFT from '../../shared/constants/time-shift';
import {IMyDateModel} from 'mydatepicker';
import AssertInClass from '../../util/AssertInClass';
import { AuditService } from "../../shared/api/audit.service";
import {DataEntryProgressIndicatorComponent  } from "../data-entry-progress-indicator/data-entry-progress-indicator.component";
import {IPolicy, IPolicyQuestionAnswer, Policy, PolicyQuestionAnswer,PolicyCuatomQuestion} from '../../shared/model/policy';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { audit } from 'rxjs/operators';
import { element } from '@angular/core/src/render3';

import { LabelOption } from './../../shared/components/selector/selector.component';
import { TwoChoiceModalDialogComponent } from '../../shared/components/two-choice-modal-dialog/two-choice-modal-dialog.component';
import { DATA_ENTRY_PAGE_COMPONENT as UI_CONSTANTS } from '../../shared/constants/uiConstants';
import { MODAL_DIALOG_FADE_DURATION_MS } from '../../shared/constants/animationConstants';
import { delay } from 'rxjs/operators'
import { TokenService } from '../../shared/service/token.service';


export interface ProgressBarChunkInput {
    questionGroupCount: number;
    target: AuditTarget;
}
export interface QuestionGroupIndexValues {
    questionGroupCount: number;
    auditCount: number;
    quesitonGroupIndex:number;
}
export enum CreateAndEditDataEntryModalDialogMode {
  Create,
  Edit,
}

export interface ICreateAndEditDataEntryModalDialog {
  mode: CreateAndEditDataEntryModalDialogMode;
  shift: string,
  unit: Unit,
  selectedDate:IMyDateModel,
  //auditForGroups:Audit,
  //existingPoliciesOfFacility: Policy[];
  policy: Policy;
  policyType:PolicyType; 
}
interface AuditSelectorOption {
  auditIndex: number;
  minQuestionGroupIndex: number;
  maxQuestionGroupIndex: number;
  isCatheter: boolean;
  canDelete: boolean;
  label: string;
}


export interface ICreateAndEditDataEntryModalDialogResult {
  needsRefresh: boolean;
}

export enum selectedCatheterInPolicy {
    CVC= 'Q12.a',
    PICC= 'Q12.b',
    IMPLANTED= 'Q12.c',
    DIALYSIS= 'Q12.d',
    MIDLINE= 'Q12.e',
    ARTERIAL= 'Q12.f',
    PIV= 'Q12.g',
    OTHER= 'Q12.h'    
}

const TWENTY_ONE_DAY_POLICY_ID: number = 1;
// TODO: see if there's a better / more consistent way to handle this identification
const COMPREHENSIVE_POLICY_ID: number = 2;

let tabcount=20;

@Component({
  selector: 'app-data-entry-add',
  templateUrl: './data-entry-add.component.html',
  styleUrls: ['./data-entry-add.component.scss'],
  animations: [
      trigger('opacityFade', [
          transition(':enter', [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))]),
          transition(':leave', [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))])
      ])
  ]

})
export class DataEntryAddComponent extends SimpleModalComponent<ICreateAndEditDataEntryModalDialog, ICreateAndEditDataEntryModalDialogResult>
implements OnInit {

  @ViewChild('formContainer') public formContainer: NgForm;
  @ViewChild('formElement') public formElement: ElementRef;
  @ViewChildren('questionElement') public questionElements: QueryList<ElementRef>;
  @ViewChild('patientSelect') public patientSelect: NgControl;
  @ViewChild('catheterSelect') public catheterSelect: NgControl;

  public mode: CreateAndEditDataEntryModalDialogMode;
  public dataEntryStart: boolean = true;
  public dataEntryNext: boolean = true;
 // public existingPoliciesOfFacility: Policy[];
  public shift: string;
  public unit: Unit;
  public selectedDate: IMyDateModel; 
  //public selectedDate:Date;
  public policyTypes?: PolicyType[]; 
  public auditForGroups?: Audit;
  public audits:Audit[]=[];
  public policyType?: PolicyType;
  public currentGroupIndex: number = 0;
  public patients : Patient[]=[];
  public patientAnswer : PatientAnswer[];
  public policyID: number = 282;
  public selectedPatient:Patient;
  public patientJson:Patient;
  public catheters: PatientCatheter[];
  private _activeAuditIndex: number = undefined;
  private _activeQuestionGroupIndex: number = 0;
  public policy: Policy;

  public auditSelectorOptions: AuditSelectorOption[];
  public auditCatheterSelectorOptions: AuditSelectorOption[];
  public activeAuditSelectorOption: AuditSelectorOption;
  public catheterAnswerMap: Map<number, PatientAnswer>;

  public existingPatientAnswer:any;
  public locations:any; 
  public catheterlocations:any=null;
  public formatedDate:string;
  public questionAnswer:QuestionAnswer[]=[];

  private readonly assert = new AssertInClass('DataEntryAddComponent');

  public shouldShowPatientList: boolean = true;
  public shouldShowCatheterList: boolean =false;
  public finishControlButton:  boolean =false;
  private readonly showErrorModalInsteadOfDisablingNextButton: boolean = false;

  public auditTargetFlag:  boolean =false; 
  public shouldShowSpinner: boolean=false;
  public enableLocationList:boolean=false;
  //private _policyTypes: Map<number, PolicyType[]> = new Map<number, PolicyType[]>();
  public patientAnswerTest : QuestionAnswer[]=[];
  public shouldBlurComponent: boolean = false;
  public activeGroupIndex: number = 0;
  public booleanCount: number = 0;
  public completeCount: number = -1;
  public questionGroupIndexMap: Map<number, QuestionGroupIndexValues>;
  //public questionGroupIndexMap: number[];
  public questionGroupIndexValues:QuestionGroupIndexValues[]=[];
  public startAuditNumber: number= 0;
  public endAuditNumber: number=5;
  public auditNumberArray: number[]=[0,1,2,3,4];
  public isNoAntimicrobialSelected:boolean=false;  
  public locationQuestion:Question;
  public policyPatientGroups:string[]=["Reference Info","Disinfecting Port Protectors","IV Tubing","Custom Metrics","Catheter","IV Dressings"];
  public policyCatheterGroups:string[]=["Catheter","IV Dressings"];

  ///Flag for new Catheter
  public CanAddNewCatheter: boolean = true;
  

  constructor(
    private dataService: DataService,
    private notificationService: NotificationService,
    private errorService: ErrorService,
    private appBlurService: AppBlurService,
    private policyTypeService: PolicyTypeService,
    private patientService:PatientService,
    private catheterService:CatheterService,
    private auditservice:AuditService,
    private patientAnswerService:PatientAnswerService,
    private catheterLocationService:CatheterLocationService,
    private simpleModalService: SimpleModalService,
    private tokenService:TokenService,
  ) {
    super();    

   }

  ngOnInit() {

  
     if(this.activeAudit==undefined ){
       
        this.shouldShowSpinner=true;
     }
    //console.log("Audit"+JSON.stringify(this.policy));
    
     var step:boolean = true;
     let testAudit= new Audit(this.policyType, this.policy);
     //for( let audit of this.audits){
        console.log("Audit"+JSON.stringify(this.policy)); 
        console.log("Audit Questions");
        if(step){
            for( let group of testAudit.questionGroups){

                for( let question of group.questions){

                    if(testAudit.shouldShowQuestion(question)){

                        console.log("Question:"+JSON.stringify(question.title));

                    }
            
                }

            }

        }
        step=false;
   // }

    this.startAudit();
    this.loadLocation();
    document.getElementById("divfooter").style.marginTop = "400px !important"
  }
   
    public startAudit(){

    this.formatedDate = this.selectedDate.date.year+"-"+this.selectedDate.date.month+"-"+this.selectedDate.date.day;
  
    this.patientAnswerService.getAllPatientAnswer(this.policy.id,this.unit.id,this.formatedDate,this.shift).subscribe(patientAnswer => {
           
           this.existingPatientAnswer= patientAnswer;
   
        
           if(patientAnswer.length == 0){
               console.log("Not Found any Record Starting new Audit");     
               if(this.policyType.id==1){
                    let i=1;   
                    while(i<6){
                        this.startNewPatient();
                        i++;
                   }
                   this.activeAuditIndex=0;
                   
               }else{
                this.startNewPatient();                
             
                
               }               
            
              
           }else{
                     
               this.notificationService.info('','Found existing audit for the current selection.');
               
               this.loadExistingAudits();
               this.activeAuditIndex=this.audits.length-1;
               this.updateAuditSelectorBasedOnCurrentState();
               console.log("Reminder:"+this.existingPatientAnswer.length%5);
               if(this.policyType.id==1&&this.existingPatientAnswer.length%5>0){
                    let i=1;   
                    while(i<(6-this.existingPatientAnswer.length%5)){
                        this.startNewPatient();
                        i++;
                    }
                }
              
           }

           this.shouldShowSpinner=false; 
                   
     }, err => {
         this.errorService.handleErrorStatusCode(err);
   });   




  }
  

    public toolTipsMsgForRemoveButton(): string{

        if(this.activeAuditSelectorOption.canDelete == false)
            return "All catheter audits with this patient will be deleted along with the Patient audit. ";
        return "Only Catheter Audit will be deleted and you have to press the End Audit Button to save the changes. ";
    }

    public loadLocation(){

        this.catheterLocationService.getCatheterLocationbyPolicyID(this.policy.id).subscribe(locations=>{
            
            this.locations=locations;   
          
         
        }, err => {
            this.errorService.handleErrorStatusCode(err);
        });
    }
    public disableRaw(auditIndex:number):boolean{
        for(let patient of this.patients){
            if(this.audits[auditIndex].patient==patient)
                return true;
        }       
        return false;
    }
 
    public onTwentyDayOneChallengeRemoveAudit(auditIndex:number){

        console.log(auditIndex);
        this.audits[auditIndex].patientAnswersMap.clear();
        this.audits[auditIndex].questionGroupToCommentMap.clear();
       /* if(this.audits[auditIndex].patient.identifier!=null){
            this.patientService.deletePatient(this.audits[auditIndex].patient.identifier).subscribe(patient=>{

               // console.log(JSON.stringify(patient));   
            
            }, err => {
                this.errorService.handleErrorStatusCode(err);
                return;
            });
        }*/
        //this.patients.splice(auditIndex,1);
    }


    public removeSelectedAudit(){
        
        let patient = this.activeAudit.patient;
        let auditTarget = this.activeAudit.auditTarget;
        if(auditTarget =="Patient"){ 

            if(patient.identifier!=null){
                this.patientService.deletePatient(patient.identifier).subscribe(patient=>{

                }, err => {
                    this.errorService.handleErrorStatusCode(err);
                    return;
                });
                
            }
            this.patients.splice(this.activePatientIndex,1);
    
            let patientAudits=this.audits.filter(audit=>audit.patient == patient);

            patientAudits.forEach(f => this.audits.splice(this.audits.findIndex(e => e.patient === f.patient),1));
    

                if(this.audits.length == 0){
           
                        this.startNewPatient();                               
                        this.completeCount=0;
                       
                }else{                   
                    this._activeAuditIndex=this.audits.length -1;            
                    //this.updateAuditSelectorBasedOnCurrentState();  
                    
                }       
        }else{

            this.audits.splice(this.activeAuditIndex, 1);        
            if(this.audits.length == 0){
                      
                this.startNewPatient();             
                this.completeCount=0;
             
            }else{
                this._activeAuditIndex=this.audits.length -1;                             
                //this.updateAuditSelectorBasedOnCurrentState();  
                        
            }

        }          
        this.activeQuestionGroupIndex=0; 
        this.updateAuditSelectorBasedOnCurrentState();
        this.notificationService.success('','Selected Audit has been removed Successfully.'); 
    
    }

    public getLocationList(name:string):any{
        
        if(this.locations == undefined)
            return undefined;
        let splitedName=name.split('-');       
           
        let arterialCatheter;
        if(splitedName[0].trim().indexOf("Arterial")>-1||splitedName[0].trim().indexOf("Art")>-1){
 
            return this.locations.filter(loc=>
                (arterialCatheter=loc.name.split(' '))[0].trim().indexOf(splitedName[0].trim()) > -1);
        }
         return this.locations.filter(loc=>loc.name.trim().indexOf(splitedName[0].trim()) > -1);
        
      
    }

  private getCommentFromAudit(auditComment:string):string{

    if(auditComment === "undefined")   {
     
        return "";
    }    
   
    else
    return auditComment;
  }

  public loadExistingAudits(){    

//console.log(JSON.stringify(this.existingPatientAnswer));
    for(let exPatAnswer of this.existingPatientAnswer){       

  
            const newPatient: Patient = new Patient({
                    answers: [],
                    timeShift: exPatAnswer.patient.timeShift,
                    policyId: this.policy.id,
                    unitId: this.unit.id,
                    auditDate: this.formatedDate,
                    identifier:exPatAnswer.patient.identifier,
                });

            let nextCatheterNumber: number;    
            var auditNew = new Audit(
                    this.policyType,
                    this.policy,
                    newPatient,
                    AuditTarget.Patient,
                    nextCatheterNumber
                    
                );

               
            
                
            const answersMap: Map<number, PatientAnswer> = new Map<number, PatientAnswer>();
            for(let p of exPatAnswer.patientAnswersMap){          
            
                auditNew.patientAnswersMap.set(p[0], p[1]);       

            }  
           
            this.patients.push(newPatient);
            if(exPatAnswer.catheterAnswersMap.length>0){

                for(let c of exPatAnswer.catheterAnswersMap[0]){

                    auditNew.patientAnswersMap.set(c[0], c[1]);  
                    if(c[1].questionId==353){
                        let valueFromUI:string=c[1].value;
                        console.log("Catheter Type Answer Map..");
                        if(valueFromUI.indexOf("no antimicrobial")>-1){
                            auditNew.isNoAntimicrobialSelected=true;
                            console.log("ValueFromUI:"+JSON.stringify(valueFromUI));
                        }else{
                            if(valueFromUI.indexOf("use antimicrobial")>-1){
                                auditNew.isNoAntimicrobialSelected=false;
                            }else{

                                if(this.isCatheterSelectedInPolicy(valueFromUI)==false){
                                    console.log("ValueFromUI:"+JSON.stringify(valueFromUI));                
                    
                                        auditNew.isNoAntimicrobialSelected=true;
                        
                                }else
                                    auditNew.isNoAntimicrobialSelected=false;
                            }
                            
                        }
                    }

                   
                }
                
                
            }
            //Comment mapping  
            let groupCount=0;      
            //auditNew.questionGroupToCommentMap.set(auditNew.questionGroups[0],exPatAnswer.patient.comment.split('\n')[0]);
            if(exPatAnswer.patient.comment !=null){
               // if(this.isComprehensiveAudit ){

                   /* if(auditNew.patientCatheterQuestionGroups.length > 1){
                    auditNew.questionGroupToCommentMap.set(this.questionGroupPages('Patient',auditNew)[0],this.getCommentFromAudit(exPatAnswer.patient.comment.split('||')[0]));
                    auditNew.questionGroupToCommentMap.set(this.questionGroupPages('Catheter',auditNew)[0],this.getCommentFromAudit(exPatAnswer.patient.comment.split('||')[1]));
                    }else{
                        
                        auditNew.questionGroupToCommentMap.set(auditNew.questionGroups[0],exPatAnswer.patient.comment.split('||')[0]);


                    }*/
                   
                    for(let name of this.policyPatientGroups){
                        let group=auditNew.questionGroups.filter(g=>g.title==name)[0];
                    //for(let group of auditNew.questionGroups){
                        auditNew.questionGroupToCommentMap.set(group,exPatAnswer.patient.comment.split('||')[groupCount]);
                        groupCount++;
             
                    }

               // }else{

                //    auditNew.questionGroupToCommentMap.set(auditNew.questionGroups[0],exPatAnswer.patient.comment.split('||')[0]);
                //}
               
            }
           /*  if(exPatAnswer.patient.catheters !=null && exPatAnswer.patient.catheters.length > 0 ){

                for(let cath of exPatAnswer.patient.catheters){

                    console.log(cath.comment);
                }
            }
            */
           

            this.insertNewAuditAndMakeActive(auditNew);

            if(exPatAnswer.catheterAnswersMap.length>1){
                let i = 0;
                for(let c of exPatAnswer.catheterAnswersMap){
                   i++;
                    if(i > 1){
                        nextCatheterNumber= i -1;
                        var auditNewCathType = new Audit(
                            this.policyType,
                            this.policy,
                            newPatient,
                            AuditTarget.Catheter,
                            nextCatheterNumber,
                            
                        );   
                        for(let cAns of c){

                            auditNewCathType.patientAnswersMap.set(cAns[0], cAns[1]);  
                        }                      
                        if(exPatAnswer.patient.comment !=null)
                        console.log("Index:"+i);
                        //if(this.audits[0].patientCatheterQuestionGroups.length > 1)
                        //auditNewCathType.questionGroupToCommentMap.set(this.questionGroupPages('Catheter',auditNewCathType)[0],this.getCommentFromAudit(exPatAnswer.patient.comment.split('||')[i]));
                        //else
                        //auditNewCathType.questionGroupToCommentMap.set(this.questionGroupPages('Catheter',auditNewCathType)[0],this.getCommentFromAudit(exPatAnswer.patient.comment.split('||')[i-1]));
                        for(let name of this.policyCatheterGroups){
                            let group=auditNewCathType.questionGroups.filter(g=>g.title==name)[0];
                        //for(let group of auditNewCathType.questionGroups){
                            // if(group.dependsOnCatheter){                            
                             auditNewCathType.questionGroupToCommentMap.set(group,exPatAnswer.patient.comment.split('||')[groupCount]);
                             groupCount++;
                            // }
     
                   
                         }
                        this.insertNewAuditAndMakeActive(auditNewCathType);
                    }                  

                }
                


            }
            
    }   
    if(!this.isComprehensiveAudit){
        this.activeAuditIndex= this.audits.length -1;

    }else{
        this.activeAuditIndex=0;
    }
    
    this.updateAuditSelectorBasedOnCurrentState();
    

  } 
 


  public ngAfterViewInit() {
    this.appBlurService.pushBlurCount();
  }

  public onSavePressed(){


  }

  public ngOnDestroy() {
      super.ngOnDestroy();
      this.appBlurService.popBlurCount();
  }
     // This is needed so that the template can access a defined type for some basic checking
  public getUserInterfaceTypeForString(untypedUserInterfaceType: keyof typeof UserInterfaceType): UserInterfaceType {
      return UserInterfaceType[untypedUserInterfaceType as keyof typeof UserInterfaceType];
  }
  public shouldShowNotApplicableOption(question: Question) {

    return question.answers.find(answer => answer.type === AnswerType.NOT_APPLICABLE);
   // return question.getFirstAnswerOfType(AnswerType.NOT_APPLICABLE);
  }
 
  public getFirstAnswerOfType(question: Question,answerType: AnswerType): QuestionAnswer {

    return question.answers.find(answer => answer.type === answerType);

  }



  public answerCodeforSelectedAnswer(selectValue:string,answers:any):string{
    const answer=answers.find(a=>a.label == selectValue);
    //console.log("Answer Code:"+answer.code);
    return answer.code;
  }

  public getFilteredQuestionGroupTitle(audit: Audit, questionGroup: IQuestionGroup): string {
    if (!questionGroup.dependsOnCatheter) {
          return questionGroup.title;
    } else {
          let catheterNumber: number = audit.catheterNumber;
          if (catheterNumber == null) {
              catheterNumber = 1;
          } else {
              catheterNumber += 1;
          }
          return `${questionGroup.title} ${catheterNumber}`;
          
    }
  }
  public getQuestionAnswerValueFromUserInterface(question: Question, questionAnswer: QuestionAnswer): string | number {
      const patientAnswer = this.activeAudit.patientAnswersMap.get(questionAnswer.id);
    
      if (patientAnswer == null) {
          return undefined;
      }
      const valueString = patientAnswer.value;
      if (valueString == undefined) {
          return undefined;
      }

      if (question.inputType === InputType.NUMBER) {
          const valueAsInteger = Number.parseInt(valueString);
          if (Number.isNaN(valueAsInteger)) {
              return undefined;
          } else {
              return valueAsInteger;
          }
      } else {
          return valueString;
      }
  }
  public getQuestionAnswerValueFromUserInterfaceByAudit(question: Question, questionAnswer: QuestionAnswer,auditIndex:number): string | number {
 
    const patientAnswer = this.audits[auditIndex].patientAnswersMap.get(questionAnswer.id);
  
    if (patientAnswer == null) {
        return undefined;
    }
    const valueString = patientAnswer.value;
    if (valueString == undefined) {
        return undefined;
    }

    if (question.inputType === InputType.NUMBER) {
        const valueAsInteger = Number.parseInt(valueString);
        if (Number.isNaN(valueAsInteger)) {
            return undefined;
        } else {
            return valueAsInteger;
        }
    } else {
       
        return valueString;
    }
}
  // TODO: This function is brittle but I (Jon) don't see a better way to do it given the data returned from the server after the changes making boolean questions like multiple choice questions
  // It returns the QuestionAnswer in the question.answers array that corresponds to the value argument
  public getQuestionAnswerFromQuestionOfBooleanValue(question: Question, value: boolean): QuestionAnswer {
      for (let questionAnswer of question.answers) {
          if (true === value) {
              if (questionAnswer.label === 'Yes') {
                  return questionAnswer;
              }
          } else {
              if (questionAnswer.label === 'No') {
                  return questionAnswer;
              }
          }
      }
      // TODO Remove workaround after server fixed: Some boolean questions have one answer of type boolean, like originally designed.
      for (let questionAnswer of question.answers) {
          if (questionAnswer.type === AnswerType.BOOLEAN) {
              return questionAnswer;
          }
      }

      const assert = this.assert.inFunction('getQuestionAnswerFromQuestionOfBooleanValue');
      assert.fail(`Question of id ${question.id} and code ${question.code} is set up on the server incorrectly and isn't structured like a boolean question.`);
  }

  public getAnswerOnCatherLocationSelection(questionID:number,label:string):QuestionAnswer|undefined{


    let catheterName=label.split(' ')[0];
    if(label!=null){
        //let temp:QuestionAnswer=this.activeAudit.questionGroups.filter(element=>element.seqNum==3)[0].questions.filter(question=>question.id==questionID)[0].answers.filter(ans=>ans.label.indexOf(catheterName.trim())> -1)[0];
        //console.log(catheterName+JSON.stringify(this.activeAudit.questionGroups.filter(element=>element.seqNum==3)[0].questions.filter(question=>question.id==questionID)[0].answers));
        let temp:QuestionAnswer=this.activeAudit.questionGroups.filter(element=>element.seqNum==5)[0].questions.filter(question=>question.id==questionID)[0].answers.filter(ans=>ans.label==label)[0];
        return temp;
    }
    return undefined;

  }
  public isAntimicrobialQuestion(question:Question):boolean{
            
        if((question.title.indexOf("ntimicrobial")>-1) && this.activeAudit.isNoAntimicrobialSelected){

            this.supplyQuestionAnswerFromUserInterface(question,this.answerForNoAntimicrobialQuestion(question)[0],this.getLabelFromQuestionAnswer(this.answerForNoAntimicrobialQuestion(question)[0]));
            return true;
        }
        else
        return false;

  }
  public answerForNoAntimicrobialQuestion(question:Question):QuestionAnswer[]{

    return question.answers.filter(a=>a.type == "NOT_APPLICABLE")
  }
  public isCatheterSelectedInPolicy(answerCode:string):boolean{   
    let code:string='0';
    switch (answerCode) {
        case 'CVC':
            code=selectedCatheterInPolicy.CVC;
            break;
        case 'PICC':
            code=selectedCatheterInPolicy.PICC;
            break;
        case 'Arterial':
            code=selectedCatheterInPolicy.ARTERIAL;
            break;
        case 'Dialysis':
            code=selectedCatheterInPolicy.DIALYSIS;
            break;
        case 'Implanted Port':
            code=selectedCatheterInPolicy.IMPLANTED;
            break;
        case 'PIV':
            code=selectedCatheterInPolicy.PIV;
            break;
        case 'Midline':
            code=selectedCatheterInPolicy.MIDLINE;
            break;
        case 'Other':
            code=selectedCatheterInPolicy.OTHER;
            break;
        default:
            console.assert(false, `Answer Value not matched}`);
    }
   // let policyQuestionAnswer:PolicyQuestionAnswer[]=this.policy.answers.filter(p=>p.answerValue="12Q.a");
    //console.log(code+"=>"+JSON.stringify(this.policy)+"Length:"+policyQuestionAnswer.length);
    for(let answer of this.policy.answers){
        if(answer.answerValue==code){
            //console.log(answer.answerValue);
            return true;
        }
    }  
        return false;
  }
  public getLocationQuestion(question:Question){
    if(question.id==354 && this.locationQuestion == null){
    
        this.locationQuestion=question;
    }
   
  
  }
  public supplyQuestionAnswerFromUserInterface(
      question: Question,
      questionAnswer: QuestionAnswer,
      valueFromUI: string,
  ) {
    
    //console.log(JSON.stringify(question));
    this.checkTextAreaLimit(valueFromUI);
   // console.log(JSON.stringify(questionAnswer));   
    const assert = this.assert.inFunction('supplyQuestionAnswerFromUserInterface');
  /*   if( questionAnswer== null || questionAnswer== undefined){
        console.log("Type is undefined");
    } */
    // Restrict to enter decimal values
    if (questionAnswer == null) {
        // This currently happens during initialization for sheet-select components and should just be a no-op
        return;
    }
    if(valueFromUI != null && questionAnswer !==undefined &&questionAnswer.type == 'NUMBER' && !valueFromUI.match('^-?[0-9]+$')){
       
        this.notificationService.error('','Decimal value is not allowed');
        this.removeAllAnswersRelatedToQuestionFromPatientAnswersMap(question);
        //valueFromUI=valueFromUI.split('.')[0];
        return;
              
    } 
            
     

      // An <input type="number"> html element will be sending a number but it converts it before it reaches this function
      if (valueFromUI != null) {
          assert.typeOf('string')(valueFromUI, 'valueFromUI');
      }
      let needToClearLocationValue = true;
      if(this.getFirstExistingQuestionAnswerInQuestion(question) == questionAnswer)
      needToClearLocationValue = false;


      this.removeAllAnswersRelatedToQuestionFromPatientAnswersMap(question); // We will add the new answer in at the end of this function.
     
      if(question.id == 353){     
         //Removing pre selected location value. 
        if(this.locationQuestion != null ){
          for(let locationAnswer of this.locationQuestion.answers){
            if(this.activeAudit.patientAnswersMap.has(locationAnswer.id) && needToClearLocationValue == true){
                console.log("Deleted:"+JSON.stringify(locationAnswer));
                this.activeAudit.patientAnswersMap.delete(locationAnswer.id);
            }
            
          } 
        } 
        
          if(valueFromUI.indexOf("no antimicrobial")>-1){
            this.activeAudit.isNoAntimicrobialSelected=true;
            //console.log("ValueFromUI:"+JSON.stringify(valueFromUI));
          }else{
            if(valueFromUI.indexOf("use antimicrobial")>-1){
              this.activeAudit.isNoAntimicrobialSelected=false;
            }else{

                if(this.isCatheterSelectedInPolicy(valueFromUI)==false){
                    //console.log("ValueFromUI:"+JSON.stringify(valueFromUI));                
    
                        this.activeAudit.isNoAntimicrobialSelected=true;
        
                }else
                    this.activeAudit.isNoAntimicrobialSelected=false;
            }
            
          }
          
        

        this.enableLocationList = true;     // console.log("this.enableLocationList:"+this.enableLocationList)+"question.id:"+question.id;    
        if(questionAnswer != undefined)
        this.getLocationOnCatheterSelection(questionAnswer);
         
      }

      
      if (valueFromUI != null) {
        if(valueFromUI == 'No')
        valueFromUI = 'false';
        if(valueFromUI == 'Yes')
        valueFromUI = 'true';

        const answer = new PatientAnswer({
            questionId: question.id,
            answerCode: questionAnswer.code,
            value: valueFromUI,
      
        });
        this.activeAudit.patientAnswersMap.set(questionAnswer.id, answer);
        this.checkForCatheterTypeAnswerMap(question);
        if(this.policyType.id==1){
            if(this.activeAuditIndex<this.audits.length-1)
            this.activeAuditIndex++;
        }
        
       // console.log(this.activeAudit.patientAnswersMap.get(questionAnswer.id));
      
        
/* 
        if(this.shouldShowPatientList != undefined && this.shouldShowPatientList == true){
          const answer = new PatientAnswer({
              questionId: question.id,
              answerCode: questionAnswer.code,
              value: valueFromUI,
              isPatientAnswer: this.shouldShowPatientList
          });
          this.activeAudit.patientAnswersMap.set(questionAnswer.id, answer);
        }else{
            const answerCath = new PatientAnswer({
              questionId: question.id,
              answerCode: questionAnswer.code,
              value: valueFromUI,
          });
          this.catheterAnswerMap.set(questionAnswer.id, answerCath);
          
        } */
      }
  }
  public supplyQuestionAnswerFromUserInterfaceByAudit(
        question: Question,
        questionAnswer: QuestionAnswer,
        valueFromUI: string,
        auditIndex:number
    

    ) {
        if(this.policyType.id==1){

            if(this.shouldDisableInputFor21DayChallenge(auditIndex)==false){
                this.removeAllAnswersRelatedToQuestionFromPatientAnswersMapByAudit(question,auditIndex); 
                let documentID:string=auditIndex.toString();
                console.log(documentID);
                //document.getElementById(documentID).checked = false;
                const eleTrue = document.getElementById(documentID+this.getQuestionAnswerFromQuestionOfBooleanValue(question, true).id) as HTMLInputElement;
                eleTrue.checked = false;
                const eleFalse = document.getElementById(documentID+this.getQuestionAnswerFromQuestionOfBooleanValue(question, false).id) as HTMLInputElement;
                eleFalse.checked = false;
                this.notificationService.error("","Please don't skip the row!!");               
                return;
            }
        }

       // console.log(auditIndex+"# We will add the new answer in at the end of this function");
        const assert = this.assert.inFunction('supplyQuestionAnswerFromUserInterface');
        
        if (questionAnswer == null) {
            // This currently happens during initialization for sheet-select components and should just be a no-op
            return;
        }

        // An <input type="number"> html element will be sending a number but it converts it before it reaches this function
        if (valueFromUI != null) {
            assert.typeOf('string')(valueFromUI, 'valueFromUI');
        }

        this.removeAllAnswersRelatedToQuestionFromPatientAnswersMapByAudit(question,auditIndex); // We will add the new answer in at the end of this function.

        if(question.id == 353){     
            //console.log(JSON.stringify(questionAnswer)+"::::"+JSON.stringify(question));
           // console.log(JSON.stringify(this.isCatheterSelectedInPolicy()));

        this.enableLocationList = true;     // console.log("this.enableLocationList:"+this.enableLocationList)+"question.id:"+question.id;    
            
        this.getLocationOnCatheterSelection(questionAnswer);
        
        }

        
        if (valueFromUI != null) {
        if(valueFromUI == 'No')
        valueFromUI = 'false';
        if(valueFromUI == 'Yes')
        valueFromUI = 'true';

        const answer = new PatientAnswer({
            questionId: question.id,
            answerCode: questionAnswer.code,
            value: valueFromUI,
        
        });
        //this.activeAudit.patientAnswersMap.set(questionAnswer.id, answer);
        this.audits[auditIndex].patientAnswersMap.set(questionAnswer.id, answer);
        // console.log(this.activeAudit.patientAnswersMap.get(questionAnswer.id));
   
        }
    }

  public getLocationOnCatheterSelection(questionAnswer:QuestionAnswer){

        this.catheterlocations =this.getLocationList(questionAnswer.label);
        if(this.catheterlocations == undefined)
            return;
    
        let filteredQuestion =this.activeAudit.questionGroups.filter(qg=>qg.seqNum==5)[0].questions.filter(q=>q.id==354)[0].answers;
        //console.log(JSON.stringify(filteredQuestion));
        let ans:QuestionAnswer[]=[];
        //this.questionAnswer=[];
        for(let l of this.catheterlocations[0].locations){
        
            if(filteredQuestion.filter(a=>(a.label==l.name)).length > 0){
                ans.push(filteredQuestion.filter(a=>(a.label==l.name))[0])

            }
        
        }       
        this.questionAnswer=[];     
        this.questionAnswer=ans;
    }

  private removeAllAnswersRelatedToQuestionFromPatientAnswersMap(question: Question) {
      for (let possibleAnswerOfQuestion of question.answers) {
          this.activeAudit.patientAnswersMap.delete(possibleAnswerOfQuestion.id);
      }
  }
  private removeAllAnswersRelatedToQuestionFromPatientAnswersMapByAudit(question: Question,auditIndex:number) {
        for (let possibleAnswerOfQuestion of question.answers) {
            this.audits[auditIndex].patientAnswersMap.delete(possibleAnswerOfQuestion.id);
        }
    }
  


  public getFirstExistingAnswerCodeInQuestion(question: Question): string {
      for (let answer of question.answers) {
          if (this.activeAudit.patientAnswersMap.has(answer.id)) {
              return answer.code;
          }
      }
      return undefined;
  }
  public isNotApplicableSelectedForQuestion(question: Question): boolean {

    
     if(this.activeAudit.patientAnswersMap.get(this.getFirstAnswerOfType(question,AnswerType.NOT_APPLICABLE).id) == undefined){
    //   console.log("IS not Applicable:"+JSON.stringify(this.activeAudit.patientAnswersMap.get(question.getFirstAnswerOfType(AnswerType.NOT_APPLICABLE).id)));
         return false;
     }else{
    //     console.log("IS not Applicable:"+JSON.stringify(this.activeAudit.patientAnswersMap.get(question.getFirstAnswerOfType(AnswerType.NOT_APPLICABLE).id)));
         return true;
     }
//return true;
    //  return this.activeAudit.patientAnswersMap.has(question.getFirstAnswerOfType(AnswerType.NOT_APPLICABLE).id);
    
  }
  public onClickSave(){

   //console.log(JSON.stringify(this.audits));
    this.submitAuditForPatient(this.selectedPatient,this.audits);
   // this.close();
  }
  public checkForCatheterTypeAnswerMap(question:Question):Boolean{
    if(question.id==354 && !this.activeAudit.isCatheterTypeSelected)  
        return true;
    else
        return false;
        
  }
  public getFirstExistingQuestionAnswerInQuestion(question: Question): QuestionAnswer | undefined {
     
      for (let answer of question.answers) {
          if (this.activeAudit.patientAnswersMap.has(answer.id)) {
              if(question.id==353){

                this.activeAudit.isCatheterTypeSelected = true;
              }
                         
                return answer;
          }
      }
      
      return undefined;
  }
  public getCatheterLocationOptions(question: Question):QuestionAnswer[] | undefined{    
     
      if(question.id ==353){
          let questionAnswer:QuestionAnswer[]=[];
          if(this.locations!=null){
            
            for(let c of this.locations){
                questionAnswer.push(this.getAnswerOnCatherLocationSelection(353,c.name))
              }
              return questionAnswer;
          }
          return;
          
      }
      if(question.id != 353 && this.enableLocationList){
        return this.questionAnswer;
      }
      if(question.id== 354 && !this.enableLocationList){
          return question.answers;
      }
      

  }
  public getFirstExistingQuestionAnswerInQuestionWithType(question: Question,questionType:string): QuestionAnswer | number {

        // console.log(questionType);
        
         for (let answer of question.answers) {
             if (this.activeAudit.patientAnswersMap.has(answer.id)) {
                        
                 return answer;
             }
         }
        this.booleanCount++;
        return this.booleanCount;
    }

  public getLabelFromQuestionAnswer(questionAnswer: QuestionAnswer): string {
      if (questionAnswer != null) {
          return questionAnswer.label;
      } else {
          return '';
      }
  }
  public integerToString(integer: number | undefined): string | undefined {
      if (integer == null) {
          return undefined;
      } else {
          return integer.toString();
      }
  }

  public patientCount = 1;
  private submitAuditForPatient(patient: Patient, allAudits: Audit[]) {
        // We have to fill in patient.answers and the catheter object inside of answers.

        const auditsForThisPatient = allAudits.filter(audit => audit.patient === patient);
        //const auditsForThisPatient = this.audits.filter(audit => audit.patient.id === patient.id);
     
        if (auditsForThisPatient == null || auditsForThisPatient.length < 1) {
            // There weren't any valid completed audits for this patient, so we don't submit anything.
           // console.log("here weren't any valid completed audits for this patient, so we don't submit anything.");
            //return;
        }

        let patientAnswers : PatientAnswer[] = [];
        let count:number=0;
        for (let audit of auditsForThisPatient) {
            let catheter: PatientCatheter;
            count++;
            if(audit.patientAnswersMap.size==0){
                console.log(count+":Not a valid audit.");
                continue;
            }
            console.log(count+":Not a valid audit:failed.");
            if (audit.doesAuditTypeHaveCatheterQuestions) {
                catheter = new PatientCatheter({
                    line: audit.getLineString(),
                    location: audit.getLocationString(),
                });    
            }
            
            const patientAnswersInAudit = Array.from(audit.patientAnswersMap.values());        

           // if(!this.isComprehensiveAudit){
             
            //    const comment = audit.questionGroupToCommentMap.get(audit.questionGroups[0]);
            //    patient.comment = comment;
            //}else{

                //for(let group of audit.patientCatheterQuestionGroups){
                //    const comment = audit.questionGroupToCommentMap.get(this.questionGroupPages(group,audit)[0]);  
                let policyGroupsName;
                if(audit.auditTarget=="Patient")
                    policyGroupsName=this.policyPatientGroups;
                else
                    policyGroupsName=this.policyCatheterGroups;

                for(let name of policyGroupsName){

                    let group=audit.questionGroups.filter(g=>g.title==name)[0];
                    const comment = audit.questionGroupToCommentMap.get(group);           
              
                    if (comment != null && comment.length > 0) {
                        if (typeof patient.comment === 'string') {
                            patient.comment += comment + '||';
                        }else{

                            patient.comment = comment + '||';
                        }
                    }else{
                        if (typeof patient.comment === 'string') {
                         patient.comment += '||';
                        }else{
                            patient.comment = '||';
                        }
                    }
    
    
                }
            //}

            

            //Creating list of catheter type questions
           // let cathGroup=[344,353,,354,355,356,357,358,359,360,361,372,105];            
            let cathGroup:number[]=[];
            for(let a of this.activeAudit.questionGroups){               
                if(a.dependsOnCatheter){
                   for(let q of a.questions){
                    cathGroup.push(q.id);
                   }
                }
            }
           // console.log(cathGroup);     
            for (let patientAnswer of patientAnswersInAudit) {                

                let flag:boolean=false;
                for(let i of cathGroup){
                    if(i== patientAnswer.questionId){
                     
                        flag=true;
                    }                  
                }
                if(flag == true){
                
                    patientAnswers.push(new PatientAnswer({
                        answerCode: patientAnswer.answerCode,
                        catheter,
                        questionId: patientAnswer.questionId,
                        value: patientAnswer.value,
                       
                    }));
                }else{
                    patientAnswers.push(new PatientAnswer({
                        answerCode: patientAnswer.answerCode,
                        
                        questionId: patientAnswer.questionId,
                        value: patientAnswer.value,
                       
                    }));

                }            }
        }

        patient.answers = patientAnswers;
        //console.log(JSON.stringify(patient));
        // OK everything should be filled in now. Send it off.
        //this.auditservice.createAuditEntity(patient);
   // if(this.patients[this.patientCount].comment !== null && this.patients[this.patientCount].comment !== undefined ){
        this.shouldShowSpinner=true;
        this.auditservice.createAuditEntity(patient).subscribe(p => {
            if (p) {
            //this.notificationService.success('', "The Audit was created successfully");
                
                this.result = { needsRefresh: true };
                if(this.patients.length > this.patientCount){
                 
                    this.submitAuditForPatient(this.patients[this.patientCount], this.audits);                    
                    this.patientCount++;
                }else{
                    this.shouldShowSpinner=false;
                    this.notificationService.success('', "The Audit was created successfully");
                    this.close();

                }
              
      
                
            }
        }, err => {
                this.errorService.handleErrorStatusCode(err);
                this.shouldShowSpinner=false;
            }
        );
   // }
        
    }
  

    
  public getCommentForQuestionGroup(questionGroup: QuestionGroup): string {
    return this.activeAudit.questionGroupToCommentMap.get(questionGroup);
   
    //return "";
  }
  public getCommentForQuestionGroupByAudit(questionGroup: QuestionGroup,auditIndex:number): string {
    return this.audits[auditIndex].questionGroupToCommentMap.get(questionGroup);
    //return "";
  }

  public setCommentForQuestionGroup(questionGroup: QuestionGroup, comment: string) {
     
        this.checkTextAreaLimit(comment);
      
        this.activeAudit.questionGroupToCommentMap.set(questionGroup, comment);
      
  }
  public setCommentForQuestionGroupByAudit(questionGroup: QuestionGroup, comment: string,auditIndex: number) {
        this.checkTextAreaLimit(comment);
        this.audits[auditIndex].questionGroupToCommentMap.set(questionGroup, comment);
    }




  private startNewPatient() {
      // TODO: (Jon) This is timeShift stuff is very clunky... sort it out elegantly after deadline.
      this.finishControlButton= false;
      let timeShiftString: 'DAY' | 'NIGHT' | 'BOTH';
      if (this.shift == TIME_SHIFT.DAY) {
          timeShiftString = 'DAY';
      } else {
          timeShiftString = 'NIGHT';
      }
      
      const newPatient: Patient = new Patient({
          answers: [],
          timeShift: timeShiftString,
          policyId: this.policy.id,
          unitId: this.unit.id,
          auditDate: this.formatedDate,
          //id:2
      });

      this.patients.push(newPatient);      
      
      this.startNewAuditWithPatient(newPatient, AuditTarget.Patient);      
      
  }

  private addCatheterToCurrentPatient() {
      this.startNewAuditWithPatient(this.activePatient, AuditTarget.Catheter);
      if((this.audits.length > this.endAuditNumber) && !this.isComprehensiveAudit){
            this.onNextTwentyOneDayPageClick();
        }

    ///Validation for new Catheter
    let index = this.getProgressBarTotalQuestionGroupCount('CurrentIndex');
    this.IsLastCatheter(index + 1);
      
  }
  private startNewAuditWithPatient(patient: Patient, auditTarget: AuditTarget): Audit {
      let nextCatheterNumber: number;      

      this.finishControlButton= false;
      
      if (auditTarget == AuditTarget.Catheter) {

          if (this.activeAudit != null && this.activeAudit.catheterNumber != null) {
              nextCatheterNumber = this.activeAudit.catheterNumber + 1;
          } else {
              nextCatheterNumber = 1;
          }
        
      }

      var auditNew = new Audit(
          this.policyType,
          this.policy,
          patient,
          auditTarget,
          nextCatheterNumber);          
         
        if(auditNew.questionGroups.length == 0){
            this.shouldShowSpinner=false;
            this.notificationService.error('','Not found any Question for selected policy');   
            this.close();
            return auditNew;
        }
      this.insertNewAuditAndMakeActive(auditNew);
      //console.log(JSON.stringify(this.policy));
      return auditNew;
  }
  private insertNewAuditAndMakeActive(newAudit: Audit) {
      // Put the audit right after the last audit of this patient.
      let lastAuditIndexOfPatient = -1;
      for (let index = this.audits.length - 1; index >= 0; index--) {
          if (this.audits[index].patient === newAudit.patient) {
              lastAuditIndexOfPatient = index;
              break;
          }
      }

      if (lastAuditIndexOfPatient === -1) {
          // This is a new patient, add to the end.          
          this.audits.push(newAudit);
          this.activeAuditIndex = this.audits.length - 1;
          this.activeQuestionGroupIndex = 0;
      } else {
          // This is an existing patient. Insert right after the last audit of that patient.         
          const newAuditIndex = lastAuditIndexOfPatient + 1;
      
          this.audits.splice(newAuditIndex, 0, newAudit);
         
          this.activeAuditIndex = newAuditIndex;
          this.activeQuestionGroupIndex =0;
        
      }
      this.updateAuditSelectorBasedOnCurrentState();
  }
  public updateAuditSelectorBasedOnCurrentState() {
      const assert = this.assert.inFunction('updateAuditSelectorBasedOnCurrent');
      this.buildAuditSelectorOptions();


      // Search for the same audit
      this.activeAuditSelectorOption = this.auditSelectorOptions.find(auditSelectorOption =>
          auditSelectorOption.auditIndex === this.activeAuditIndex
          && this.activeQuestionGroupIndex >= auditSelectorOption.minQuestionGroupIndex
          && this.activeQuestionGroupIndex <= auditSelectorOption.maxQuestionGroupIndex
      );
          
      this.shouldShowSpinner=false; 
      
  
      assert.notNull('this.activeAuditSelectorOption', this.activeAuditSelectorOption);
      
  }
  public buildAuditSelectorOptions() {
      console.log("Called");
      const assert = this.assert.inFunction('buildAuditSelectorOptions');
      let auditSelectorOptions: AuditSelectorOption[] = [];
      let currentPatient: Patient;
      let currentPatientNumber: number = 0;
      let currentCatheterNumber: number = 0;
      let auditIndex: number = 0;    
      
      // && this.auditTargetFlag == false

      for (let audit of this.audits) {
          if (currentPatient !== audit.patient ) {
              currentPatientNumber++;
              currentPatient = audit.patient;
              currentCatheterNumber = 0;
              auditSelectorOptions.push({
                  auditIndex,
                  minQuestionGroupIndex: 0,
                  maxQuestionGroupIndex: -1, // Compute in block below
                  isCatheter: false,
                  label: `Patient ${currentPatientNumber}`,
                  canDelete: false,
              });
          }
          if (true == audit.doesAuditTypeHaveCatheterQuestions) {
             
              currentCatheterNumber++;
             //const questionGroupIndex: number = audit.firstQuestionGroupIndexWithCatheterQuestions;
             const questionGroupIndex: number = (audit.patientCatheterQuestionGroups.length >1?1:0 );
              assert.condition(questionGroupIndex != null, `Audit has cather type questions but the audit.firstQuestionGroupIndexWithCatheterQuestions couldn't be calculated and is undefined`);
              auditSelectorOptions.push({
                  auditIndex,
                  minQuestionGroupIndex: questionGroupIndex,
                  maxQuestionGroupIndex: -1, // Compute in block below
                  isCatheter: true,
                  label: `- Patient ${currentPatientNumber} - Catheter ${currentCatheterNumber}`,
                  canDelete: currentCatheterNumber !== 1,
              }); 

          }
          auditIndex++;
      }

      // Figure out the maxQuestionGroupIndex for real based on what the next AuditSelectorOption is
       for (let index = 0; index < auditSelectorOptions.length; index++) {
          const isLastIndex: boolean = index >= auditSelectorOptions.length - 1;
          const lastQuestionGroupIndexOfThisAudit = this.audits[auditSelectorOptions[index].auditIndex].questionGroups.length - 1;   
          //const lastQuestionGroupIndexOfThisAudit = this.audits[auditSelectorOptions[index].auditIndex].patientCatheterQuestionGroups.length - 1;       
          if (isLastIndex) {
              auditSelectorOptions[index].maxQuestionGroupIndex = lastQuestionGroupIndexOfThisAudit;
          } else {
              const thisAuditSelectorOption = auditSelectorOptions[index];
              const nextAuditSelectorOption = auditSelectorOptions[index + 1];
              if (nextAuditSelectorOption.auditIndex != thisAuditSelectorOption.auditIndex) {
                  auditSelectorOptions[index].maxQuestionGroupIndex = lastQuestionGroupIndexOfThisAudit;
              } else {
                  auditSelectorOptions[index].maxQuestionGroupIndex = nextAuditSelectorOption.minQuestionGroupIndex - 1;
              }

          }
      }   
       
      this.auditSelectorOptions = auditSelectorOptions;
  }

  public get activeQuestionGroup(): QuestionGroup {
    if (this.activeAudit && this.activeQuestionGroupIndex < 0 || this.activeQuestionGroupIndex >= this.activeAudit.patientCatheterQuestionGroups.length) {
        return null;
    }
    return this.activeAudit.questionGroups[this.activeQuestionGroupIndex];
  }



  public get activeQuestionGroupIndex(): number {
      return this._activeQuestionGroupIndex;
  }

  public set activeQuestionGroupIndex(value: number) {
      this._activeQuestionGroupIndex = value;
      if (this.formElement != null) {
          // Only scroll to the top when there is already a form loaded, otherwise it'll just default there.
          this.formElement.nativeElement.scrollTop = 0;
      }
  }
  public get activeAudit(): Audit {
      /* if(this.activeAuditIndex >= this.audits.length){

        this.activeAuditIndex= this.audits.length -1;
      } */
      const assert = this.assert.inFunction('get activeAudit');

      if (this.activeAuditIndex != null) {
         
          assert.isIndexInArrayBounds('this.activeAuditIndex', this.activeAuditIndex, 'this.audits', this.audits);
          return this.audits[this.activeAuditIndex];
      } else {
          return undefined;
          //return this.audits[0];
          
      }
  }
  public get activeAuditIndex(): number {
      return this._activeAuditIndex;
  }

  public set activeAuditIndex(newValue: number) {
      this._activeAuditIndex = newValue;
  }

  public onNextQuestionGroupClicked() {
      
        const questionGroupCount: number = this.activeAudit.patientCatheterQuestionGroups.length;
        ///Validation for new Catheter
        let index = this.getProgressBarTotalQuestionGroupCount('CurrentIndex');
        this.IsLastCatheter(index + 1);

        if (this.activeAuditIndex == this.audits.length - 1 ){
            if(this.activeAudit.patientCatheterQuestionGroups.length -1 ==this.activeQuestionGroupIndex){
                // console.log(this.activeAudit.patientCatheterQuestionGroups.length,this.activeQuestionGroupIndex)
                 this.showFinishedAuditDialog();
            }
         }
        if (this.activeQuestionGroupIndex >= questionGroupCount - 1) {
             const wasValid = this.validateActiveAuditAndFocusInterfaceOnFirstError();
             
           
            if (wasValid) {
                // Is there another audit after this one and is it the same patient as this one?            
                if (
                    this.activeAuditIndex < this.audits.length - 1 &&
                    this.audits[this.activeAuditIndex + 1].patient === this.activePatient
                ) {
                    // If so, we want to load that one up.                   
                    this.activeQuestionGroupIndex = 0;
                    this.activeAuditIndex += 1;
                } else {
                    // Otherwise, we're at the end of the question groups and catheters for this patient
                    //if (this.activeAudit.doesAuditTypeHaveCatheterQuestions) {
                    if(this.activeAuditIndex < this.audits.length -1){
                            this.activeQuestionGroupIndex = 0;
                            this.activeAuditIndex += 1;
                       // this.showFinishedAuditDialog();
                     
                    }if (this.activeAuditIndex == this.audits.length - 1 ){
                       if(this.activeAudit.patientCatheterQuestionGroups.length -1 ==this.activeQuestionGroupIndex){
                            //console.log(this.activeAudit.patientCatheterQuestionGroups.length,this.activeQuestionGroupIndex)
                            this.showFinishedAuditDialog();
                       }
                    }
                     else {
                        //this.startNewPatient();          
                       //this.showFinishedAuditDialog();
                    }
                }
            } 
           

        } else {
             if (this.getTotalNumberOfErrorsUpToQuestionGroupIndex(this.activeQuestionGroupIndex) > 0) {
                this.validateActiveAuditAndFocusInterfaceOnFirstError(); 
            } else { 
                this.activeQuestionGroupIndex += 1;
            }
           
        }

        this.updateAuditSelectorBasedOnCurrentState();
    }
    private validateActiveAuditAndFocusInterfaceOnFirstError(doesUserAlreadyKnowAuditIsIncomplete: boolean = false): boolean {
        const { overallIndexOfFirstError, questionGroupIndex, erroredQuestion } = this.getQuestionLocationOfFirstError();

         if (overallIndexOfFirstError !== -1) {
            this.focusUserInterfaceOnQuestionWithLocation(overallIndexOfFirstError, questionGroupIndex);
            const errorTitle = doesUserAlreadyKnowAuditIsIncomplete ? `Here's what needs to be completed.` : 'Incomplete Group';
            const additionalErrorsString = this.getAdditionalErrorsString();
            
            //this.showError(errorTitle, `The question titled '${erroredQuestion.title}' does not have a valid answer. Please fix it before continuing. ${additionalErrorsString}Thanks!`);
            return false;
        } 
        return true;
    }

    
    private getAdditionalErrorsString(): string {
        const errorCount = this.getTotalNumberOfErrorsUpToQuestionGroupIndex(this.activeQuestionGroupIndex);

        if (errorCount < 2) {
            return '';
        } else {
            if (errorCount === 2) {
                return 'There is one additional error in this group, but that is the first one. '
            } else {
                return `There are ${errorCount - 1} additional errors in this group, but that is the first one. `;
            }
        }
    }
    private focusUserInterfaceOnQuestionWithLocation(overallIndexInForm, questionGroupIndex) {
        const assert = this.assert.inFunction('focusUserInterfaceOnQuestion');
        const questionElements: ElementRef[] = this.questionElements.toArray();
        assert.isIndexInArrayBounds('overallIndexOfFirstError', overallIndexInForm, 'this.questionElements', questionElements);
        assert.isIndexInArrayBounds('questionGroupIndex', questionGroupIndex, 'this.activeAudit.questionGroups', this.activeAudit.questionGroups);
        this.activeQuestionGroupIndex = questionGroupIndex;
        window.setTimeout(() => {
            questionElements[overallIndexInForm].nativeElement.scrollIntoView();
        }, 250); // If I don't delay the scrolling action, the element isn't there to scroll to. This is unideal but I'm not sure how to make it better properly
    }

    private getQuestionLocationOfFirstError(): { overallIndexOfFirstError: number, questionGroupIndex: number, erroredQuestion: Question } {
        let runningQuestionCount = 0;
        for (let questionGroupIndex = 0; questionGroupIndex < this.activeAudit.patientCatheterQuestionGroups.length; questionGroupIndex++) {
            const questionGroup = this.activeAudit.questionGroups[questionGroupIndex];
            for (let questionIndex = 0; questionIndex < questionGroup.questions.length; questionIndex++) {
                const question = questionGroup.questions[questionIndex];
                if (this.activeAudit.shouldShowQuestion(question)) {
                    runningQuestionCount++;
                    const answer = this.getFirstExistingAnswerInQuestion(question);
                    if (answer === undefined) {
                        return {
                            overallIndexOfFirstError: runningQuestionCount - 1,
                            questionGroupIndex,
                            erroredQuestion: question
                        };
                    }
                }
            }
        }
        return {
            overallIndexOfFirstError: -1,
            questionGroupIndex: undefined,
            erroredQuestion: undefined,
        };
    }

    public onPreviousQuestionGroupClicked() {
        const assert = this.assert.inFunction('onPreviousQuestionGroupClicked');
        ///Validation for new Catheter
        let index = this.getProgressBarTotalQuestionGroupCount('CurrentIndex')
        this.IsLastCatheter(index-1);

        if (this.activeQuestionGroupIndex === 0) {
            if (this.activeAuditIndex > 0) {
                this.activeAuditIndex--;
                this.activeQuestionGroupIndex = this.activeAudit.patientCatheterQuestionGroups.length - 1;
            } else {
                assert.fail(
                    'AuditPage#onPreviousQuestionGroupClicked called but the button that calls this should be disabled'
                );
                return;
            }
        } else {
            this.activeQuestionGroupIndex -= 1;
        }

        this.updateAuditSelectorBasedOnCurrentState();
    }

    public get isPreviousQuestionGroupButtonDisabled(): boolean {
        return this.activeAuditIndex === 0 && this.activeQuestionGroupIndex === 0;
    }
    private showFinishedAuditDialog() {
       
        this.finishControlButton= true;
        //console.log(this.finishControlButton);
    }
    
    private getTotalNumberOfErrorsUpToQuestionGroupIndex(index: number): number {
        let totalGroup = this.activeAudit.patientCatheterQuestionGroups.length;
        let errorCount: number = 0;
        let currentQuestionGroupIndex = 0;
        for (let groups of this.activeAudit.patientCatheterQuestionGroups) { 
            for (let questionGroup of this.questionGroupPages(groups)) {
                for (let question of questionGroup.questions) {
                    if (this.activeAudit.shouldShowQuestion(question)) {
                        const answer = this.getFirstExistingAnswerInQuestion(question);
                        if (answer === undefined) {
                            errorCount++;
                        }
                    }
                }
                
            }
            currentQuestionGroupIndex++;
                if (currentQuestionGroupIndex > index) {
                    return errorCount;
            }
        }    
        return errorCount;
    }

    public getFirstExistingAnswerInQuestion(question: Question): PatientAnswer | undefined {
        for (let answer of question.answers) {
            if (this.activeAudit.patientAnswersMap.has(answer.id)) {
                return this.activeAudit.patientAnswersMap.get(answer.id);
            }
        }
        return undefined;
    }

    private get activePatientIndex(): number {
        return this.patients.findIndex(patient => patient === this.activeAudit.patient);
    }
    
    public get activePatient(): Patient {
        const assert = this.assert.inFunction('get activePatient');
       
        assert.notNull('this.activeAudit', this.activeAudit);

        return this.activeAudit.patient;
    }

    public onAuditSelectorChanged(newActiveAuditSelectorOption: AuditSelectorOption) {
        console.log("Passed Value:"+JSON.stringify(newActiveAuditSelectorOption)+"Current Value:"+JSON.stringify(this.activeAuditSelectorOption));

        ///Validation for new Catheter
        let index = this.auditSelectorOptions.findIndex(item => item.label == newActiveAuditSelectorOption.label);
        this.IsLastCatheter(index);

        if (newActiveAuditSelectorOption != this.activeAuditSelectorOption) {
            this.activeAuditSelectorOption = newActiveAuditSelectorOption;

            this.activeAuditIndex = this.activeAuditSelectorOption.auditIndex;
            this.activeQuestionGroupIndex = this.activeAuditSelectorOption.minQuestionGroupIndex;
        }
        this.updateAuditSelectorBasedOnCurrentState();
        this.finishControlButton = false;
    }

    public attemptToFinishAuditAndClosePage(){

        this.submitAllValidAudits();
    }
    
    private submitAllValidAudits() {
        // Assume all audits that aren't activeAudit are valid because the UI ensures that.
        /* const { erroredQuestion } = this.getQuestionLocationOfFirstError();
        let validAudits = this.audits;
        if (erroredQuestion != undefined) {
            // If the current audit is invalid, filter it out.
            validAudits = this.audits.filter(audit => audit !== this.activeAudit);
        }

        for (let patient of this.patients) {
            this.submitAuditForPatient(patient, validAudits);
        } */
        let count:number=0;
        //for (let patient of this.patients) {
            var patient = this.patients[0];
           count++;
           if(!this.isComprehensiveAudit){
                
                for(let audit of this.audits){
                    if(audit.patientAnswersMap.size==0 || audit.patientAnswersMap == null){
                        let i = 0
                        for(let p of this.patients){

                            if(audit.patient == p){
                                if(audit.patient.identifier!=null){
                                    this.patientService.deletePatient(audit.patient.identifier).subscribe(patient=>{
                        
                                       // console.log(JSON.stringify(patient));   
                                    
                                    }, err => {
                                        this.errorService.handleErrorStatusCode(err);
                                        return;
                                    });
                                }
                                this.patients.splice(i,1);
                               
                            } else
                                i++;
                        }
                        
                      //  console.log(this.patients);
                     //   continue;
                    }//else                    
                        
                }

           }
           /*let auditsForThisPatient = this.audits.filter(audit => audit.patient === patient);
            if(this.policyType.id ==1 && auditsForThisPatient[0].patientAnswersMap.size==0){
                console.log("Not a Valid Audits:"+JSON.stringify(auditsForThisPatient.length))
                //continue;
            }else{*/

                this.submitAuditForPatient(patient, this.audits);
                
       
           // }
           
        //}
        //this.close();
       
    }
    /* public get shouldDisableNextOnEndOfAudit():boolean{
        if(this.audits.length -1 == this.activeAuditIndex){
            if(this.activeAudit.patientCatheterQuestionGroups.length -1 ==this.activeQuestionGroupIndex){

                console.log("Next Disabled");
                return true;
            }    
        }
        
    } */
    async delay(ms: number) {
        await new Promise(resolve => setTimeout(()=>resolve, ms)).then(()=>console.log("fired"));
    }
   /*  public delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    } */
    public get shouldDisableEndAudit():boolean{
        let index = this.getProgressBarTotalQuestionGroupCount('CurrentIndex');
        this.IsLastCatheter(index);

        if(this.policyType.id!=1){
          return  this.shouldDisableNextQuestionGroupButton;      

        }else{

            return false;
        }
        

    }
    public shouldDisableInputFor21DayChallenge(auditIndex:number):boolean{
        
        let errorCount: number=0;
     
       /*  while(count<(auditIndex-1)){
            if(this.getTotalNumberOfErrorsUpToQuestionGroupIndexByAudit(this.activeQuestionGroupIndex,this.audits[count]) > 0){
                console.log(this.getTotalNumberOfErrorsUpToQuestionGroupIndexByAudit(this.activeQuestionGroupIndex,this.audits[count]));
                errorCount=errorCount+1;
            }
            count=count+1;
        }  */
       // while(count<(auditIndex)){
       // console.log(auditIndex+":"+JSON.stringify(this.audits[auditIndex].patientAnswersMap));
        for(let i=0;i<auditIndex;i++){
            console.log(auditIndex+":"+JSON.stringify(this.audits[i].patientAnswersMap));
            if(this.audits[i].patientAnswersMap.size==0){

                errorCount++;
                console.log(errorCount);
                break;
            }
            
             /* for (let questionGroup of this.audits[i].questionGroups) {

                for (let question of questionGroup.questions) {
                    if (this.audits[i].shouldShowQuestion(question)) {
                        const answer = this.getFirstExistingAnswerInQuestion(question);
                        if (answer === undefined) {
                            errorCount++;
                            console.log(errorCount);
                        }
                    }
                }
            }  */
           // count=count+1;
        }
        
            if(errorCount>0)
                return false;
            else 
                return true;
        
      /*   if(this.getTotalNumberOfErrorsUpToQuestionGroupIndexByAudit(this.activeQuestionGroupIndex,this.audits[auditIndex]) > 0){
            console.log("Print True");
           return true;
        }else        
        return false;
 */
       
    }
    public get shouldDisableNextQuestionGroupButtonOnEndOfAudit(): boolean{
        if(!this.shouldDisableNextQuestionGroupButton && this.activeAuditIndex==this.audits.length -1){
            if(this.activeAudit.patientCatheterQuestionGroups.length>1 && this.activeQuestionGroupIndex ==1){
                return true;
            }else if(this.activeAudit.patientCatheterQuestionGroups.length<2 && this.activeQuestionGroupIndex ==0)
                return true;
            
        }else{
            return this.shouldDisableNextQuestionGroupButton;
        }
        
    }
    public get shouldDisableNextQuestionGroupButton(): boolean {
        if (this.showErrorModalInsteadOfDisablingNextButton) {
            console.log("Next:False");
            
            return false;
            
        }

        return this.getTotalNumberOfErrorsUpToQuestionGroupIndex(this.activeQuestionGroupIndex) > 0;
    }
    private getTotalNumberOfErrorsUpToQuestionGroupIndexByAudit(index: number,audit:Audit): number {  
           
            let errorCount: number = 0;
            let currentQuestionGroupIndex = 0;
            for (let groups of audit.patientCatheterQuestionGroups) { 
                for (let questionGroup of this.questionGroupPages(groups)) {
                    for (let question of questionGroup.questions) {
                        if (audit.shouldShowQuestion(question)) {
                            const answer = this.getFirstExistingAnswerInQuestion(question);
                            if (answer === undefined) {
                                errorCount++;
                            }
                        }
                    }
                    
                }
                currentQuestionGroupIndex++;
                    if (currentQuestionGroupIndex > index) {
                        return errorCount;
                }
            }    
            return errorCount;
     
    } 
    public onAddNewPatient(){
       
       // if (this.activePatientIndex >= this.patients.length - 1) {
          if (this.activeAuditIndex >= this.audits.length - 1) {
            if(this.policyType.id==1){
                let i=1;   
                while(i<6){
                    this.startNewPatient();
                    i++;
               }
               if(this.audits.length/5 >0){
                   this.activeAuditIndex=this.audits.length-5;
               }
               
           }else{
            this.startNewPatient();
           }          
            if((this.audits.length > this.endAuditNumber) && !this.isComprehensiveAudit){
                this.onNextTwentyOneDayPageClick();
            }
        } else {
          
            this.activeAuditIndex += 1;
            this.activeQuestionGroupIndex = 0;
        }

        ///Validation for new Catheter
        let index = this.getProgressBarTotalQuestionGroupCount('CurrentIndex');
        this.IsLastCatheter(index);
    }

    public onCancelClick(){

        this.finishControlButton = false;
    }

    public buttonSelected(styleSet){

        let myStyles;
        if(styleSet=='true') {
            myStyles='button-yes';
        }else if(styleSet=='false') {
            myStyles='button-no';
        }else if(styleSet=='N/A') {
            myStyles='button-na';
        }        
        return myStyles;
    }
     public buttonSelectedNotActiveAudit(styleSet){
         

        let myStyles;
        if(styleSet=='true') {
            myStyles='button-yes-not-active';
        }else if(styleSet=='false') {
            myStyles='button-no-not-active';
        }else if(styleSet=='N/A') {
            myStyles='button-na-not-active';
        }        
        return myStyles;
    } 

    public onClosePressed(): void {
       /* console.assert(
            this.formContainer != null,
            'CreateAndEditPolicyModalDialogComponent#onClosePressed - this.formContainer was null'
        );*/
        
      //  if (!this.formContainer.pristine) {
            this.shouldBlurComponent = true;

            this.simpleModalService
            .addModal(
                TwoChoiceModalDialogComponent,
                { ...UI_CONSTANTS.CLOSE_CONFIRMATION },
                {
                    animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                }
            )
            .subscribe(result => {
                this.shouldBlurComponent = false;
                if (result.didSelectDefaultChoice) {
                    this.close();
                }
            });
    
       // } else {
         //   this.close();
       // }
    }
    public onRemoveAudit(): void {

             this.shouldBlurComponent = true;
             let removeAuditCloseConfirmation = UI_CONSTANTS.REMOVE_AUDIT_CLOSE_CONFIRMATION;
             removeAuditCloseConfirmation.message = "";
             removeAuditCloseConfirmation.message =this.toolTipsMsgForRemoveButton()+removeAuditCloseConfirmation.message;
 
             this.simpleModalService
             .addModal(
                 TwoChoiceModalDialogComponent,
                 { ...removeAuditCloseConfirmation },
                 {
                     animationDuration: MODAL_DIALOG_FADE_DURATION_MS,
                 }
             )
             .subscribe(result => {
                 this.shouldBlurComponent = false;
                 if (result.didSelectDefaultChoice) {
                     this.removeSelectedAudit();
                 }
             });
  
     }
    public get isComprehensiveAudit(): boolean {
        if (this.policyType
            && this.policyType.id === COMPREHENSIVE_POLICY_ID
        ) {
          return true;
        }
    
        return false;
    }
    public setColumnStyleForPaperAudit(title: string): string {
        if (title === 'Catheter') {
        return '27%';
        }
    }

    public setPaddingLeftForPaperAudit(title: string): string {
        if (title === 'Catheter') {
        return '16%';
        }
    }  
    public setPaddingRightForPaperAudit(title: string): string {
        if (title === 'Catheter') {
          return '150px';
        }
    }

    public questionGroupPages(groups:string,audit?:Audit):QuestionGroup[]{
        if(audit!=null){

            if (groups == "Patient")
            return audit.patientGroup;    
            if (groups == "Catheter")
            return audit.catheterGroup; 
        }else{
            if (groups == "Patient")
            return this.activeAudit.patientGroup;    
            if (groups == "Catheter")
            return this.activeAudit.catheterGroup; 
        }
        
    }

    public getProgressBarTotalQuestionGroupCount(valueType:string): number {
        
        let audits: Audit[]=this.audits;
        let questionGroupTotal: number = 0;

        
        if(valueType=="TotalCount"){
            questionGroupTotal = this.totalGroupCountForSelectedAudits(audits);
            //console.log("TotalCount:",questionGroupTotal);  
            return questionGroupTotal;
        }
        if(valueType=="CurrentIndex" || valueType == "CompletedCount"){
            if(this.activeAuditIndex<audits.length -1){
                questionGroupTotal = this.totalGroupCountForSelectedAudits(audits.slice(0,this.activeAuditIndex+1));
            }else{
                questionGroupTotal = this.totalGroupCountForSelectedAudits(audits);
            }
        
            if(valueType=="CurrentIndex"){ 
                //console.log("CurrentIndex:",this.totalGroupCountForSelectedAudits([this.activeAudit]),this.activeQuestionGroupIndex >0 ?questionGroupTotal -1:(this.activeAudit.auditTarget=='PatientCatheter'?questionGroupTotal -1:this.totalGroupCountForSelectedAudits([this.activeAudit])>1?questionGroupTotal -2:questionGroupTotal -1))   
                return this.activeQuestionGroupIndex >0 ?questionGroupTotal -1:(this.activeAudit.auditTarget=='PatientCatheter'?questionGroupTotal -1:this.totalGroupCountForSelectedAudits([this.activeAudit])>1?questionGroupTotal -2:questionGroupTotal -1);
            }
            if(valueType=="CompletedCount"){
                let completeCount=this.activeQuestionGroupIndex >0 ?questionGroupTotal -1:(this.activeAudit.auditTarget=='PatientCatheter'?questionGroupTotal -1:this.totalGroupCountForSelectedAudits([this.activeAudit])>1?questionGroupTotal -2:questionGroupTotal -1);
                
                completeCount =this.shouldDisableNextQuestionGroupButton?completeCount -1:completeCount;
                if(completeCount > this.completeCount){
                    this.completeCount=completeCount;

                }
               // console.log("CompletedCount:",this.completeCount);
                return this.completeCount;
            }
        }
       
    }
    public totalGroupCountForSelectedAudits(audits:Audit[]):number{

        let questionGroupTotal: number = 0;        
        
        for (let audit of audits) {
          
            let patientGroupType:boolean=false;
            let cathetertGroupType:boolean=false;
            
            for(let group of audit.questionGroups){
                if(group.dependsOnCatheter){
                    cathetertGroupType = true;
                } 
                if (!group.dependsOnCatheter){
                    patientGroupType = true;

                }
            }            
            if(cathetertGroupType == true){
                questionGroupTotal ++;  
              
            }
            if(patientGroupType == true){
                questionGroupTotal ++;
         
            }
        }

        return questionGroupTotal;

    }
    public get getQuestiongroupIndex():QuestionGroupIndexValues[]{

        let questionGroupTotal: number = 0;    
        let auditCount:number = 0;
        this.questionGroupIndexValues=[];
        
        for (let audit of this.audits) {
            
            auditCount ++;
            let groupIndex:number= 0;
            let patientGroupType:boolean=false;
            let cathetertGroupType:boolean=false;
            
            for(let group of audit.questionGroups){
                if(group.dependsOnCatheter){
                    cathetertGroupType = true;
                } 
                if (!group.dependsOnCatheter){
                    patientGroupType = true;
                }
            }            
            if(cathetertGroupType == true){
                questionGroupTotal ++;  
                groupIndex ++;
                
                this.questionGroupIndexValues.push({questionGroupCount:questionGroupTotal,auditCount:auditCount,quesitonGroupIndex:groupIndex});
                
            }
            if(patientGroupType == true){
                questionGroupTotal ++;
                groupIndex ++;
                
                this.questionGroupIndexValues.push({questionGroupCount:questionGroupTotal,auditCount:auditCount,quesitonGroupIndex:groupIndex});                
      
            }
        }
        return this.questionGroupIndexValues

    }
    public onQuestionGroupBubbleClicked(selectedIndex:number){
        //console.log(selectedIndex);
        ///Validation for new Catheter
        this.IsLastCatheter(selectedIndex);

         if(this.getQuestiongroupIndex.length>0){

            for(let index of this.getQuestiongroupIndex){

                if(index.questionGroupCount == selectedIndex+1){
                    this.activeQuestionGroupIndex = index.quesitonGroupIndex -1;
                    this.activeAuditIndex = index.auditCount -1;
                    //console.log(this.activeQuestionGroupIndex,this.activeAuditIndex);
                    this.updateAuditSelectorBasedOnCurrentState();
                    break;
                }    
            }          
        
        } 

    }
    

    public catheterAuditSelectorOptions(auditSelectorOptions:AuditSelectorOption[]):AuditSelectorOption[]{
       
        return auditSelectorOptions.filter(list => true == list.isCatheter );

    }
    public patientAuditSelectorOptions(auditSelectorOptions:AuditSelectorOption[]):AuditSelectorOption[]{

        return auditSelectorOptions.filter(list => false == list.isCatheter );

    }

    public get disableCatheterSelection():boolean{
                   
        if (this.activeAudit.patientCatheterQuestionGroups.length > 1){
            if(this.activeQuestionGroupIndex == 0){
                return false;
            }
            else{

                return true;
            }
        }else{
            if(this.activeAudit.patientCatheterQuestionGroups[0] == "Patient")
            return false;
            if(this.activeAudit.patientCatheterQuestionGroups[0] == "Catheter")
            return true;
            
        }
        
    }
    public totalOfActiveQuestion(group:QuestionGroup):number{
        let count:number = 0;
        for(let question of group.questions){
            if(this.activeAudit.shouldShowQuestion(question)){
                count ++;
            }

        }
        return count;
        

    }
    public get lastAuditGroup():boolean{
        if(!this.isComprehensiveAudit){

            return true;
        }

        if(this.audits.length -1 == this.activeAuditIndex){
            
            if(this.activeAudit.patientCatheterQuestionGroups.length -1 ==this.activeQuestionGroupIndex){
                return true;
            }else false;
        }else false;
    }
    public get shouldDisableNextPatientOnEndOfAudit():boolean{

        if(this.isComprehensiveAudit){           
            if(this.audits.length -1 !== this.activeAuditIndex){   
                return true;
            }else false;            
        }else false;

    }

    public get activePatientSelectorOption():string{
              
        return "Patient "+(this.auditNumberArray[0]+1)+"-"+(this.auditNumberArray[0]+5);
    }
    public onPatientSelectorChanged(patientGroup){           
        
        for(let i=0;i<this.patientSelector.length;i++) {

            if(this.patientSelector[i] == patientGroup ){
                this.startAuditNumber=(i*5);
                this.endAuditNumber=(i*5+5);
                this.auditNumberArray=[];
                for(let i=this.startAuditNumber;i<this.endAuditNumber;i++) {
                    this.auditNumberArray.push(i);
                  }
            }
        }


    }
    public get patientSelector():string[]{

        let patientGroups:string[]=[];        
        if(this.audits.length>5){
            
            for(let i=0;i<this.audits.length/5;i++) {

                patientGroups[i]="Patient "+(i*5+1)+"-"+(i*5+5);

            }
        }else{
            patientGroups[0]="Patient 1-5";
        }
        return patientGroups;


    }
    public onPreviousTwentyOneDayPageClick(){        
        

        if(this.audits.length > 5){
            //let totalRow = this.startAuditNumber + 1;
            if(this.endAuditNumber > 5){
                //let extraAudit = this.startAuditNumber % 5;
                //this.startAuditNumber= this.startAuditNumber - extraAudit;
                //.endAuditNumber=this.startAuditNumber + 6;
                this.startAuditNumber = this.startAuditNumber -5;
                this.endAuditNumber = this.endAuditNumber -5;
                
                this.auditNumberArray=[];
                for(let i=this.startAuditNumber;i<this.endAuditNumber;i++) {
                    this.auditNumberArray.push(i);
                  }
            }
        }else{

            this.startAuditNumber = 0;
        }


    }
    public onNextTwentyOneDayPageClick(){
        
        console.log("Patient Selector"+JSON.stringify(this.patientSelector));
        if(this.audits.length > 5){
            if((this.audits.length ) > (this.startAuditNumber + 5)){

                this.startAuditNumber= this.startAuditNumber + 5;
                this.endAuditNumber= this.endAuditNumber + 5;
                this.auditNumberArray=[];
                for(let i=this.startAuditNumber;i<this.endAuditNumber;i++) {
                    this.auditNumberArray.push(i);
                  }
            }
        
        }
     


    }

    //Delete audits 

    public get labelOptionsForActiveAuditSelector(): LabelOption[] {
        return this.auditSelectorOptions.map(auditSelectorOption => {
            const labelOption: LabelOption = {
                indentPixels: auditSelectorOption.isCatheter ? 22 : 0, // TODO Move this magic UI spacing number to a more sensible place
                onDeletePressedAtIndex: auditSelectorOption.canDelete ?
                    (index) => {
                        this.onDeletePressedAtAuditSelectorOptionIndexPressed(index);
                     }
                     : undefined,
            };
            return labelOption;
        });
    }

    private onDeletePressedAtAuditSelectorOptionIndexPressed(auditSelectorOptionIndex: number) {
                
               
    }
    public supplyLabelForAuditSelectorCurrentSelection(auditSelectorOptionToFilter: AuditSelectorOption, array: AuditSelectorOption[]) {
        if (auditSelectorOptionToFilter.isCatheter && array != null) {
            const indexOfOption = array.findIndex(auditSelectorOption => auditSelectorOptionToFilter === auditSelectorOption);
            const assert = this.assert.inFunction('supplyLabelForAuditSelectorCurrentSelection');
            assert.condition(indexOfOption !== -1, `indexOfOption was not found and is ${indexOfOption}`);

            for (let index = indexOfOption; index >= 0; index--) {
                const auditSelectorOption = array[index];
                if (false == auditSelectorOption.isCatheter) {
                    return auditSelectorOption.label;
                }
            }

            assert.fail(`Couldn't find a non-catheter AuditSelectorOption before the one to be filtered. A patient is required to precede a catheter at some point.`);
        } else {
            return auditSelectorOptionToFilter.label;
        }
    } 
    public sortByValue(answers:QuestionAnswer[],inputType?: string ):QuestionAnswer[]{
       
        if(inputType!= null && inputType == 'Select')
            return answers.sort((a, b) => a.label < b.label ? -1 : 1);

        return answers.sort((a, b) => a.label > b.label ? -1 : 1);
        
    }
   
    public gettabindex(){
        tabcount++;
    }
    public getTextAreaName(name:string):string{
        var formInput="data-entry."+name;
        console.log(formInput);


        return formInput;
    }

    public checkTextAreaLimit(comment:string){

        if(comment.length == 100 ){
            console.log('Comment:'+comment+','+ 'Length:'+comment.length);
            alert("You reached the Max limit of 100.");
          }
    }

    ///Validation for new Catheter
    public IsLastCatheter(index: number){
        //console.log(this.auditSelectorOptions);
        //console.log("Index => " + index);
        let currentObject = this.auditSelectorOptions[index];
        if(!currentObject.isCatheter)
            this.CanAddNewCatheter = true;
        else
        {
            let label = currentObject.label;
            let index1 = label.indexOf('-');
            let index2 = label.indexOf('-',3);
            let patient = label.substring(index1, index2+1);
            //console.log(patient);
            let patientItems = this.auditSelectorOptions.filter(item => item.label.includes(patient));
            if(patientItems.length == 1 && currentObject.isCatheter)
                this.CanAddNewCatheter = false;
            else if(label === patientItems[patientItems.length-1].label)
                this.CanAddNewCatheter = false;
            else
                this.CanAddNewCatheter = true;
        }
    }


}
