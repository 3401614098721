import {CrudApiService} from "./common/crud.api.service";

import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TokenService} from "../service/token.service";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';

import { QuestionAnswer,IQuestionAnswer } from '../../shared/model/question-answer';

@Injectable()
export class CatheterLocationService extends CrudApiService<QuestionAnswer, IQuestionAnswer, Partial<QuestionAnswer>> {
    constructor(http: HttpClient, tokenService: TokenService) {
       // super(http, tokenService, "api/catheterlocation");
       super(http, tokenService, "api/catheterbypolicy");
    }

    protected assembleSingleInstance(_: any): QuestionAnswer {
        return new QuestionAnswer(_);
    }

    getCatheterLocationbyPolicyID(policyID: number): Observable<any> {

            //console.log(policyID);
            //console.log(this.get<any>(`/${policyID}`));
        return this.get<any>(`/${policyID}`);
    } 
     getCatheterlocations(): Observable<any> {
        //console.log( this.get<any>());
    return this.get<any>();
    } 
    /* getCatheterlocations(): Observable<Array<any>> {

          return this.get<Array<any>>().pipe(map(_ => {
            if (Array.isArray(_)) {
                return this.assembleInstances(_);
            } else {
                console.assert(false, 'CrudApiService: getAllEntities expected array from server');
            }
        }));
    } */
} 



