import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { TWO_CHOICE_MODAL_DIALOG_COMPONENT as UI_CONSTANTS } from '../../constants/uiConstants';
import { AppBlurService } from '../../service/app-blur.service';

import { ContactUs } from '../../model/contact-us';
import {ContactUsService} from "../../api";
import {NotificationService} from '../../service/notification.service';
import {ErrorService} from '../../service/error-service';

import {OnInit, ViewChild} from '@angular/core';
import {NgControl, NgForm} from '@angular/forms';

export interface ITwoChoiceModalDialogComponent {
    title: string;
    message: string;
    defaultChoiceLabel?: string;
    alternateChoiceLabel?: string;
    oneChoice?: string;
}

export interface ITwoChoiceModalDialogComponentResult {
    didSelectDefaultChoice: boolean;
    didSelectAlternateChoice: boolean;
}

@Component({
    selector: 'app-two-choice-modal-dialog',
    templateUrl: './two-choice-modal-dialog.component.html',
    styleUrls: ['./two-choice-modal-dialog.component.scss'],
})
export class TwoChoiceModalDialogComponent
    extends SimpleModalComponent<ITwoChoiceModalDialogComponent, ITwoChoiceModalDialogComponentResult>
    implements ITwoChoiceModalDialogComponent, AfterViewInit, OnDestroy {
    
    public title: string;
    public message: string;    
    public defaultChoiceLabel?: string;
    public alternateChoiceLabel?: string;
    public oneChoice?: string;
    public contactUs: ContactUs;
    public name: string;
    public email: string;
    public username: string; // For now using this as Subject input Variable
    public phone: number;
    public facility: string;
    public mode: string;
    public message2: string;  
    public submitted: boolean = false; 

    
    
   /* private appBlurService: AppBlurService;
    private contactUsService : ContactUsService;
    private notificationService: NotificationService;
    private errorService: ErrorService;*/

    @ViewChild('formContainer') public formContainer: NgForm;
    @ViewChild('nameInput') public nameInput: NgControl;
    @ViewChild('emailInput') public emailInput: NgControl;
    @ViewChild('modeInput') public modeInput: NgControl;
    @ViewChild('userNameInput') public userNameInput: NgControl; // For now using this as Subject input
    @ViewChild('phoneInput') public phoneInput: NgControl;
    @ViewChild('facilityInput') public facilityInput: NgControl;
    @ViewChild('messageInput') public messageInput: NgControl;
   

    constructor (
        
        private appBlurService: AppBlurService,
        private contactUsService : ContactUsService,
        private notificationService: NotificationService,
        private errorService: ErrorService,
        ) {
        super();
        this.appBlurService = appBlurService;
       
    }

    public ngAfterViewInit() {
            this.appBlurService.pushBlurCount();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.appBlurService.popBlurCount();
    }

    public get usedLabelForAlternateChoice(): string {
        if (undefined != this.alternateChoiceLabel) {
            return this.alternateChoiceLabel;
        } else {
            return UI_CONSTANTS.ALTERNATE_CHOICE_DEFAULT_LABEL;
        }
    }
    public get isContactUS():boolean{

          
        if(this.oneChoice == 'true'){
            
            return true;
        }
        return false;
    }

    public get usedLabelForDefaultChoice(): string {
        if (undefined != this.defaultChoiceLabel) {
            return this.defaultChoiceLabel;
        } else {
            return UI_CONSTANTS.DEFAULT_CHOICE_DEFAULT_LABEL;
        }
    }

    onDefaultChoicePressed() {
        this.result = {
            didSelectDefaultChoice: true,
            didSelectAlternateChoice: false,
        };
        this.close();
    }

    onAlternateChoicePressed() {
        this.result = {
            didSelectDefaultChoice: false,
            didSelectAlternateChoice: true,
        };
        this.close();
    }

    //New contact us functions

    public onSubmitPressed(): void {

        this.submitted = true;
 
        if (false == this.formContainer.form.valid) {
            console.log("Error check stating");
            this.handleFormErrors();
            return;
        } 
        this.contactUs=new ContactUs({
            name: this.name,
            email: this.email,
            userName: this.username,
            facility: this.facility,
            mode: this.mode,
            message: this.message2,
            
        });

        console.log(JSON.stringify(this.contactUs));
        this.contactUsService.createEntity(this.contactUs).subscribe(contactUs => {
            this.contactUs = contactUs;
            this.notificationService.success('', "Message has been sent successfully");
            //if (shouldCloseDialogOnSuccess) {
                //this.result = { needsRefresh: true };
                this.close();
          //  }
        }, err => {
           
                this.errorService.handleErrorStatusCode(err);
            
        });
    }

   /* public get hasSubmitted(): boolean {
        console.assert(this.formContainer != null, 'ContactUsComponent#hasSubmitted - this.formContainer was null');

        return this.formContainer.submitted;
    }*/
    public shouldControlShowErrorStyle(control: NgControl): boolean {
        console.assert(control != null, 'ContactUsComponent#shouldControlShowErrorStyle - control argument was null');

        if (!control.touched &&!this.submitted) {
            return false;
        }

        if (control.errors && control.invalid) {
           
            return true;
        }
        //this.handleFormErrors();
        return control.errors != null; // This will return true if the control is required and the input missing.
   
    }
    private handleFormErrors(): void {
        
        let areAllRequiredFieldsFilled: boolean = true;

        if(this.emailInput.invalid) {
            console.log("invalid Email address");
        }
       
      
        if (this.emailInput.errors && this.emailInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }
             
        if (this.nameInput.errors && this.nameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

     
        if (this.modeInput.errors && this.modeInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }

        if (this.facilityInput.errors && this.facilityInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }
        if (this.userNameInput.errors && this.userNameInput.errors.required) {
            areAllRequiredFieldsFilled = false;
        }
        if (this.messageInput.errors && this.messageInput.errors.required) {
            console.log("invalid messageInput");
            areAllRequiredFieldsFilled = false;
        }


        if (false === areAllRequiredFieldsFilled && this.emailInput.invalid){
            this.notificationService.error('', "All fields are required and Email address should be valid");
        }else if (this.emailInput.invalid) {
            this.notificationService.error('', "Email address should be valid");
        }else if(false === areAllRequiredFieldsFilled) {
            this.notificationService.error('', "All fields are required");
        }
    
        
    }
}
