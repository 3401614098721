import {NgModule} from '@angular/core';
import {Http} from '@angular/http';
import {TranslateLoader, TranslateModule, TranslateStaticLoader} from 'ng2-translate';
import {RouterModule} from '@angular/router';
import {UserAddModule} from './user-add/user-add.module';
import {UserListModule} from './user-list/user-list.module';
import {UserDetailModule} from './user-detail/user-detail.module';
// services
import {UserRoutes} from './user.route';
import {UserAddComponent} from './user-add/user-add.component';
import {SaveFormsGuard} from '../shared/guard/save-forms.guard';

export function translateLoader(http: Http): TranslateStaticLoader {
    return new TranslateStaticLoader(http, '/assets/i18n', '.json');
}

export function saveFormsGuardHttpFactory(): SaveFormsGuard<UserAddComponent> {
    return new SaveFormsGuard<UserAddComponent>();
}

@NgModule({
    declarations: [],
    imports: [
        UserAddModule,
        UserListModule,
        UserDetailModule,
        RouterModule.forRoot(UserRoutes, { useHash: true }),
        TranslateModule.forRoot({
            provide: TranslateLoader,
            useFactory: translateLoader,
            deps: [Http],
        }),
    ],
    providers: [{ provide: 'SaveFormsGuardComponent', useFactory: saveFormsGuardHttpFactory }],
})
export class UserModule {}
