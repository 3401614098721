import { Question } from "./question";
import {sortByQuestionCode} from "../../util/alphaNumericSort";

export interface IQuestionGroup {
    id: number;
    title: string;
    dependsOnCatheter: boolean;
    seqNum: number;
}

export class QuestionGroup {
    id: number;
    title: string;
    dependsOnCatheter: boolean;
    private _questions: Question[];
    seqNum: number;

    constructor(args: IQuestionGroup, questions: Question[]) {
        console.assert(args !== undefined && args !== null, 'QuestionGroup constructor passed null or undefined args object');
        console.assert(typeof args.title === 'string', 'QuestionGroup constructor passed args with invalid title');
        console.assert(typeof args.dependsOnCatheter === 'boolean', 'QuestionGroup constructor passed args with invalid dependsOnCatheter prop');
        console.assert(typeof args.seqNum === 'number', 'QuestionGroup constructor passed args with invalid seqNum prop');
        console.assert(Array.isArray(questions), 'QuestionGroup constructor passed args with invalid questions array');

        this.id = args.id;
        this.title = args.title;
        this.dependsOnCatheter = args.dependsOnCatheter;
        this.seqNum = args.seqNum;
        this._questions = questions;
    }

    public get questions(): Question[] {
       // return sortByQuestionCode(this._questions);
        if(this.id = 1){
            return this._questions;
        }
        else{
            return sortByQuestionCode(this._questions);
        }   
    }
}

// FIXME: this is a workaround because we don't have an API route for this data,
// it can be safely hard coded for now, but it would be better for it to be data driven.
export const QuestionGroupsMap: IQuestionGroup[] = [
    {
        id: 1,
        title: 'Catheter',
        dependsOnCatheter: true,
        seqNum: 5,
    },
    {
        id: 2,
        title: 'IV Dressings',
        dependsOnCatheter: true,
        seqNum: 6,
    },
    {
        id: 3,
        title: 'IV Tubing',
        dependsOnCatheter: false,
        seqNum: 3,
    },
    {
        id: 4,
        title: 'Disinfecting Port Protectors',
        dependsOnCatheter: false,
        seqNum: 2,
    },
    {
        id: 5,
        title: 'Comments',
        dependsOnCatheter: false,
        seqNum: 6,
    },   
    {
        id: 6,
        title: 'Reference Info',
        dependsOnCatheter: false,
        seqNum: 1,
    },
    {
        id: 7,
        title: 'Custom Metrics',
        dependsOnCatheter: false,
        seqNum: 4,
    },
];