
export interface IPolicySettings {
    id?: number;
    minGreenRate: number;
    minYellowRate: number;
}

export class PolicySettings implements IPolicySettings {
    id?: number;
    minGreenRate: number;
    minYellowRate: number;

    constructor(args: IPolicySettings) {
      console.assert(args !== undefined && args !== null, 'PolicySettings constructor passed null or undefined args object');
      if (args.id !== undefined && args.id !== null) {
        console.assert(typeof args.id === 'number', 'PolicySettings constructor passed args with invalid value');
      }
      console.assert(typeof args.minGreenRate === 'number', 'PolicySettings constructor passed args with invalid value');
      console.assert(typeof args.minYellowRate === 'number', 'PolicySettings constructor passed args with invalid value');

      this.id = args.id || undefined;
      this.minGreenRate = args.minGreenRate;
      this.minYellowRate = args.minYellowRate;
    }
}
