import {Injectable} from '@angular/core';
import {DATEFORMAT} from '../../constants/dateFormat';
import {ConstantService} from '../constant-service';
import {ReportContext} from "./report.context";

declare var require: any;
declare interface PdfMake {
    vfs: any;
}

const pdfmake: PdfMake = require('pdfmake/build/pdfmake');
const vfsFonts = require('pdfmake/build/vfs_fonts');

pdfmake.vfs = vfsFonts.pdfMake.vfs;

@Injectable()
export class PdfService {

  constructor(private constantService: ConstantService) { }

    /**
     * TODO: Fix Pdf version exporting
     * @param {ReportContext} context
     * @param {string} fileName
     */
  public exportToPdf(context: ReportContext, fileName: string) {
    // pdfMake.createPdf(this.buildPdf(jsonData, columnArray, reportParameters, true)).download(fileName + ".pdf");
  }

  private table(data, columns) {
    return {
      table: {
        headerRows: 1,
        style: 'tblheader',
        body: this.buildTableBody(data, columns)
      }
    };
  }

  private buildTableBody(data, columns): Array<Array<any>> {
    const body = [];
    body.push(columns);

    data.forEach(function (row) {
      const dataRow = [];
      columns.forEach(function (column) {
        dataRow.push(row[column] ? row[column] : '');
      });
      body.push(dataRow);
    });
    return body;
  }

  private buildPdf(context: ReportContext) {

    const docDefinition = {
      pageSize: 'A3',
      pageOrientation: 'landscape',
      pageMargins: [40, 60, 40, 60],
      content: [
        {
          width: 130,
          text: '',
          style: 'leftSideContent'
        }
        , {
          width: 130,
          text: context.reportType.title,
          style: 'centerSideContent'
        },
        {
          width: 130,
          text: 'Facility: ' + context.facility.name,
          style: 'leftSideWithMarginContent'
        },
        {
          width: 130,
          text: 'Report: ' + context.reportType.title,
          style: 'leftSideWithMarginContent'
        },
        {
          width: 130,
          text: 'Shift: ' + context.timeShift,
          style: 'leftSideWithMarginContent'
        },
        {
          width: 130,
            //TODO: unclear what is that type
          text: 'Type : ' + '',
          style: 'leftSideWithMarginContent'
        },
        {
          width: 130,
            //TODO: include policy type info to context
          text: 'Policy : ' + '',
          style: 'leftSideWithMarginContent'
        },
        {
          width: 130,
          text: 'Created Date: ' + this.constantService.getDateInFormat(new Date(), DATEFORMAT.MMM_DD_YYYY),
          style: 'rightSideContent'
        },
        {text: '', style: 'header', alignment: 'center'},
          //TODO: fix table data
        // this.table(value, columnArray),
       /* {
          width: 130,
          text: (reportParameters['dashMetricSecondLastLine']) ? reportParameters['dashMetricSecondLastLine'] : '',
          style: 'leftSideContent'
        },
        {
          width: 130,
          text: (reportParameters['dashMetricLastLine']) ? reportParameters['dashMetricLastLine'] : '',
          style: 'leftSideContent'
        },*/
      ],
      footer: function (pagenumber, pagecount) {
        return {
          alignment: 'center',
          text: 'Page ' + pagenumber,
          fontSize: 10,
          fontColor: '#808080'
        };
      },
      styles: {
        tblheader: {
          bold: true,
          alignment: 'center',
          fillColor: '#4682B4'
        },
        header: {
          fontSize: 20,
          bold: true,
          color: 'green',
          margin: [0, 20, 0, 8]
        },
        rightSideContent: {
          fontSize: 12,
          bold: true,
          alignment: 'right',
          color: '#696969',
          margin: [0, 0, 0, 0]
        },
        centerSideContent: {
          fontSize: 20,
          bold: true,
          alignment: 'center',
          color: '#00B432',
          margin: [0, 0, 0, 10]
        },
        leftSideContent: {
          fontSize: 12,
          bold: true,
          alignment: 'left',
          color: '#696969',
          margin: [0, 5, 0, 0]
        },
        leftSideWithMarginContent: {
          fontSize: 14,
          bold: true,
          // alignment: 'left',
          color: '#AAE600',
          margin: [60, 5, 0, 0]
        }
      }
    };

    return docDefinition;
  }

}
