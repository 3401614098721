import { Pipe, PipeTransform } from '@angular/core';
import {Unit} from "../shared/model/unit";

@Pipe({
    name: 'FilterPipe',
})

export class FilterPipe implements PipeTransform {
    transform(list: Unit[], input: string) {
        if (input) {
            input = input.toLowerCase();
            return list.filter(unit => {
                return unit.name.toLowerCase().indexOf(input) != -1;
            });
        }
        return list;
    }
  }