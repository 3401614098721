export const CommonEvent = {
  login: 'loginSuccessfull',
  add: 'add',
  modify: 'modify',
  print: 'print',
  ppt: 'ppt',
  excel: 'excel',
  archive: 'archive',
  printPolicy: 'printPolicy',
  printPaperAudit: 'audit-questions'
};
// TODO: NL - make this a string enum for better type checking