// TODO: NL - general proof reading and cleanup, remove unused keys
export const MESSAGES = {
  ERROR: {
    HEADER: {
      ERROR_MESSAGE: 'Error Message',
      NO_INPUT_SELECTED: 'No inputs selected'
    },
    NO_INPUT_SELECTED: 'Please select input questions to create Audit-policy',
    SOMETHING_WRONG: 'Something went wrong, please try again',
    USER_EXPIRED: 'User expired, please login again',
    PLEASE_LOGIN: 'Please Login',
    LOGOUT_TO_LEAVE_PAGE: 'Logout to leave the page',
    USER_CAN_NOT_DELETED: 'User can not be deleted, please contact admin',
    USER_CAN_NOT_ACTIVATED: 'User can not be activated, please contact admin',
    USER_CAN_NOT_DEACTIVATED: 'User can not be deactivated, please contact admin',
    USER_CAN_NOT_COPIED: 'User can not be copied, please contact admin',
    FACILITY_CAN_NOT_DELETE: 'Facility can not be deleted, please contact admin',
    FACILITY_CAN_NOT_DEACTIVATED: 'Facility can not be deactivated, please contact admin',
    FACILITY_CAN_NOT_ACTIVATED: ' Facility can not be activated, please contact admin',
    NO_FACILITY_SELECTED: 'No Facility selected',
    USER_NOT_EXIST: 'User Does Not Exists',
    CONFLICTING_RECORD: 'There is already a record matching these details in the database.',
    UNIQUE_SAP_CONSTRAINT: 'The SAP# is already in use',
    SERVER_ERROR: 'Server Error !!',
    INTERNAL_SERVER_ERROR: 'Something went wrong, please try again',
    FORGOT_PASSWORD_INITIATED: 'Forgot Password Initiated',
    WRONG_USERNAME_PASSWORD: 'Wrong Username or Password',
    ACTIVATE_ACCOUNT: 'Please Activate Account',
    ACCOUNT_LOCKED: 'Account is Locked',
    ACCOUNT_EXPIRED: 'Account is Expired',
    ERROR_CONTACT_ADMIN: 'Error Contact Admin',
    AN_ERROR_OCCURRED: 'An Error occurred, please try again',
    ACCESS_DENIED: 'Access denied',
    INPUT_UNIT: 'Please enter unit',
    SELECT_LOCATION: 'Please select a facility.',
    EXIST_LOCATION: 'Facility already exists.',
    ACCESS_DENIED_USER: 'Access denied for this user.',
    TOKEN_NOT_VALID: 'The token has either expired or is invalid',
    PASSWORD_MISMATCH:'Password and Confirm password did not Match',
    PASSWORD_VALIDATION: 'Password should be minimum 8 character.  \n Password must include 1 Capital letter , 1 special character and 1 number.',
    USER_INVALID_CREDENTIAL:'Invalid Credentials'
  },
  SUCCESS: {
    HEADER: {
      SUCCESS_MESSAGE: 'Success Message',
      FACILITY_DELETED: 'Facility deleted',
      FACILITY_MODIFIED: 'Facility modified',
      FACILITY_ADDED: 'Facility created',
      USER_DELETED: 'User deleted',
      USER_DEACTIVATED: 'User deactivated',
      USER_ACTIVATED: 'User activated',
      USER_ADDED: 'User created',
      USER_MODIFIED: 'User modified',
      USER_COPIED: 'User copied',
      LOCATION_MODIFIED: 'Facility modified',
      LOCATION_ADDED: 'Facility created',
      POLICY_CREATED: 'Policy created',
      AUDIT_POLICY_SAVED: 'Audit policy saved',
      AUDIT_POLICY_MODIFEID: 'Audit policy modified',
      RESET_PASSWORD: 'Password reset',
      FACILITY_LINKED: 'Facility linked',
      FACILITY_ALREADY_CHILD: 'Facility is already child',
      UNIT_CREATED: 'Unit created',
      UNIT_MODIFIED: 'Unit modified'
    },
    FACILITY_DELETED: 'Facility has been deleted successfully',
    FACILITY_DEACTIVATED: 'Facility has been deactivated successfully',
    FACILITY_ACTIVATED: 'Facility has been activated successfully',
    FACILITY_MODIFIED: 'Facility has been modified successfully',
    FACILITY_ADDED: 'Facility has been created successfully',
    USER_ADDED: 'User has been created successfully',
    USER_DELETED: 'User has been deleted successfully',
    USER_MODIFIED: 'User has been modified successfully',
    USER_ENABLED: 'User has been enabled successfully',
    USER_DISABLED: 'User has been disabled successfully',
    USER_COPIED: 'User has been copied successfully',
    USER_DEACTIVATED: 'User has been deactivated successfully',
    USER_ACTIVATED: 'User has been activated successfully',
    POLICY_CREATED: 'Policy has been created successfully',
    AUDIT_POLICY_SAVED: 'Audit policy has been saved successfully',
    AUDIT_POLICY_MODIFIED: 'Audit policy has been modified successfully',
    HEALTHSYSTEM_CREATED: 'Health System has been created successfully',
    HEALTHSYSTEM_MODIFIED: 'Health System has been modified successfully',
    LOCATION_CREATED: 'Facility has been created successfully',
    LOCATION_MODIFIED: 'Facility has been modified successfully',
    RESET_PASSWORD: 'A Reset password link has been sent to the email address specified',
    FACILITY_LINKED: 'External facility linked successfully',
    FACILITY_ALREADY_CHILD: 'Applied facility is already child of specified Health system',
    TOKEN_COPY_SUCCESSFULL: 'Token copied to clipboard successfully',
    UNIT_CREATED: 'Unit has been created successfully',
    UNIT_MODIFIED: 'Unit has been modified successfully',
    CLABSI_SAVED: 'CLABSI Rates details saved successfully',
    PASSWORD_SET: 'Password has been updated successfully'
  },
  INFO: {
    HEADER: {
      WARNING_MESSAGE: 'Warning Message',
      REPORT_NOT_FOUND: 'Report is not found',
      NO_DATA_FOUND: 'No data',
      WANT_TO_CONTINUE: 'Are you sure want to continue?',
      TOKEN_INVALID: 'Invalid Token',
      TOKEN_EXPIRED: 'Token Expired',
      SESSION_TIMEOUT: 'Session Timeout',
      NOT_AUTHORISED: 'Not Authorised',
    },
    REPORT_NOT_FOUND: 'Sorry, selected report format is not found',
    NO_DATA_FOUND: 'No data found for given criteria',
    WANT_TO_CONTINUE: 'You might forget to add/update unit name, please click on add/update else change will not take effect',
    WANT_TO_DELETE: 'Are you sure that you want to delete this record?',
    NO_UNITS: 'Are you sure that you want to create facility without units?',
    SELECT_FACILITY: 'Please select a facility.',
    WANT_TO_DEACTIVATE_FACILITY: 'Are you sure that you want to deactivate this facility?',
    WANT_TO_ACTIVATE_FACILITY: 'Are you sure that you want to activate this facility?',
    WANT_TO_DEACTIVATE_USER: 'Are you sure that you want to deactivate this user?',
    WANT_TO_ACTIVATE_USER: 'Are you sure that you want to activate this user?',
    TOKEN_INVALID: 'Token is invalid, please coordinate with admin ',
    TOKEN_EXPIRED: 'Token is expired, please coordinate with admin ',
    SESSION_TIMEOUT: 'You have been automatically logged out due to inactivity.',
    NOT_AUTHORISED: 'You are not authorised to access further',
    NO_PERMISSIONS: 'Your account does not appear to have any permissions, contact an admin',
    EXPORT_FOR_MORE:'Please export to see entire date range',
    MOBILE_LOGIN:'Please login from mobile device',
    NO_LOCATION_EXIST:'There are no facilities or audits to show at this time, please setup a facility and audit policy, and perform your first audit'
  },
  PRINTED_FORM: {
    SINGLE_IV_CATHETER_LABEL: 'For patients with one IV catheter:',
    SINGLE_IV_CATHETER_INSTRUCTION: 'Assess and record all metrics on one row.',
    MULTIPLE_IV_CATHETER_LABEL: 'For patients with multiple IV catheters:',
    MULTIPLE_IV_CATHETER_INSTRUCTION: 'Assess all disinfecting port protectors and tubing and record results on one row of form.  Assess and record results for first IV catheter on same row. Assess and record results for additional IV catheters on separate rows using same item # as first row for that patient.'
  }
};
