/**
 * @deprecated should be environment configurable API url
 */
export class WebserviceUrl {
  // public static readonly WEBSERVICE_URL = 'https://nl-peak-new-server.herokuapp.com/';
  //public static readonly WEBSERVICE_URL = 'https://nl-peak-server-internal-qa.herokuapp.com/';
  // public static readonly WEBSERVICE_URL = 'https://nl-peak-server.herokuapp.com/';
  // public static readonly WEBSERVICE_URL = 'https://nl-peak-staging-server.herokuapp.com/';
  //public static readonly WEBSERVICE_URL = 'https://3mpeakapi.azurewebsites.net/vascular/';
  //public static readonly WEBSERVICE_URL = 'https://3mpeakstage.azurewebsites.net/vascular/';
  public static readonly WEBSERVICE_URL = 'https://peakqa.3m.com/vascular/';

  // use this one for 3m deployments where webapp and API server use the same domain.
  //public static readonly WEBSERVICE_URL = window.location.origin + '/vascular/';
  //public static readonly WEBSERVICE_URL = window.location.origin;
}
