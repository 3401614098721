import {Injectable} from '@angular/core';

import 'rxjs/Rx';
import {Observable} from 'rxjs';
import {ExcelService} from './excel.service';
import {PdfService} from './pdf.service';
import {PptService} from './ppt.service';
import {ReportContext} from './report.context';
import { ReportType } from '../../constants/reports';
import { TimeShift } from '../../model/time.shift';
import { ReportDataService } from '../../api/report.data.service';
import * as moment from 'moment';
import {NotificationService} from '../../../shared/service/notification.service';
import { Policy } from '../../model/policy';
import { QuestionAnswer } from '../../model/question-answer';

export enum ExportFormat {
    PPT, PDF, EXCEL
}
interface PolicyResponse {
    question: string;
    answer: string[];
}
@Injectable()
export class ReportService {

    constructor(
        private excelService: ExcelService,
        private pdfService: PdfService,
        private pptService: PptService,
        private reportDataService: ReportDataService,
        private notificationService: NotificationService,
    ) {}

    /**
     * The main method for exporting reports.
     * @param reportContext
     * @param {ExportFormat} format
     * @returns {Observable<any>}
     */
    exportReport(reportContext: ReportContext, format: ExportFormat): void {
        console.assert(reportContext != null, 'ReportService#exportReport reportContext was null');
        console.assert(reportContext.facility != null, 'ReportService#exportReport reportContext.facility was null');
        console.assert(reportContext.reportType != null, 'ReportService#exportReport reportContext.reportType was null');
        
        const reportTitle: string = reportContext.reportView != null ? ` - ${reportContext.reportView.title}` : 'Report';
        const fileName = `${reportContext.facility.name} - ${reportContext.reportType.title} - ${reportTitle}`;

        switch (format) {
            case ExportFormat.PPT:
                this.pptService.exportReport(reportContext, fileName);
                break;
            case ExportFormat.EXCEL:
                this.excelService.exportReport(reportContext, fileName);
                break;
            default:
                throw new Error('Not implemented report export for format: ' + format);
        }
    }

    exportAllReports(reportContext: ReportContext, format: ExportFormat, questionResponse: Array<PolicyResponse>, selectedPolicy: Policy, healthSystem?: string): void {
        console.assert(reportContext != null, 'ReportService#exportReport reportContext was null');
        console.assert(reportContext.facility != null, 'ReportService#exportReport reportContext.facility was null');
        console.assert(reportContext.reportType != null, 'ReportService#exportReport reportContext.reportType was null');
        
        const fileName = `${(healthSystem != undefined )? healthSystem:reportContext.facility.name} - ${reportContext.reportType.title} - All`;
        console.log(fileName);
        switch (format) {
            case ExportFormat.PPT:
                console.log("hs:"+healthSystem);
                this.pptService.exportAllReports(reportContext, fileName, questionResponse, selectedPolicy, healthSystem);
                break;
            default:
                throw new Error('Not implemented exportAllReports for format: ' + format);
        }
    }
    exportAllShiftReports(reportContextDay: ReportContext, reportContextNight: ReportContext,reportContextBoth: ReportContext,format: ExportFormat, questionResponse: Array<PolicyResponse>, selectedPolicy: Policy, healthSystem?: string): void {
        console.assert(reportContextDay != null, 'ReportService#exportReport reportContext was null');
        console.assert(reportContextDay.facility != null, 'ReportService#exportReport reportContext.facility was null');
        console.assert(reportContextDay.reportType != null, 'ReportService#exportReport reportContext.reportType was null');
        
        const fileName = `${reportContextDay.facility.name} - ${reportContextDay.reportType.title} - All`;

        switch (format) {
            case ExportFormat.PPT:
                if(healthSystem == undefined){
                   // this.pptService.exportAllShiftReports(reportContextDay,reportContextNight,reportContextBoth, fileName, questionResponse, selectedPolicy, healthSystem);
                   this.pptService.exportAllShiftReports(reportContextDay,reportContextNight,reportContextBoth, fileName, questionResponse ,selectedPolicy);
                }else{
                   // this.pptService.exportAllShiftReports(reportContextDay,reportContextNight,reportContextBoth, fileName, questionResponse ,selectedPolicy);
                    this.pptService.exportAllShiftReports(reportContextDay,reportContextNight,reportContextBoth, fileName, questionResponse, selectedPolicy, healthSystem);
                }
                
                break;
            default:
                throw new Error('Not implemented exportAllReports for format: ' + format);
        }
    }

    exportRawReport(policyId: number, timeShift: TimeShift, fromDate: Date, toDate: Date, reportType: ReportType, format: ExportFormat,isSelectedForHealthSystem :boolean, healthSystem?:string): void {
        this.reportDataService
        .reportRawData(policyId, timeShift, fromDate, toDate, reportType.rawDataKey,isSelectedForHealthSystem)
        .subscribe(rawReports => {

            if(rawReports == [] || rawReports.length < 1){
                window.setTimeout(() => this.exportRawReport(policyId, timeShift, fromDate, toDate, reportType, format, isSelectedForHealthSystem, healthSystem), 5000);
                //setInterval(function () {this.exportRawReport(policyId, timeShift, fromDate, toDate, reportType, format, isSelectedForHealthSystem, healthSystem)}, 5000);
            }
            const facilityName = rawReports.length > 0 ? rawReports[0].facilityName : 'Facility';            
            const fileName = `${(isSelectedForHealthSystem == true)?healthSystem:facilityName} - ${moment(fromDate).format('YYYY-MM-DD')} to ${moment(toDate).format('YYYY-MM-DD')}`;
            switch (format) {
                case ExportFormat.EXCEL:
                    if(isSelectedForHealthSystem == true){
                        this.excelService.exportRawData(rawReports, fileName, healthSystem);
                    }else{
                        this.excelService.exportRawData(rawReports, fileName);
                    }
                    
                    break;
                default:
                    throw new Error('Not implemented raw report export for format: ' + format);
            }
        },
        (error) => {
            console.log('raw report error', error);
        });
    }
    exportAnalytic(): void {
        this.reportDataService
        .reportAnalyticData()        
        .subscribe(rawAnalytics => {
                    this.excelService.exportRawData(rawAnalytics, "AnalyticData_"+moment().format('YYYY-MM-DD'));
            
        },
        (error) => {
            console.log('Analytic error', error);
        });
    }
    exportFilterAnalytic(fromDate: Date, toDate: Date): void {
        this.reportDataService     
        .filterReportAnalyticData(fromDate, toDate)
        .subscribe(rawAnalytics => {
                    //if(rawAnalytics.length == 0)
                        //this.notificationService.error("","No any data found for selected date range. Please select different date range.")
                    this.excelService.exportRawData(rawAnalytics, "AnalyticData_"+moment().format('YYYY-MM-DD'));
            
        },
        (error) => {
            console.log('Analytic error', error);
            this.notificationService.error("","Oops, Some thing went wrong.");
                    
        });
    }
}
