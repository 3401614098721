import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {WebserviceUrl} from "../../service/webservice-url";
import {TokenService} from "../../service/token.service";

const CONTENT_TYPE_JSON: string = 'application/json';
const AUTH_TOKEN_HEADER_NAME: string = 'X-AUTH-TOKEN';

/**
 * Base class for any service which is going to interact with backend API.
 * @version 1.0
 * @author Aleksey K
 */
export abstract class ApiService {

    protected readonly basePath: string;

    protected constructor(protected http: HttpClient,
                          protected tokenService: TokenService,
                          basePath: string) {
        this.basePath = WebserviceUrl.WEBSERVICE_URL + basePath;
    }

    protected get<T>(path: string = '', params?: HttpParams, headers?: HttpHeaders): Observable<T> {
        return this.http.get<T>(this.fullPath(path), {params: params, headers: headers || this.commonHeaders()});
    }

    protected post<T>(path: string = '', body: any, headers?: HttpHeaders): Observable<T> {
        return this.http.post<T>(this.fullPath(path), body, {headers: headers || this.commonHeaders()});
    }

    protected put<T>(path: string, obj: any, headers?: HttpHeaders): Observable<T> {
        return this.http.put<T>(this.fullPath(path), obj, {headers: headers || this.commonHeaders()})
    }

    protected delete<T>(path: string, headers?: HttpHeaders): Observable<T> {
        return this.http.delete<T>(this.fullPath(path), {headers: headers || this.commonHeaders()})
    }

    private fullPath(relPath: string): string {
        return `${this.basePath}${relPath}`;
    }

    //protected commonHeaders(): HttpHeaders {
    //    let headers = new HttpHeaders().set('Content-Type', "text/plain");
       /* if (this.tokenService.get()) {
            return headers.set(AUTH_TOKEN_HEADER_NAME, this.tokenService.get());
        }
        */
    //    return headers;
    // }
    protected commonHeaders(): HttpHeaders {

        let headers = new HttpHeaders({
        'X-Auth-TOKEN': this.tokenService.get(),       
        'JWT_AUTH_TOKEN': this.tokenService.getJwtCodeToken(),
        'Content-Type': 'text/plain',
        'Cache-Control':  'no-cache, no-store, must-revalidate, post-check=0, pre-check=0'
       
        });       
        //let headers = new HttpHeaders({"x-auth-token": this.tokenService.get()}, {'Content-Type': "text/plain"});   
   
        console.log(headers);     
       
        
        if (this.tokenService.get()) {       
        //headers.append(AUTH_TOKEN_HEADER_NAME, this.tokenService.get());
        }     
              
        return headers;
       
    }
    
    



}