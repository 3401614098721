import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {DataService} from '../../shared/service/data.service';
import {NotificationService} from '../../shared/service/notification.service';
import {CommonEvent, MESSAGES, Route} from '../../shared/constants';
import {Util} from '../../util/util';
import {TokenService} from '../../shared/service/token.service';
import * as html2canvas from "html2canvas"

// TODO: NL - general clean up of this file, once we're able to test it a bit.

const specialElementHandlers = {
  '#editor': function (element, renderer) {
    return true;
  }
};

declare const pdfMake: any;

@Component({
  templateUrl: 'policy-review.component.html',
})

export class PolicyReviewComponent implements OnInit {
  public spinner: Boolean = false;
  public policy: any [];
  public routes = Route;
  public auditPolicyValue: any;
  public auditData: any = [];
  public auditPolicy: any;
  public questionDependency: Array<any>;
  public pritPolicy: Boolean = false;
  public printPaperAudit: Boolean = false;
  public policyType: string;
  public policyName: any;
  public policyTypeId: any;
  public policyId :any;
  public facilityPolicyJsonId: any;
  public auditQuestions: any;
  public questions: any;
  public text_align = 'center';
  constructor(private router: Router,
              private tokenService: TokenService,
              private dataService: DataService,
              private notificationService: NotificationService) {

    this.auditPolicyValue = {};
    this.questionDependency = [];

  }

  public ngOnInit() {
    this.spinner = false;
    const routeUrl = (this.router.url).split('/');
    const action = routeUrl[routeUrl.length - 1];
    if (action === CommonEvent.print || action === CommonEvent.archive) {
      this.pritPolicy = true;
      const policyData = this.dataService.policy;
      this.policyType = policyData.policyType ? policyData.policyType : policyData.policyName;
      this.policyTypeId = policyData.policyTypeId;

      this.createFormattedPolicyJSON(JSON.parse(policyData.auditPolicyJson));
      this.getPolicyInputs();
    } else if (action === CommonEvent.printPaperAudit) {
      this.printPaperAudit = true;
      this.getPolicyQuestions();
    } else {
      let policyDetails = this.dataService.policyDetailsForReview;
      if (Util.isDefinedAndNotNull(policyDetails)) {
        this.auditPolicyValue = policyDetails.auditPolicyValue;
        console.log(this.auditPolicyValue);
        this.policyName = policyDetails.policyType;
        this.policyTypeId = policyDetails.policyTypeId;
        this.facilityPolicyJsonId = policyDetails.facilityPolicyJsonId;
        this.policyType = policyDetails.policyType;
        this.getPolicyInputs();
      } else {
        this.router.navigate([Route.POLICY]);
      }
    }
  }

  public getPolicyInputs() {
    this.spinner = true;
    if (Util.isDefinedAndNotNull(this.policyTypeId)) {
      //TODO(refactoring): it should get policy questions for setup
     /* this.policyAddService.getAuditPolicyInputsByFacilityId(this.policyTypeId).subscribe(auditPolicy => {
        this.spinner = false;
        this.auditPolicy = auditPolicy.policyQues;
        this.questionDependency = auditPolicy.dependancies as any[];
      }, err => {
        this.spinner = false;
        this.errorService.handleErrorStatusCode(err);
      });*/
    }else{
      this.spinner=false;
    }
  }


  public removeChildObjects(questionObj) {
    if (Util.isDefinedAndNotNull(questionObj)) {
      if (Util.isDefinedAndNotNull(questionObj.childQue) && questionObj.childQue.length > 0) {
        for (let i = 0, iLen = questionObj.childQue.length; i < iLen; i++) {
          if (Util.isDefinedAndNotNull(this.auditPolicyValue[questionObj.childQue[i].questionId])) {
            delete this.auditPolicyValue[questionObj.childQue[i].questionId];
          }
          if (Util.isDefinedAndNotNull(questionObj.childQue[i].childQue) && questionObj.childQue[i].childQue.length > 0) {
            this.removeChildObjects(questionObj.childQue[i]);
          }
        }
      }
    }
  }

  public isDisplay(questionObj) {
    let isQuestionDependent = false;
    let questionDependentObj = null;
    for (let i = 0, iLen = this.questionDependency.length; i < iLen; i++) {
      if (this.questionDependency[i].questionId === questionObj.questionId) {
        isQuestionDependent = true;
        questionDependentObj = this.questionDependency[i];
        break;
      }
    }

    if (isQuestionDependent) {
      if (Util.isDefinedAndNotNull(this.auditPolicyValue[questionDependentObj.dependantQueId])) {
        if (this.auditPolicyValue[questionDependentObj.dependantQueId] == questionDependentObj.value) {
          return true;
        } else {
          this.removeChildObjects(questionObj);
          return false;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  public saveAuditPolicy() {
    this.spinner = true;
    //TODO(refactoring): Use PolicyService#updateEntity
    /*this.policyAddService.saveAuditPolicy(this.policy).subscribe(data => {
      this.spinner = false;
      this.notificationService.success(MESSAGES.SUCCESS.HEADER.POLICY_CREATED, data.auditPolicyName + MESSAGES.SUCCESS.POLICY_CREATED);
      this.router.navigate([Route.POLICY]);
    }, err => {
      this.spinner = false;
      this.errorService.handleErrorStatusCode(err);
    });*/
  }

  public onClickSave() {
    this.spinner = true;
    for (const key in this.auditPolicyValue) {
      if (this.auditPolicyValue.hasOwnProperty) {
        const element = this.auditPolicyValue[key];
        if (Util.isDefinedAndNotNull(element)) {
          const auditObj: any = {};
          const loggedUser = this.tokenService.getUserToken();

          auditObj.policyCreatedByUserId = loggedUser.id;
          // if (this.addPolicy) {
          /*if (this.dataService.defaultFacility) {
              auditObj.facilityId = this.dataService.defaultFacility.id;
            } else {
              this.notificationService.error(MESSAGES.ERROR.HEADER.ERROR_MESSAGE, MESSAGES.ERROR.NO_FACILITY_SELECTED);
              this.spinner = false;
              this.router.navigate([Route.POLICY]);
           }*/
          /*TODO: Facility Id should be reflected over here*/
            console.log(this.dataService.defaultLocation);
            if (Util.isDefinedAndNotNull(this.dataService.defaultLocation)) {
              auditObj.facilityId = this.dataService.defaultLocation.id;
            } else {
              auditObj.facilityId = 0;
            }
          auditObj.facilityAuditPolicyJSONId = this.facilityPolicyJsonId ? this.facilityPolicyJsonId : 0;
          auditObj.policyName = this.policyName;
          auditObj.auditPolicyType = this.policyTypeId;

          if (Util.isBoolean(element)) {
            if (element) {
              auditObj.questionId = key;
              auditObj.answerValue = element;
              this.auditData.push(auditObj);
            }
          } else if (Util.isString(element)) {
            if (!Util.isEmptyString(element)) {
              auditObj.questionId = key;
              auditObj.answerValue = element;
              this.auditData.push(auditObj);
            }
          } else if (Array.isArray(element)) {
            if (!Util.isEmptyArray(element)) {
              auditObj.questionId = key;
              auditObj.answerValue = element;
              this.auditData.push(auditObj);
            }
          } else {
            auditObj.questionId = key;
            auditObj.answerValue = element;
            this.auditData.push(auditObj);
          }
        }
      }
    }

    if (this.auditData.length !== 0) {
      this.spinner = true;
      //TODO(refactoring): use PolicyService#updateEntity
        /*this.policyAddService.saveAuditPolicy(this.auditData).subscribe(auditPolicy => {
          this.spinner = false;
          this.notificationService.success(MESSAGES.SUCCESS.HEADER.AUDIT_POLICY_SAVED, auditPolicy.auditPolicyName + MESSAGES.SUCCESS.AUDIT_POLICY_SAVED);
          this.dataService.policyDetailsForReview = {};
          this.router.navigate([Route.POLICY]);
        }, err => {
          this.spinner = false;
          this.errorService.handleErrorStatusCode(err);
        });*/
    } else {
      this.spinner = false;
      this.notificationService.error(MESSAGES.ERROR.HEADER.NO_INPUT_SELECTED, MESSAGES.ERROR.NO_INPUT_SELECTED);
    }

  }

  public isShow(question) {
    if (Util.isDefinedAndNotNull(this.auditPolicyValue[question.questionId])) {
      return true;
    } else {
      let isShow: boolean = false;
      for (let i = 0, iLen = question.childQue.length; i < iLen; i++) {
        if (Util.isDefinedAndNotNull(this.auditPolicyValue[question.childQue[i].questionId])) {
          isShow = true;
          break;
        }
      }
      return isShow;
    }
  }

  /*TODO: PRINT functionality is in progress*/
  public printPolicy() {
    // TODO: NL - type definitions say this key doesn't exist on the interface the html2canvas library expects, this code may not be working at all, or the typedefs may not match the installed version
    const obj: any = {
      onrendered: function (canvas) {
        const data = canvas.toDataURL();
        const docDefinition = {
          content: [{
            image: data,
            width: 500,
          }]
        };
        pdfMake.createPdf(docDefinition).download("policy.pdf");
      }
    };
    html2canvas(document.getElementById('policyReviewPrint'), obj);
  }

  /*public printPolicy() {
   /!*Print normal format*!/
    //   doc.fromHTML($('#policyReviewPrint').html(), 15, 15, {
    //     'width': 170,
    //     'elementHandlers': specialElementHandlers
    //   });
    //   doc.save('sample-file.pdf');
    // }

   /!*Print image format*!/
    var obj = {
      onrendered: function (canvas) {
        var imgData = canvas.toDataURL(
          'image/png');
        var doc = new jsPDF('p', 'mm');
        doc.addImage(imgData, 'PNG', -70, 0);
        doc.save('policy.pdf');
      }
    }
    html2canvas(document.getElementById("policyReviewPrint"), obj);
   }*/

  public createFormattedPolicyJSON(policyJSON: any) {
    for (const key in policyJSON) {
      const element = policyJSON[key];
      if (element) {
        this.auditPolicyValue[element.questionId] = element.answerValue;
      }
    }
  }

  public printsPolicy(divId: any) {

    const divToPrint = document.getElementById(divId);

    const newWin = window.open();

    const printedFormHtml = '<html><head><style type="text/css" media="print"> @page { size: landscape; } @-moz-document url-prefix() { body { margin-top: 30%; margin-left: 0%; -moz-transform:rotate(-90deg); } } body { filter: progid:DXImageTransform.Microsoft.BasicImage(Rotation=3); } .selector:not(*:root), .logo-print{ margin-top: 3%; list-style-position: inside; } </style><style>.clearfix{clear:both;}.prt-lft{float: left;width: 33.33333%;}.text-center{text-aligh:center;}table{border-collapse: collapse;}#active td{border: 1px solid #ccc;padding-bottom:20px;}td.hide-next + td{border-left: none !important;}td.hide-next{border-right: none !important;}#active td {border: 1px solid #000;}#active tr th {border: 1px solid #000;}.prnt-wdth {width: 25% !important;float:left;margin-bottom:10px;}#policyQuestionsPrint #print-logo{float: left;}</style></head><body>' + divToPrint.innerHTML + '</body></html>'

    newWin.document.write(printedFormHtml);
    console.log(printedFormHtml);
    newWin.document.close();
    newWin.focus();
    newWin.print();

    // This delay allows the logo image time to load
    setTimeout(() => {
      newWin.close();
    }, 10);

  }

  public getPolicyQuestions() {

    let facility = this.dataService.defaultLocation;
    if (Util.isDefinedAndNotNull(facility)) {
      //TODO(refactoring): rework it according to new policy data model
     /* this.policyListService.auditQuestion(facility.id).subscribe(policyQuestions => {
        this.auditQuestions = policyQuestions.auditDTOs;
        /!*this is to move first object (Comments) to last index*!/
        this.auditQuestions[0].auditQuestionGroupDTOs.push(this.auditQuestions[0].auditQuestionGroupDTOs.shift());
        this.questions = this.auditQuestions[0].auditQuestionGroupDTOs;
        const name = policyQuestions.policyName;
        if (typeof name === 'string') {
          this.policyType = name;
        } else {
          console.assert(false, 'PolicyReviewComponent#getPolicyQuestions - unexpected response formatting for policyName')
        }
        const typeId = policyQuestions.auditTypeId;
        if (typeof typeId === 'number') {
          this.policyId = typeId;
        } else {
          console.assert(false, 'PolicyReviewComponent#getPolicyQuestions - unexpected response formatting for auditTypeId')
        }
      }, err => {
        this.spinner = false;
        this.errorService.handleErrorStatusCode(err);
      });*/
    }
  }

public back(){
  this.router.navigate([Route.POLICY]);
}
}
